import {
	GradientText,
	SubText,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import CommandeFormReadOnly from '../../Components/CommandeFormReadOnly'
import Container from '../../Components/LayoutClient/container.component'
import TransportTracking from '../../Components/TransportTracking/TransportTracking'
import { getCommande } from '../../api/commande'
import { useParams,useHistory  } from 'react-router'
import { useSelector } from 'react-redux'
import Logo from './../../assets/img/logo-TPE.png'
function DetailsCommande(props) {
	const { id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isOrderCanceled, setIsOrderCanceled ] = useState(false)
	const [commande, setCommande] = useState(null)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const history= useHistory()
	useEffect(() => {
		async function _getCommande(id) {
			setChargementEnCours(true)
			const commande = await getCommande(id,token)
			setCommande(commande)
			setChargementEnCours(false)

		}

		if (id) {
			_getCommande(id)
		}
	}, [id])
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const success = params.get('success');
		console.log({document})
		if (success === 'true') {
			setModalIsOpen(true)
			setIsOrderCanceled(false)
		}
		if (success === 'false') {
			setModalIsOpen(true)
			setIsOrderCanceled(true)
		}
	},[])
	return (
		<Container>
			<Wrapper>
			<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
                                history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
								marginTop:'1rem'
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								{...props}
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText  marginTop={'unset'}>{`${'Détail COMMANDE'.toUpperCase()} ${!!commande ? ' N°' + commande.__id : '' }`}</GradientText>
					</div>
					<SubText>
					Ici retrouver le détail complet du transport à effectuer
				</SubText>
				{chargementEnCours ?
                 <div
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   backgroundColor: 'white',
                   marginTop:"10rem"
                 }}
                 >
                 <div className='customLoader'></div>	
                 </div>
				 :
                 <>
				{/* <div
					style={{
						width: '65%',
					}}
				>
					
					{commande?.type_commande === 'mise-a-disposition' && (
						<TransportTracking commande={commande} setCommande={setCommande} display hideForfait hideForfaits />
					)}
					
				</div> */}

				{commande && (
					<CommandeFormReadOnly
						hideSubmit
						disabled
						onSubmit={() => {}}
						preloadForm={true}
						preloadData={commande}
					/>
				)}
					</>
			   }   
			</Wrapper>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
					<img
					style={{
						width: '10rem',
						backgroundColor: 'grey',
						padding: '2px'
					}}
					alt='logo'
					src={Logo}
				/>
						<h1>{isOrderCanceled ? "Erreur de paiement": "paiement effectué avec succès"}</h1>
					</div>

					<button
						onClick={() => setModalIsOpen(false)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</Container>
	)
}

export default DetailsCommande

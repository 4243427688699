import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'
import { getSingleClient,updateClient } from '../../api/clients'
import { useSelector } from 'react-redux'

import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import { PUBLIC } from '../../api/base'
import UploadFile from '../../Components/UploadSingleFile/upload.component'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { useHistory,useParams } from 'react-router'
function ClientAccountForCommercial() {
  const {id}=useParams()
	const history=useHistory()

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm()
  const [client, setClient] = useState()
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user) 
  const [chargementEnCours, setChargementEnCours] = useState(false);
  const [loading,setLoading]=useState(false)

 useEffect(() => {
   async function fetchClient(id) {
	setChargementEnCours(true)
     const data = await getSingleClient(id,token)

     delete data.createdAt
     delete data.epi
     delete data.manutention
     delete data.premium
     delete data.role
     delete data.status
     delete data.suspendre
     delete data.updatedAt
     delete data.vehicules
     delete data.verification
     delete data.verified
     setClient(data)
     reset(data)
	 setChargementEnCours(false)
   }

   if (id) {
     fetchClient(id)
   }
 }, [id])
  const logo = useRef()
	logo.current = watch('logo', null)

	const kbis = useRef()
	kbis.current = watch('kbis', null)
  const onError = async (errors) => {
		if (errors.password) {
			toast('Mot de pass doit être de 8 caractères minimum', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
	}

	const onSubmit = async (values) => {
		setLoading(true)
		values.logo = logo.current?.id
		values.kbis = kbis.current?.id
		
        delete values.password
		delete values._id
		delete values.verified
		delete values.verification
		delete values.updatedAt
		delete values.logo
		delete values.kbis
		delete values.createdAt
		delete values.email
		delete values.suspendre
		delete values.status
        delete values.city
        delete values.address
        delete values.phone
        delete values.postalCode
		
		
		
	

    if(values.reduction && values.reduction>user.reduction){
			toast.error(
				`La réduction ne peut pas dépasser ${user.reduction}`,
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
			return;
		  }

		
		try {
			
      await updateClient(id, values,token)
	  toast.success('Votre compte du client  a été mis à jour', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
      history.goBack()
				
	} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		} finally{
			setLoading(false)
		}
	}
  return (
    
  <LayoutCommercant>
    	<Wrapper>
				<GradientText>{`COMPTE CLIENT`}</GradientText>
			</Wrapper>
			{chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:"10rem"
				}}
				>
				<div className='customLoader'></div>	
				</div>
				:
				
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						width: '60%',
						fontFamily: 'Montserrat',
						alignSelf: 'center',
					}}
				>
				
					<form onSubmit={handleSubmit(onSubmit, onError)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Wayne'
										{...register('societe', {
											required: true,
										})}
									/>
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										SIRET
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='833 543 509 000'
										{...register('siret', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
							            disabled
										type='text'
										placeholder='Doe'
										{...register('last_name', {
											required: true,
										})}
									/>
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										placeholder='John'
										disabled
										{...register('first_name', {
											required: true,
										})}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité
									</span>
									<CustomInput
                     disabled
										type='text'
										placeholder='Directeur d’exploitation'
										{...register('quality', {
											required: 'Veuillez indiquer la qualité du poste.',
											minLength: {
												value: 3,
												message: 'La qualité doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'La qualité doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.quality && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                      {errors.quality.message}
                                    </div>
                                     )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Domaine d’activité
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Transport frigorifique'
										{...register('domain', {
											required: true,
										})}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
                  disabled
									type='text'
									placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
									{...register('address', {
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^[A-Za-z0-9\s',-]+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
									})}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
										type='text'
                    disabled
										placeholder='(0) 01 45 66 32 88'
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                               )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='test@gmail.com'
										{...register('email', {
											required: true,
										})}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											 Réduction en %
										</span>
									</div>
									<CustomInput
									type='number'
									min={0}
									max={100}
									{...register('reduction', {})}
								/>
								</div>

								 
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
									width: '50%',
									fontFamily: 'Montserrat',
									//marginLeft: 10,
									marginTop: 10,
								}}
							>
								{logo.current &&
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
										<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Logo(PNG ou JPG)
									</span>
								</div>
                                }
								{logo.current && (
									<div>
									
										<img
											alt=''
											style={{
												width: 100,
												height: 100,
												borderRadius: 5,
												objectFit: 'cover',
											}}
											src={`${PUBLIC}/${logo.current?.name}`}
										/>
									</div>
								)}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '50%',
									fontFamily: 'Montserrat',
									//marginLeft: 10,
									marginTop: 10,
								}}
							>
								{kbis?.current &&
								kbis?.current?.mimetype !==
									'application/pdf' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										KBIS (PDF ou JPG)
									</span>
									
								</div>
									)
                                }
								{kbis?.current &&
								kbis?.current?.mimetype !==
									'application/pdf' && (
									<img
										alt=''
										style={{
											width: 100,
											height: 100,
											borderRadius: 5,
											objectFit: 'cover',
										}}
										src={`${PUBLIC}/${kbis?.current?.name}`}
									/>
								)
								}
                                {kbis?.current &&
								kbis?.current?.mimetype ==
									'application/pdf' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<img
											alt=''
											src={File}
											style={{
												height: 20,
												marginRight: '1rem',
											}}
										/>
										<span
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${PUBLIC}/${kbis?.current?.name}`
												)
											}}
										>
											{kbis?.current?.name}
										</span>
									</div>
								)}
							</div>
						</FormSectionCard>
						<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
							<SubmitButton type='submit'>
								{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					
             
					</form>
				</div>
			</div>
           }
  </LayoutCommercant>
  )
}

export default ClientAccountForCommercial
import React, { useEffect, useState } from 'react'

import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import Modal from 'react-modal'
import { getChauffeursByTransporter } from '../../api/chauffeurs'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')


function ListChauffeurs() {
	const history = useHistory()
	const [chauffeurs, setChauffeurs] = useState([])
	const [page, setPage] = useState(1)
	const [total, setTotal] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(10) 
    const [chargementEnCours,setChargementEnCours]=useState(false)
	const transporter = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);
    
	async function fetchChauffeurs(page,size) {
		setChargementEnCours(true)
		try {
			const res = await getChauffeursByTransporter(
				transporter._id,
				page,
				size,
				token
			)
			setChauffeurs(res.docs)
			setTotal(res.totalPages)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
         setChargementEnCours(false)
		}
		
	}
	const handlePageChange = (page) => {
        setPage(page);
        fetchChauffeurs(page, rowsPerPage);
	}

    const handlePerRowsChange = async (newPerPage, page) => {
        setRowsPerPage(newPerPage);
        fetchChauffeurs(page, newPerPage);
    };


	useEffect(() => {
		fetchChauffeurs()
	}, [page])

	const columns = [
        {
            name: "ID",
            selector: (row) => row.__id,
        },
        {
            name: "Nom  du  chauffeur",
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,        
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:'250px'
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={async () => {
							history.push(
								`/chauffeurs/${row._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
					
					
					
					
				</div>
				
									<div
										onClick={async () => {
											history.push(
												`/chauffeurs/${row._id}/edit`
											)
										}}
										style={{
											cursor: 'pointer',
										}}
									>
										<div
											style={{
												height: 24,
												width: 24,
												color: '#51C7F2',
											}}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 20 20'
												fill='currentColor'
											>
												<path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z' />
												<path
													fillRule='evenodd'
													d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z'
													clipRule='evenodd'
												/>
											</svg>
										</div>
									</div>
						           
				    
					
					
					
				</div>
			),
		},
    ];

	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '1rem',
						}}
					>

						<GradientText marginTop={'unset'}>
							{'Mes chauffeurs'.toUpperCase()}
						</GradientText>
                        
						<div
							style={{
								width: '1rem',
							}}
						/>
						<div
							onClick={() => history.push('/chauffeurs/new')}
							style={{
								background: '#51C7F2',
								padding: '1rem 2rem',
								borderRadius: 5,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<span
								style={{
									color: 'white',
									fontSize: '1rem',
									fontWeight: 'bold',
								}}
							>
								Ajouter
							</span>
						</div>
                  
					</div>
				{chargementEnCours ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    <div className='customLoader'></div>
                </div>
				:
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						height: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '4rem',
					}}
				>
					
					<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={chauffeurs}
						progressPending={chargementEnCours}
                        pagination
                        paginationServer
                        paginationTotalRows={total}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        noDataComponent="Il n'y a pas des signalements à afficher"
                        progressComponent='Chargement'
                        paginationComponentOptions={{
                            rowsPerPageText: 'Rangs par page',
                            rangeSeparatorText: 'sur',
                                }}
							
					/>
				</div>

					
				</div>
				}

			</div>
		</LayoutTransporter>
	)
}

export default ListChauffeurs

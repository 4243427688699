import React from 'react'
import {  loadStripe } from '@stripe/stripe-js';
import {  Elements} from '@stripe/react-stripe-js';
import CardSetupForm from './CardSetupForm';
import { Wrapper,GradientText ,FormSectionCard} from '../PasserCommande/PasserCommande.styles';
import Container from '../../Components/LayoutClient/container.component';
function CardSetupFormWrapper() {
    const stripePromise = loadStripe('pk_test_51MleobDtUrWQAc5ssxA8kPX1PLI4xbO4sqXHzd0BpyCDFgutP25UHj9QxGaZvUE4R8HZGDDtCHLqSRhguRu0tLQA00KimeCRXV');

  return (
  <Container>
   <Wrapper>
    <GradientText>
    Informations de Paiement
    </GradientText>
   </Wrapper>
   <div
		style={{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		}}
	>
        <div
			style={{
			width: '60%',
			fontFamily: 'Montserrat',
			alignSelf: 'center',
			}}
		>
      
        <Elements stripe={stripePromise}> 
        <CardSetupForm/>
        </Elements>
        
      
        </div>
    </div>
 
 </Container>  
  )
}

export default CardSetupFormWrapper
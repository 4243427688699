import api  from './base'

export const  getAllCommercant=async(page,size,token)=>{
	const  res=await api.get(`/users?filter=commercial&fields=role&page=${page}&limit=${size}&sort=name&order=-1`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export  const getValidCommerciaux=async(page, size,token)=>{
	const res = await api.get(`/users/commercial/validated/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export  const getNotValidCommerciaux=async(page, size,token)=>{
	const res = await api.get(`/users/commercial/not-validated/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export const  getCommercantbyIdAndName=async(query,token,status)=>{
  query=query.trim()
	const  res=await api.get(`/users/commercial/byIdandName/${query}/all?status=${status}&filter=notdelete`,{
		headers: { 
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const  getCommercialDeletedbyIdAndName=async(query,token)=>{
	query=query.trim()
	  const  res=await api.get(`/users/commercial/byIdandName/${query}/all?filter=delete`,{
		  headers: { 
				Authorization: `Bearer `+token
			  },
			})
	  return res.data
  }
export const getClientOfCommercant=async(id,token)=>{
	const  res=await api.get(`/users/commercant/${id}/client`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const  getClientByNameAndIdForCommercial=async(query,id,token)=>{
	const  res=await api.get(`/users/clientforcommercial/${query}/${id}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesByMonthByClientCommercial=async(id,token)=>{
	const res=await api.get(`/commandes/commercial/comptabilite/${id}/all/month`,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const  getArchivedCommercial=async(page,size,token)=>{
	const  res=await api.get(`/users/commercial/archived/all?filter=commercial&fields=role&page=${page}&limit=${size}&sort=name&order=-1`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const updateCommercial = async (data,id,token) => {
	const res = await api.patch(`/users/commercial/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const restoreDeletedCommercial = async (id,token) => {
	
	const res = await api.get(`/users/commercial/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const toggleSuspendreCommercial = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteCommercial = async (id,token) => {
	const res = await api.get(`/users/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercial = async (id,page, size,token) => {
	const res = await api.get(`/comptabilite/commercial/${id}/all?size=${size}&page=${page}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercialByMonth = async (id,page, size,token,month,year) => {
	const res = await api.get(`/comptabilite/commercial/month/${id}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercialByMonthAndName = async (id,page, size,token,month,year,query) => {
	query=query.trim() 
	const res = await api.get(`/comptabilite/commercial/month/${id}/${query}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercialByName= async (id,name,token) => {
	name=name.trim()
	const res = await api.get(`/comptabilite/commercial/${id}/${name}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getValidClientOfCommercant=async(id,token)=>{
	const  res=await api.get(`/users/commercial/${id}/valid-client`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
import api from './base'

export const createTarif = async (data,token) => {
	const res = await api.post('/tarif', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })
	return res.data
}

export const getTarif = async (type="MISEADISPO") => {
	const res = await api.get(`/tarif?type=${type}`)
	return res.data
}



export const payTarif = async (data,token) => {
	const res = await api.post('/tarif/pay', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const payTarifSEPA = async (data) => {
	const res = await api.post('/tarif/pay/sepa', data)
	return res.data
}
export const sendPaymentLinkToClient=async(id,token)=>{
	const res = await api.post(`/tarif/sendpayment/${id}`,null ,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}

import api from './base'

export const getDistanceBetweenTwoPlaces = async (origin, destination,token) => {
	const res = await api.get(
		`/distance?origin=${origin}&destination=${destination}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)

	const rows = res.data.rows

	const result = rows[0].elements[0].distance

	return result.value
}
export const getHourBetweenTwoPlaces = async (origin, destination,token) => {
	const res = await api.get(
		`/distance/hour?origin=${origin}&destination=${destination}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)

	

	return res.data
}

import api from './base'

export const getInvoice = async (type, client, commandes,token) => {
	const res = await api.post('/invoice', {
		type,
		client,
		commandes,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })

	return res.data
}

export const getInvoiceForOneCommande = async (client, commande,token) => {
	const res = await api.post('/invoice/single', {
		client,
		commande,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })

	return res.data
}
export const getInvoiceForSignalementCommande = async ( token,signalement,tarif) => {
	const res = await api.post('/invoice/signaler', {
		signalement,
		tarif
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })

	return res.data
}
export const mergeAvoirForCommande= async ( commande,token) => {
	const res = await api.post('/invoice/mergeavoir', {
		commande,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })

	return res.data
}
export const mergeInvoiceForCommande= async ( commande,token) => {
	const res = await api.post('/invoice/mergeinvoice', {
		commande,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })

	return res.data
}
export const getInvoiceMonthTransporter=async(transporteur,month,price,token)=>{
	const res = await api.post('/invoice/month-tansporter', {
		transporteur,
		month,
		price,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
	  })

	return res.data
}
export  const exportInvoices=async(commandes,token)=>{
	const res = await api.post('/invoice/export-invoice', {
		commandes
	},{
		headers: {
			  Authorization: `Bearer `+token,
		},
		responseType: 'blob' 
	  })

	return res.data
}
export  const exportInvoicesForTransporteur=async(transporteurId,token)=>{
	const res = await api.get(`/invoice/export-invoice/${transporteurId}`,{
		headers: {
			  Authorization: `Bearer `+token,
		},
		responseType: 'blob' 
	  })

	return res.data
}
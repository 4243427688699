import React ,{useEffect,useState}from 'react'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import {getRetrieveFailedPayments} from '../../api/commande'
import {sendPaymentLinkToClient} from '../../api/tarif'
import {getClientsWithFailedCharge} from '../../api/clients'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import {BASE_URL } from '../../api/base'
import { FaCheckCircle,FaRegFilePdf } from "react-icons/fa";
function FailedCharge() {
	const history = useHistory()
    const token = useSelector((state) => state.auth.token);
    const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [searchQuery, setSearchQuery] = useState('')
    const [data,setData]=useState([])
	const [selectedFailed,setSelectedFailed]=useState('')
    const fetchData = async () => {
        setChargementEnCours(true)
        const res = await getRetrieveFailedPayments(token)
        setData(res)
        setChargementEnCours(false)
    }
    useEffect(()=>{
     fetchData()
    },[])
	const searchForClient = async (q) => {
		if (q.length === 0) {
			return
		}

		setLoading(true)

		const res = await getClientsWithFailedCharge(q,token)
		setData(res)
		console.log(res)
		setLoading(false)
	}
    const columns = [
		{
			name: 'ID Client',
			selector: (row) => row.clientID.__id,
			width:'150px',
			sortable: false,
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.clientID.societe}`,
			sortable: false,
			width:'200px',
		},
		{
			name: 'Nom du Client',
			selector: (row) =>`${row.clientID.first_name} ${row.clientID.last_name}` ,
			width:'250px',
			sortable: false,
		},
		
		{
			name: 'Montant',
			selector: (row) =>`${row.compteur.toFixed(2)}€` ,
            width:'200px',
			sortable: false,
		},
        {
			name: 'Transmettre Paiement',
			button: true,
            width:'400px',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<button
					    
						onClick={ () => {
							setSelectedFailed(row._id)
							setModalIsOpen(true)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '2rem',
							background: '#ECC9F7',
							border: 'none',
							cursor: 'pointer',
							color: '#AB1CC5',
							borderRadius: 7,
							
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Transmettre  paiement

					</button>

					
				</div>
			),
		},
		{
			name: 'Email Envoyé', 
			selector: (row) =>(row?.status ? <FaCheckCircle  style={{ fontSize: "22px" ,color:'#50F5A9'}}/> : <FaCheckCircle  style={{ fontSize: "22px" ,color:'red'}}/>) ,  
			sortable: true,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
                    <div
					 onClick={()=>{
						window.open(`${BASE_URL}/${row.facture}`)


					 }}
						style={{
							cursor: 'pointer',
						}}>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'#51C7F2'}} />

					</div>
					
					<div
						onClick={()=>history.push(`/admin/failedChargeDetails/${row._id}`)}
						style={{
							cursor: 'pointer',
							marginLeft:'2px'
						}}
					>
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
				
			),
		},
		
		
		
		
	]

  return (
 <LayoutAdmin>
    <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
               <GradientText marginTop={'0'}>
					{'Prélèvements échoués'.toUpperCase()}
				</GradientText>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<input
						disabled={loading}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}	
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								searchForClient(searchQuery)

							}
							
						}}
							style={{
								padding: '1rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								width: '22rem',
								fontFamily: 'Montserrat',
							}}
							placeholder="Rechercher par l'identifiant, nom client /societe "
						/>
					<button
						onClick={() => searchForClient(searchQuery)}

							style={{
								padding: '1rem 2rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								//marginTop: 20,
								marginLeft: 20,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Rechercher
						</button>
						<button
						onClick={async () => {
							await fetchData()
							setSearchQuery('')
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>


						
					</div>
					
					
				</div> 
                <div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						noDataComponent="Il n'y a pas des prélèvements à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
            </div>
			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Transmettre Paiement</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir effectuer cette action sur ce client ?</p>
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						
					}}>
					<button
					
						onClick={async () => {	
						setLoading(true)
						try {
				        const res= await sendPaymentLinkToClient(selectedFailed,token)	
						toast.success(res.message, {
							style: {
								fontFamily: 'Montserrat',
							},
						})
						setModalIsOpen(false)
						} catch (error) {
							setModalIsOpen(false)

						
						}finally {
							setLoading(false)
							window.location.reload()

						}	
						}}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
						    justifyContent: 'center',
						    alignItems: 'center'
						}}
					>
					 {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpen(false)	
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>
 </LayoutAdmin>
  )
}

export default FailedCharge
import React ,{useEffect,useState}from 'react'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import {FailedChargeDetails} from '../../api/commande'
import { useSelector } from 'react-redux'
import { useParams,useHistory } from 'react-router'
import DataTable from 'react-data-table-component'
import { PUBLIC } from '../../api/base'
import moment from 'moment'



function SingleFailedCharge() {
    const {id}=useParams()
    const history = useHistory()
    const token = useSelector((state) => state.auth.token);
    const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
    const [commandes,setCommandes]=useState([])
    const [packages,setPackages]=useState([])
    const [signalements,setSignalements]=useState([])
    const fetchData = async () => {
        setChargementEnCours(true)
        const res = await FailedChargeDetails(id,token)
        setCommandes(res.commandes)
        setPackages(res.packages)
		setSignalements(res.signalements)
        setChargementEnCours(false)
    }
    useEffect(()=>{
        fetchData()
    },[])
    const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			width: '110px',
			sortable: false,
		},
		{
			name: 'Type du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: '#51C7F2',
							marginBottom: 3,
						}}
					>
						{row.type_commande == 'course-a-course'
							? 'Course à course'
							: 'Mise à disposition'}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		
		{
			name: 'Date début',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row.debut}</span>
							<span style={{marginLeft:'0.5rem'}}>{row.heure_debut}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].date_debut}</span>
								<span style={{marginLeft:'0.5rem'}}>{row.courses[0].heure_debut}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Date fin',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row.fin}</span>
							<span style={{marginLeft:'0.5rem'}}>{row.heure_fin}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								
								<span>{row.courses.length===1 ? row.courses[0].date_fin :row.courses[row.courses.length-1].date}</span>
								<span style={{marginLeft:'0.5rem'}}> {row.courses.length===1 ? row.courses[0].heure_fin : row.courses[row.courses.length-1].heure}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Départ',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row?.adresse_debut?.label}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].adresse_debut?.label}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: ' Arrivé',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row?.adresse_fin?.label}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length===1 ? row.courses[0].adresse_fin?.label :row.courses[row.courses.length-1].adresse?.label}</span>
						</div>	
					)
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: 'Montant',
			selector: (row) => {
				
				const montantTotal = row.prix ;
				const montantAvecMargin = (montantTotal - (montantTotal * (row.margin ?? 0)) / 100).toFixed(2);
			  
				return (
				  <div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{montantTotal.toFixed(2)}€</span>
					<span>{montantAvecMargin}€</span>
				  </div>
				);
			  },
			  width: '110px',
			sortable: false,
		},
		{
			name: 'Client',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onClick={()=>{
						if(row.clientID?.deletedAt !== null){
							history.push(`/admin/clients/single/${row.clientID._id}`)

						}else{
							history.push(`/admin/clients/${row.clientID._id}`)

						}
					}}
				>
					<img
						style={{
							width: '2rem',
							height: '2rem',
							borderRadius: '1rem',
							marginRight: 10,
						}}
						src={
							row.clientID && row.clientID.logo
								? `${PUBLIC}/${row.clientID.logo.name}`
								: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
						}
						alt=''
					/>
                  <span style={{
					fontWeight: 'bold',
					cursor: 'pointer',
					color:row?.clientID?.deletedAt !== null ? 'red' :'#51C7F2'
				  }}>
                  {row?.clientID?.societe}
                  </span>				
				  </div>
			),
			sortable: false,
			width:'150px'
		},
		{
			name: 'Type de compte',
			selector: (row) => (row.clientID.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width:'150px'
		},
		{
			name: 'Status du payment',
			selector: (row) => row.paymentStatus,
			width: '200px',
			sortable: false,
		},
		{
			name: '% marge appliqué',
			selector: (row) => <div 
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		><span  
		style={{
			// background: '#50F5A9',
			border: '1px solid',
			borderBlockColor: 'grey',
			// cursor: 'pointer',
			color: 'black',
			fontFamily: 'Montserrat',
			padding: '.5rem',
			borderRadius: 6,
		}} >{row.margin ?? 0}</span></div>,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Type de véhicule',
			selector: (row) => {
				return (
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							width: 100,
							padding: '1rem 0 1rem 0',
						}}
					>
						{row.type_transport == 'Poids lourds' ? (
							<>
								<span
									style={{
										marginBottom: 10,
									}}
								>
									{row.type_transport}
								</span>
								<span
									style={{
										marginBottom: 10,
									}}
								>
									{row.vehicule_poids_lourds}
								</span>
								{row.options_poids_lourds.map((item) => (
									<span key={item}>{item}</span>
								))}
							</>
						) : (
							<>
								<span>{row.type_transport}</span>
								<span
									style={{
										marginBottom: 10,
									}}
								>
									{row.type_transport_details}
								</span>

								{row.options_vehicule_leger.map((item) => (
									<span key={item}>{item}</span>
								))}
							</>
						)}
					</span>
				)
			},
			sortable: false,
			width: '150px',
		},
		{
			name: 'Date de création',
		    selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			width: '200px',
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(
								`/admin/plateforme/commandes/${row._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>

					<div style={{ width: 10 }} />

					
				</div>
			),
		},
	]
    const columnsForfait = [
		{
			name: 'ID du commande',
			selector: (row) => row.commande.__id,
			width: '150px',
			sortable: false,
		},
       
        {
			name: 'Forfait',
			selector: (row) => row.commande.packages[row.packageIndex].value,
			width: '150px',
			sortable: false,
		},
        {
			name: 'Montant',
			selector: (row) => {
				
				const montantTotal =  row.commande.packages[row.packageIndex].price ;
				const montantAvecMargin = (montantTotal - (montantTotal * (row.commande.margin ?? 0)) / 100).toFixed(2);
			  
				return (
				  <div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{montantTotal.toFixed(2)}€</span>
					<span>{montantAvecMargin}€</span>
				  </div>
				);
			  },
			  width: '150px',
			sortable: false,
		},
        {
			name: 'Status du payment',
			selector: (row) => row.commande.packages[row.packageIndex].paymentStatus,
			width: '200px',
			sortable: false,
		},
        {
			name: '% marge appliqué',
			selector: (row) => <div 
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		><span  
		style={{
			// background: '#50F5A9',
			border: '1px solid',
			borderBlockColor: 'grey',
			// cursor: 'pointer',
			color: 'black',
			fontFamily: 'Montserrat',
			padding: '.5rem',
			borderRadius: 6,
		}} >{row.commande.margin ?? 0}</span></div>,
			sortable: false,
			width:'150px'
		},
        {
			name: 'Client',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onClick={()=>{
						if(row.clientID?.deletedAt !== null){
							history.push(`/admin/clients/single/${row.clientID._id}`)

						}else{
							history.push(`/admin/clients/${row.clientID._id}`)

						}
					}}
				>
					<img
						style={{
							width: '2rem',
							height: '2rem',
							borderRadius: '1rem',
							marginRight: 10,
						}}
						src={
							row.commande.clientID && row.commande.clientID.logo
								? `${PUBLIC}/${row.commande.clientID.logo.name}`
								: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
						}
						alt=''
					/>
                  <span style={{
					fontWeight: 'bold',
					cursor: 'pointer',
					color:row?.commande.clientID?.deletedAt !== null ? 'red' :'#51C7F2'
				  }}>
                  {row?.commande.clientID?.societe}
                  </span>				
				  </div>
			),
			sortable: false,
			width:'150px'
		},
	
		
		
		
		
		
		
		
		
		
		
	
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(
								`/admin/plateforme/commandes/${row.commande._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>

					<div style={{ width: 10 }} />

					
				</div>
			),
		},
	]
	const columnsSignalement = [
		{
			name: 'ID du commande',
			selector: (row) => row.commande.__id,
			width: '150px',
			sortable: false,
		},
		{
			name: "Type d'anomalie ",
			selector: (row) => row.type,
			width: '150px',
			sortable: false,
		},
		{
			name: "Temps du retard",
			selector: (row) => (row.commande?.type_commande==="course-a-course" ? row.duration:'__' ),
			width: '140px',
			sortable: false,
		},
		{
			name: "Temps supplémentaire",
			selector: (row) => (row.commande?.type_commande==="mise-a-disposition" ? row.duration:'__' ),
			width: '180px',
			sortable: false,
		},
		{
			name: "Statut du paiement",
			selector: (row) => row.paymentStatus,
			width: '160px',
			sortable: false,
		},
		{
			name: 'Montant',
		    selector: (row) => `${row.prix} €`,
			width: '110px',
			sortable: false,
		},
		{
			name: 'Note',
			selector: (row) => row.note,
			width: '110px',
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(
								`/admin/plateforme/commandes/${row.commande._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>

					<div style={{ width: 10 }} />

					
				</div>
			),
		},
		
	]
  return (
    <LayoutAdmin>
         <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
             <GradientText marginTop={'0'} >
					{'Commandes'.toUpperCase()}
				</GradientText>  
                <div
					style={{
						width: '100%',
						padding: '0 1rem',
                        marginTop: '1rem',

					}}
				>
					<DataTable
						columns={columns}
						data={commandes}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						noDataComponent="Il n'y a pas des commandes à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div> 
                <GradientText marginTop={'10'} >
					{'Forfaits'.toUpperCase()}
				</GradientText> 
                <div
					style={{
						width: '100%',
						padding: '0 1rem',
                        marginTop: '1rem',

					}}
				>
					<DataTable
						columns={columnsForfait}
						data={packages}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						noDataComponent="Il n'y a pas des forfaits à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div> 
				<GradientText marginTop={'10'} >
					{'Signalements'.toUpperCase()}
				</GradientText> 
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
                        marginTop: '1rem',

					}}
				>
					<DataTable
						columns={columnsSignalement}
						data={signalements}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						noDataComponent="Il n'y a pas des signalements à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div> 
            </div>
    </LayoutAdmin>
  )
}

export default SingleFailedCharge

import React ,{useEffect,useState}from 'react'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import {getPayments} from '../../api/commande'
import {getClientsForPrelevement} from '../../api/clients'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import DataTable from 'react-data-table-component'
import {BASE_URL } from '../../api/base'
import { FaRegFilePdf } from "react-icons/fa";
function Prelevements() {
	const history = useHistory()
    const token = useSelector((state) => state.auth.token);
    const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [searchQuery, setSearchQuery] = useState('')
    const [data,setData]=useState([])
    const fetchData = async () => {
        setChargementEnCours(true)
		const resulta=await getPayments(token)
        setData(resulta)
        setChargementEnCours(false)
    }
    useEffect(()=>{
     fetchData()
    },[])
	const searchForClient = async (q) => {
		if (q.length === 0) {
			return
		}

		setLoading(true)

		const res = await getClientsForPrelevement(q,token)
		setData(res)
		console.log(res)
		setLoading(false)
	}
    const columns = [
		{
			name: 'ID Client',
			selector: (row) => row.clientID.__id,
			sortable: false,
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.clientID.societe}`,
			sortable: false,
		},
		{
			name: 'Nom du Client',
			selector: (row) =>`${row.clientID.first_name} ${row.clientID.last_name}` ,
			sortable: false,
		},
		
		{
			name: 'Montant',
			selector: (row) =>`${row.compteur.toFixed(2)}€` ,
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
                    <div
					 onClick={()=>{
						window.open(`${BASE_URL}/${row.facture}`)


					 }}
						style={{
							cursor: 'pointer',
						}}>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'#51C7F2'}} />

					</div>
					
					<div
						onClick={()=>history.push(`/admin/failedChargeDetails/${row._id}`)}
						style={{
							cursor: 'pointer',
							marginLeft:'2px'
						}}
					>
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
				
			),
		},
		
		
		
		
	]

  return (
 <LayoutAdmin>
    <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
               <GradientText marginTop={'0'}>
					{'Prélèvements'.toUpperCase()}
				</GradientText>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<input
						disabled={loading}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}	
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								searchForClient(searchQuery)

							}
							
						}}
							style={{
								padding: '1rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								width: '22rem',
								fontFamily: 'Montserrat',
							}}
							placeholder="Rechercher par l'identifiant, nom client /societe"
						/>
					<button
						onClick={() => searchForClient(searchQuery)}

							style={{
								padding: '1rem 2rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								//marginTop: 20,
								marginLeft: 20,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Rechercher
						</button>
						<button
						onClick={async () => {
							await fetchData()
							setSearchQuery('')
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>


						
					</div>
					
					
				</div> 
                <div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						noDataComponent="Il n'y a pas des prélèvements à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
            </div>
			
 </LayoutAdmin>
  )
}

export default Prelevements
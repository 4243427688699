import { GradientText, SubText } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'

import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import _ from 'lodash'
import {getCommandesByTransporterIdByIdForCompatibilite,getCommandesByTransporterIdByAdresseForCompatibilite,getCommandesByTransporterIdByPriceForCompatibilite,getCommandesByTransporterIdByDateForCompatibilite,getCommandesByTransporterIDByDay } from '../../api/commande'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import GooglePlacesAutoComplete from '../../Components/GooglePlacesAutoComplete/GooglePlacesAutoComplete'


function ComptabiliteByDayTransporter(props) {
	const history = useHistory()
	const [total, setTotal] = useState(0)
	const token = useSelector((state) => state.auth.token);
	const [commandes, setCommandes] = useState([])
	const transporterID = useSelector((state) => state.auth.user._id)
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [adresseValue, setAdresseValue] = useState('');
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const [min, setMin] = useState(0)
	 const [max, setMax] = useState(300)
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 }

	 async function _getCommandes(transporterID) {
		const res=await getCommandesByTransporterIDByDay(transporterID,token)
		setCommandes(res.data)
        setTotal(res.total_all.toFixed(2))
		setSearchQueryId('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		  setSearchCriteria("id")
	}

	useEffect(() => {
		_getCommandes(transporterID)
	}, [])

	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>{'comptabilité'.toUpperCase()}</GradientText>
				<SubText>
					Ici retrouver la totalité des transports facturer
				</SubText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div
						style={{
							width: '100%',
							fontFamily: 'Montserrat',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							marginBottom: '2rem',
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					
					>
						<div
						style={{
							width: '360px',
							fontFamily: 'Montserrat',
							// paddingLeft: '1rem',
							// paddingRight: '1rem',
							// marginBottom: '2rem',
							// marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'city', label: "Recherche par ville " },
								{value:'prix',label:"Recherche par tarif"},
								{ value: 'date', label: 'Recherche par Date du transport' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>	
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '18rem',
								fontFamily: 'Montserrat',
								
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'prix' ? (
						<>
						<input
							type='number'
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
								marginRight: '1rem',
							}}
							value={min}
							onChange={(e) => {
								setMin(Number(e.target.value))
							}}
							placeholder='Prix min'
						/>
						<input
							type='number'
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={max}
							onChange={(e) => {
								setMax(Number(e.target.value))
							}}
							placeholder='Prix max'
						/>
					</>
					) : null}
					{searchCriteria==="city" ?(
                     <GooglePlacesAutoComplete
                    value={adresseValue}
                     onChange={(place) => {
						
						setAdresseValue(place)
					}}
                    />
                    ): null}
                    
					
					{searchCriteria === 'date' ? (
							<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={selectedDate}
							type="date"
							onChange={(e) => setSelectedDate(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					<button
					   onClick={async () => {
						if (
							searchCriteria === 'id' &&
							searchQueryId.length === 0
						) {
							return
						}
						let res
						if (searchCriteria === 'id') {
							res =
								await getCommandesByTransporterIdByIdForCompatibilite(
									transporterID,
									searchQueryId,
									token
								)
						}
						if (searchCriteria === 'city') {
							res =
								await getCommandesByTransporterIdByAdresseForCompatibilite(
									transporterID,
									adresseValue.label,
									token
								)
						}
						if (searchCriteria === 'prix') {
							res =
								await getCommandesByTransporterIdByPriceForCompatibilite(
									transporterID,
									min,
									max,
									token
								)
						}
						if (searchCriteria === 'date') {
							res =
								await getCommandesByTransporterIdByDateForCompatibilite(
									transporterID,
									selectedDate,
									token
								)
						}
						setCommandes(res.data)
                        setTotal(res.total_all.toFixed(2))
					}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
					        onClick={async () => {
								await _getCommandes(transporterID)
							}}	
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							marginRight:'1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					<div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{total}€</span>
					</div>
					</div>

					
				</div>

				<div
					style={{
						width: '100%',
						padding: '1rem',
						marginBottom: '1rem',
					}}
				>
					<span
						onClick={() =>
							history.push('/comptabilite/transporter/month')
						}
						style={{
							padding: '1rem 2rem',
							borderRadius: 5,
							cursor: 'pointer',
							marginRight: '1rem',
							fontWeight: 'bold',
							color: 'white',
							background: '#E4EAF0',
							fontFamily: 'Montserrat',
						}}
					>
						Mois
					</span>
					<span
						onClick={() =>
							history.push('/comptabilite/transporter/day')
						}
						style={{
							padding: '1rem 2rem',
							borderRadius: 5,
							cursor: 'pointer',
							fontWeight: 'bold',
							color: 'white',
							marginRight: '1rem',
							background: '#50F5A9',
							fontFamily: 'Montserrat',
						}}
					>
						Jours
					</span>
				</div>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>     {commandes.length === 0 ? 
					<span
					style={{
					
						
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					Il n'y a pas des commandes à afficher
				</span>
					:commandes.map((commande,index) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={index}
									commande={commande}
									hideAttribuer
									hidecancel
									hideRecu
									hidefacture
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/transports/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/transporter/transports/${commande._id}/signaler`
										)
									}}
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={index}
									commande={commande}
									hideAttribuer
									hidecancel
									hideRecu
									hidefacture
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/transports/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/transporter/transports/${commande._id}/signaler`
										)
									}}
								/>
							)
						}
					})}
				</div>
			</div>
		</LayoutTransporter>
	)
}

export default ComptabiliteByDayTransporter

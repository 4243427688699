import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getStatsForAllSignalementsByCommandeID } from '../../api/stats'
import UpdateInfomationsSignataire from '../UpdateInfomationsSignataire/UpdateInfomationsSignataire'

function MiseADispoHorizontal({
	commande,
	onClickDetails = () => {},
	onClickFacture=()=>{},
	onClickAvoir=()=>{},
	hideTransfert=true,
	hidecancel=false,
	hideTarif = false,
	hidefacture=false,
	hideUpdate=false,
	onClickCancel=()=>{},
	onClickPayer=()=>{},
	onClickSignaler=()=>{},
	setModalIsOpenPayer,
}) {
	const [commandeData,setCommandeData]=useState({})
	const user = useSelector(state => state.auth.user)
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpenUpdate,setModalIsOpenUpdate]=useState(false)
    
    const history=useHistory()
	function combineDateTime(date, time) {
		return new Date(`${date} ${time}`);
	  }
	function getMaxEndDateIndex(missions) {
		return missions.reduce((maxIndex, mission, index) => {
		  const missionEndDate = combineDateTime(mission.date_fin, mission.heure_fin);
		  const maxEndDate = combineDateTime(missions[maxIndex].date_fin, missions[maxIndex].heure_fin);
	  
		  return missionEndDate > maxEndDate ? index : maxIndex;
		}, 0); 
	  }
	const index=getMaxEndDateIndex(commande?.missions)
	
	  
	const fetchStatsForAllSignalementByCommandeID=async(id)=>{
		const res=await getStatsForAllSignalementsByCommandeID(id,token)
		const newCommande = { ...commande }; 
		newCommande.prixSignalements = res.sommePayé; 
		newCommande.prixSignalementRemboursé=res.sommeRemboursé
		setCommandeData(newCommande)
	   
  }


	useEffect(()=>{
		fetchStatsForAllSignalementByCommandeID(commande._id)
	},[commande])

	return (
		<div
			style={{
				width: '100%',
				background: '#E4EAF0',
				display: 'flex',
				flexDirection: 'row',
				padding: '1rem',
				borderRadius: 10,
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginBottom: 10,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						marginBottom: 3,
					}}
				>
					Mise a disposition {!!commande ? 'N°' + commande.__id : '' } /{' '}
					<span style={{ color: 'red' }}>
						{commande.temporisation === 'immediat'
							? 'Immédiat'
							: 'Planifier'}
					</span>
				</span>
                <div 
				style={{
					display: 'flex',
					flexDirection: 'row'
				}}>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Date du Transports :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red',

						}}
					>
						{commande?.missions[0]?.date_debut}
				</span>
				</span>
				
				</div>
				
                <span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginBottom: '1rem',

					}}
				>
					Date du Création :{' '}
					<span
						style={{
							marginRight: 13,
							color:'red'
						}}
					>
				    {moment(commande.createdAt).format('DD/MM/YYYY')}
					</span>
				</span>
				<span
					style={{
						marginRight: 13,
						marginBottom: '1rem',
						color: '#838EAB',
						width: '15rem',

					}}
				>
          Date de début  {' '}
		  <span 
		  style={{color:"red",
           }}>
		  {commande?.missions[0]?.date_debut}
		  {' '}
          {commande?.missions[0]?.heure_debut} {commande?.missions[0]?.adresse_debut?.label}
		  </span>
		 
				</span>


        <span
        style={{
        marginRight: 13,
        color: '#838EAB',
        width: '15rem',
         }}
        >
        Date de fin:{' '}
      <span style={{ color: "red" }}>
       {commande?.missions[index]?.date_fin}{' '}
    {commande?.missions[index]?.heure_fin}{' '}
    {commande?.missions[index]?.adresse_fin?.label}
  </span>
</span>

                <span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,

					}}
				>
					 L'état de paiement :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red'
						}}
					>
				 {commande.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,

					}}
				>
					 Statut de la commande :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red'
						}}
					>
                    {commande?.statut==="init" && "Non commencée"}
                    {commande?.statut==="en-cours" && "En cours"}
                    {commande?.statut==="terminer" && "Terminée"}
                   {commande?.statut==="annuler" && "Annulée"}
					</span>
				</span>
				{(!hideTransfert && commande.recu && commande.statut==="en-cours") && (
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
						
					}}
				>
					 Statut de Transfert :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {commande?.statutTransfert === 'transferé' && "Transferé"}
             {commande?.statutTransfert==="en cours de transfert" && "En cours de transfert"}
             {commande?.statutTransfert==="aucun transfert" && "Aucun transfert"}

					</span>
				</span>
				)}
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,

					}}
				>
					Nombre de missions: {' '}
					<span style={{color:'red'}}>
					{commande?.missions?.length}
					</span>
					
				</span>
			</div>

			{/*  */}
             
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					height: '100%',

				}}
			>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Nombre de rippeurs :{' '}
					<span style={{ color: 'red' }}>
					{(commande?.missions?.reduce((total, mission) => {
                    return total + (mission?.nombre_rippeur || 0);
                    }, 0) || 0)}

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Documents :{' '}
					<span style={{ color: 'red' }}>
					{commande?.files?.length + (commande?.missions?.reduce((total, mission) => {
                    return total + (mission?.files?.length || 0);
                    }, 0) || 0)}

					</span>
				</span>
				<span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Référence interne client :{' '}
					<span style={{ color: 'red' }}>
					{commande.ref_client}
					</span>
				</span>
                <span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Nombre de  kilomètrage :{' '}
					<span style={{ color: 'red' }}>
					{commande.distance}
					</span>
				</span>
				{user?.role==="client" && 
				<span
				style={{
					marginBottom: 3,
					color: '#838EAB',
					marginBottom: '1rem',

				}}
			>
				 Affectation :{' '}
				 <span style={{ color: 'red' }}>
				{!commande.transporterID && 'Non Affecté'}
				 {(commande.transporterID && !commande.chauffeurID ) && 'Transporteur Affecté' }
				 {(commande.chauffeurID && commande.transporterID) &&'Chauffeur Affecté'}
				</span>
			 </span>
			 
                }
				<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',

						}}
					>
						Notes utiles:{' '}
						<span style={{ color: 'red' }}>
							{ commande?.missions?.some(mission => mission?.notes) ? 'Oui' : 'Non'}
						</span>
					</span>
				
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft:'0.5rem',
					marginRight:'0.5rem',
					fontSize: 13,
				}}
			>
				{!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .1rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
							Montant Totale:{' '}
							{
                              user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin' ?
                              (commande.prix + (commande.packages || []).reduce((total, p) => total + p.price, 0)+Number(commandeData.prixSignalements)).toFixed(2) :
                              ((commande.prix+(commande.packages || []).reduce((total, p) => total + p.price, 0)+Number(commandeData.prixSignalements))- ((commande.prix+(commande.packages || []).reduce((total, p) => total + p.price, 0)+Number(commandeData.prixSignalements)) * (commande.margin ?? 0)) / 100).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
				{!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
						marginTop:2
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Initiale:{' '}
							
							{
                              user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin' ?
                              (commande.prix.toFixed(2) ):
                              ((commande.prix)- ((commande.prix) * (commande.margin ?? 0)) / 100).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
				{!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Forfait:{' '}
							{
                              user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin' ?
                              ( (commande.packages || []).reduce((total, p) => total + p.price, 0)).toFixed(2) :
                              (((commande.packages || []).reduce((total, p) => total + p.price, 0))- (((commande.packages || []).reduce((total, p) => total + p.price, 0)) * (commande.margin ?? 0)) / 100).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
			    {!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Signalement:{' '}
							{
                              user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin' ?
                               Number(commandeData?.prixSignalements).toFixed(2) :
							 (   Number(commandeData?.prixSignalements) - (Number(commandeData?.prixSignalements) * (commande.margin ?? 0)) / 100).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
				{!hideTarif && commandeData.statut === "annuler" && commandeData.rembourse === true && commandeData.packages.every(forfait => forfait.rembourse) && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span >
						Montant Remboursé:{' '}
						<span  className='price'>
							{
                              ((user.role === 'client' || user.role === 'commercial' || user.role === 'admin'  || user.role === 'super admin') && commandeData.statutPrecedent==='init') &&
							  (Number(commandeData?.prixRembourse)).toFixed(2) 
                            }€
							{
                              ((user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin') && commandeData.statutPrecedent==='en-cours' ) &&
                              (Number(commandeData?.prixRembourse).toFixed(2)) 
                            }€
						</span>
						</span>
					</span>
				</div> )}
				{!hideTarif && commandeData.statut === "annuler" && commandeData.rembourse === true  && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span >
						Montant Signalement Remboursé:{' '}
						<span  className='price' >
							{
                              user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin' ?
                              (Number(commandeData?.prixSignalementRemboursé).toFixed(2)) :
                              (Number(commandeData?.prixSignalementRemboursé)- (Number(commandeData?.prixSignalements)) / 100).toFixed(2)
                            }€
						</span>
						</span>
					</span>
				</div> )}
                
			</div>

			{/*  */}

			<div
				style={{
					color: '#838EAB',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span>
					Diable:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Diable') && 'Oui'}
					</span>
				</span>
				<span>
					Transpalette manuel:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Transpalette manuel') &&
							'Oui'}
					</span>
				</span>
				<span>
					Transpalette électrique:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes(
							'Transpalette électrique'
						) && 'Oui'}
					</span>
				</span>
				<span>
					Sangle:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Sangle') && 'Oui'}
					</span>
				</span>

				<span>
					Casque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Casque') && 'Oui'}
					</span>
				</span>
				<span>
					Gants:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gants') && 'Oui'}
					</span>
				</span>
				<span>
					Masque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Masque') && 'Oui'}
					</span>
				</span>
				<span>
					Lunettes:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Lunettes') && 'Oui'}
					</span>
				</span>
				<span>
					Chaussure de sécurité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Chaussure de sécurité') &&
							'Oui'}
					</span>
				</span>
				<span>
					Gilet de visibilité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gilet de visibilité') && 'Oui'}
					</span>
				</span>
			</div>

			<div
				style={{
					//width: '16%',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					marginTop: 35,
				}}
			>
				
				<button
					onClick={onClickDetails}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir détails
				</button>
				<button
				onClick={onClickSignaler}
				style={{
					padding: '0.5rem 1.5rem',
					background: '#838EAB',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Signalements
			   </button>
				
				
				{!hidefacture &&
				<button
					onClick={onClickFacture}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir Facture
				</button>
                }
				{!hidefacture &&  commande.statut==="annuler" && commande.rembourse===true &&
				<button
					onClick={onClickAvoir}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir Avoir
				</button>
                }
				{!hidecancel&&
				<button
					onClick={onClickCancel}
					style={{
						padding: '0.5rem 1.5rem',
						background:commande?.statut ==="annuler" ? 'red' : '#ff9999',
						border: 'none',
						cursor: 'pointer',
						borderRadius: 7,
						color: commande?.statut ==="annuler"? 'white' : 'white',
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Annuler
				</button>
                }
				{(!hidecancel && !user.premium && commande.prix!==0 &&  commande.paymentStatus==="non payé")&&
				<button
					// onClick={onClickPayer}
					onClick={() => {
						if (commande?.statut === "annuler") {
						  setModalIsOpenPayer(true);
						} else {
						  onClickPayer();
						}
					  }}
					style={{
						padding: '0.5rem 1.5rem',
						background:commande?.statut ==="payé" ? '#66ff66' : '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Payer
				</button>
                }
				{!hideUpdate && 
				<button
				onClick={()=>setModalIsOpenUpdate(true)}
				style={{
					padding: '0.5rem 1.5rem',
					background:'#5bc0de',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Modifier Signataire
			   </button>
				}
			 
				<button 
				onClick={()=>{history.push(`/client/valider-signature/${commande._id}`)}}
				style={{
					padding: '0.5rem 1.5rem',
					background:'#5bc0de',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}>
					Suivi des livraisons et signature 
				</button>
			     {commande?.statut==="en-cours" &&
				<button 
				onClick={()=>{history.push(`/client/forfait/${commande._id}`)}}
				style={{
					padding: '0.5rem 1.5rem',
					background:'#5bc0de',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}>
					Ajouter Fofait

				</button>
                }
			
               
				 
				
				 
				 
			</div>
			
		
		     <UpdateInfomationsSignataire
			 commande={commande}
			 modalIsOpenUpdate={modalIsOpenUpdate}
			 setModalIsOpenUpdate={setModalIsOpenUpdate}
			 />
			
		
			
		</div>
	)
}

export default React.memo(MiseADispoHorizontal)

import { Select, StyledTextArea,StyledInput } from './PasserCommande.styles'

import { Controller } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import {BASE_URL} from '../../api/base'
import React,{useEffect,useState} from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import UploadFileCommande from '../../Components/UploadFileCommande/UploadFileCommande'
import { getDistanceBetweenTwoPlaces,getHourBetweenTwoPlaces } from '../../api/distance'
import moment from 'moment'
import { useSelector } from 'react-redux'
import AutocompleteAddress from '../../Components/GooglePlacesAutoComplete/AutocompleteAddress'
function SingleCourseStep({
	collapsedItems,
	keepFilesAsObjects,
	i,
	watch,
	register,
	getValues,
	setValue,
	control,
	display,
	placesVector,
	setPlacesVector,
	distanceVector,
	setDistanceVector,
	errors,
	setHeur
}) {
	const [isAfterDay,setIsAfterDay]=useState(false)
	const token = useSelector((state) => state.auth.token);

	  const decimalToTime = (decimalHours) => {
		const hours = Math.floor(decimalHours);
		const minutesDecimal = decimalHours - hours;
		const minutes = Math.round(minutesDecimal * 60);
		return { hours, minutes };
	 };
	
	   const calculateEndDate=(time,startDate,startTime="00:00")=>{
		 // Extraire les heures et minutes de startTime
		 const [startHour, startMinute] = startTime.split(":").map(Number);

		 // Convertir la date de début en objet Date
		 const endDateTime = new Date(startDate);
	 
		 // Ajouter l'heure et les minutes de début à la date de début
		 endDateTime.setHours(startHour, startMinute, 0, 0);
	 
		 // Convertir le temps de déplacement en heures et minutes
		 const { hours: travelHours, minutes: travelMinutes } = decimalToTime(time);
	 
		 // Ajouter le temps de déplacement à la date et l'heure de début
		 endDateTime.setMinutes(endDateTime.getMinutes() + (travelHours * 60) + travelMinutes);
	 
		 return endDateTime;
	}
	const handleDateDebutChange = (date_fin) => {
		if(i===1){
			if(watch(`courses[${i-1}].date_fin`) && watch(`courses[${i-1}].heure_fin`) && watch(`courses[${i}].hourBetweenTwoPlaces`) ){
				const  datefin=calculateEndDate(watch(`courses[${i}].hourBetweenTwoPlaces`),watch(`courses[${i-1}].date_fin`),watch(`courses[${i-1}].heure_fin`))
				if (moment(datefin, "DD/MM/YYYY").isBefore(moment(date_fin, "DD/MM/YYYY"))) {
				
					setValue(`courses[${i}].date`, date_fin);
				} else {
				  setValue(`courses[${i}].date`, datefin);
				}
			}
		}
		if(i>1){
			if(watch(`courses[${i-1}].date`) && watch(`courses[${i-1}].heure`) && watch(`courses[${i}].hourBetweenTwoPlaces`) ){
				const  datefin=calculateEndDate(watch(`courses[${i}].hourBetweenTwoPlaces`),watch(`courses[${i-1}].date`),watch(`courses[${i-1}].heure`))
				if (moment(datefin, "DD/MM/YYYY").isBefore(moment(date_fin, "DD/MM/YYYY"))) {
				
					setValue(`courses[${i}].date`, date_fin);
				} else {
				  setValue(`courses[${i}].date`, datefin);
				}
			}	
		}
		
	};
	const filterEndTimeOptions = (time, startTime = "00:00",isAfterDay=false) => {
		const [startHour, startMinute] = startTime.split(":").map(Number);
		const startTimeInMinutes = startHour * 60 + startMinute;
		// Ajouter le temps de déplacement à l'heure de début
		let { hours: travelHours, minutes: travelMinutes } = decimalToTime(time);
		let startTravelMinutes = 0;

        if (travelMinutes > 0 && travelMinutes <= 30) {
        startTravelMinutes = 30;
        } else if (travelMinutes > 30) {
         startTravelMinutes = 0;
        }
		if (travelMinutes > 30) {
			travelHours++;
		}
		
		const adjustedStartTimeInMinutes = startTimeInMinutes + (travelHours * 60) + startTravelMinutes;
		const maxEndTimeInMinutes = 23*60 + 30 
		let minStartTimeInMinutes=adjustedStartTimeInMinutes
		if(adjustedStartTimeInMinutes>= maxEndTimeInMinutes){
			minStartTimeInMinutes=adjustedStartTimeInMinutes-maxEndTimeInMinutes
		}
	    console.log('heure  fin',watch(`courses[${i-1}].heure_fin`))
		const options = [];
		if( isAfterDay ||!watch(`courses[${i}].hourBetweenTwoPlaces`) ||  !watch(`courses[${i-1}].date_fin`) || !watch(`courses[${i-1}].heure_fin`) || !watch(`courses[${i-1}].adresse_fin`) || !watch(`courses[${i}].adresse`)){
			for (let hours = 0; hours < 24; hours++) {
				for (let minutes = 0; minutes < 60; minutes += 30) {
				  const hourText = hours.toString().padStart(2, '0');
				  const minuteText = minutes.toString().padStart(2, '0');
				  const time = `${hourText}:${minuteText}`;
				  options.push({ label: time, value: time });
				}
			  }
		}else{

		for (let minutes = minStartTimeInMinutes; minutes <= maxEndTimeInMinutes; minutes += 30) {
			const hours = Math.floor(minutes / 60) % 24;
			const mins = minutes % 60;
			options.push({
				label: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
				value: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
			});
		}
	    }
	
		return options;
	};
	const filterEndTimeOptions2=(time,startTime="00:00",isAfterDay=false)=>{
		const [startHour, startMinute] = startTime.split(":").map(Number);
		const startTimeInMinutes = startHour * 60 + startMinute;
		// Ajouter le temps de déplacement à l'heure de début
		let { hours: travelHours, minutes: travelMinutes } = decimalToTime(time);
		let startTravelMinutes = 0;

        if (travelMinutes > 0 && travelMinutes <= 30) {
        startTravelMinutes = 30;
        } else if (travelMinutes > 30) {
         startTravelMinutes = 0;
        }
		if (travelMinutes > 30) {
			travelHours++;
		}
		
		const adjustedStartTimeInMinutes = startTimeInMinutes + (travelHours * 60) + startTravelMinutes;
		const maxEndTimeInMinutes = 23*60 + 30 
		let minStartTimeInMinutes=adjustedStartTimeInMinutes
		if(adjustedStartTimeInMinutes>= maxEndTimeInMinutes){
			minStartTimeInMinutes=adjustedStartTimeInMinutes-maxEndTimeInMinutes
		}
	
		const options = [];
		if( isAfterDay || !watch(`courses[${i}].hourBetweenTwoPlaces`) || !watch(`courses[${i-1}].date`) || !watch(`courses[${i-1}].heure`) || !watch(`courses[${i-1}].adresse`) || !watch(`courses[${i}].adresse`)){
			for (let hours = 0; hours < 24; hours++) {
				for (let minutes = 0; minutes < 60; minutes += 30) {
				  const hourText = hours.toString().padStart(2, '0');
				  const minuteText = minutes.toString().padStart(2, '0');
				  const time = `${hourText}:${minuteText}`;
				  options.push({ label: time, value: time });
				}
			  }
		}else{

		for (let minutes = minStartTimeInMinutes; minutes <= maxEndTimeInMinutes; minutes += 30) {
			const hours = Math.floor(minutes / 60) % 24;
			const mins = minutes % 60;
			options.push({
				label: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
				value: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
			});
		}
	    }
	
		return options;
	}
	useEffect(()=>{
		if(i===1 && watch(`courses[${i-1}].date_fin`) && watch(`courses[${i-1}].heure_fin`) && watch(`courses[${i}].hourBetweenTwoPlaces`)    ){

			const  datefin=calculateEndDate(watch(`courses[${i}].hourBetweenTwoPlaces`),watch(`courses[${i-1}].date_fin`),watch(`courses[${i-1}].heure_fin`))

			setValue(`courses[${i}].date`,datefin)
		}
		
	 },[i===1,watch(`courses[${i-1}].date_fin`),watch(`courses[${i-1}].heure_fin`),watch(`courses[${i}].hourBetweenTwoPlaces`)])
	 useEffect(()=>{
		if( i>1 && watch(`courses[${i-1}].date`) && watch(`courses[${i-1}].heure`) && watch(`courses[${i}].hourBetweenTwoPlaces`) ){
			const  datefin=calculateEndDate(watch(`courses[${i}].hourBetweenTwoPlaces`),watch(`courses[${i-1}].date`),watch(`courses[${i-1}].heure`))
	         setValue(`courses[${i}].date`,datefin)
		}
		
	 },[i>1,watch(`courses[${i-1}].date`),watch(`courses[${i-1}].heure`),watch(`courses[${i}].hourBetweenTwoPlaces`)])
	 useEffect(()=>{
		if( i===1 && watch(`courses[${i}].date`) && watch(`courses[${i-1}].date_fin`) && watch(`courses[${i-1}].heure_fin`) && watch(`courses[${i}].hourBetweenTwoPlaces`)){
		const start = new Date(watch(`courses[${i-1}].date_fin`));
		const time=watch(`courses[${i-1}].heure_fin`)
		const end = new Date(watch(`courses[${i}].date`));
		const  datefin=calculateEndDate(watch(`courses[${i}].hourBetweenTwoPlaces`),start,time)
		if (moment(end, "DD/MM/YYYY").isAfter(moment(datefin, "DD/MM/YYYY"))) {
			setIsAfterDay(true)
		}else{
			setIsAfterDay(false)

		}
	}
	   },[i===1,watch(`courses[${i}].date`),watch(`courses[${i-1}].date_fin`),watch(`courses[${i-1}].heure_fin`),watch(`courses[${i}].hourBetweenTwoPlaces`)])
	   useEffect(()=>{
		if(i>1 && watch(`courses[${i}].date`) && watch(`courses[${i-1}].date`) && watch(`courses[${i-1}].heure`) && watch(`courses[${i}].hourBetweenTwoPlaces`)){
		const start = new Date(watch(`courses[${i-1}].date`));
		const time=watch(`courses[${i-1}].heure`)
		const end = new Date(watch(`courses[${i}].date`));
		const  datefin=calculateEndDate(watch(`courses[${i}].hourBetweenTwoPlaces`),start,time)
		if (moment(end, "DD/MM/YYYY").isAfter(moment(datefin, "DD/MM/YYYY"))) {
			setIsAfterDay(true)
		}else{
			setIsAfterDay(false)

		}
	}
	   },[i>1,watch(`courses[${i}].date`),watch(`courses[${i-1}].date`),watch(`courses[${i-1}].heure`),watch(`courses[${i}].hourBetweenTwoPlaces`)])
	
	 
	  useEffect(()=>{
		if(i===1 && watch(`courses[${i}].hourBetweenTwoPlaces`)   &&  watch(`courses[${i-1}].heure_fin`)){
		const options= filterEndTimeOptions(watch(`courses[${i}].hourBetweenTwoPlaces`),  watch(`courses[${i-1}].heure_fin`),isAfterDay)
		if (options.length > 0) {
			setValue(`courses[${i}].heure`, options[0].value);
		  } 
		}
	 },[watch(`courses[${i}].hourBetweenTwoPlaces`),isAfterDay,watch(`courses[${i-1}].heure_fin`)])
	 useEffect(()=>{
		if(i>1 && watch(`courses[${i}].hourBetweenTwoPlaces`)   &&  watch(`courses[${i-1}].heure`)){
		const options= filterEndTimeOptions2(watch(`courses[${i}].hourBetweenTwoPlaces`),  watch(`courses[${i-1}].heure`),isAfterDay)
		if (options.length > 0) {
			setValue(`courses[${i}].heure`, options[0].value);
		  } 
		}
	 },[watch(`courses[${i}].hourBetweenTwoPlaces`),isAfterDay,watch(`courses[${i-1}].heure`)])
	 useEffect(()=>{
		if(watch(`courses[${i}].distance`)){
			setDistanceVector(
				new Map(
				distanceVector.set(`${i}-fin`, watch(`courses[${i}].distance`))
				)
			   );
		}
		if(watch(`courses[${i}].adresse`)) {
			setPlacesVector(
				new Map(
				placesVector.set(`${i}-fin`, watch(`courses[${i}].adresse`).value.place_id)
				)
			);
		} 
		 
	 },[])
	return (
		<div
			style={{
				display: collapsedItems.includes(i) ? 'none' : 'flex',
				flexDirection: 'column',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '50%',
					marginBottom: '2.5rem',
				}}
			>
				

				<div
					className='radio-btn-group-palettes'
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '2rem',
					}}
				>
					<div
						className='radio'
						style={{
							marginRight: '1rem',
						}}
					>
						<input
							id={`type-chargement-${i}`}
							type='radio'
							//disabled={display}
							value='Chargement'
							{...register(`courses[${i}].type`, {
								required: true,
							})}
						/>

						<label htmlFor={`type-chargement-${i}`}>
							Chargement
						</label>
					</div>

					<div
						className='radio'
						style={{
							marginRight: '1rem',
						}}
					>
						<input
							id={`type-dechargement-${i}`}
							type='radio'
							//disabled={display}
							value='Déchargement'
							{...register(`courses[${i}].type`, {
								required: true,
							})}
						/>

						<label htmlFor={`type-dechargement-${i}`}>
							Déchargement
						</label>
					</div>
					
				</div>
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].type
						 &&
					    <div style={{ color: 'red', marginTop: '0.5rem' }}>
					     Veuillez sélectionner le type de transport
					    </div> 
				         }
			</div>
            <div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '2.5rem',
				}}
			>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					}}
				>
					Lieux <span style={{ color: 'red' }}>*</span>
				</span>
				<Controller
                name={`courses[${i}].adresse`}
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                <AutocompleteAddress
                onPlaceSelect={(placeDetails) => {
                console.log('Place ID:', placeDetails.place_id);
                console.log('Description:', placeDetails.description);
                }}
                onValueChange={async (selectedValue) => {
                console.log('Selected Value:', selectedValue);
                if (selectedValue) {
                onChange(selectedValue);
                setPlacesVector(
                new Map(
                placesVector.set(`${i}-fin`, selectedValue.value.place_id)
                )
                );
                const origin = placesVector.get(`${i - 1}-fin`);
                const destination = selectedValue.value.place_id;
                if (origin) {
                 try {
                 const hour = await getHourBetweenTwoPlaces(origin, destination,token);
				 setValue(`courses[${i}].hourBetweenTwoPlaces`,hour)
				 setHeur({
					item:hour,
					index:i
				 })
                const distance = await getDistanceBetweenTwoPlaces(
                origin,
                destination,
				token
              );

              setDistanceVector(
				new Map(
					distanceVector.set(
						`${i}-fin`,
						distance
					)
				)
			  )
			  setValue(`courses[${i}].distance`,distance)

			
            } catch (error) {
              console.log(error);
            }
                }
        }
        }}
		value={value}
       />
       )}
       />

				{distanceVector.get(`${i}-fin`) !== undefined && (
					<span style={{ marginTop: '1rem' }}>
						Nombre de Km depuis la derniere étapes:{' '}
						<strong>
							{(distanceVector.get(`${i}-fin`) || 0) / 1000}
						</strong>
					</span>
				)}
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].adresse	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				    Veuillez indiquer  le lieu  de transport 
					</div> 
				    }
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '2.5rem',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '50%',
						fontFamily: 'Montserrat',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Date du transport <span style={{ color: 'red' }}>*</span>
					</span>

					<Controller
						name={`courses[${i}].date`}
						rules={{ required: true }}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Flatpickr
								disabled={display}
								onChange={([date]) => {
									onChange(date)
									handleDateDebutChange(date)
								}}
								className='date-input'
								options={{
									mode: 'single',
									locale: French,
									minDate: moment().startOf('day').toDate(),
									dateFormat: 'd/m/Y',
								}}
								value={value}
							/>
						)}
					/>
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].date	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
						Veuillez indiquer la date de transport
					</div> 
				}
				</div>

				<div
					style={{
						width: '1rem',
					}}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						//marginBottom: '2.5rem',
						width: '50%',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Heure <span style={{ color: 'red' }}>*</span>
					</span>

					    <Controller
							name={`courses[${i}].heure`}
							rules={{ required: true }}
							control={control}
							render={({ field: { onChange, value } }) => (	
							<select value={value} onChange={onChange}
								style={{
										padding: '1rem',
										borderRadius: '0.5rem',
										color: '#858ea8',
										border: 'none',
										fontFamily: 'Montserrat',
										flex: '1',
										outline: 'none'
									 }}
								>	
								{i===1 ?
								filterEndTimeOptions(watch(`courses[${i}].hourBetweenTwoPlaces`),watch(`courses[${i-1}].heure_fin`),isAfterDay).map((option) => (
								  <option key={option.value} value={option.value}>
									{option.label}
								  </option>
								  
								))
								:
								filterEndTimeOptions2(watch(`courses[${i}].hourBetweenTwoPlaces`),watch(`courses[${i-1}].heure`),isAfterDay).map((option) => (
									<option key={option.value} value={option.value}>
									  {option.label}
									</option>
									
								  ))
							}
							</select>
							)}
						/>
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].heure	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				       Veuillez indiquer l'heure de transport
					</div> 
				}
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '2.5rem',
					width: '100%',
				}}
			>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					}}
				>
					Notes Utiles
				</span>
				<StyledTextArea
					disabled={display}
					type='text'
					rows={4}
					placeholder=''
					{...register(`courses[${i}].notes`)}
				/>
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].notes	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
					Veuillez indiquer les notes utiles 
					</div> 
				    }
			</div>
           
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					//marginBottom: '2.5rem',
					width: '50%',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Joindre Documents (PDF)
					</span>
					{!display && (
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							(Facultatif)
						</span>
					)}
				</div>

				{!display && (
					<UploadFileCommande
						initialFiles={watch(`courses[${i}].files`)}
						name={`file_${i}`}
						onChange={(files) => {
							if (keepFilesAsObjects) {
								setValue(
									`courses[${i}].files`,
									files.map((file) => file)
								)
							} else {
								setValue(
									`courses[${i}].files`,
									files.map((file) => file.id)
								)
							}
						}}
					/>
				)}

				{display &&
					(watch(`courses[${i}].files`) || []).map((file, index) => (
						<div
							onClick={() => {
								window.open(`${BASE_URL}/${file?.path}`)
							}}
							key={String(index)}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
								marginBottom: 20,
								cursor: 'pointer',
							}}
						>
							<img
								alt=''
								src={File}
								style={{
									height: 20,
									marginRight: '1rem',
								}}
							/>
							<span
								style={{
									marginRight: '1rem',
									width: '10rem',
								}}
							>
								{file?.name}
							</span>
						</div>
					))}
			</div>
			<div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				       Nom du  destinataire <span style={{ color: 'red' }}>*</span>				 
				</span>
				<StyledInput
							type='text'
							disabled={display}
							placeholder=''
							 {...register(`courses[${i}].lastNameDistinator`,
							 {required: 'Veuillez indiquer le nom',
							 minLength: {
								 value: 3,
								 message: 'Le nom  doit contenir au moins 3 caractères.',
							   },
							   pattern: {
								 value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
								 message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
							   },	
							})}
				/>
				 {  errors && errors.courses && errors.courses[i] && errors.courses[i].lastNameDistinator	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
					{errors.courses[i].lastNameDistinator.message}
					</div> 
				}
				</div>	
				
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				       Prénom du  destinataire <span style={{ color: 'red' }}>*</span>					 
				</span>
				<StyledInput
							type='text'
							disabled={display}
							pl aceholder=''
							 {...register(`courses[${i}].firstNameDistinator`,{
								required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
							 })}
				/>
				{  errors && errors.courses && errors.courses[i] && errors.courses[i].firstNameDistinator   &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				     {errors.courses[i].firstNameDistinator.message}
					</div> 
				}
				</div>
			</div>
			<div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				         Numéro de Téléphone 1 du destinataire <span style={{ color: 'red' }}>*</span>
				</span>
				<Controller
						       name={`courses[${i}].phone1Distinator`}
						       rules={
								{required: 'Veuillez indiquer le numéro de téléphone .',
							     pattern: {
								 value: /^[0-9]+$/, 
								 message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
								},}
							   }
						      control={control}
	
						      render={({ field: { onChange, value } }) => (
						 	   <PhoneInput 
								country='fr'
                                
								placeholder=''
								value={value}
								onChange={onChange}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'
								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>
						)}
                        />
				{   errors && errors.courses && errors.courses[i] && errors.courses[i].phone1Distinator  &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				     {errors.courses[i].phone1Distinator.message}
					</div> 
				}
				</div>	
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				         Numéro de Téléphone 2	du destinataire	<span style={{ color: 'red' }}>*</span>					 
				</span>
					<Controller
						       name={`courses[${i}].phone2Distinator`}
						       rules={
								{required: 'Veuillez indiquer le numéro de téléphone .',
							     pattern: {
								 value: /^[0-9]+$/, 
								 message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
								},}
							   }
						      control={control}
	
						      render={({ field: { onChange, value } }) => (
						 	   <PhoneInput 
								country='fr'
                               
								placeholder=''
								value={value}
								onChange={onChange}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>
						)}
                        />
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].phone2Distinator  &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				     {errors.courses[i].phone2Distinator.message}
					</div> 
				}
				</div>
				 </div>
		</div>
	)
}

export default SingleCourseStep

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutTransporter from '../../../Components/LayoutTransporter/LayoutTransporter'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { getSignalementForCommandeMiseADispo } from '../../../api/signalements';
import toast from 'react-hot-toast'

function SignalerTransporterAll() {
	const { id } = useParams()
	const history = useHistory()
	const [signalements, setSignalements] = useState([])
	const token = useSelector((state) => state.auth.token);
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [perPage, setPerPage] = useState(10)
	const [chargementEnCours, setChargementEnCours] = useState(false);

	const fetchData = async (page) => {
		setChargementEnCours(true);
		try {
			const res = await getSignalementForCommandeMiseADispo(id, token, page, perPage);
			setSignalements(res.docs);
			setTotalRows(res.totalDocs);
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false);
		}
       
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setChargementEnCours(true);
        const res = await getSignalementForCommandeMiseADispo(id, token, page, newPerPage);
        setSignalements(res.docs);
        setTotalRows(res.totalDocs);
        setPerPage(newPerPage);
        setChargementEnCours(false);
    };

	useEffect(() => {
        fetchData(currentPage);
    }, [id]);
	
	const columns = [
        {
            name: "Type d'anomalie",
            selector: (row) => row.type,
        },
        
        {
            name: 'Numéro de mission',
            selector: (row) => row.mission_index,
			
        },
        {
            name: "Adresse du point de passage",
            selector: (row) => row.points_passage.adresse?.label || 'Adresse non disponible',  // Assurez-vous que l'adresse est présente, sinon afficher un message par défaut
        },
		{
            name: "Chauffeur",
            sortable: false,
            cell: (row) => (
                <div 
                onClick={()=>{
                        history.push(`/chauffeurs/${row.chauffeur._id}`)


                }}
                style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color:'#51C7F2'
                    }}
                >
                  {row.chauffeur && `${row.chauffeur.first_name} ${row.chauffeur.last_name}`}
                </div>
              ),

        },

        {
            name: 'Actions',
            button: true,
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/transporter/signalement-details/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
					
					
			
                 
                </div>
            ),
        },
    ];
	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div
					style={{
						width: '100%',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '1rem',
							marginTop: '2rem',
						}}
					>
						<GradientText marginTop={'unset'}>
							{'signalement'.toUpperCase()}
						</GradientText>
						
					</div>
					{chargementEnCours ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white',marginTop:'10rem'}}>
                            <div className='customLoader'></div>
                        </div> :
			        <div style={{ width: '100%', padding: '0 1rem', marginTop: '4rem' }}>
					<DataTable
						columns={columns}
						data={signalements}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des signalements à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
					</div>
                    }
				</div>
			</div>
		</LayoutTransporter>
	)
}

export default SignalerTransporterAll

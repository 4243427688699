import React, { useEffect, useState } from 'react'
import {getCommande} from '../../api/commande'
import { useParams,useHistory } from 'react-router'
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles'
import Container from '../../Components/LayoutClient/container.component'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import MissionCard from '../MissionCard/MissionCard'

function ValiderSignature() {
    const  {id}=useParams()
    const history=useHistory()
    const token = useSelector((state) => state.auth.token);
    const [commande, setCommande] = useState(null)
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const [chargement,setChargement]=useState(false)

    async function _getCommandeWithLoading(id) {
        setChargementEnCours(true)
        try {
            const commande = await getCommande(id,token)
            setCommande(commande)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        }finally{
            setChargementEnCours(false)

        }
       

    }

	async function _getCommande(id) {
        try {
            const commande = await getCommande(id,token)
            setCommande(commande)
        } catch (error) {
          console.log(error)
        }
       

    }

	useEffect(() => {
		if (id) {
			_getCommandeWithLoading(id)
		}
	}, [id])
     useEffect(()=>{
		_getCommande(id)
	 },[chargement])
  return (
    <Container>
        <div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
                <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{`Valider la Signature`.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginTop:'10rem'
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div> 
                   :
                   <div
                style={{width:'90%',
                    marginTop:'1rem'
                }}>
            {commande?.missions.map((mission, index) => (
            <div key={index}
			style={{
						width: '100%',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						marginTop: '1rem',
						paddingLeft: '3rem',
						paddingRight: '3rem',
				}}
			>
		    <MissionCard mission={mission} index={index} commande={commande} setChargement={setChargement}/>
           </div>
           ))}
                   </div>
                   }
           </div>
    </Container>
  )
}

export default ValiderSignature
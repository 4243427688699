import {
	FormContentSection,
	FormSectionTitle,
	GradientText,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	requestSignatureFile,
} from '../../api/sign'
import { getCommande,updateStatutCommandeForChauffeur } from '../../api/commande'
import { useSelector } from 'react-redux'
import CommandeFormReadOnly from '../../Components/CommandeFormReadOnly'
import LayoutChauffeur from '../../Components/LayoutChauffeur/LayoutChauffeur'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal'

function CommandeStatus({ commande }) {
	const history = useHistory()
	const [statut, setStatut] = useState(commande.statut)
	const [loadingSignature, setLoadingSignature] = useState(false)
	const [showSignatureLoading, setShowSignatureLoading] = useState(false)
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpenStatus, setModalIsOpenStatus] = useState(false)
	const [modalIsOpenSignalier,setModalIsOpenStatusSignalier]=useState(false)
	const [modalIsOpenStatusInit,setModalIsOpenStatusInit]=useState(false)
	const [modalIsOpenStatusSendSMS,setModalIsOpenStatusSendSMS]=useState(false)
	const [modalIsOpenStatusSendSMSMisADISPO,setModalIsOpenStatusSendSMSMisADISPO]=useState(false)
	const [modalIsOpenStatusAnnuller,setModalIsOpenStatusAnnuller]=useState(false)
	const [modalIsOpenStatusInitTerminer,setModalIsOpenStatusInitTerminer]=useState(false)
    const [message,setMessage]=useState('')
	const [modalIsOpen,setModalIsOpen]=useState(false)
	return (
		<>
		<FormContentSection>
			<FormSectionTitle>Statut du transport</FormSectionTitle>
			<div
				style={{
					//width: '16%',
					display: 'flex',
					flexDirection: 'row',
					fontSize: 13,
				}}
			>
				<div
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<button
						type='button'
						onClick={() => {
							setStatut('en-cours')
						}}
						disabled={loadingSignature}
						style={{
							padding: '1rem',
							background:
								statut === 'en-cours' ? '#C752B6' : '#838EAB',
							color: statut === 'en-cours' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							fontSize: 20,
							cursor: 'pointer',
							border: 'none',
							fontFamily: 'Montserrat',
						}}
					>
						En cours
					</button>

					<button
						type='button'
						onClick={() => {
							if(commande.statut==="annuler"){
								setModalIsOpenStatusSignalier(true)
							}
							else if(commande.statut==="terminer"){
								setModalIsOpen(true)
							} else if(commande.statut==="init"){
								
								setModalIsOpenStatusInit(true)
							}else{
								history.push(
									`/chauffeur/transports/${commande._id}/signaler`
								)
							}
							
						}}
						disabled={loadingSignature}
						style={{
							padding: '1rem',
							background: commande?.signaler
								? '#FCC200'
								: '#838EAB',
							color: commande?.signaler ? 'white' : '#9EA8BF',
							cursor: 'pointer',
							borderRadius: 50,
							fontWeight: 'bold',
							fontSize: 20,
							border: 'none',
							fontFamily: 'Montserrat',
						}}
					>
						Signaler{' '}
						{commande?.signaler && `+${commande.signalement_count}`}
					</button>

					<button
						type='button'
						onClick={() => {
							if(commande.statut==="init"){
                              setModalIsOpenStatusInitTerminer(true)
							}
							else {
								setStatut('terminer')
							}
					

							}
						}
						disabled={loadingSignature}
						style={{
							padding: '1rem',
							background:
								statut === 'terminer' ? '#0FD39E' : '#838EAB',
							color: statut === 'terminer' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							fontSize: 20,
							cursor: loadingSignature ? 'wait' : 'pointer',
							border: 'none',
							fontFamily: 'Montserrat',
						}}
					>
						Terminer
					</button>
				</div>
			</div>

			{commande.signed && (
				<button
					type='button'
					onClick={async () => {
						setShowSignatureLoading(true)
						await requestSignatureFile(commande._id)

						//await downloadSignature(envelopeId, access_token)
						setShowSignatureLoading(false)
					}}
					style={{
						padding: '1rem',
						background: '#0FD39E',
						color: 'white',
						borderRadius: 50,
						fontWeight: 'bold',
						fontSize: 20,
						cursor: showSignatureLoading ? 'wait' : 'pointer',
						border: 'none',
						fontFamily: 'Montserrat',
						marginTop: '1rem',
					}}
				>
					{showSignatureLoading ? 'Chargement' : 'Voir signature'}
				</button>
			)}

			<div
				onClick={async () => {
					setLoadingSignature(true)
					try {
						await updateStatutCommandeForChauffeur(commande._id, statut,token);
						history.goBack()
					}catch(error){
                        setMessage(error.response.data.error)
                        setModalIsOpenStatus(true)
					}
					 finally {
						setLoadingSignature(false)
					}
					
				}}
				style={{
					fontSize: 20,
					background: '#50F5A9',
					padding: '1rem 2rem',
					marginTop: '2rem',
					color: 'white',
					borderRadius: 5,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
					fontWeight: 'bold',
				}}
			>
				{loadingSignature ? 'Chargement' : 'Enregistrer'} 
			</div>
		</FormContentSection>
		<Modal
    isOpen={modalIsOpenStatus}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
            {message}
          </div>

        <button
            onClick={() => {
                setModalIsOpenStatus(false);
				history.goBack()
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenSignalier}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
         Vous ne pouvez pas signaler une commande annulée.
		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusSignalier(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpen}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
         Vous ne pouvez pas signaler une commande terminer.
		 </div>

        <button
            onClick={() => {
                setModalIsOpen(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusInit}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
		 Vous ne pouvez pas signaler une commande avant de la démarrer.
		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusInit(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusSendSMS}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
Vous ne pouvez pas marquer la commande comme terminée tant que toutes les courses n'ont pas été livrées.		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusSendSMS(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusSendSMSMisADISPO}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
Vous ne pouvez pas marquer la commande comme terminée tant qu'elle n'a pas été livrée..		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusSendSMSMisADISPO(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusAnnuller}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
         Vous ne pouvez pas changer le statut d'une commande annulée.        </div>

        <button
            onClick={() => {
                setModalIsOpenStatusAnnuller(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusInitTerminer}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
			Vous ne pouvez pas définir le statut de la commande comme terminée avant qu'elle ne démarre.
		 </div>

        <button
            onClick={() => {
                
				setModalIsOpenStatusInitTerminer(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   
		</>
	)
}


function SingleTransportChauffeur(props) {
	const { id } = useParams()
	const token = useSelector((state) => state.auth.token);
    const [chargementEnCours,setChargementEnCours]=useState(false)
	const [commande, setCommande] = useState(null)
    const history = useHistory()
	useEffect(() => {
		async function _getCommande(id) {
            setChargementEnCours(true)
			const commande = await getCommande(id,token)
			setCommande(commande)
            setChargementEnCours(false)

		}

		if (id) {
			_getCommande(id)
		}
	}, [id])

	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
                  <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
                                history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								{...props}
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>
                       <GradientText marginTop={'unset'}>
							{`détails commande ${! chargementEnCours ? commande?.__id :'' }`.toUpperCase()}
						</GradientText>


					</div>
                 {chargementEnCours &&
                 <div
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   backgroundColor: 'white',
                   marginTop:"10rem"
                 }}
                 >
                 <div className='customLoader'></div>	
                 </div>
                 }
				<div
					style={{
						width: '65%',
					}}
				>
					{commande && commande?.type_commande==="course-a-course" && <CommandeStatus commande={commande} />}
				</div>

				

				{commande && (
					<CommandeFormReadOnly
						hideSubmit
						disabled
						hideSendSMS
						onSubmit={() => {}}
						preloadForm={true}
						preloadData={{ ...commande, prix: null }}
					/>
				)}
			</div>
			
			
		</LayoutChauffeur>
		
	)
}

export default SingleTransportChauffeur

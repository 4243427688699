import React,{useState,useEffect} from 'react'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import {getDemandeTransferById} from '../../api/commande'
import { useForm } from 'react-hook-form';
import {CustomInput} from '../../Containers/RegisterTransporter/style'
import { GradientText ,FormSectionCard,StyledTextArea} from '../../Containers/PasserCommande/PasserCommande.styles'
function DetailsDemandeTransfert() {
    const {id}=useParams()
    const { register,reset } = useForm();
    const token = useSelector((state) => state.auth.token);
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const [data,setData]=useState(null)

    const fetchData=async()=>{
        setChargementEnCours(true)
        try {
            const res=await getDemandeTransferById(id,token)
            setData(res)
            reset(res)
        } catch (error) {
           console.log(error)  
        }finally{
        setChargementEnCours(false)

        }
    }
    useEffect(()=>{
        fetchData()
    },[id])
  return (
    <LayoutAdmin>
        <div 
        style={{
            //height: '80vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '2rem',
            paddingBottom: '4rem',
            fontFamily: 'Montserrat',
        }}>
        <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Détails Demande de transfert'.toUpperCase()}
						</GradientText>
        </div>

        {chargementEnCours ?
        <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          marginTop:'10rem'
        }}
        >
        <div className='customLoader'></div>	
        </div> 
        :
        <div
			style={{
				width: '100%',
				padding: '0 1rem',
                marginTop:"3rem",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
			}}
				>
                    <form  style={{ width: '60%' }} >
					<FormSectionCard>

					<div
					 style={{
							display: 'flex',
							flexDirection: 'column',
							width:'100%',
							marginRight: '1rem',
							}}
					>
					 <span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
							}}
							>
						   Distance parcourue					 
					</span>
					<CustomInput
									type='number'
                                    disabled={true}
									placeholder=''
									min={1}
									{...register('distanceParcourue', { required: 'La distance est requise.' })}

								/>	

				    </div>	
					<div
					 style={{
							display: 'flex',
							flexDirection: 'column',
							width:'100%',
							marginRight: '1rem',
							marginTop:"1rem"
							}}
					>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
							}}
							>
						   Remarque						 
					</span>
					<StyledTextArea
						type='text'
                        disabled={true}
						rows={5}
						placeholder=''
						{...register('remarque', { required: 'La remarque est requise.' })}				
					/>


					</div>	
					</FormSectionCard>


					</form>
                </div>
        }
         </div>
    </LayoutAdmin>
  )
}

export default DetailsDemandeTransfert
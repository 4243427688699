import React from 'react'

function VerfiyEmail() {
  return (
    <div>
       <p>Nous avons bien vérifié votre adresse e-mail</p> 
       <p>Vous pouvez maintenant vous connecter à notre plateforme.</p>
    </div>
  )
}

export default VerfiyEmail
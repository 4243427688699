import React, {  useState } from 'react'
import { useSelector } from 'react-redux'
import  {updateStatutMission} from '../../api/commande'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'
import Modal from 'react-modal'
import moment from 'moment'


function MissionHorizontal({
	commande,
   	hideStatus = false,
    hideSignalement=false,
	onClickDetails = () => {},
    setUpdateStatus
}) {
	const token = useSelector((state) => state.auth.token);
    const  [loading,setLoading]=useState(false)
    const [modalIsOpenStatusEnCours,setModalIsOpenStatusEnCours]=useState(false)
    const [modalIsOpenStatusTerminer,setModalIsOpenStatusTerminer]=useState(false)
    const history=useHistory()
	


	return (
		<div 
		style={{
			width: '100%',
			background: '#E4EAF0',
			padding: '1rem',
			borderRadius: 10,
			marginBottom: 10,
			
		}}> 
		<div style={{
		marginBottom:'1rem',
		display: 'flex', 
		flexDirection: 'row', 
		justifyContent: 'space-between'
		}}>
		<span
		style={{
			fontWeight: 'bold',
			color: '#51C7F2',
			fontSize: 13,
		}}
	>
		Mise a disposition {commande ? 'N°' + commande.__id : '' } /{' '}
		<span style={{ color: 'red' }}>
			{commande.temporisation === 'immediat'
				? 'Immédiat'
				: 'Planifier'}
		</span>
	    </span>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
		<button
					onClick={onClickDetails}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						width:'130px',
					
					}}
				>
					Voir détails
		</button>
        
       </div>
		</div>
		<div>
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '100%' }}>
        {commande.missions.map((mission, index) => (
            <div key={index}> 
                <span
                    style={{
                        fontWeight: 'bold',
                        color: '#51C7F2',
                        fontSize: 13,
                    }}
                >
                    Mission N° {index + 1} {' '}
                </span>
                <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <div
                    style={{
                        width: '80%',
                        background: '#E4EAF0',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '1rem',
                        borderRadius: 10,
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            color: '#838EAB',
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 13,
                        }}
                    >
                        <span
                            style={{
                                marginRight: 13,
                                marginTop: "0.5rem",
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Adresse de début : {' '}
                            <span style={{ color: "red" }}>
                                {mission?.adresse_debut?.label}
                            </span>
                        </span>
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Adresse de fin : {' '}
                            <span style={{ color: "red" }}>
                                {mission?.adresse_fin?.label}
                            </span>
                        </span>
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de début : {' '}
                            <span style={{ color: "red" }}>
                                {mission?.date_debut} {mission?.heure_debut} {' '}
                            </span>
                        </span>
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de fin:{' '}
                            <span style={{ color: "red" }}>
                                {mission?.date_fin} {mission?.heure_fin} {' '}
                            </span>
                        </span>
                    </div>
					<div
                    
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
                    {!hideStatus && 
                    <>
					<span
						onClick={ () => {
                            if(mission.statut==="en-cours"){
                                toast.error("La mission est déjà démarrée", {
                                    style: {
                                        fontFamily: 'Montserrat',
                                    },
                                })
                            }else{
                                setModalIsOpenStatusEnCours(true)
                            }
                       }}
						style={{
							padding: '0.3rem 0.7rem',
							background:
								mission?.statut === 'en-cours' ? '#C752B6' : '#838EAB',
							color: mission?.statut === 'en-cours' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						En cours
					</span>
					<span
					onClick={ () => {
                       setModalIsOpenStatusTerminer(true)    
                }}
						style={{
							padding: '0.3rem 0.7rem',
							background:
								mission?.statut === 'terminer' ? '#0FD39E' : '#838EAB',
							color: mission?.statut === 'terminer' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						Terminer
					</span>
                    </>}

                    {mission?.date_debut_reel &&
					<span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de début réelle : {' '}
                            <span style={{ color: "red" }}>
                                {moment(mission?.date_debut_reel).format('DD/MM/YYYY HH:mm')}{' '}
                            </span>
                        </span>
                    }
                    {mission?.date_fin_reel &&
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de fin réelle:{' '}
                            <span style={{ color: "red" }}>
                            {moment(mission?.date_fin_reel).format('DD/MM/YYYY HH:mm')}{' '}
                            </span>
                        </span>
                    }
					
				</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                            fontSize: 13,
                        }}
                    >
                        <span
                            style={{
                                color: '#838EAB',
                                marginBottom: '0.5rem',
                            }}
                        >
                            Nombre de kilométrage :{' '}
                            <span style={{ color: 'red' }}>
                                {mission.kilometrage}
                            </span>
                        </span>
                        <span style={{ color: '#838EAB' }}>
                            Nombre de ripper en plus du chauffeur :{' '}
                            <span style={{ color: 'red' }}>
                                {(mission.nombre_rippeur === null || mission.nombre_rippeur === undefined) ? '0' : mission.nombre_rippeur}
                            </span>
                        </span>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '0.5rem',
                            }}
                        >
                            <span
                                style={{
                                    color: '#838EAB',
                                    marginBottom: '.5rem',
                                }}
                            >
                                {mission.type_transport}{' : '}
                                {mission.type_transport === "Poids lourds" ? mission.vehicule_poids_lourds : mission.type_transport_details}
                            </span>
                            <span style={{ color: '#838EAB', marginBottom: '.5rem' }}>
                                {mission.options_vehicule_leger.join(',')}
                            </span>
                            <span style={{ color: '#838EAB', marginBottom: '.5rem' }}>
                                {mission.options_poids_lourds.join(',')}
                            </span>
                        </div>
                    </div>
                    
                    
                             
                </div> 
                <div 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
			            alignItems: 'center',
			            justifyContent: 'center',
                        width:'20%'
                    }}>
                <>
                {mission?.statut==='en-cours' &&
                    <button
                    onClick={ () =>{
                        history.push(`/chauffeur/adresses/${mission._id}`)
                    }}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						width:'160px',
					
					}}
				>
					Ajouter adresse
		        </button>            
                }
                {!hideSignalement && 
                <button
                onClick={()=>{
                    history.push(`/chauffeur/mission/${mission._id}/single`)
                }}
                style={{
                    padding: '0.5rem 1.5rem',
                    background: '#51C7F2',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    marginTop: 5,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    width:'130px',
                
                }}
            >
             Signalements
              </button>
                }
                </>
                </div>
                
           
                </div>
                <Modal
        isOpen={modalIsOpenStatusEnCours}
        style={{
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.45)',
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: 19,
                padding: '2rem',
            },
        }}
        contentLabel='Example Modal'
    >
        <div
            style={{
                width: '20rem',
                fontFamily: 'Montserrat',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 15,
                textAlign: 'center',
            }}
        >
            <div
            style={{
                fontWeight: 'bold',
                fontFamily: 'Montserrat',
                fontSize:20,
                color: '#51C7F2',
            }}
            > Démarrer la mission</div>
            <div
                style={{
                    marginTop: 4,
                    width: '100%',
                }}
            >
                
                <p>Êtes-vous sûr de vouloir démarrer la mission ? </p>

            </div>
             <div
             style={{
                width: '100%',
                fontFamily: 'Montserrat',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '5px',
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
            <button
            
                onClick={async () => {	
                    setLoading(true)
                    try {
                       const response= await updateStatutMission(
                            mission._id,
                            mission.commande,
                            'en-cours',
                            token
                        )
                        toast.success(response.message, {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        })
                        setUpdateStatus(true)
                    } catch (error) {
                        toast.error(error.response.data.error, {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        })
                        
                    }finally{
                        setModalIsOpenStatusEnCours(false)
                        setLoading(false)
                    }
                      
                      
                    }}

                disabled={loading}
                style={{
                    width:"150px",
                    height:"50px",
                    background: '#50F5A9',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    marginTop: 5,
                    fontFamily: 'Montserrat',
                    marginRight:10,
                    fontWeight: 'bold',
                    display: 'flex',
                     justifyContent: 'center',
                    alignItems: 'center'
                }}

            >
             {loading ? (
                            <div className='loader'></div>
                        ) : (
                            'Confirmer'
                        )}
            </button>
            <button
                onClick={ () => {
                    setModalIsOpenStatusEnCours(false)
                                        
                }}
                style={{
                    width:"150px",
                    height:"50px",
                    background: 'red',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    marginTop: 5,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                }}
            >
                Annuler
            </button>
            </div>
            
        </div>
   </Modal>
   <Modal
        isOpen={modalIsOpenStatusTerminer}
        style={{
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.45)',
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: 19,
                padding: '2rem',
            },
        }}
        contentLabel='Example Modal'
    >
        <div
            style={{
                width: '20rem',
                fontFamily: 'Montserrat',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 15,
                textAlign: 'center',
            }}
        >
            <div
            style={{
                fontWeight: 'bold',
                fontFamily: 'Montserrat',
                fontSize:20,
                color: '#51C7F2',
            }}
            > Terminer la mission</div>
            <div
                style={{
                    marginTop: 4,
                    width: '100%',
                }}
            >
                
                <p>Êtes-vous sûr de vouloir terminer la mission ? </p>

            </div>
             <div
             style={{
                width: '100%',
                fontFamily: 'Montserrat',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '5px',
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
            <button
            
                onClick={async () => {	
                    setLoading(true)
                    try {
                        const response= await updateStatutMission(
                             mission._id,
                             mission.commande,
                             'terminer',
                             token
                         )
                         toast.success(response.message, {
                             style: {
                                 fontFamily: 'Montserrat',
                             },
                         })
                         setUpdateStatus(true)
                     } catch (error) {
                         toast.error(error.response.data.error, {
                             style: {
                                 fontFamily: 'Montserrat',
                             },
                         })
                         
                     }finally{
                        setModalIsOpenStatusTerminer(false)
                         setLoading(false)
                     }
                      
                    }}

                disabled={loading}
                style={{
                    width:"150px",
                    height:"50px",
                    background: '#50F5A9',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    marginTop: 5,
                    fontFamily: 'Montserrat',
                    marginRight:10,
                    fontWeight: 'bold',
                    display: 'flex',
                     justifyContent: 'center',
                    alignItems: 'center'
                }}

            >
             {loading ? (
                            <div className='loader'></div>
                        ) : (
                            'Confirmer'
                        )}
            </button>
            <button
                onClick={ () => {
                    setModalIsOpenStatusTerminer(false)
                                        
                }}
                style={{
                    width:"150px",
                    height:"50px",
                    background: 'red',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    marginTop: 5,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                }}
            >
                Annuler
            </button>
            </div>
            
        </div>
   </Modal>
            </div>
        ))}
    </div>
   
</div>
		
		</div>
		</div>
     
	)
}

export default React.memo(MissionHorizontal)

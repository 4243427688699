import Footer from '../../Components/Footer/footer.component'
import Logo from '../../assets/img/logo-TPE@2x.png'
import React,{useEffect} from 'react'
import { login } from '../../api/auth'
import { loginAction } from '../../redux/auth'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory,useLocation  } from 'react-router'
import { useSnackbar } from 'react-simple-snackbar'
import {BASE_URL} from '../../api/base'
import imagebg from '../../assets/img/bg@2x.png'
function SignInV2() {
	const history = useHistory()
	const location = useLocation();
	const dispatch = useDispatch()
	const { register, handleSubmit,formState: { errors, isValid, isDirty } } = useForm()
	const [openSnackbar] = useSnackbar({
		position: 'top-center',
		style: {
			color: 'black',
			background: 'white',
			fontFamily: 'Montserrat',
		},
		closeStyle: {
			fontSize: '2rem',
			color: '#51C7F2',
		},
	})
    useEffect(()=>{
		const getQueryParam = (name) => {
			const urlSearchParams = new URLSearchParams(location.search);
			return urlSearchParams.get(name);
		  };
		  const emailVerified = getQueryParam('emailVerified');
		  const emailVerifiedtransporter=getQueryParam('emailVerifiedtransporter');
		  if (emailVerified === 'true') {
			openSnackbar(
                 'Nous avons bien vérifié votre adresse e-mail. Vous pouvez maintenant vous connecter à notre plateforme.',
				 120000
			)
		  }
		  if(emailVerifiedtransporter==='true'){
			openSnackbar(
				"Nous avons bien vérifié votre adresse e-mail. Cependant, veuillez patienter jusqu'à ce que votre compte soit validé afin de pouvoir vous connecter à notre plateforme.",
				120000
			)
		  }
	},[])
	
	const onSubmit = async (data) => {
		try {
			const res = await login(data.email, data.password)
			dispatch(loginAction(res.user, res.token))

			if (res.user.role === 'chauffeur') {
				if(res.user.deletedAt !== null){
					openSnackbar(
						'Votre compte a été supprimé. Si vous avez des questions, veuillez nous contacter.',
						120000
					)
					return
				}
				if (res.user.status !== 'ok') {
					openSnackbar(
						`Nous vous avons envoyé un mail de confirmation à ${res.user.email}. Nous vous invitons à aller le consulter et cliquer sur le lien pour finaliser la création de votre compte`,
						120000
					)
					

					return
				}
				history.push('/chauffeur/transports')
			}

			if (res.user.role === 'client') {
				if (res.user.status !== 'ok') {
					openSnackbar(
						`Nous vous avons envoyé un mail de confirmation à ${res.user.email}. Nous vous invitons à aller le consulter et cliquer sur le lien pour finaliser la création de votre compte`,
						120000
					)
					

					return
				}
				if(res.user.deletedAt !== null){
					openSnackbar(
						'Votre compte a été supprimé. Si vous avez des questions, veuillez nous contacter.',
						120000
					)
					return
				}
				history.push('/commandes/new')
			}
			if (res.user.role === 'commercial') {
				if (res.user.status !== 'ok') {
					openSnackbar(
						`Nous vous avons envoyé un mail de confirmation à ${res.user.email}. Nous vous invitons à aller le consulter et cliquer sur le lien pour finaliser la création de votre compte`,
						120000
					)
					

					return
				}
				if(res.user.deletedAt !== null){
					openSnackbar(
						'Votre compte a été supprimé. Si vous avez des questions, veuillez nous contacter.',
						120000
					)
					return
				}
				history.push('/commerçant/client/new')
			}
			if (res.user.role === 'transporter') {
				if(res.user.deletedAt !== null){
					openSnackbar(
						'Votre compte a été supprimé. Si vous avez des questions, veuillez nous contacter.',
						120000
					)
					return
				}
				if (!res.user.emailVerified) {
					openSnackbar(
						`Nous vous avons envoyé un mail de confirmation à ${res.user.email}. Nous vous invitons à aller le consulter et cliquer sur le lien pour finaliser la création de votre compte`,
						120000
					);
				} else if (res.user.status !== 'ok') {
					openSnackbar(
						"Votre demande de création d'un compte transporteur sur notre plate-forme est en cours de traitement, vous recevrez prochainement un mail avec nos indications. À très vite 😉",
						120000
					);
				} else  {
					history.push('/transports');
				}
			}
				
			
			if ((res.user.role === 'admin') || (res.user.role === 'super admin')|| (res.user.role === 'admin distribution')||(res.user.role==='admin service après vente')) {
				if (res.user.status !== 'ok') {
					openSnackbar(
						`Nous vous avons envoyé un mail de confirmation à ${res.user.email}. Nous vous invitons à aller le consulter et cliquer sur le lien pour finaliser la création de votre compte`,
						120000
					)


					return
				}
				if(res.user.deletedAt !== null){
					openSnackbar(
						'Votre compte a été supprimé. Si vous avez des questions, veuillez nous contacter.',
						120000
					)
					return
				}
				history.push('/admin/clients')
			}
			if(res.user.role==='admin comptabilité'){
				if (res.user.status !== 'ok') {
					openSnackbar(
						`Nous vous avons envoyé un mail de confirmation à ${res.user.email}. Nous vous invitons à aller le consulter et cliquer sur le lien pour finaliser la création de votre compte`,
						120000
					)


					return
				}
				if(res.user.deletedAt !== null){
					openSnackbar(
						'Votre compte a été supprimé. Si vous avez des questions, veuillez nous contacter.',
						120000
					)
					return
				}
				history.push('/admin/plateforme/facturation')
			}
		} catch (error) {
			console.log(error)

			toast.error(
				'Erreur de connexion, veuillez vérifier votre email et votre mot de passe.',
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}
	}
	

	return (
		<div>
			<div
				style={{
					height: '95vh',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					background:
					`url(${imagebg})`,
					backgroundSize: 'auto',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<div
					style={{
						width: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: 50,
					}}
				>
					<img src={Logo} style={{ height: 80 }} alt='' />
				</div>

				<div
					style={{
						padding: '1rem',
						height: '80%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						//background: 'red',
						fontFamily: 'Montserrat',
					}}
				>
					<form
						onSubmit={handleSubmit(onSubmit)}
						style={{
							background: '#E4EAF0',
							// width: '25rem',
							// height: '20rem',
							display: 'flex',
							flexDirection: 'column',
							padding: '3rem 4rem',
							borderRadius: 30,
						}}
					>
						<label
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
							}}
						>
							<span
								style={{
									marginBottom: 7,
									fontSize: 14,
								}}
							>

							


								Adresse email 

							


							</span>
							<input
								{...register('email', {
									required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#95989A',
									borderRadius: 5,
									border: 'none',
									background: 'white',
								}}
								name='email'
								placeholder='Email'
								type='text'
							/>
							{errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
						</label>

						<label
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: 7,
										fontSize: 14,
									}}
								>
									Mot de passe 
								</span>
								<span
									style={{
										//marginBottom: 7,
										fontSize: 12,
										cursor: 'pointer',
									}}
									onClick={()=>history.push('/forgot')}

								>
									Mot de passe oublié ?
								</span>
							</div>
							<input
								{...register('password', {
									required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#95989A',
									borderRadius: 5,
									border: 'none',
									background: 'white',
								}}
								name='password'
								placeholder='Mot de passe'
								type='password'
							/>
							{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
						</label>

						<button
							style={{
								color: 'white',
								fontFamily: 'Montserrat',
								background: '#51C7F2',
								border: 'none',
								padding: '1rem',
								cursor: 'pointer',
								fontWeight: 'bold',
								borderRadius: 6,
								marginBottom: '1rem',
							}}
							type='submit'
						>
							Connexion
						</button>

						<span
							onClick={() => {
								history.push('/CreateAccount')
							}}
							style={{
								fontSize: 14,
								cursor: 'pointer',
							}}
						>
							Vous n'avez pas de compte ? Inscrivez-vous
						</span>
					</form>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default SignInV2

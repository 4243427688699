import React, { useEffect, useState } from 'react'

import CourseACourseHorizontal from '../../Components/SingleMiseADispoHorizontalForChauffeur/CourseACourseHorizontal'
import MiseADispoHorizontal from '../../Components/SingleMiseADispoHorizontalForChauffeur/MiseADispoHorizontal'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutChauffeur from '../../Components/LayoutChauffeur/LayoutChauffeur'
import { getCommandesByChauffeur,getCommandeByCityForChauffeur,getCommandeByIdForChauffeur,getCommandeBydateForChauffeur } from '../../api/commande'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import AutocompleteAddress from '../../Components/GooglePlacesAutoComplete/AutocompleteAddress'
import Modal from 'react-modal'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'

const SIZE = 10
function ListTransportsChauffeurs(props) {
	const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const user = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [updateStatus,setUpdateStatus]=useState(false)
	const [adresseValue, setAdresseValue] = useState('');
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedDate, setSelectedDate] = useState(null);
	const [modalIsOpenStatus, setModalIsOpenStatus] = useState(false)
	const [modalIsOpenStatusAnnuller,setModalIsOpenStatusAnnuller]=useState(false)
	const [modalIsOpenSignalier,setModalIsOpenStatusSignalier]=useState(false)
	const [modalIsOpenStatusInit,setModalIsOpenStatusInit]=useState(false)
	const [modalIsOpenStatusInitTerminer,setModalIsOpenStatusInitTerminer]=useState(false)
    const [message,setMessage]=useState('')
	const [modalIsOpen,setModalIsOpen]=useState(false)
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const [total, setTotal] = useState(1)
	const [page, setPage] = useState(1)
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 }
	 async function fetchCommandes() {
        setChargementEnCours(true)
		const res = await getCommandesByChauffeur(user._id,token,page,SIZE)
        setCommandes(res.docs)
      
        setTotal(res.totalDocs)
		setSearchQueryId('')
        setAdresseValue('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		  setSearchCriteria("id")
          setChargementEnCours(false)

	}
    const handlePageChange = async (newPage) => {
        setPage(newPage);
        setChargementEnCours(true);

        let res;
        switch (searchCriteria) {
            case 'id':
                if (searchQueryId.length === 0) {
                    res = await getCommandesByChauffeur(user._id, token, newPage, SIZE);
                } else {
                    res = await getCommandeByIdForChauffeur(user._id, searchQueryId, token, newPage, SIZE);
                }
                break;
            case 'city':
                res = await getCommandeByCityForChauffeur(user._id, adresseValue.label, token, newPage, SIZE);
                break;
            case 'date':
                res = await getCommandeBydateForChauffeur(user._id, selectedDate, token, newPage, SIZE);
                break;
            default:
                res = await getCommandesByChauffeur(user._id, token, newPage, SIZE);
                break;
        }

        setCommandes(res.docs);
        setTotal(res.totalDocs);
        setChargementEnCours(false);
    };
	useEffect(() => {
		handlePageChange(page)
	}, [page])
	useEffect(()=>{
		fetchCommandes()	
	  },[updateStatus])
	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>MES TRANSPORTS</GradientText>

				<div
					style={{
						width: '90%',
						fontFamily: 'Montserrat',
						minHeight: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '1rem',
					}}
				>
					<div
						style={{
							width: '100%',
							fontFamily: 'Montserrat',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							marginBottom: '2rem',
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					
					>
						<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							// paddingLeft: '1rem',
							// paddingRight: '1rem',
							// marginBottom: '2rem',
							// marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'city', label: "Recherche par ville " },
								{ value: 'date', label: 'Recherche par Date du transport' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>	
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
						
						/>
					) : null}
					{searchCriteria==="city" ?(
                     <div style={{ width: '20rem',}}> 
                     <AutocompleteAddress 
                     onPlaceSelect={(placeDetails) => {
                      }}
                      onValueChange={async (selectedValue) => {
                        if (selectedValue) {
                            setAdresseValue(selectedValue);
                        }}}
                        value={adresseValue}

                      />
                     </div>
                    ): null}
                    
					
					{searchCriteria === 'date' ? (
							<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={selectedDate}
							type="date"
							onChange={(e) => setSelectedDate(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					<button
					onClick={async () => {
						if (
							searchCriteria === 'id' &&
							searchQueryId.length === 0
						) {
							return
						}
						let res
						if (searchCriteria === 'id') {
							res =
								await getCommandeByIdForChauffeur(
									user._id,
									searchQueryId,
									token
								)
						}
						if (searchCriteria === 'city') {
							res =
								await getCommandeByCityForChauffeur(
									user._id,
									adresseValue.label,
									token,
                                    page,
                                    SIZE
								)
						}
						if (searchCriteria === 'date') {
							res =
								await getCommandeBydateForChauffeur(
									user._id,
									selectedDate,
									token,
                                    page,
                                    SIZE
									
								)
								
						}
						setCommandes(res.docs)
                        setTotal(res.totalDocs ?res.totalDocs :1)


					} }
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
					onClick={async () => {
						await fetchCommandes()
					}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					</div>
                    {chargementEnCours ?
                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginLeft:"25rem",
                      marginTop:"10rem"
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div>
                    :
					<div
	style={{
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		height: '100%',
		alignItems: 'flex-start',
		marginBottom: '2rem',
	}}
>
	{(commandes?.length === 0) ? (
		<span
			style={{
				display: 'flex',
				textAlign: 'center',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
			}}
		>
			Il n'y a pas de commandes ou de missions à afficher
		</span>
	) : (
		
			commandes?.map((commande) => {
				if (commande.type_commande === 'course-a-course') {
					return (
						<CourseACourseHorizontal
							key={commande._id}
							commande={commande}
							enableSaveStatus
							setModalIsOpenStatus={setModalIsOpenStatus}
							setUpdateStatus={setUpdateStatus}
							setMessage={setMessage}
							setModalIsOpenStatusAnnuller={setModalIsOpenStatusAnnuller}
							setModalIsOpenStatusInitTerminer={setModalIsOpenStatusInitTerminer}
							onClickDetails={() => {
								history.push(`/chauffeur/transports/${commande._id}`)
							}}
							onClickSignaler={() => {
								if (commande.statut === "init") {
									setModalIsOpenStatusInit(true)
								} else {
									history.push(`/chauffeur/transports/${commande._id}/signaler`)
								}
							}}
						/>
					)
				}
				return (
                    <MiseADispoHorizontal
                    key={commande._id}
                    commande={commande}
                    hideSignalement
                    setUpdateStatus={setUpdateStatus}
                    onClickDetails={() => {
                        history.push(`/chauffeur/transports/${commande._id}`)
                    }}
                    />
                );
			})
			
		   
	       )}
          </div>

                    }
				</div>
                <div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={total>SIZE ? Math.ceil(total / SIZE):1}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>
			</div>
        
                 
			<Modal
    isOpen={modalIsOpenStatus}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
            {message}
          </div>

        <button
            onClick={() => {
                setModalIsOpenStatus(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusAnnuller}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
         Vous ne pouvez pas changer le statut d'une commande annulée.        </div>

        <button
            onClick={() => {
                setModalIsOpenStatusAnnuller(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenSignalier}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
         Vous ne pouvez pas signaler une commande annulée.
		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusSignalier(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpen}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
         Vous ne pouvez pas signaler une commande terminer.
		 </div>

        <button
            onClick={() => {
                setModalIsOpen(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusInit}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
		 Vous ne pouvez pas signaler une commande avant de la démarrer.
		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusInit(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatusInitTerminer}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
			Vous ne pouvez pas définir le statut de la commande comme terminée avant qu'elle ne démarre.
		 </div>

        <button
            onClick={() => {
                
				setModalIsOpenStatusInitTerminer(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		</LayoutChauffeur>
	)
}

export default ListTransportsChauffeurs

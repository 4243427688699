import {
	GradientText,
	Wrapper,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	getAllSignalementsByCommandeID,
} from '../../../api/signalements'
import { useHistory, useParams } from 'react-router-dom'
import Logo from '../../../assets/img/logo-TPE.png'

import Container from '../../../Components/LayoutClient/container.component'

import Modal from 'react-modal'

import { useSelector } from 'react-redux'
import { getCommande } from '../../../api/commande'
import DataTable from 'react-data-table-component'

function SignalerClientAll(props) {
	const { id } = useParams()
	const history = useHistory()
	const [signalements, setSignalements] = useState([])
	const [commande,setCommande]=useState(null)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [perPage, setPerPage] = useState(10)
	const [loading,setLoading]=useState(false)
	const [isOrderCanceled, setIsOrderCanceled ] = useState(false)
	const token = useSelector((state) => state.auth.token);
	const fetchData = async (page) => {
		setChargementEnCours(true)
		const res = await getAllSignalementsByCommandeID(id,token,page,perPage)
		setSignalements(res.docs)
		const data = await getCommande(id,token)
		setCommande(data)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)

	}
	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchData(page)	
	}	
	const handlePerRowsChange = async (newPerPage,page) => {
		setChargementEnCours(true)
		const res = await getAllSignalementsByCommandeID(id,token,page,perPage)
		setSignalements(res.docs)
		const data = await getCommande(id,token)
		setCommande(data)
		setTotalRows(res.totalDocs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}
  
	const columns = [
		{
			name: "Type d'anomalie ",
			selector: (row) => row.type,
			sortable: false,
		},
		{
			name: "Temps du retard",
			selector: (row) => ((commande?.type_commande==="course-a-course" && row.type!=="commentaire" && row.type!=="course") ? row.duration:'__' ),
			sortable: false,
		},
		{
			name: "Temps supplémentaire",
			selector: (row) => ((commande?.type_commande==="mise-a-disposition" && row.type!=="commentaire" && row.type!=="course") ? row.duration:'__' ),
			sortable: false,
		},	
		{
			name: "Statut du paiement",
			selector: (row) =>( (row.type ==="commentaire" || row.type==="course") ? '__' : row.paymentStatus ) ,
			width: '160px',
			sortable: false,
		},
		{
			name: 'Note',
			selector: (row) => row.note,
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
						<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/client/signalement-details/${commande?._id}/${row?._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
					
					
					
					
				</div>
			),
		},
	]
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const success = params.get('success');
		console.log({document})
		if (success === 'true') {
			setModalIsOpen(true)
			setIsOrderCanceled(false)
		}
		if (success === 'false') {
			setModalIsOpen(true)
			setIsOrderCanceled(true)
		}
	},[])
	useEffect(() => {
		fetchData(currentPage)
	}, [])

	return (
		<Container>
			<Wrapper>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						paddingTop: '2rem',
						paddingBottom: '4rem',
						fontFamily: 'Montserrat',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								{...props}
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'signalement'.toUpperCase()}
						</GradientText>
					</div>
					{chargementEnCours ?
					<div
					style={{
					  display: 'flex',
					  alignItems: 'center',
					  justifyContent: 'center',
					  backgroundColor: 'white',
					  marginTop:"3rem",
					  top: 0,
					  left: 0,
					  right: 0,
					  bottom: 0,
		   
					}}
					>
					<div className='customLoader'></div>	
					</div>
					:
					<div
					    style={{
						width: '100%',
						padding: '0 1rem',
						marginTop:'4rem'
					}}
				    >
                        <DataTable
						columns={columns}
						data={signalements}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des signalements à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				    </div>
                    }
					
				</div>
			</Wrapper>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
					<img
					style={{
						width: '10rem',
						backgroundColor: 'grey',
						padding: '2px'
					}}
					alt='logo'
					src={Logo}
				/>
						<h1>{isOrderCanceled ? "Erreur de paiement": "paiement effectué avec succès"}</h1>
					</div>

					<button
						onClick={() => setModalIsOpen(false)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</Container>
	)
}

export default SignalerClientAll

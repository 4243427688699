import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	getSingleTransporter,
	updateTransporterAccount,
} from '../../api/transporteurs'
import { useDispatch, useSelector } from 'react-redux'

import AddVehicleRegister from '../../Components/AddVehicle/AddVehicleRegister'
import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import Modal from 'react-modal'
import { BASE_URL } from '../../api/base'
import UploadFile from '../../Components/UploadSingleFile/upload.component'
import toast from 'react-hot-toast'
import { updateProfile } from '../../redux/auth'
import { useForm ,Controller } from 'react-hook-form'


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function TransporterAccount() {
	const { _id } = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);
	const [chargementEnCours, setChargementEnCours] = useState(false);
    const [loading,setLoading]=useState(false)

	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		control,
		formState: { errors },
	} = useForm()

	const [transporter, setTransporter] = useState()
	const [files, setFiles] = useState({
		fiscale: null,
		urssaf: null,
		assurance_marchandises: null,
		kbis: null,
		licence_moins35t: null,
		licence_plus35t: null,
		piece_identite: null  ,
		logo:null
	  });
	
	  const handleFileChange = (files) => {
		setFiles(prevFiles => ({
		  ...prevFiles,
		  [files.type]: files.file
		}));
	  };

	  async function fetchTransporter(_id) {
		setChargementEnCours(true)
		try {
			const data = await getSingleTransporter(_id,token)
			setTransporter(data)
			reset(data)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
	
		

	}
	useEffect(() => {
		fetchTransporter(_id)		
	}, [_id])

	
	

	
	



	const onSubmit = async (data) => {
		setLoading(true)
		const modifiableData = {
			
			quality: data.quality,
			address: data.address,
			city: data.city,
			postalCode: data.postalCode,
			phone: data.phone,
			password:data.password,
			rippeurs:data.rippeurs,
			manutention:data.manutention,
			vehicules:data.vehicules

		}
		if (modifiableData.password.length === 0) {
			delete modifiableData.password
		}
		try {
			
			const res = await updateTransporterAccount(_id,modifiableData,files,token)
			
			toast.success('Votre compte a été mis à jour', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
			dispatch(updateProfile(res))
			fetchTransporter(_id)			
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setLoading(false)
		}
	}


	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<Wrapper>
					<GradientText>MON COMPTE</GradientText>
				</Wrapper>
				{chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:"10rem"
				}}
				>
				<div className='customLoader'></div>	
				</div>
				:
				
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							//width: '60%',
							fontFamily: 'Montserrat',
							alignSelf: 'center',
						}}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormSectionCard>
								<div style={{
									display: 'flex',
									flexDirection: 'row',
								}}>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
										marginRight:'1rem'
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Wayne'
										{...register('societe', {
											required: true,
										})}
									/>
								</div>

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Doe'
										{...register('last_name', {
											required: true,
										})}
									/>
								</div>
								</div>
								<div style={{
									display: 'flex',
									flexDirection: 'row',
								}}>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
										marginRight:'1rem'
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='John'
										{...register('first_name', {
											required: true,
										})}
									/>
								</div>

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité
									</span>
									<CustomInput
										type='text'
										//disabled
										placeholder='Directeur d’exploitation'
										{...register('quality', {
											required: 'Veuillez indiquer la qualité du poste.',
											minLength: {
												value: 3,
												message: 'La qualité doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'La qualité doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
										{errors.quality && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                      {errors.quality.message}
                                    </div>
                                     )}
								</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse
									</span>
									<CustomInput
										type='text'
										//disabled
										placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
										{...register('address', {
											required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^[A-Za-z0-9\s',-]+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
										})}
									/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
								</div>
								<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									placeholder=''
									{...register('city', {
										required: 'Veuillez indiquer la ville.',
										minLength: {
										   value: 3,
										   message: 'La ville doit contenir au moins 3 caractères.',
										 },
										 pattern: {
										   value: /^.+$/,
										   message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										 },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal 
								</span>
								<CustomInput
									type='text'
									placeholder=''
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>
                                <div style={{
									display: 'flex',
									flexDirection: 'row',
								}}>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
										marginRight:"1rem"
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Email
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='test@gmail.com'
										{...register('email', {
											required: true,
										})}
									/>
								</div>

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
										type='text'
										placeholder='(0) 01 45 66 32 88'
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                                    )}
								</div>
								</div>
								<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							    >
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Changer mon mot de passe
										</span>
									</div>
									<CustomInput
										//disabled
										autoComplete='new-password'
										placeholder='Saisir mot de passe'
										{...register('password', {
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                                    )}
								</div>
								<div style={{ width: '1rem' }} />

								
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
										marginLeft: 10,
										marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Logo(PNG ou JPG)
										</span>
										<span
											style={{
												marginBottom: '1rem',
												color: '#838EAB',
												fontSize: 14,
											}}
										>
											(Facultatif)
										</span>
									</div>
									<UploadFile
										name='logo'
										accept='.jpg, .png'
										onFile={handleFileChange}
									/>
									{watch('logo') &&
									watch('logo')?.mimetype !==
										'application/pdf' && (
										<img
											style={{
												width: 100,
												height: 100,
												borderRadius: 5,
												objectFit: 'cover',
											}}
											src={`${BASE_URL}/${watch("logo")?.path}`}
										/>
									)}
									{watch('logo') &&
									watch('logo').mimetype ==
										'application/pdf' && (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${watch('logo').path}`
													)
												}}
											>
												{watch('logo')?.name}
											</span>
										</div>
									)}
								</div>

								

								
							</FormSectionCard>

							<div style={{ height: '2rem' }} />

							
							<Controller
                            name="vehicules"
                            control={control}
                            rules={{ required: 'Veuillez sélectionner au moins un véhicule.' }}  
                            render={({ field }) => (
                            <>
                            <AddVehicleRegister
								initialVehicles={transporter?.vehicules}
								onChange={(items) => {
									setValue('vehicules', items)
									
								}}
							/>
                           {errors.vehicules && (
                           <p style={{ color: 'red' }}>{errors.vehicules.message}</p>
                          )}
                           </>
                            )}
                           />

							<div style={{ height: '2rem' }} />

							<FormContentSection>
								<FormSectionTitle>Rippeur</FormSectionTitle>
								<FormSectionCard>
									<div
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
											//marginBottom: '2.5rem',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Combien de rippeur pouvez-vous en
											plus du chauffeur ?
										</span>

										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<div>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='13.605'
													height='27.839'
													viewBox='0 0 13.605 27.839'
													style={{
														marginRight: '.5rem',
													}}
												>
													<g
														id='user'
														transform='translate(0.5 0.5)'
													>
														<path
															id='Path_11624'
															d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
															transform='translate(-25.195 -1.992)'
															fill='#51c7f2'
															stroke='#51c7f2'
															strokeWidth='1'
														/>
														<path
															id='Path_11625'
															d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
															transform='translate(-20.562 -14.552)'
															fill='#51c7f2'
															stroke='#51c7f2'
															strokeWidth='1'
														/>
														<path
															id='Path_11626'
															d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
															transform='translate(-30.081 -21.807)'
															fill='#51c7f2'
															stroke='#51c7f2'
															strokeWidth='1'
														/>
														<path
															id='Path_11627'
															d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
															transform='translate(-24.794 -21.807)'
															fill='#51c7f2'
															stroke='#51c7f2'
															strokeWidth='1'
														/>
													</g>
												</svg>
											</div>

											<CustomInput
												width={'100%'}
												type='number'
												
												min={0}
												{...register('rippeurs')}
											/>
										</div>
									</div>
								</FormSectionCard>
							</FormContentSection>

							<div style={{ height: '2rem' }} />

							<FormContentSection>
								<FormSectionTitle>
									Matériels supplémentaire
								</FormSectionTitle>
								<FormSectionCard>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											//marginBottom: '2.5rem',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Matériels de manutention
										</span>

										<div
											style={{
												display: 'flex',
												flexWrap: 'wrap',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											{[
												'Diable',
												'Transpalette manuel',
												'Transpalette électrique',
												'Sangle',
											].map((item, index) => (
												<div
													key={String(index)}
													style={{
														width: '30%',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
														marginBottom: '1rem',
													}}
												>
													<input
														type='checkbox'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'manutention',
															{
																required: true,
															}
														)}
														value={item}
													/>

													<span>{item}</span>
												</div>
											))}
										</div>
										{errors.manutention && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',marginBottom:'1rem',fontSize:"14px" }}>
                                      Veuillez sélectionner au moins un matériel de manutention.
                                       </div>
                                   )}
									</div>

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											//marginBottom: '2.5rem',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Epi
										</span>

										<div
											style={{
												display: 'flex',
												flexWrap: 'wrap',
												flexDirection: 'row',
												//marginBottom: '1rem',
											}}
										>
											{[
												'Casque',
												'Gants',
												'Masque',
												'Lunettes',
												'Chaussure de sécurité',
												'Gilet de visibilité',
											].map((item, index) => (
												<div
													key={String(index)}
													style={{
														width: '25%',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
														marginBottom: '1rem',
													}}
												>
													<input
														type='checkbox'
														style={{
															marginRight: '1rem',
														}}
														{...register('epi', {
															required: true,
														})}
														value={item}
													/>

													<span>{item}</span>
												</div>
											))}
										</div>
									</div>
									{errors.epi && (
                                    <div style={{ color: 'red', marginTop: '0.5rem' }}>
                                     Veuillez sélectionner au moins un équipement de protection individuelle.
                                     </div>
                                     )}
								</FormSectionCard>
							</FormContentSection>
							<div style={{height:'2rem'}}/>
							<FormContentSection>
                        <FormSectionTitle>Documents Administratifs</FormSectionTitle>
                        <FormSectionCard>
                        <div   
                        style={{
                         display: 'flex',
                         flexDirection: 'column', 
                         marginBottom: '1rem',
                        }}
                        >
                       {[
                       { name: 'fiscale', label: 'Attestation de régularité fiscale (PDF)' },
                       { name: 'urssaf', label: 'Attestation de vigilance Urssaf (PDF)' },
					   { name: 'assurance_marchandises', label: 'Assurance marchandises (PDF)' },
					   { name: 'kbis', label: 'Kbis (PDF)' },
                       { name: 'licence_moins35t', label: 'Licence de transport de marchandises de moins de 3,5t (PDF)' },
                       { name: 'licence_plus35t', label: 'Licence de transport de marchandises de plus de 3,5t (PDF)' },
					   { name: 'piece_identite', label: "Pièce d'identité (PDF)" },
                    
                       ].reduce((rows, field, index, array) => {
                       if (index % 2 === 0) {
                        rows.push(array.slice(index, index + 2));
                        }
                        return rows;
                       }, []).map((pair, index) => (
                       <div
                        key={index}
                        style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        }} 
                        >
                        {pair.map((field) => (
                        <div
                         key={field.name}
                         style={{
                         display: 'flex',
                         flexDirection: 'column',
                         width: 'calc(50% - 1rem)',
                         fontFamily: 'Montserrat',
                         marginLeft: 10,
                         marginTop: 10,
                        }}
                        >
                       <span
                        style={{
                        marginBottom: '1rem',
                        color: 'black',
                        fontSize: 14,
                        }}
                        >
                       {field.label}
                       </span> 
                      <UploadFile
                        name={field.name}
                        accept={'.pdf'} 
                        onFile={handleFileChange}
                      />
					  
					  <div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${watch(field.name)?.path}`
													)
												}}
											>
												{watch(field.name)?.name}
												
											
											</span>
					 </div>
            </div>
          ))}
        </div>
      ))}
    </div>
                        </FormSectionCard>
                         </FormContentSection>
							<div style={{ height: '1rem' }} />
							<SubmitButtonContainer>
								<SubmitButton type='submit' disabled={loading}>
								{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
								</SubmitButton>
							</SubmitButtonContainer>
						</form>
					</div>
				</div>
                } 
			</div>
		</LayoutTransporter>
	)
}

export default TransporterAccount

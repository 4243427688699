import 'flatpickr/dist/themes/material_blue.css'

import {
    FormSectionCard,
	FormContentSection,
	FormSectionTitle,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'
import AddCourse from '../../assets/img/add_course@2x.png'
import Agrandir from '../../assets/img/agrandir@2x.png'
import MissionsMiseADispoReadOnly from './MissionsMiseADispoReadOnly'
import Reduire from '../../assets/img/reduire@2x.png'
import _ from 'lodash'
import { totalDistance } from '../../utils/price'
import { cancelMission } from '../../api/commande'
import { useAtom } from 'jotai'
import MiseADispoReadOnly from './MiseADispoReadOnly'
import Modal from 'react-modal'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'


function InformationsMiseADispoReadOnly({
	unregister,
	register,
	watch,
	setValue,
	initialNumberOfMissions = 1,
	display = false,
	keepFilesAsObjects = false,
	control,
	getValues,
	errors,
	setHeur,
	isStepOne,
    isActiverRippeur,
}) {
	const [distance, setDistance] = useAtom(totalDistance)
	const [modalIsOpenCanceled, setModalIsOpenCanceled] = useState(false);
	const [numberOfMissions, setNumberOfMissions] = useState(initialNumberOfMissions)
	const [collapsedItems, setCollapsedItems] = useState([])
    const [selectedMission,setSelectedMission]=useState(null)
	const [loading,setLoading]=useState(false)
	const token = useSelector((state) => state.auth.token);

    const _missions = useRef([]);
    const missions = watch('missions');
    
    if (missions) {
        _missions.current = missions;
      }
      
	useEffect(() => {
		setNumberOfMissions(initialNumberOfMissions)
	}, [initialNumberOfMissions])
	useEffect(() => {
		if(!isStepOne  ){
		localStorage.setItem('numberOfMissions', numberOfMissions?.toString());
		}
		if(isStepOne && numberOfMissions!==1 ){
		localStorage.setItem('numberOfMissions', numberOfMissions?.toString());
		}
		  }, [numberOfMissions]);
		  
			
	  
	  useEffect(() => {

		if(isStepOne  ){
			const storedNumberOfMissions = localStorage.getItem('numberOfMissions');
			setNumberOfMissions(parseInt(storedNumberOfMissions))
		}
		
	  }, []);
	
	const [distanceVector, setDistanceVector] = useState(new Map())
  
	useEffect(() => {
		setDistance(_.sum(Array.from(distanceVector.values())))
	}, [distanceVector])
	
    
    


	return (
		<div>
			

			{[...Array(parseInt(numberOfMissions || 1)).keys()].map((i) => (
				
				<div key={String(i)}>
				
				<div
				
			style={{
				display: 'flex',
				flexDirection: 'row',
				marginTop:'1rem'
			}}
		>
			<FormSectionTitle>
			<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
			Informations de transport mission {i+1}
			<button
			        onClick={()=>{
						setSelectedMission(missions[i]._id)
						setModalIsOpenCanceled(true)}}
					style={{
						marginLeft:".5rem",
						padding: '0.5rem 1.5rem',
						background:'red' ,
						border: 'none',
						cursor: 'pointer',
						borderRadius: 7,
						color:  'white' ,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Annuler
			</button>
		    </div>
				</FormSectionTitle>

			
			</div>
				<FormSectionCard >
					
					<div
						onClick={() =>
							setCollapsedItems(
								collapsedItems.filter((x) => x != i)
							)
						}
						style={{
							position: 'absolute',
							top: 10,
							right: 10,
							cursor: 'pointer',
							display: !collapsedItems.includes(i)
								? 'none'
								: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<span>Agrandir</span>

						<img
							src={Agrandir}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
					</div>

					
					{(!display && numberOfMissions-1===i) && (
						<div
							onClick={async () => {
                                if (_missions.current && _missions.current.length > i) {
                                    _missions.current.splice(i, 1);
                                    setNumberOfMissions(numberOfMissions - 1);
                                    localStorage.setItem('numberOfMissions', parseInt(localStorage.getItem('numberOfMissions')) - 1);
                                  
                                        // Supprimer la distance associée à l'index i de distanceVector
                                        const filteredDistanceVector = new Map(
                                            Array.from(distanceVector.entries()).filter(([key, value]) => Number(key) !== i)
                                        );
                                        setDistanceVector(filteredDistanceVector);
										
                                        
                                        
                                  } else {
                                    console.error('Cannot modify _missions.current: invalid index or undefined array');
                                  }

								setValue('missions', _missions.current)
								unregister(`missions.${i}.date_debut`)
								unregister(`missions.${i}.heure_debut`)
								unregister(`missions.${i}.heure_fin`)
								unregister(`missions.${i}.date_fin`)
								unregister(`missions.${i}.kilo_jour`)
                                unregister(`missions.${i}.nombre_rippeur`)
                                unregister(`missions.${i}.heure_rippeur`)
								unregister(`missions[${i}].manutention_chauffeur`)
								unregister(`missions[${i}].livraison`)
								unregister(`missions[${i}].etage`)
								unregister(`missions[${i}].type_etage`)
							}}
							style={{
								position: 'absolute',
								bottom: collapsedItems.includes(i)
									? 'unset'
									: 10,
								top: collapsedItems.includes(i) ? 10 : 'unset',
								right: 120,
								cursor: 'pointer',
								display: i > 0 ? 'flex' : 'none',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									height: 30,
									width: 30,
								}}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
									/>
								</svg>
							</div>
						</div>
					)}

					<div
						style={{
							display: collapsedItems.includes(i)
								? 'flex'
								: 'none',
							flexDirection: 'row',
						}}
					>
						
					</div>

					{
						i === 0 ? (
							<MiseADispoReadOnly
								collapsedItems={collapsedItems}
								keepFilesAsObjects={keepFilesAsObjects}
								i={i}
								watch={watch}
								register={register}
								errors={errors} 
								getValues={getValues}
								setValue={setValue}
								control={control}
								display={display}
								distanceVector={distanceVector}
								setDistanceVector={setDistanceVector}
								setHeur={setHeur}
                                isActiverRippeur={isActiverRippeur}
							/>
						) : (
							<MissionsMiseADispoReadOnly
								collapsedItems={collapsedItems}
								keepFilesAsObjects={keepFilesAsObjects}
								i={i}
								watch={watch}
								register={register}
								getValues={getValues}
								setValue={setValue}
								control={control}
								display={display}
                                distanceVector={distanceVector}
								setDistanceVector={setDistanceVector}
								errors={errors} 
								setHeur={setHeur}
                                isActiverRippeur={isActiverRippeur}
							/>
						)
					}

				</FormSectionCard>
				</div>
			))}

			{!display && (
				<div
					onClick={() => setNumberOfMissions(numberOfMissions + 1)}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						cursor: 'pointer',
						marginTop: '2rem',
					}}
				>
					<img
						style={{ height: 40, marginRight: '1rem' }}
						src={AddCourse}
						alt=''
					/>
					<span>Ajouter une missions</span>
				</div>
			)}
			   <Modal
				isOpen={modalIsOpenCanceled}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Annuler Commande</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir annuler cette  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					  
					  onClick={async () => {	
						setLoading(true)
						try {
							const  res=await cancelMission(
								watch('_id'),
								selectedMission,
								token
		
							)
							toast.success(
								res.message,
								{
									style: {
										fontFamily: 'Montserrat',
									},
								}
							)
							
							
						} catch (error) {
							toast.error(
								error.response.data.message,
								{
									style: {
										fontFamily: 'Montserrat',
									},
								}
							)
						}finally {
							setLoading(false)
							setModalIsOpenCanceled(false)
						}
						  
						  
						}}
						disabled={loading}

						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenCanceled(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		</div>
		
	)
}

export default InformationsMiseADispoReadOnly

import { Controller, useForm } from 'react-hook-form'
import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	StyledTextArea,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'
import { getSignalement } from '../../../api/signalements'
import { useHistory, useParams } from 'react-router-dom'
import UploadMultipleFiles from '../../../Components/UploadMultipleFiles/UploadMultipleFiles'
import { useSelector } from 'react-redux'
import Container from '../../../Components/LayoutClient/container.component'
import toast from 'react-hot-toast'
function DetailsSignalement(props) {
	const history = useHistory()
	const {  signalement_id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [signalement, setSignalement] = useState(null)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const { register, setValue, reset, control, watch  } =
		useForm()

	const type = useRef()
	type.current = watch('type', '')
	useEffect( () => {
		async function _getSignalement(_id) {
			setChargementEnCours(true)
			try {
				const data = await getSignalement(_id,token)
				setSignalement(data)

				reset({
					note: data.note,
					type: data.type,
				})
			} catch (error) {
				toast.error("Une erreur s'est produite", {
					style: {
						fontFamily: 'Montserrat',
					},
				});
			}finally{
				setChargementEnCours(false)
			}
		}
		signalement_id && _getSignalement(signalement_id)
		
	}, [signalement_id])



	

	return (
		<Container>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								{...props}
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'DÉTAIL signalement'.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
						<div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  marginTop:'10rem'
						}}
						>
						<div className='customLoader'></div>	
						</div> 
					:<form>
						<FormContentSection>
								<FormSectionTitle></FormSectionTitle>
								<FormSectionCard>
								<div 
								style={{
									display: 'flex',
									flexDirection: 'column',
									 marginBottom: '2rem'}}>
									<span
									style={{
										marginBottom: '1rem',
									  color: 'black',
									  fontSize: '14px',
									  display: 'block',
									  fontWeight: 'bold',
									}}
									
								>
									Sélectionnez le type d'anomalie 
								</span>
								
								<Controller
										name="type"
										control={control}
										rules={{ required: true }}
										defaultValue=""
										render={({ field }) => (
											<select
												{...field}
												disabled={true}
												style={{
													padding: '1rem',
													borderRadius: '0.5rem',
													color: '#858ea8',
													border: 'none',
													fontFamily: 'Montserrat',
													flex: '1',
													outline: 'none',
													width: '100%'
												}}
											>
												<option value="" disabled>Sélectionnez une option</option>
													<option value="client_absent">Client absent</option>
													<option value="retard_aux_chargement">Retard aux chargement</option>
													<option value="livraison_reportée">Livraison reportée</option>
													<option value="retard_aux_déchargement">Retard aux déchargement</option>
													<option value="marchandises_manquantes_chargement">Marchandises manquantes/chargement</option>
													<option value="marchandise_refusée">Marchandise refusée</option>
													<option value="marchandises_incomplètes">Marchandises incomplètes</option>
													<option value="marchandise_abîmée">Marchandise abîmée</option>
													<option value="route_inaccessible_fermée">Route inaccessible ou fermée</option>
													<option value="circulation_dense">Circulation dense</option>
											</select>
										)}
									/>
									
									</div>
								</FormSectionCard>
							</FormContentSection>
					

					<FormContentSection>
						<FormSectionTitle></FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2.5rem',
									width: '100%',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
										fontWeight: 'bold',
									}}
								>
									Note
								</span>
								<StyledTextArea
									type='text'
									rows={4}
									placeholder=''
									disabled={true}
									{...register('note', {
										required: true,
									})}
								/>
								
							</div>

							
								{signalement && (
									<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										{signalement.files.length!==0 &&
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
												fontWeight: 'bold',
	
											}}
										>
											Joindre images ou PDF
										</span>
										}
									</div>
										<UploadMultipleFiles
										    disabled= {false}
											allowDelete={false}
											name='files'
											initialFiles={signalement.files}
											onChange={(files) => {
												setValue('files', files)
											}}
										/>
										</div>
									)}
								

							
						</FormSectionCard>
					</FormContentSection>

				
				   </form>
						

				
                    }
				</div>
			</div>
		
		</Container>
	)
}

export default DetailsSignalement

import React, { useEffect, useState } from 'react';
import { getSignalementForAdresse } from '../../../api/signalements';
import { useHistory, useParams } from 'react-router-dom';
import { GradientText } from '../../PasserCommande/PasserCommande.styles';
import { useSelector } from 'react-redux';

import DataTable from 'react-data-table-component';
import Container from '../../../Components/LayoutClient/container.component'

function SignalerClientAllAdresse() {

    const { placeID } = useParams();
    const history = useHistory();
    const token = useSelector((state) => state.auth.token);
    const [signalements, setSignalements] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [chargementEnCours, setChargementEnCours] = useState(false);

    const fetchData = async (page) => {
        setChargementEnCours(true);
        const res = await getSignalementForAdresse(placeID, token, page, perPage);
        setSignalements(res.docs);
        setTotalRows(res.totalDocs);
        setChargementEnCours(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setChargementEnCours(true);
        const res = await getSignalementForAdresse(placeID, token, page, newPerPage);
        setSignalements(res.docs);
        setTotalRows(res.totalDocs);
        setPerPage(newPerPage);
        setChargementEnCours(false);
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [placeID]);

    const columns = [
        {
            name: "Type d'anomalie",
            selector: (row) => row.type,
        },
        
        {
            name: 'Note',
            selector: (row) => row.note,
        },
        {
            name: 'Actions',
            button: true,
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/client/signalement-details/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
					
					
				
                    
                </div>
            ),
        },
    ];

    return (
        <Container>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '2rem', paddingBottom: '4rem', fontFamily: 'Montserrat' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                        <div onClick={() => history.goBack()} style={{ background: '#51C7F2', width: '3rem', height: '3rem', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width={14.017} height={14.11} viewBox='0 0 14.017 14.11'>
                                <g fill='none' stroke='#fff' strokeWidth={2}>
                                    <path d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232' transform='translate(-8.838 -11.113)' />
                                    <path d='M22.147 11.818l-6.348 6.349 6.348 6.348' transform='translate(-8.838 -11.113)' />
                                </g>
                            </svg>
                        </div>
                        <div style={{ width: '1rem' }} />
                        <GradientText marginTop='unset'>
                            { 'signalement'.toUpperCase() }
                        </GradientText>
                        <div style={{ width: '1rem' }} />
                        
                    </div>
                    {chargementEnCours ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div className='customLoader'></div>
                        </div> :
                        <div style={{ width: '100%', padding: '0 1rem', marginTop: '4rem' }}>
                            <DataTable
                                columns={columns}
                                data={signalements}
                                progressPending={chargementEnCours}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                noDataComponent="Il n'y a pas des signalements à afficher"
                                progressComponent='Chargement'
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rangs par page',
                                    rangeSeparatorText: 'sur',
                                }}
                            />
                        </div>
                    }
                </div>  
               
               
           </div>
          	
        </Container>
    )
}
export default SignalerClientAllAdresse;     
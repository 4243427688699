import React, { useState } from 'react'
import {  StyledTextArea ,StyledInput} from '../PasserCommande/PasserCommande.styles'

import { Controller } from 'react-hook-form'
import { BASE_URL } from '../../api/base'
import { useSelector } from 'react-redux'
import  {sendSMS,verifyCodeSMS} from '../../api/commande'
import toast from 'react-hot-toast'
import UploadFileCommande from '../../Components/UploadFileCommande/UploadFileCommande'
import Modal from 'react-modal'
function CourseStepReadOnly({
	collapsedItems,
	keepFilesAsObjects,
	i,
	watch,
	register,
	getValues,
	setValue,
	control,
	display,
	placesVector,
	setPlacesVector,
	distanceVector,
	setDistanceVector,
	errors,
	setHeur,
	hideSendSMS,
	commande
}) {
	const nameError = errors && errors.courses && errors.courses[i] && errors.courses[i].type_debut;
	console.log('Errors:', nameError);
	console.log(errors);
	const token = useSelector((state) => state.auth.token);
	const [enteredCode, setEnteredCode] = useState('');
	const [modalIsOpenStatus, setModalIsOpenStatus] = useState(false)
	const [modalIsOpenSendSMS, setModalIsOpenSendSMS] = useState(false)
    const [loading,setLoading]=useState(false)
	const [loadingverifySMS,setLoadingverifySMS]=useState(false)
    const [phone,setPhone]=useState("")
	const getCurrentTime = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, '0');   
	    const minutes = now.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	};
	const generateTimeOptions = () => {
		const options = [];
	
		for (let hours = 0; hours < 24; hours++) {
		  for (let minutes = 0; minutes < 60; minutes += 30) {
			const hourText = hours.toString().padStart(2, '0');
			const minuteText = minutes.toString().padStart(2, '0');
			const time = `${hourText}:${minuteText}`;
			options.push({ label: time, value: time });
		  }
		}
	
		return options;
	  };
	 
	  return (
		<>
			<div
				style={{
					display: collapsedItems.includes(i) ? 'none' : 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Début du transport
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='type_debut-chargement'
								type='radio'
								name={`courses[${i}].type_debut`}
								disabled={display}
								value='Chargement'
								{...register(`courses[${i}].type_debut`, {
									required: true,
								})}
							/>

							<label htmlFor='type_debut-chargement'>
								Chargement
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='type_debut-dechargement'
								type='radio'
								name={`courses[${i}].type_debut`}
								disabled={display}
								value='Déchargement'
								{...register(`courses[${i}].type_debut`, {
									required: true,
								})}
							/>

							<label htmlFor='type_debut-dechargement'>
								Déchargement
							</label>
						</div>
					</div>

					
					{nameError &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
					Veuillez sélectionner le type de debut transport
					</div> 
					}
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '50%',
							fontFamily: 'Montserrat',
						}}
					>
						
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Date du transport
						</span>
						<Controller
							name={`courses[${i}].date_debut`}
							rules={{ required: true }}
							control={control}
							render={({ field: { onChange, value } }) => (
                                <StyledInput
                                type='text'
                                disabled={display}
                                placeholder=''
                                value={value}
                            />
							)}
						/>
					</div>
					
					<div
						style={{
							width: '1rem',
						}}
					/>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							//marginBottom: '2.5rem',
							width: '50%',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Heure
						</span>

						<Controller
							name={`courses[${i}].heure_debut`}
							rules={{ required: true }}
							control={control}
							defaultValue={getCurrentTime()}
							render={({ field: { onChange, value } }) => (	
							   <select value={value} onChange={onChange}
								style={{
										padding: '1rem',
										borderRadius: '0.5rem',
										color: '#858ea8',
										border: 'none',
										fontFamily: 'Montserrat',
										flex: '1',
										outline: 'none'
									 }}
                                     disabled={display}
								>
								{generateTimeOptions().map((option) => (
								  <option key={option.value} value={option.value}>
									{option.label}
								  </option>
								))}
							  </select>
							)}
						/>
						
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '2.5rem',
						marginTop:'-2rem'
					}}
				>
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].date_debut	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
						Veuillez remplir la date de début de transport
					</div> 
				}
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].heure_debut	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				       Veuillez remplir l'heure de début de transport
					</div> 
				}
				</div>
				
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Lieux
					</span>
					<Controller
  name={`courses[${i}].adresse_debut`}
  rules={{ required: true }}
  control={control}
  render={({ field: { onChange, value } }) => (
   
	<StyledInput
		type='text'
		disabled={display}
		placeholder=''
		value={value.label }
	/>
	
  )}
   />

					{distanceVector.get(`${i}-debut`) !== undefined && (
						<span style={{ marginTop: '1rem' }}>
							Nombre de Km depuis la derniere étapes:{' '}
							<strong>
								{(distanceVector.get(`${i}-debut`) || 0) / 1000}
							</strong>
						</span>
					)}
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].adresse_debut	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				    Veuillez remplir le lieu de début du transport
					</div> 
				    }
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
						width: '100%',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Notes Utiles
					</span>
					<StyledTextArea
						disabled={display}
						type='text'
						rows={4}
						placeholder=''
						{...register(`courses[${i}].notes_debut`,)}
					/>
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].notes_debut	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
					Veuillez remplir les notes utiles pour le début du transport
					</div> 
				    }
				</div>
               
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						//marginBottom: '2.5rem',
						width: '50%',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Joindre Documents (PDF)
						</span>
						{!display && (
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								(Facultatif)
							</span>
						)}
					</div>

					{!display && (
						<UploadFileCommande
							initialFiles={watch(`courses[${i}].files_debut`)}
							name={`file_debut_${i}`}
							onChange={(files) => {
								if (keepFilesAsObjects) {
									setValue(
										`courses[${i}].files_debut`,
										files.map((file) => file)
									)
								} else {
									setValue(
										`courses[${i}].files_debut`,
										files.map((file) => file.id)
									)
								}
							}}
						/>
					)}

					{display &&
						(watch(`courses[${i}].files_debut`) || []).map(
							(file, index) => (
								<div
									onClick={() => {
										window.open(`${BASE_URL}/${file?.path}`)
									}}
									key={String(index)}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginBottom: 20,
										cursor: 'pointer',
									}}
								>
									<img
										alt=''
										src={File || 'https://secure.gravatar.com/favicon.ico'}
										style={{
											height: 20,
											marginRight: '1rem',
										}}
									/>
									<span
										style={{
											marginRight: '1rem',
											width: '10rem',
										}}
									>
										{file?.name}
									</span>
								</div>
							)
						)}
				</div>
				
			</div>

			{/* ################################################## */}
			<p
				style={{
					marginTop: '2rem',
					marginBottom: '2rem',
				}}
			>
				<b>Suite du transport</b>
			</p>
			{/* ################################################## */}

			<div
				style={{
					display: collapsedItems.includes(i) ? 'none' : 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Type de course
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='type_fin-chargement'
								type='radio'
								disabled={display}
								value='Chargement'
								{...register(`courses[${i}].type_fin`, {
									required: true,
								})}
							/>

							<label htmlFor='type_fin-chargement'>
								Chargement
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='type_fin-dechargement'
								type='radio'
								disabled={display}
								value='Déchargement'
								{...register(`courses[${i}].type_fin`, {
									required: true,
								})}
							/>

							<label htmlFor='type_fin-dechargement'>
								Déchargement
							</label>
						</div>
					</div>
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].type_fin
						 &&
					    <div style={{ color: 'red', marginTop: '0.5rem' }}>
					     Veuillez sélectionner le type de fin du transport
					    </div> 
				         }
					
				</div>
				

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '50%',
							fontFamily: 'Montserrat',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Date du transport
						</span>

						<Controller
							name={`courses[${i}].date_fin`}
							rules={{ required: true }}
							control={control}
							render={({ field: { onChange, value } }) => (
								<>{console.log(`courses[${i}].date_fin`, value)}
								 <StyledInput
                                    type='text'
                                    disabled={display}
                                    placeholder=''
                                    value={value}
                                />
								</>
							)}
						/>
						
					</div>

					<div
						style={{
							width: '1rem',
						}}
					/>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							//marginBottom: '2.5rem',
							width: '50%',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Heure
						</span>
                       						
						<Controller
						    name={`courses[${i}].heure_fin`}
							rules={{ required: true }}
							control={control}
							defaultValue={getCurrentTime()}
							render={({ field: { onChange, value } }) => (	
							<select value={value} onChange={onChange}
								style={{
										padding: '1rem',
										borderRadius: '0.5rem',
										color: '#858ea8',
										border: 'none',
										fontFamily: 'Montserrat',
										flex: '1',
										outline: 'none'
									 }}
                                     disabled={display}
								>
								{generateTimeOptions().map((option) => (
								  <option key={option.value} value={option.value}>
									{option.label}
								  </option>
								))}
							  </select>
							
							)}
						/>

					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '2.5rem',
						marginTop:'-2rem'
					}}
				>
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].date_fin	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
						Veuillez remplir la date de fin de transport
					</div> 
				}
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].heure_fin	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				       Veuillez remplir l'heure de fin de transport
					</div> 
				}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Lieux
					</span>
					
								<Controller
  	            name={`courses[${i}].adresse_fin`}
                rules={{ required: true }}
                control={control}
                 render={({ field: { onChange, value } }) => (
                    <StyledInput
                    type='text'
                    disabled={display}
                    placeholder=''
                    value={value.label }
                />
  )}
   />
					{distanceVector.get(`${i}-fin`) !== undefined && (
						<span style={{ marginTop: '1rem' }}>
							Nombre de Km depuis la derniere étapes:{' '}
							<strong>
								{(distanceVector.get(`${i}-fin`) || 0) / 1000}
							</strong>
						</span>
					)}
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].adresse_fin
						 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
					Veuillez remplir le lieu de fin du transport
					</div> 
				    }
				</div>
	


				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
						width: '100%',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Notes Utiles
					</span>
					<StyledTextArea
						disabled={display}
						type='text'
						rows={4}
						placeholder=''
						{...register(`courses[${i}].notes_fin`,)}
					/>
					{ errors && errors.courses && errors.courses[i] && errors.courses[i].notes_fin	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
			    	Veuillez remplir les notes utiles pour la fin du transport
					</div> 
				    }
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
						width: '50%',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Joindre Documents (PDF)
						</span>
						{!display && (
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								(Facultatif)
							</span>
						)}
					</div>

					{!display && (
						<UploadFileCommande
							initialFiles={watch(`courses[${i}].files_fin`)}
							name={`file_fin_${i}`}
							onChange={(files) => {
								if (keepFilesAsObjects) {
									setValue(
										`courses[${i}].files_fin`,
										files.map((file) => file)
									)
								} else {
									setValue(
										`courses[${i}].files_fin`,
										files.map((file) => file.id)
									)
								}
							}}
						/>
					)}

					{display &&
						(watch(`courses[${i}].files_fin`) || []).map(
							(file, index) => (
								<div
									onClick={() => {
										window.open(`${BASE_URL}/${file?.path}`)
									}}
									key={String(index)}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginBottom: 20,
										cursor: 'pointer',
									}}
								>
									<img
										alt=''
										src={File}
										style={{
											height: 20,
											marginRight: '1rem',
										}}
									/>
									<span
										style={{
											marginRight: '1rem',
											width: '10rem',
										}}
									>
										{file?.name}
									</span>
								</div>
							)
						)}
				</div>
				<div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				       Nom du  destinataire						 
				</span>
				<StyledInput
							type='text'
							disabled={display}
							placeholder=''
							 {...register(`courses[${i}].lastNameDistinator`,
							 {required: 'Veuillez indiquer le nom',
							 minLength: {
								 value: 3,
								 message: 'Le nom  doit contenir au moins 3 caractères.',
							   },
							   pattern: {
								 value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
								 message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
							   },	
							})}
				/>
				 {  errors && errors.courses && errors.courses[i] && errors.courses[i].lastNameDistinator	 &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
					{errors.lastNameDistinator?.message}
					</div> 
				}
				</div>	
				
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				       Prénom du  destinataire						 
				</span>
				<StyledInput
							type='text'
							disabled={display}
							placeholder=''
							 {...register(`courses[${i}].firstNameDistinator`,{
								required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
							 })}
				/>
				{  errors && errors.courses && errors.courses[i] && errors.courses[i].firstNameDistinator   &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				     {errors.firstNameDistinator?.message}
					</div> 
				}
				</div>
				 </div>
				 <div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				         Numéro de Téléphone 1 du destinataire
				</span>
				<StyledInput
							type='text'
							disabled={display}
							placeholder=''
							 {...register(`courses[${i}].phone1Distinator`,{required: 'Veuillez indiquer le numéro de téléphone .',
							 pattern: {
								 value: /^[0-9]+$/, 
								 message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
									 },})}
				/>
				{   errors && errors.courses && errors.courses[i] && errors.courses[i].phone1Distinator  &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				     {errors.phone1Distinator?.message}
					</div> 
				}
				{hideSendSMS && 
				<>
				<button 
				onClick={async(e)=>{
					e.preventDefault()
					if(commande?.statut==='init'){
                      setModalIsOpenStatus(true)
					}
					else{
						setPhone( watch(`courses[${i}].phone1Distinator`))
						setModalIsOpenSendSMS(true)
					}
					
					
				}}
				style={{
					padding: '1rem 2rem',
					background: '#60d2f8',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					marginRight: '1rem',
					marginTop:'1rem',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
						Envoyer le  code
              
                </button>
				</>}
				</div>	
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				         Numéro de Téléphone 2	du destinataire						 
				</span>
				<StyledInput
							type='text'
							disabled={display}
							placeholder=''
							 {...register(`courses[${i}].phone2Distinator`,{required: 'Veuillez indiquer le numéro de téléphone .',
							 pattern: {
								 value: /^[0-9]+$/, 
								 message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
									 },})}
				/>
				{ errors && errors.courses && errors.courses[i] && errors.courses[i].phone2Distinator  &&
					<div style={{ color: 'red', marginTop: '0.5rem' }}>
				     {errors.phone2Distinator?.message}
					</div> 
				}
				{hideSendSMS &&
					<button  
					onClick={async (e)=>{
						e.preventDefault()
						if(commande?.statut==='init'){
							setModalIsOpenStatus(true)
						}else{
							setPhone( watch(`courses[${i}].phone2Distinator`))
							setModalIsOpenSendSMS(true)
					   }
					} 
                        
					}
					style={{
						padding: '1rem 2rem',
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						marginTop:'1rem',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						Envoyer le code
                </button>
				}
			
				</div>
				 </div>
				 <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						 Livrée
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							disabled={display}
							 style={{
								background:watch(`courses[${i}].livrée`) ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: watch(`courses[${i}].livrée`) && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:watch(`courses[${i}].livrée`) ?'#fff': '#444'
							}}
							>Oui</button>
						</div>

						<div
							
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							disabled={display}
							 style={{
								background:!watch(`courses[${i}].livrée`) ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: !watch(`courses[${i}].livrée`) && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:!watch(`courses[${i}].livrée`) ?'#fff': '#444'
							}}
							>Non</button>
						</div>
					</div>					
					
				</div>
				 {hideSendSMS &&
				 <div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
                 <div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				       Introduit le code 					 
				</span>
				<StyledInput
							type='text'
							placeholder=''
							value={enteredCode}
                           onChange={(e) => setEnteredCode(e.target.value)}

							
				/>
				
				</div>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
					 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				     					 
				</span>
					<button
					disabled={loadingverifySMS} 
				onClick={async(e)=>{
					setLoadingverifySMS(true)
					e.preventDefault()
					try {
					const response=await verifyCodeSMS({"courses":watch(`courses[${i}]`),"code":enteredCode},token)
                     setEnteredCode('')
                        toast.success(response.message, {
							style: {
								fontFamily: 'Montserrat',
							},
						})
						setValue(`courses[${i}].livrée`, true);
					} catch (error) {
						toast.error("Une erreur s'est produite", {
							style: {
								fontFamily: 'Montserrat',
							},
						})						
					}finally{
						setLoadingverifySMS(false)
					}
					
				}}
				style={{
					padding: '1rem 2rem',
					background: '#60d2f8',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					marginRight: '1rem',
					marginTop:'1rem',
					
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
             
			   {loadingverifySMS ? (
									<div className='loader'></div>
								) : (
									'  Valider le code'
								)}
                </button>
					</div>	
				 </div>
                 }
			</div>
			<Modal
    isOpen={modalIsOpenStatus}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
       Vous ne pouvez pas envoyer le code au client tant que la commande n'a pas encore démarré
       </div>
        <button
            onClick={() => {
                setModalIsOpenStatus(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
				isOpen={modalIsOpenSendSMS}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Envoyer Code</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir envoyer le  code  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								const res=await sendSMS({ "numero": phone,"courses":watch(`courses[${i}]`) },token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally {
								setModalIsOpenSendSMS(false)
								setLoading(false)
							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
					        justifyContent: 'center',
					        alignItems: 'center'
						}}
					>
					 {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenSendSMS(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		  
		</>
	)
}

export default CourseStepReadOnly

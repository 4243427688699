import React,{useState} from 'react';
import {useElements, useStripe ,IbanElement} from '@stripe/react-stripe-js';
import { updateClient } from '../../api/clients';
import {SubmitButton,	SubmitButtonContainer,FormSectionCard} from '../PasserCommande/PasserCommande.styles'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import toast from 'react-hot-toast'
function CardSetupForm() {
  const stripe = useStripe();
  const history = useHistory()
  const elements=useElements();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user)
  const [loading,setLoading]=useState(false)
  const { handleSubmit, register, formState: { errors }, setError, clearErrors } = useForm();

 
	const onSubmit = async (data) => {
	setLoading(true)
    try {
      if (!stripe || !elements) {
        return;
      }
      
    const ibanElement = elements.getElement(IbanElement);
    const accountholderName = `${user.last_name} ${user.first_name}`;
    const result = await stripe.confirmSepaDebitSetup(user?.clientSecret, {
    payment_method: {
      sepa_debit: ibanElement,
      billing_details: {
        name: accountholderName,
        email: user.email,
      },
      
    }
    });
	await updateClient(user._id, {paymentMethodId:result.setupIntent.payment_method,role:'client'},token)
    toast.success('Vos informations de paiement ont été enregistrées avec succès !', {
		style: {
			fontFamily: 'Montserrat',
		},
	})
	history.push(`/client/account`)
    } catch (error) {
      console.error('Erreur lors de la confirmation de la Setup Intent :', error);
	 
    }finally{
	setLoading(false)

	}
   
	}

  const IBAN_STYLE = { 
    base: { 
      color: "#707070", 
      fontSize: "14px",
      background: 'white !important', 
      "::placeholder": { 
        color: "#707070", 
      }, 
      ":-webkit-autofill": { 
        couleur : "#707070", 
      }, 
    }, 
    invalide : { 
      couleur : "#fa755a", 
      iconColor : "#fa755a", 
      ":-webkit-autofill": { 
        couleur : "#fa755a", 
      }, 
    }, 
  } ;
  const IBAN_ELEMENT_OPTIONS = { 
    supportedCountries : ["SEPA"], 
    placeholderCountry : "FR", 
	  
  } ;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <FormSectionCard>
    <div
    style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '2rem',
    }}
    > 
    <span
	    style={{
		color: '#262626',
		paddingBottom: '1rem',
		}}
    >
    IBAN <span style={{color:'red'}}>*</span>
	</span>
	<div
            style={{
              padding: '1rem',
              borderRadius: '0.3rem',
              background: 'white',
            }}
     >
  <IbanElement options={IBAN_ELEMENT_OPTIONS} onChange={(event) => {
              if (event.error) {
                setError('iban', { type: 'manual', message: event.error.message });
              } else {
                clearErrors('iban');
              }
            }} />
	{errors.iban && <span style={{ color: 'red' ,marginTop:"2rem"}}>{errors.iban.message}</span>}

   </div>
    </div>
    </FormSectionCard>
    <div style={{ height: '1rem' }} />
		<SubmitButtonContainer>
		<SubmitButton type='submit' disabled={loading}>
		{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
		</SubmitButton>
		</SubmitButtonContainer>
    
     
    </form>
  );
}



export default CardSetupForm;
import React, { useState } from 'react';
import File from '../../assets/img/file@2x.png';
import { FileInputContainer } from '../../Containers/PasserCommande/PasserCommande.styles';

function UploadFile({ name, onFile, accept = '' }) {
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
	console.log(selectedFile)
    if (selectedFile) {
      const fileType = selectedFile.type.split('/')[1];
      if (accept.includes(fileType)) {
        setFile(selectedFile);
        onFile({
			file:selectedFile,
			type:name
		}); 
      } else {
        alert(`Désolé, le fichier ${selectedFile.name} n'est pas pris en charge. Veuillez choisir un fichier au format ${accept.replace('.', '')}.`);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        borderRadius: '0.5rem',
        background: 'white',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        position: 'relative',
      }}
    >
      {file && (
        <img
          src={File}
          style={{
            height: 20,
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
          alt='Fichier'
        />
      )}
      <span
        style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {file && file.name}
      </span>
      <FileInputContainer>
        <input
          type='file'
          id={name}
          name={name}
          className='file'
          accept={accept}
          onChange={handleChange}
        />
        <label htmlFor={name}>Télécharger</label>
      </FileInputContainer>
    </div>
  );
}

export default UploadFile;

import { GradientText, SubText } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'

import CommandeFormReadOnly from '../../Components/CommandeFormReadOnly'
import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import TransportTracking from '../../Components/TransportTracking/TransportTracking'
import { getCommande } from '../../api/commande'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

function DetailsCommandeCommercant() {
    const { id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [commande, setCommande] = useState(null)
    useEffect(() => {
		async function _getCommande(id) {
			const commande = await getCommande(id,token)
			setCommande(commande)
		}

		if (id) {
			_getCommande(id)
		}
	}, [id])
  return (
    <LayoutCommercant>
	<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText>{`${'Détail COMMANDE'.toUpperCase()} ${!!commande ? ' N°' + commande.__id : '' }`}</GradientText>
				<SubText>
					Ici retrouver le détail complet du transport à effectuer
				</SubText>

				<div
					style={{
						width: '65%',
					}}
				>
					{commande?.waypoints_overage && (
						<TransportTracking
							display
							hideForfaits
							commande={commande}
							//showFacture
						/>
					)}
				</div>

				{commande && (
					<CommandeFormReadOnly
						hideSubmit
						disabled
						onSubmit={() => {}}
						preloadForm={true}
						preloadData={commande}
					/>
				)}
			</div>
    </LayoutCommercant>
  )
}

export default DetailsCommandeCommercant

import api, { BASE_URL }  from './base'
import axios from 'axios'
import qs from 'qs';


export const getClients = async (page, size,token) => {
	
	const res = await api.get(`/users?filter=client&fields=role&page=${page}&limit=${size}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export  const getNotValidClients=async(page, size,token)=>{
	const res = await api.get(`/users/clients/not-validated/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export  const getValidClients=async(page, size,token)=>{
	const res = await api.get(`/users/clients/validated/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export const getDeletelClient = async (page, size,token) => {
	
	const res = await api.get(`/users/client/delete/all?filter=client&fields=role&page=${page}&limit=${size}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const getDeletelTransporteur = async (page, size,token) => {
	
	const res = await api.get(`/users/transporteur/delete/all?filter=client&fields=role&page=${page}&limit=${size}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}

export const restoreDeletedClient = async (id,token) => {
	
	const res = await api.get(`/users/client/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const restoreDeletedTransporteur = async (id,token) => {
	
	const res = await api.get(`/users/transporteur/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const getClientsForFacturation  = async (query,token) => {
	const res = await api.get(`/users/clientforfacturation/${query}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getClientsByDateForFacturation  = async (query,token) => {
	const res = await api.get(`/users/clientforfacturation/${query}/date`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getSingleClient = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getSingleTransporter = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  }
	)
	return res.data
}

export const getSingleChauffeur = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const toggleSuspendreClient = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const updateClient = async (id, values,token) => {
	const params = new URLSearchParams()

	Object.entries(values).map((item) => params.append(item[0], item[1]))

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/users/${id}`, params, config)

	return res.data
}

export const updateTransporter = async (id, values,token) => {
	

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/users/${id}`, qs.stringify(values), config);


	return res.data
}

export const deleteClient = async (id,token) => {
	const res = await api.delete(`/users/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteClient = async (id,token) => {
	const res = await api.get(`/users/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getComptabiliteClients = async (page, size,token) => {
	const res = await api.get(`/comptabilite/clients?size=${size}&page=${page}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteClientsByMonth = async (page, size,token,month,year) => {
	const res = await api.get(`/comptabilite/clients/month?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteClientsByMonthAndName = async (page, size,token,month,year,query) => {
	query=query.trim()
	const res = await api.get(`/comptabilite/clients/month/${query}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteClientByName= async (name,token) => {
	name=name.trim()
	const res = await api.get(`/comptabilite/client/${name}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const updateClientAccount = async (data,token) => {
	const res = await api.patch('/profile', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}



export const updateChauffeurAccount = async (data,token) => {
	const res = await api.patch('/profile', data,{
		headers: {
			  Authorization: `Bearer `+token,
			},
		  })
	return res.data
}

export const searchCommandesByPrixMinMaxForClient = async (
	min,
	max,
	client,
	type,
	token
) => {
	/*const res = await axios({
		method: 'POST',
		url: `${BASE_URL}/indexes/commandes/search`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
		data: JSON.stringify({
			filter: `prix >= ${min} AND prix <= ${max} AND client_id = ${client}`,
		}),
	})*/
	const res = await api.get(`/commandes/client/price/${client}/${min}/${max}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const searchCommandesByDateForClient = async (min, max, client,type,token) => {
	/*const res = await axios({
		method: 'POST',
		url: `${BASE_URL}/indexes/commandes/search`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
		data: JSON.stringify({
			filter: `absolute_start_date_unix >= ${min} AND absolute_start_date_unix <= ${max} AND client_id = ${transporter}`,
		}),
	})*/
	const res = await api.get(`/commandes/client/date/${client}/${min}/${max}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const searchCommandesByIdForClient=async(client,query,type,token)=>{
	const res = await api.get(`/commandes/byclient/${client}/${query}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const affecterClientACommercial = async (
	clients,
	commercialId,
	token
) => {
	const res = await api.post('/users/affecter-client-commercial', {
		clients,
		commercialId,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const removeClientFromCommercial=async (
	clients,
	token
) => {
	const res = await api.post('/users/remove-client-commercial', {
		clients
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getClientsWithFailedCharge=async(query,token)=>{
	const  res=await api.get(`/users/admin/clientwithfailedcharge/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getClientsForPrelevement=async(query,token)=>{
	const  res=await api.get(`/users/admin/prelevement/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const comparerReductions = async (
	clients,
	commercialId,
	token
) => {
	const res = await api.post('/users/comparerreductions-client-commercial', {
		clients,
		commercialId,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
import {
	GradientText,
	SubText,
	Wrapper,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'

import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import TransportTracking from '../../../Components/TransportTracking/TransportTracking'
import { getCommande } from '../../../api/commande'
import { useParams,useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import CommandeFormReadOnly from '../../../Components/CommandeFormReadOnly'
function DetailCommandeAdmin() {
	const { id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [commande, setCommande] = useState(null)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const history=useHistory()

	const onSubmit = async (data) => {}

	useEffect(() => {
		async function _getCommande(id) {
			setChargementEnCours(true)
			const commande = await getCommande(id,token)
			setCommande(commande)
			setChargementEnCours(false)

		}

		if (id) {
			_getCommande(id)
		}
	}, [id])

	return (
		<LayoutAdmin>
			<Wrapper>
			<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

                    <GradientText  marginTop={'unset'}>{`${'Détail COMMANDE'.toUpperCase()} ${!!commande ? ' N°' + commande.__id : '' }`}</GradientText>

					</div>
				<SubText>
					Ici retrouver le détail complet du transport à effectuer
				</SubText>
				{chargementEnCours ?
					<div
					style={{
					  display: 'flex',
					  alignItems: 'center',
					  justifyContent: 'center',
					  backgroundColor: 'white',
					  marginTop:"10rem"
					}}
					>
					<div className='customLoader'></div>	
					</div>
					 :
					 <>
					 <div
						 style={{
							 width: '65%',
						 }}
					 >
						 {/* {commande?.waypoints_overage && (
							 <TransportTracking
								 display
								 commande={commande}
								 showFacture
							 />
						 )} */}
							 {/* {commande?.type_commande === 'mise-a-disposition' && (
							 <TransportTracking commande={commande} setCommande={setCommande} display />
						 )} */}
					 </div>
					 {commande && (
						 <CommandeFormReadOnly
							 hideSubmit
							 disabled
							 onSubmit={() => {}}
							 preloadForm={true}
							 preloadData={commande}
						 />
					 )}
					 </>
				}
               

				
			</Wrapper>
		</LayoutAdmin>
	)
}

export default DetailCommandeAdmin

import { GradientText } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import {getHistoriqueChauffeurByMissionId} from '../../api/commande'
import DataTable from 'react-data-table-component'
import moment from 'moment'


function HistoriqueChauffeurMission() {
  const history=useHistory()
  const {id}=useParams()
  const transporter = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token);
  const [chargementEnCours, setChargementEnCours] = useState(false);
  const [data,setData]=useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

   const fetchData = async (page) => {
        setChargementEnCours(false)
        try {
            const res=await getHistoriqueChauffeurByMissionId(id,transporter._id,token,page,perPage)
            setData(res.docs)
            setTotalRows(res.totalDocs)
        } catch (error) {
            console.log(error)

        }finally{
            setChargementEnCours(false)

        }
    }

    const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchData(page)	
	}
	const handlePerRowsChange = async (newPerPage,page) => {

        setChargementEnCours(false)
        try {
            const res=await getHistoriqueChauffeurByMissionId(id,transporter._id,token,page,perPage)
            setData(res.docs)
            setTotalRows(res.totalDocs)
            setPerPage(newPerPage)
        } catch (error) {
            console.log(error)
        }finally{
            setChargementEnCours(false)

        }
	}
  const columns = [
		
		{
            name: "Ancien Chauffeur",
            sortable: false,
            cell: (row) => (
                <div 
                onClick={()=>{
                        history.push(`/chauffeurs/${row.ancienChauffeurId._id}`)


                }}
                style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color:'#51C7F2'
                    }}
                >
                  {row.ancienChauffeurId && `${row.ancienChauffeurId.first_name} ${row.ancienChauffeurId.last_name}`}
                </div>
              ),

        },
        {
            name: "Nouveau Chauffeur",
            sortable: false,
            cell: (row) => (
                <div 
                onClick={()=>{
                        history.push(`/chauffeurs/${row.nouveauChauffeurId._id}`)


                }}
                style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color:'#51C7F2'
                    }}
                >
                  {row.nouveauChauffeurId && `${row.nouveauChauffeurId.first_name} ${row.nouveauChauffeurId.last_name}`}
                </div>
              ),
        },
        {
			name: "Statut de commande ",
			selector: (row) => row.statut==='init' ? "Non commencée" : row.statut==='en-cours' ? 'En cours' : row.statut==='terminer' ? "Terminée" :"Annulée",
			sortable: false,
            width:"170px"
		},
       {
			name: "Distance Parcours ",
			selector: (row) => row.distance ? row.distance :'0' ,
			sortable: false,
            width:"170px"
		},
        {
			name: "Remarque ",
			selector: (row) => row.remarque  ,
			sortable: false,
            width:"170px"
		},
      
        {
			name: "Date de changement ",
            selector: (row) => moment(row.dateChangement).format('DD/MM/YYYY'),
			sortable: false,
		}


	]
      useEffect(() => {
		fetchData(currentPage)
	}, [])

  return (
    <LayoutTransporter>
       <div
        style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '2rem',
            paddingBottom: '4rem',
            fontFamily: 'Montserrat',
        }}
    >
       <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        background: '#51C7F2',
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={14.017}
                        height={14.11}
                        viewBox='0 0 14.017 14.11'

                    >
                        <g
                            data-name='Group 11287'
                            fill='none'
                            stroke='#fff'
                            strokeWidth={2}
                        >
                            <path
                                data-name='Path 11687'
                                d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
                                transform='translate(-8.838 -11.113)'
                            />
                            <path
                                data-name='Path 11688'
                                d='M22.147 11.818l-6.348 6.349 6.348 6.348'
                                transform='translate(-8.838 -11.113)'
                            />
                        </g>
                    </svg>
                </div>

                <div
                    style={{
                        width: '1rem',
                    }}
                />

                <GradientText marginTop={'unset'}>
                    {' Historique Chauffeurs'.toUpperCase()}
                </GradientText>
            </div>
            <div
					style={{
						width: '100%',
						padding: '0 1rem',
            marginTop:'5rem'
					}}
				>
					 <DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des chauffeurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/> 
				</div>
    </div>
    </LayoutTransporter>
  )
}

export default HistoriqueChauffeurMission
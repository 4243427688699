import { Controller, useForm } from 'react-hook-form'
import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	StyledTextArea,
	SubmitButton,
	SubmitButtonContainer,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import LayoutChauffeur from '../../../Components/LayoutChauffeur/LayoutChauffeur'
import Modal from 'react-modal'
import UploadMultipleFiles from '../../../Components/UploadMultipleFiles/UploadMultipleFiles'
import { createSignalement } from '../../../api/signalements'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

function NewSignalementMiseADispo() {
	
	const { placeID,commandeID } = useParams();

	const history = useHistory()

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, watch, setValue, control,formState: { errors}} = useForm()
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user)
	const file = useRef()
	file.current = watch('file', null)

	const type = useRef()
	type.current = watch('type', '')

	

	const onSubmit = async (values) => {
		// values.commande=commandeID
		values.points_passage = placeID
		values.chauffeur=user._id
		 
		// Vérifiez si une photo est requise
		 const typesRequisPhoto = [
			'marchandise_abîmée',
			'route_inaccessible_fermée',
			'circulation_dense'
		];
		if (typesRequisPhoto.includes(values.type) && (!values.files || values.files.length === 0)) {
			
			toast.error(
				'Une photo est obligatoire pour ce type d\'anomalie.',
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
			return;
		}
		setLoading(true)
		try {
			await createSignalement(values,token)
			setModalIsOpen(true)
		} catch (error) {
			toast.error('Une erreur est survenue lors de la création de le signalement.',
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}finally{
			setLoading(false)
		}
	}

	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                        <div onClick={() => history.goBack()} style={{ background: '#51C7F2', width: '3rem', height: '3rem', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width={14.017} height={14.11} viewBox='0 0 14.017 14.11'>
                                <g fill='none' stroke='#fff' strokeWidth={2}>
                                    <path d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232' transform='translate(-8.838 -11.113)' />
                                    <path d='M22.147 11.818l-6.348 6.349 6.348 6.348' transform='translate(-8.838 -11.113)' />
                                </g>
                            </svg>
                        </div>
                        <div style={{ width: '1rem' }} />
                        <GradientText marginTop='unset'>
                            { 'ajouter signalement'.toUpperCase() }
                        </GradientText>
                        <div style={{ width: '1rem' }} />
                       
                    </div>
                    
						<form onSubmit={handleSubmit(onSubmit)}>
						
					
							<FormContentSection>
									<FormSectionTitle></FormSectionTitle>
									<FormSectionCard>
									<div 
									style={{
										display: 'flex',
                                        flexDirection: 'column',
                                         marginBottom: '2rem'}}>
										<span
									    style={{
											marginBottom: '1rem',
                                          color: 'black',
                                          fontSize: '14px',
                                          display: 'block',
										  fontWeight: 'bold',
										}}
										
									>
										Sélectionnez le type d'anomalie <span style={{ color: 'red' }}>*</span>
									</span>
									
									<Controller
											name="type"
											control={control}
											rules={{ required: true }}
											defaultValue=""
											render={({ field }) => (
												<select
													{...field}
													style={{
														padding: '1rem',
														borderRadius: '0.5rem',
														color: '#858ea8',
														border: 'none',
														fontFamily: 'Montserrat',
														flex: '1',
														outline: 'none',
														width: '100%'
													}}
												>
													<option value="" disabled>Sélectionnez une option</option>
													<option value="client_absent">Client absent</option>
													<option value="retard_aux_chargement">Retard aux chargement</option>
													<option value="livraison_reportée">Livraison reportée</option>
													<option value="retard_aux_déchargement">Retard aux déchargement</option>
													<option value="marchandises_manquantes_chargement">Marchandises manquantes/chargement</option>
													<option value="marchandise_refusée">Marchandise refusée</option>
													<option value="marchandises_incomplètes">Marchandises incomplètes</option>
													<option value="marchandise_abîmée">Marchandise abîmée</option>
													<option value="route_inaccessible_fermée">Route inaccessible ou fermée</option>
													<option value="circulation_dense">Circulation dense</option>
												</select>
											)}
										/>
										{errors.type && <p style={{ color: 'red', marginTop: '0.5rem' }}>Veuillez sélectionner le type d'anomalie</p>}
										
										</div>
									</FormSectionCard>
								</FormContentSection>
						

						<FormContentSection>
							<FormSectionTitle></FormSectionTitle>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2.5rem',
										width: '100%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
											fontWeight: 'bold',
										}}
									>
										Note <span style={{ color: 'red' }}>*</span>
									</span>
									<StyledTextArea
										type='text'
										rows={4}
										placeholder=''
										{...register('note', {
											required: true,
										})}
									/>
									{errors.note && 
									<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez saisir une  note</p>}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
										// marginLeft: 10,
										// marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
												fontWeight: 'bold',

											}}
										>
											Joindre images ou PDF
										</span>
									</div>
									<UploadMultipleFiles
										name='files'
										onChange={(files) =>
											setValue(
												'files',
												files.map((f) => f.id)
											)
										}
										id={commandeID}

									/>

								</div>
							</FormSectionCard>
						</FormContentSection>

						<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
							{loading ? (
									<div className='loader'></div>
								) : (
									'Ajouter'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					   </form>
						
				
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				//onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
					},
				}}
				contentLabel='Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontSize: '25px',
							fontWeight: 'bold',
						}}
					>
						Enregistrement
					</span>

					<div
						style={{
							marginTop: 20,
						}}
					>
						Vos données ont été enregistrés avec succès.
					</div>

					<button
						onClick={() => {
							setModalIsOpen(false)
							history.goBack()
						}}
						style={{
							padding: '1rem 3rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</LayoutChauffeur>
	)
}

export default NewSignalementMiseADispo

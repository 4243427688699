import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	getSingleTransporter,
	toggleSuspendreTransporter,
	softDeleteTransporter
} from '../../../api/transporteurs'
import Switch from 'react-switch'
import { useHistory, useParams } from 'react-router'
import AddVehicleRegister from '../../../Components/AddVehicle/AddVehicleRegister'
import { CustomInput } from '../../RegisterTransporter/style'
import File from '../../../assets/img/file@2x.png'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { BASE_URL } from '../../../api/base'
import toast from 'react-hot-toast'
import { updateTransporter } from '../../../api/clients'
import { useForm,Controller } from 'react-hook-form'
import { useSnackbar } from 'react-simple-snackbar'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
Modal.setAppElement('#root')
function SingleTransporter(props) {
	const { register, handleSubmit, setValue,control, reset,formState: { errors, isValid, isDirty }  } = useForm()
	const { id } = useParams()

	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [transporter, setTransporter] = useState(null)
	const [suspendre, setSuspendre] = useState(false)
	const [loading, setLoading] = useState(false)
	const user = useSelector((state) => state.auth.user)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [openSnackbar] = useSnackbar({
		position: 'top-center',
		style: {
			color: 'black',
			background: 'white',
			fontFamily: 'Montserrat',
		},
		closeStyle: {
			fontSize: '2rem',
			color: '#51C7F2',
		},
	})

	useEffect(() => {
		async function getTransporter(id) {
			setChargementEnCours(true)
			const data = await getSingleTransporter(id,token)
			setTransporter(data)
			setSuspendre(data.suspendre === true)
			reset(data)
			setChargementEnCours(false)

		}

		if (id) {
			getTransporter(id)
		}
	}, [id])

	const onSubmit = async (values) => {
		
		delete values._id
		delete values.verified
		delete values.verification
		delete values.updatedAt
		delete values.logo
		delete values.kbis
		delete values.createdAt
		//delete values.email
		delete values.suspendre
		delete values.status
		
    
		if (!values.reduction) {
			values.reduction = 0
		}
		try {
		await updateTransporter(transporter._id, values,token)
		
		
		if(!modalIsOpen){
            history.goBack()
        }		
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
		
	
	
	
	}
    const onError = (errors) => {
        console.log('Erreur de soumission', errors);
    };

	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					fontFamily: 'Montserrat',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingBottom: '1rem',
				}}
			>
			    {chargementEnCours ?
				<div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"10rem"
			}}
			>
			<div className='customLoader'></div>	
			    </div>
				:	
				<form
					style={{ width: '65%' }}
					onSubmit={handleSubmit(onSubmit, onError)}

				>
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Fiche Transporteur
								</p>
								
							</div>

							<div
								style={{
									display: 'flex',
								}}
							>
								<button
									onClick={async () => {
										setModalIsOpen(true)
									}}
									style={{
										border: 'none',
										background: '#ECC9F7',
										color: '#AB1CC5',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
									}}
								>
									{suspendre ? 'Activer' : 'Suspendre'}
								</button>
								{user.role==="admin" &&
								<button
									onClick={ () => {
										
										setModalIsOpenDelete(true)
									}}
									style={{
										border: 'none',
										background: '#F7CDC9',
										color: '#C5431C',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '1rem',
									}}
								>
									Supprimer
								</button>
                                 }
								<button
									onClick={()=>history.push(`/admin/transports/${transporter._id}/chauffeur`)}
									style={{
										border: 'none',
										background: '#50F5A9',
										color: '#FFF',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '1rem',
									}}
								>
									 Liste Chauffeur
								</button>
								<button
								    type="submit"
									style={{
										border: 'none',
										background: '#168EEA',
										color: '#FFF',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '2rem',
									}}
								>
									Enregistrer
								</button>
							</div>
						</div>
					</FormSectionCard>

					<FormSectionCard>
						<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Nom société
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='Wayne'
								value={transporter?.societe ||''}
							/>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Nom
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='Doe'
								value={transporter?.last_name ||''}

							/>
						</div>
						</div>
						<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Prénom
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='John'
								value={transporter?.first_name ||''}

								
							/>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Qualité
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='Directeur d’exploitation'
								value={transporter?.quality ||''}

							/>
						</div>
                        </div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Adresse
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								value={transporter?.address ||''}

							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Ville
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								value={transporter?.city ||''}

							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Code Postal
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								value={transporter?.postalCode ||''}

							/>
						</div>
                        <div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Email
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='test@gmail.com'
								value={transporter?.email ||''}

							/>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Téléphone
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='(0) 01 45 66 32 88'
								value={transporter?.phone ||''}

							/>
						</div>
						</div>
						
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     TVA
								</span>
								<Controller
									control={control}
									name='tva'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>
							<div style={{ width: 50 }} />
                            
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
								width: '50%',
								fontFamily: 'Montserrat',
								marginLeft: 10,
								marginTop: 10,
							}}
						>
							{transporter?.logo && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Logo(PNG ou JPG)
									</span>
								</div>
									<div>
										<img
											style={{
												width: 100,
												height: 100,
												borderRadius: 5,
												objectFit: 'cover',
											}}
											src={`${BASE_URL}/${transporter.logo.path}`}
										/>
									</div>
								
							</div>
							)}
						</div>
                       
					</FormSectionCard>

					<div style={{ height: '2rem' }} />

					<AddVehicleRegister
						initialVehicles={
							transporter ? transporter.vehicules : []
						}
						disableAdd
						onChange={(items) => setValue('vehicules', items)}
					/>

					<div style={{ height: '2rem' }} />
                     
					<FormContentSection>
						<FormSectionTitle>Rippeur</FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Nombre de ripper en plus du chauffeur
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<div>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='13.605'
											height='27.839'
											viewBox='0 0 13.605 27.839'
											style={{
												marginRight: '.5rem',
											}}
										>
											<g
												id='user'
												transform='translate(0.5 0.5)'
											>
												<path
													id='Path_11624'
													d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
													transform='translate(-25.195 -1.992)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
												<path
													id='Path_11625'
													d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
													transform='translate(-20.562 -14.552)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
												<path
													id='Path_11626'
													d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
													transform='translate(-30.081 -21.807)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
												<path
													id='Path_11627'
													d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
													transform='translate(-24.794 -21.807)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
											</g>
										</svg>
									</div>

									<CustomInput
										width={'100%'}
										type='number'
										disabled
										value={transporter?.rippeurs || 0}

									/>
								</div>
							</div>
						</FormSectionCard>
					</FormContentSection>

					<div style={{ height: '2rem' }} />

					<FormContentSection>
						<FormSectionTitle>
							Matériels supplémentaire
						</FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Matériels de manutention
								</span>

								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'row',
										marginBottom: '1rem',
									}}
								>
									{[
										'Diable',
										'Transpalette manuel',
										'Transpalette électrique',
										'Sangle',
									].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '30%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
											    disabled
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
												
												checked={transporter?.manutention?.includes(item)}


												value={item}
												
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Epi
								</span>

								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'row',
										//marginBottom: '1rem',
									}}
								>
									{[
										'Casque',
										'Gants',
										'Masque',
										'Lunettes',
										'Chaussure de sécurité',
										'Gilet de visibilité',
									].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '25%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
											    disabled
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
											
												checked={transporter?.epi?.includes(item)}

																								

												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
							</div>
							
						</FormSectionCard>
					</FormContentSection>
					<div style={{height:'2rem'}}/>
					<FormContentSection>
					<FormSectionTitle>Documents Administratifs</FormSectionTitle>
					<FormSectionCard>
					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Attestation de régularité fiscale (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.fiscale?.path}`
													)
												}}
											>
												{transporter?.fiscale?.name}
												
											
											</span>
					        </div>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Attestation de vigilance Urssaf (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.urssaf?.path}`
													)
												}}
											>
												{transporter?.urssaf?.name}
												
											
											</span>
					        </div>
						</div>
                    </div>
					<div style={{ height: '1rem' }} />
					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Assurance marchandises (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.assurance_marchandises?.path}`
													)
												}}
											>
												{transporter?.assurance_marchandises?.name}
												
											
											</span>
					        </div>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Kbis (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.kbis?.path}`
													)
												}}
											>
												{transporter?.kbis?.name}
												
											
											</span>
					        </div>
						</div>
                    </div>
					<div style={{ height: '1rem' }} />
                    <div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Licence de transport de marchandises de moins de 3,5t (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.licence_moins35t?.path}`
													)
												}}
											>
												{transporter?.licence_moins35t?.name}
												
											
											</span>
					        </div>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Licence de transport de marchandises de plus de 3,5t (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.licence_plus35t?.path}`
													)
												}}
											>
												{transporter?.licence_plus35t?.name}
												
											
											</span>
					        </div>
						</div>
                    </div>
					<div style={{ height: '1rem' }} />
					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Pièce d'identité (PDF)
							</span>
							<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${transporter?.piece_identite?.path}`
													)
												}}
											>
												{transporter?.piece_identite?.name}
												
											
											</span>
					        </div>
						</div>

						
                    </div>
					</FormSectionCard>
					</FormContentSection>
					<div style={{ height: '2rem' }} />

				</form>
                }
			</div>
			<Modal
				isOpen={modalIsOpenDelete}
                onRequestClose={() => setModalIsOpenDelete(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Transporteur</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce Transporteur ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								const res=await softDeleteTransporter(transporter._id,token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								setModalIsOpenDelete(false)
								history.goBack()
							   } catch (error) {
								toast.error(
									error.response.data.message,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
								setModalIsOpenDelete(false)

							   }finally {
								setLoading(false)
							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
					 {loading ? 'Chargement' : 'Confirmer'} 
					</button>
					<button
						onClick={async () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		   <Modal
				isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>{suspendre ? 'Activer Transporteur' : 'Suspendre Transporteur'}
		            </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						{`Êtes-vous sûr de vouloir ${suspendre ? 'Activer' : 'Suspendre'} ce Transporteur ?`}

						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								const data={suspendre:!suspendre}
								const res=await toggleSuspendreTransporter(
									transporter._id,
									data,
									token
						        )
						toast.success(res.message, {
							style: {
								fontFamily: 'Montserrat',
							},
						})
						setSuspendre(!suspendre)
						setModalIsOpen(false)
						history.goBack()
							} catch (error) {
								toast.error(
									error.response.data.message,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
								setModalIsOpen(false)
							}finally {
								setLoading(false)
							}
                              
                            }}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
				   {loading ? 'Chargement' : 'Confirmer'} 

					</button>
					<button
						onClick={async () => {
							setModalIsOpen(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		</LayoutAdmin>
	)
}

export default SingleTransporter

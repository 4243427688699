import React, { useEffect, useState } from 'react'
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import DataTable from 'react-data-table-component'
import { useHistory,useParams } from 'react-router'
import { useSelector } from 'react-redux'
import {getDemandeTransfertForCommande} from '../../api/commande'
function DemandeTransfert() {
    const history=useHistory()
    const {id}=useParams()
    const [data, setData] = useState([])
	const [chargementEnCours,setChargementEnCours]=useState(false)
    const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
    const token = useSelector((state) => state.auth.token);

    const fetchData = async (page) => {
		setChargementEnCours(true)
		const res = await getDemandeTransfertForCommande(id,token,page, perPage)
        setData(res.docs)
		setTotalRows(res.totalDocs)
        setChargementEnCours(false)
	}
    const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchData(page)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getDemandeTransfertForCommande(id,token,page, perPage)
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(true)
	}
    const columns = [
		{
			name: 'commande ID',
			selector: (row) => row.commandeId.__id,
			width: '200px',
			sortable: false,
		},
        {
			name: 'Transporteur',
			cell: (row) => (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
								onClick={()=>{
									if(row.transporteurId?.deletedAt !== null){
										history.push(`/admin/transporters/single/${row.transporteurId._id}`)

									}else{
										history.push(`/admin/transporters/${row.transporteurId._id}`)

									}
								}}
							>

								<span style={{
							    fontWeight: 'bold',
								cursor: 'pointer',
								color:row?.transporteurId?.deletedAt !== null ? 'red' :'#51C7F2'
						        }}>
			                    {row?.transporteurId?.societe}
			                   </span>
							</div>

						</div>
					),
					sortable: false,
					width: '200px',
		},
		{
			name: 'Chauffeur',
			cell: (row) => (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
								onClick={()=>{
                                    history.push(`/admin/transports/chauffeur/single/${row.chauffeurId._id}`)

								}}
							>

								<span style={{
							    fontWeight: 'bold',
								cursor: 'pointer',
								color:'#51C7F2'
						        }}>
			                    {row?.chauffeurId?.last_name }  {row?.chauffeurId?.first_name}
			                   </span>
							</div>

						</div>
					),
					sortable: false,
					width: '200px',
		},
		{
			name: 'Statut de transfert',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: '#51C7F2',
						}}
					>
						{row.commandeId.statutTransfert === 'transferé' ? 'Transferé' : (row.commandeId.statutTransfert === "en cours de transfert" ? "En cours de transfert" : row.commandeId.statutTransfert === "aucun transfert" && "Aucun transfert")}
					</span>
				</div>
			),
			sortable: false,
			width: '200px',
		},
        {
			name: 'Distance parcourue',
			selector: (row) => row.distanceParcourue,
			sortable: false,
			width: '200px',
		},		
		{
					name: 'Actions',
					button: true,
					cell: (row) => (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								onClick={() => {
									history.push(
										`/admin/demande-transfert/details/${row._id}`
									)
								}}
								style={{
									cursor: 'pointer',
								}}
							>
							 <svg
			                  xmlns="http://www.w3.org/2000/svg"

							  width='26'
							  height='26'
			                  viewBox="0 0 24 24"
			                  >
			                  <path
			                  fill="#b4b4c3"
			                   d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
			                   />
			                   <path
			                   fill="#b4b4c3"
			                    d="M11 7h2v7h-2zm0 8h2v2h-2z"
			                   />
			                  </svg>
							</div>

							<div style={{ width: 10 }} />

						</div>
					),
				},
	]
    useEffect(()=>{
        fetchData(currentPage)
    },[id])
  return (
    <LayoutAdmin>
        <div 
        style={{
            //height: '80vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '2rem',
            paddingBottom: '4rem',
            fontFamily: 'Montserrat',
        }}>
        <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'

							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Demandes de transfert'.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ?
						<div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  marginTop:'10rem'
						}}
						>
						<div className='customLoader'></div>	
						</div> 
                        :
                        <div
					style={{
						width: '100%',
						padding: '0 1rem',
                        marginTop:"3rem"
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}						
						noDataComponent="Il n'y a pas des demandes de transfert à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
                    }
        </div>
    </LayoutAdmin>
  )
}

export default DemandeTransfert
import axios from 'axios'
import api, { BASE_URL } from './base'


export const searchCommandesById = async (query,type,token) => {
	const res = await api.get(`/commandes/search/${query}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const geoSearchChauffeurs = async (lat, lng, radius) => {
	const res = await axios({
		method: 'POST',
		url: `${BASE_URL}/indexes/users/search`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
		data: JSON.stringify({
			filter: `_geoRadius(${lat}, ${lng}, ${radius})`,
		}),
	})

	return res.data
}

export const searchCommandesByClientAndTransporter = async (query,type,token) => {
	/*const res = await axios({
		method: 'GET',
		url: `${BASE_URL}/indexes/commandes/search?q=${query}`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
	})
    */
   const res = await api.get(`/commandes/searchclient/${query}/all?filter=${type}`,{
	headers: {
		  Authorization: `Bearer `+token
		},
	  })
	return res.data
}
export const searchCommandesByClientAndTransporterForPlateforme = async (query,type,token) => {

   const res = await api.get(`/commandes/plateforme/${query}/all?filter=${type}`,{
	headers: {
		  Authorization: `Bearer `+token
		},
	  })
	return res.data
}
export const searchCommandesByPrixMinMax = async (min, max,type,token) => {
	/*const res = await axios({
		method: 'POST',
		url: `${BASE_URL}/indexes/commandes/search`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
		data: JSON.stringify({
			filter: `prix >= ${min} AND prix <= ${max}`,
		}),
	})*/
	const res = await api.get(`/commandes/price/${min}/${max}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const searchCommandesAnomaliesByPrixMinMax = async (min, max,token) => {
	
	const res = await api.get(`/commandes/anomalie/price/${min}/${max}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const searchCommandesByDate = async (min, max,type,token) => {
	/*const res = await axios({
		method: 'POST',
		url: `${BASE_URL}/indexes/commandes/search`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
		data: JSON.stringify({
			filter: `absolute_start_date_unix >= ${min} AND absolute_start_date_unix <= ${max}`,
		}),
	})*/
	const res = await api.get(`/commandes/date/${min}/${max}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const searchCommandesAnomaliesByDate = async (min, max,token) => {
	
	const res = await api.get(`/commandes/anomalie/date/${min}/${max}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const searchCommandesAnomaliesByClientAndTransporter = async (query,token) => {
	
	const res = await api.get(`/commandes/anomalie/search/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const searchCommandesAnomaliesById = async (id,token) => {

	const res = await api.get(`/commandes/anomalie/${id}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const fullTextSearchChauffeurs = async (q) => {
	const res = await axios({
		method: 'GET',
		url: `${BASE_URL}/indexes/users/search?q=${q}`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
	})

	return res.data
}

export const fullTextSearchClients = async (query,token) => {
	query=query.trim()
	const res = await api.get(`/users/clientforadmin/${query}/all?filter=supprimer`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchClientsForAdmin = async (query,token,type,status) => {
	query=query.trim()
	const res = await api.get(`/users/clientforadmin/${query}/all?filter=${type}&status=${status}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchChauffeur = async (query,token,status) => {
	query=query.trim()
	const res = await api.get(`/users/chauffeurs/byIdandName/${query}/all?status=${status}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchTransporteurs = async (query,token,type) => {
	query=query.trim()
	const res = await api.get(`/users/transporteurforadmin/${query}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const fullTextSearchNewTransporteurs = async (query,token) => {
	query=query.trim()
	const res = await api.get(`/users/newtransporteur/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchValidNewTransporteurs = async (query,token) => {
	query=query.trim()
	const res = await api.get(`/users/newtransporteur/${query}/all?status=validated`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchNoValidNewTransporteurs = async (query,token) => {
	query=query.trim()
	const res = await api.get(`/users/newtransporteur/${query}/all?status=notValidated`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

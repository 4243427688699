import React, { useState } from 'react'

import {BASE_URL } from '../../api/base'
import UploadFileSignalement from '../UploadFileSignalement/UploadFileSignalement'
import File from "../../assets/img/file@2x.png"
import {deleteMedia} from "../../api/upload"
function UploadMultipleFiles({ name, initialFiles = [], onChange,id,setDeletedFiles,allowDelete=true,disabled=true }) {
	const [files, setFiles] = useState(initialFiles)
	const [newFiles,setNewFiles]=useState([])
	return (
		<div>
			{disabled &&
			<UploadFileSignalement
				name={name}
				accept='.jpg, .png, .pdf'

				onFile={(file) => {
					setFiles([...files, file])
					onChange([...files, file])
					setNewFiles([...newFiles, file]);

				}}
				id={id}
			/>
           }

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '500px',
					flexWrap: 'wrap',
				}}
			>
				{files.map((file, index) => (
					<div
						key={String(index)}
						style={{
							display: 'flex',
							flexDirection: 'column',
							//alignItems: 'center',
							justifyContent: 'flex-start',
							marginBottom: '1rem',
							marginRight: '1rem',
							width: 100,
						}}
					>
						{file.mimetype !== 'application/pdf' ?
						<img
							style={{
								width: 100,
								height: 100,
								borderRadius: 5,
								objectFit: 'cover',
								marginBottom: '1rem',
							}}
							src={`${BASE_URL}/${file?.path}`}
							alt=''
						/>
                       :
					   <div
					   style={{
						   display: 'flex',
						   flexDirection: 'row',
						   marginBottom: '1rem',
					   }}
				   >
					   <img
						   alt=''
						   src={File}
						   style={{
							   height: 20,
							   marginRight: '1rem',
						   }}
					   />
					   <span
						   style={{
							   cursor: 'pointer',
						   }}
						   onClick={() => {
							   window.open(
								   `${BASE_URL}/${file?.path}`
							   )
						   }}
					   >
						   {file?.name}
					   </span>
				   </div>
					   }
					   {allowDelete &&
						<button
							type='button'
							onClick={async() => {
								const fileExists = newFiles.some((f) => f.name === file.name);
                                if(fileExists){
									await deleteMedia(file.id)
								}else{
									setDeletedFiles(prevDeletedFiles => [...prevDeletedFiles, file]);
								}
								setFiles(
									files.filter((f) => f.name !== file.name)
								)
								onChange(
									files.filter((f) => f.name !== file.name)
								)
							}}
							style={{
								color: 'white',
								border: 'none',
								backgroundColor: '#51C7F2',
								padding: '0.5rem 1rem',
								fontFamily: 'Montserrat',
								borderRadius: '0.5rem',
								cursor: 'pointer',
							}}
						>
							Supprimer
						</button>
                       }
					</div>
				))}
			</div>
		</div>
	)
}

export default React.memo(UploadMultipleFiles)

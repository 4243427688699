import React, { useEffect, useState } from 'react'
import {
	softDeleteCommande,
	getAllCommandesByClientIdForFacturation,
	getAllCommandeByClientForFacturation,
	remboursementCommande,
	getCommandeByClientByDateForFacturation,
	getCommandeByClientByPriceForFacturation

} from '../../../api/commande'
import Select from 'react-select'
import { getStatsForSingleClient,getStatsForAllSignalementsByCommandeID } from '../../../api/stats'
import { useParams } from 'react-router'
import toast from 'react-hot-toast'
import { FaRegFilePdf } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi2";
import DataTable from 'react-data-table-component'
import EditTransportStatus from '../../../Components/EditTransportStatus/EditTransportStatus'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { BASE_URL,PUBLIC} from '../../../api/base'
import { getInvoice,mergeInvoiceForCommande,mergeAvoirForCommande,exportInvoices } from '../../../api/invoice'
import moment from 'moment'
import { useSelector } from 'react-redux'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')



function SingleClientFacturation(props) {

	const { id } = useParams()
	const [selectedDate, setSelectedDate] = useState(null);

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [loadingRemboursemet, setLoadingRemboursemet] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const token = useSelector((state) => state.auth.token);
	const [selected, setSelected] = useState([])
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [montantTotal, setMontantTotal] = useState(null)
    const [nouveauPrix,setNouveauPrix]=useState(0)
	const [loadingStats, setLoadingStats] = useState(false)

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const [currentPage, setCurrentPage] = useState(1)
	const [pdfLoading, setPdfLoading] = useState(false)
   const  [remboursementModalIsOpen,setRemboursementModalIsOpen]=useState(false)
	const [editModalIsOpen, setEditModalIsOpen] = useState(false)
	const [selectedTransport, setSelectedTransport] = useState(null)
	const [selectedCommande, setSelectedCommande] = useState(null)
	const [searchQuery, setSearchQuery] = useState('')
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
	const [deleteCommandeLoading, setDeleteCommandeLoading] = useState(false)
	const [selectedCommandeToDelete, setSelectedCommandeToDelete] =
		useState(null)
	const [searchCriteria, setSearchCriteria] = useState('id')

	const [selectedOption, setSelectedOption] = useState({
			value: 'id', label: "Recherche par l'identifiant " 
		 });
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
    const handleChange = (selectedOption) => {
		   setSelectedOption(selectedOption);
		   setSearchCriteria(selectedOption.value)
		 };
	const handleViewInvoices = async (row) => {
				try {
					const res=await mergeInvoiceForCommande(row._id,token)
					window.open(`${BASE_URL}/${res.mergedPdfPath}`)
				} catch (error) {
					console.log(error)
				}
		};
	
	const handleViewAvoir = async (row) => {
			try {
				const res=await mergeAvoirForCommande(row._id,token)
				window.open(`${BASE_URL}/${res.mergedPdfPath}`)
			} catch (error) {
				console.log(error)
			}
	};

	const fetchStatsForAllSignalementByCommandeID=async(id)=>{
		const res=await getStatsForAllSignalementsByCommandeID(id,token)
	   return res.sommePayé
    }
	const fetchStatsForAllSignalementRembourséByCommandeID=async(id)=>{
		const res=await getStatsForAllSignalementsByCommandeID(id,token)
	   return res.sommeRemboursé
    }
	const getCommandeForClient = async (id,page) => {
		setChargementEnCours(true)
		const res = await getAllCommandesByClientIdForFacturation(id,token,page, perPage)
		const commandes = res.docs;
        const prixSignalementPromises = commandes.map((commande) => fetchStatsForAllSignalementByCommandeID(commande._id));
        const prixSignalements = await Promise.all(prixSignalementPromises);
        commandes.forEach((commande, index) => {
            commande.prixSignalement = prixSignalements[index];
        });
		const prixSignalementRembourséPromises=commandes.map((commande) => fetchStatsForAllSignalementRembourséByCommandeID(commande._id));
		const prixSignalementsRemboursé = await Promise.all(prixSignalementRembourséPromises);
		commandes.forEach((commande, index) => {
            commande.prixSignalementRemboursé = prixSignalementsRemboursé[index];
        });
		setData(commandes)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)
		setSearchQuery('')
		setMin(0)
		setMax(300)
		setSelectedDate(null)
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		  setSearchCriteria("id")
	}
	const isCommandeRembourse = (commande) => {
		let isCommandeRembourse = true;
		if (commande.oldPrice) {
			isCommandeRembourse = commande.rembourse === true;
		}
		let areAllPackagesRembourse = true;
		for (const item of commande.packages) {
			if (item.oldPrice) {
				areAllPackagesRembourse = areAllPackagesRembourse && item.rembourse === true;
			}
		}
		return isCommandeRembourse && areAllPackagesRembourse;
	  };



	const handlePageChange = (page) => {
		setCurrentPage(page)
		getCommandeForClient(id,page)
	}

	const handlePerRowsChange = async (newPerPage,page) => {
		setLoading(true)

		const res = await getAllCommandesByClientIdForFacturation(id,token,page,newPerPage)
		const commandes = res.docs;
        const prixSignalementPromises = commandes.map((commande) => fetchStatsForAllSignalementByCommandeID(commande._id));
        const prixSignalements = await Promise.all(prixSignalementPromises);
        commandes.forEach((commande, index) => {
            commande.prixSignalement = prixSignalements[index];
        });
		const prixSignalementRembourséPromises=commandes.map((commande) => fetchStatsForAllSignalementRembourséByCommandeID(commande._id));
		const prixSignalementsRemboursé = await Promise.all(prixSignalementRembourséPromises);
		commandes.forEach((commande, index) => {
            commande.prixSignalementRemboursé = prixSignalementsRemboursé[index];
        });
		setData(commandes)
		setPerPage(newPerPage)
		setLoading(false)
	}

	const fetchStats = async () => {
		setLoadingStats(true)

		const res = await getStatsForSingleClient(id,token)

		
		setMontantTotal(res.montantTotal)

		setLoadingStats(false)
	}
	const handleDownload = async () => {
		try{
			const response = await exportInvoices(data,token)
            console.log('response',response)
			const url = window.URL.createObjectURL(new Blob([response], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			const currentDate = new Date().toISOString().slice(0, 10);
            link.setAttribute('download', `factures_${currentDate}.zip`); 
			document.body.appendChild(link);
			link.click();
			link.remove();
		}catch(error){
        console.log("error",error)
		}
	}

	useEffect(() => {
		getCommandeForClient(id,currentPage)

		fetchStats()
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.clientID.societe}`,
			sortable: false,
			width: '150px',
		},
		{
			name: 'Type du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: '#51C7F2',
							marginBottom: 3,
						}}
					>
						{row.type_commande == 'course-a-course'
							? 'Course à course'
							: 'Mise à disposition'}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		{
			name: 'Date du transport',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row.debut}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].date_debut}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '150px',
		},
		{
			name: 'Montant du transport',
			selector: (row) => {
				const totalForfait = row.packages || []; 
				const totalPackagePrice = totalForfait.reduce((total, p) => total + p.price, 0);
				return (row.prix + totalPackagePrice).toFixed(2);
			},
			sortable: false,
			width:'170px'
		},	
		{
			name: 'Montant du remboursement',
			selector: (row) => {
				if(row.statut==="annuler" && row.rembourse===true && row.type_commande==="course-a-course"){	
					return (row.prixRembourse).toFixed(2);
				}
				if(row.statut==="annuler" && row.rembourse===true && row.statutPrecedent==="init" && row.type_commande==="mise-a-disposition" && row.packages.every(forfait => forfait.rembourse) ){	
					return (row.prixRembourse).toFixed(2);
				}
				if(isCommandeRembourse(row)&& row.statut==="annuler" && row.type_commande==="mise-a-disposition" &&  row.statutPrecedent==="en-cours"){
					return (row.prixRembourse).toFixed(2);
				}
			
			},
			sortable: false,
			width:'210px'
		},
		{
			name: 'Montant signalement',
			selector: (row) => `${row.prixSignalement}`,
			sortable: false,
			width: '190px',
		},
		{
			name:'Montant du signalement remboursé',
			selector: (row) => `${row.prixSignalementRemboursé}`,
			sortable: false,
			width: '260px',

		},
		{
			name: 'Statut commande',
			selector: (row) => {
				if (row?.statut === "init") return "Non commencée";
				if (row?.statut === "en-cours") return "En cours";
				if (row?.statut === "terminer") return "Terminée";
				if (row?.statut === "annuler") return "Annulée";
				return ""; 
			},
			sortable: false,
			width: '160px'
		},
		
		{
			name: 'Statut du paiement',
			selector: (row) =>row.paymentStatus,
			sortable: false,
			width:'160px'
		},
		{
			name: 'Statut de remboursement',
			selector: (row) =>{
				if(row.statut==="annuler"  && row.statutPrecedent==="init" && row.type_commande==="course-a-course"){
					return row.rembourse === true ? "remboursé" : "non remboursé";
				}
				if(row.statut==="annuler"  && row.statutPrecedent==="en-cours" && row.type_commande==="course-a-course"){
					return row.rembourse === true && row.remboursementLance=== true ? "remboursé" : "non remboursé";
				}
				if(row.statut==="annuler" && row.statutPrecedent==="init" && row.type_commande==="mise-a-disposition"  ){	
					return  row.rembourse===true && row.packages.every(forfait => forfait.rembourse) ? "remboursé" : "non remboursé";
				}
				if(isCommandeRembourse(row)){
					return 'remboursé'
				}else{
					return ' non remboursé'
				}

			},
			sortable: false,
			width:'200px'
		},
		{
			name: 'Date de paiement',
			selector: (row) => `${row.datePaiement? moment(row.datePaiement).format('DD/MM/YYYY') : '__'}`,
			sortable: false,
			width:'160px'
			
		},
		{
			name: 'Date  de remboursement ',
			selector: (row) => `${ row.dateRemboursement ? moment(row.dateRemboursement).format('DD/MM/YYYY') :'__'}`,
			sortable: false,
			width:'190px'
			
		},
		{
			name: 'Date de la création',
			selector: (row) => `${moment(row.createdAt).format('DD/MM/YYYY')}`,
			sortable: false,
			width:'150px'
			
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>

					
					<div
						
						onClick={() => handleViewInvoices(row)}
						style={{
							cursor: 'pointer',
						}}
					>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'#51C7F2'}} />


					</div>
					{row.avoir.length!==0 &&
					<div
						
						onClick={() => handleViewAvoir(row)}
						style={{
							cursor: 'pointer',
							marginLeft:'2px'
						}}
					>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'red'}} />


					</div>
		            }

                {((row?.statut === "annuler"  && row?.paymentStatus === "payé" && !row?.clientID.premium && !row.remboursementLance && row?.statutPrecedent === "en-cours" ) ||
                (row?.statut === "annuler" && !row?.rembourse && row?.clientID.premium && row?.statutPrecedent === "en-cours")) && (
					<div
						onClick={() => {
							setSelectedCommande(row)
							setRemboursementModalIsOpen(true)
						}}
						style={{
							cursor: 'pointer',
							marginLeft:'2px'

						}}
					>
					< HiCurrencyDollar style={{ fontSize: "28px" ,color:'#51C7F2'}} />

					</div>
				)}
					
					

					
				</div>
				
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Facturation'.toUpperCase()}
				</GradientText>
				<div style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						justifyContent: 'flex-end',
					}}>
							<div
						style={{
							//background: 'red',
							width: '30%',
							padding: '1rem',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							border: 'solid .1rem black',
							borderRadius: '1rem',
							marginRight: '0.5rem',
						}}
					>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<span>Montant total</span>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: 20,
								}}
							>
								{montantTotal}
							</span>
						</div>
					</div>
                   </div>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
						<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{ value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
				
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
						{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}
						{searchCriteria === 'date' ? (
						<input
						    type='date'
							value={selectedDate}
							onChange={(e) => setSelectedDate(e.target.value)}
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							placeholder='Recherche'
						/>
					) : null}

					<button
						onClick={async() => {
							if (
								searchCriteria === 'id' &&
								searchQuery.length === 0
							) {
								return
							}
							let res
							if (searchCriteria === 'id') {
								res =await  getAllCommandeByClientForFacturation(id,searchQuery,token)
									
							}
							if (searchCriteria === 'prix') {
								res =await  getCommandeByClientByPriceForFacturation(id,min,max,token)
							}
							if (searchCriteria === 'date') {
								res =await  getCommandeByClientByDateForFacturation(id,selectedDate,token)
							}
							const commandes = res;
							const prixSignalementPromises = commandes.map((commande) => fetchStatsForAllSignalementByCommandeID(commande._id));
                            const prixSignalements = await Promise.all(prixSignalementPromises);
                            commandes.forEach((commande, index) => {
                            commande.prixSignalement = prixSignalements[index];
                             });
		                    const prixSignalementRembourséPromises=commandes.map((commande) => fetchStatsForAllSignalementRembourséByCommandeID(commande._id));
		                    const prixSignalementsRemboursé = await Promise.all(prixSignalementRembourséPromises);
		                    commandes.forEach((commande, index) => {
                            commande.prixSignalementRemboursé = prixSignalementsRemboursé[index];
                            });
							setData(res)
						}}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginLeft:'1rem',
							// marginTop: 20,
							// marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							marginRight: '0.5rem',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={async () => {
							await getCommandeForClient(id,currentPage)
							setSearchQuery('')
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginRight: '0.5rem',
							
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					<button
						onClick={async () => {
							await handleDownload()
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginRight: '0.5rem',
							
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
					 Exporter factures
					</button>

					
{/* 
					<button
						onClick={() => {
							setModalIsOpen(true)
						}}
						style={{
							padding: '1rem 2rem',
							background: '#60d2f8',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							marginRight: '0;5rem',
						}}
					>
						Facturer
					</button> */}
				</div>

				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent='pas de commandes à afficher'
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						selectableRows
						onSelectedRowsChange={(state) => {
							setSelected(state.selectedRows)
						}}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>

				<Modal
					isOpen={modalIsOpen}
					onRequestClose={() => setModalIsOpen(false)}
					style={{
						overlay: {
							position: 'fixed',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'rgba(0, 0, 0, 0.45)',
						},
						content: {
							top: '50%',
							left: '50%',
							right: 'auto',
							bottom: 'auto',
							marginRight: '-50%',
							transform: 'translate(-50%, -50%)',
							borderRadius: 19,
							padding: '2rem',
						},
					}}
					contentLabel='Example Modal'
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<button
							onClick={async () => {
								if (selected.length === 0) {
									alert('Il faut séléctionner des commandes')
									return
								}

								setPdfLoading(true)

								const data = await getInvoice(
									'select',
									id,
									selected,
									token
								)

								window.open(`${PUBLIC}/${data.invoice}.pdf`)

								setPdfLoading(false)
							}}
							disabled={pdfLoading}
							style={{
								padding: '1rem 2rem',
								background: '#60d2f8',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
								marginRight: '1rem',
								width: '14rem',
								marginBottom: '1rem',
							}}
						>
							{pdfLoading ? 'Chargement' : 'Un groupe de facture'}
						</button>
						<button
							onClick={async () => {
								// if (selected.length === 0) {
								// 	alert('Il faut séléctionner des commandes')
								// 	return
								// }

								setPdfLoading(true)

								const data = await getInvoice(
									'month',
									id,
									selected,
									token
								)

								window.open(`${PUBLIC}/${data.invoice}.pdf`)

								setPdfLoading(false)
							}}
							disabled={pdfLoading}
							style={{
								padding: '1rem 2rem',
								background: '#60d2f8',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
								marginRight: '1rem',
								width: '14rem',
							}}
						>
							{pdfLoading ? 'Chargement' : 'Facture du mois'}
						</button>
					</div>
				</Modal>

				<Modal
					isOpen={deleteModalIsOpen}
					onRequestClose={() => setDeleteModalIsOpen(false)}
					style={{
						overlay: {
							position: 'fixed',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'rgba(0, 0, 0, 0.45)',
						},
						content: {
							top: '50%',
							left: '50%',
							right: 'auto',
							bottom: 'auto',
							marginRight: '-50%',
							transform: 'translate(-50%, -50%)',
							borderRadius: 19,
							padding: '2rem',
						},
					}}
					contentLabel=''
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<p style={{ fontFamily: 'Montserrat' }}>
							Êtes-vous sûr de vouloir supprimer ?
						</p>
						<button
							onClick={async () => {
								if (!selectedCommandeToDelete) {
									return
								}

								setDeleteCommandeLoading(true)

								await softDeleteCommande(
									selectedCommandeToDelete._id,
									token
								)

								await getCommandeForClient(id,currentPage)

								await fetchStats()

								setDeleteCommandeLoading(false)
								setDeleteModalIsOpen(false)
							}}
							disabled={deleteCommandeLoading}
							style={{
								padding: '1rem 2rem',
								background: '#60d2f8',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
								marginRight: '1rem',
								width: '100%',
								marginBottom: '1rem',
							}}
						>
							{deleteCommandeLoading ? 'Chargement' : 'OK'}
						</button>
					</div>
				</Modal>
				<Modal
			isOpen={remboursementModalIsOpen}
			onRequestClose={() => setRemboursementModalIsOpen(false)}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: 'rgba(0, 0, 0, 0.45)',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					bottom: 'auto',
					marginRight: '-50%',
					transform: 'translate(-50%, -50%)',
					borderRadius: 19,
					padding: '2rem',
				},
			}}
			contentLabel=''
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontFamily: 'Montserrat',
				}}
			>
				<h2 style={{ 
					marginBottom: '1rem' ,
					color:'#60d2f8',
					textAlign: 'center',


				}}>Remboursement</h2>
				<label>Nouveau Prix</label>
				<input
                style={{
                padding: '1rem',
                borderRadius: 5,
                border: '1px solid #E4EAF0',
                width: '100%',
                fontFamily: 'Montserrat',
                marginBottom: '1rem',
                }}
                type='number'
				value={nouveauPrix}
				onChange={(e) => setNouveauPrix(Number(e.target.value))}
              
                />
				 <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
				 <button
                  onClick={async () => {
					setLoadingRemboursemet(true);
                  if (!selectedCommande) {
					return
				}
				try {
				const res=await remboursementCommande(token,selectedCommande._id,nouveauPrix)
				toast.success(
					res.message,
					{
						style: {
							fontFamily: 'Montserrat',
						},
					}
				)
				
				} catch (error) {
					toast.error(
						error.response.data.error,
						{
							style: {
								fontFamily: 'Montserrat',
							},
						}
					)
					setLoading(false);
					setRemboursementModalIsOpen(false);
			     }finally{
					setLoadingRemboursemet(false);
					setRemboursementModalIsOpen(false);
				
					window.location.reload()
				   }

				
                  }}
				 disabled={loadingRemboursemet}
                 style={{
					width:"150px",
					height:"50px",
					background: '#60d2f8',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
                }}
    >
       {loadingRemboursemet ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
                </button>
				<button
						onClick={ () => {
							setRemboursementModalIsOpen(false)	

						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							marginLeft:'1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',

						}}
					>
						Annuler
					</button>
				</div>
  
			</div>
		</Modal>

				{selectedTransport && (
					<EditTransportStatus
						modalIsOpen={editModalIsOpen}
						setModalIsOpen={setEditModalIsOpen}
						transport={selectedTransport}
						onSuccess={async () => {
							getCommandeForClient(id,currentPage)

							fetchStats()
						}}
					/>
				)}
			</div>
		</LayoutAdmin>
	)
}

export default SingleClientFacturation

import React,{ useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { CustomInput } from '../../Containers/RegisterTransporter/style'
import {StyledTextArea } from '../../Containers/PasserCommande/PasserCommande.styles'
import {getDemandeTransfertByCommandeIdAndTransprteurId} from '../../api/transporteurs'
import {updateDemandeTransfert} from '../../api/commande'
import { useSelector } from 'react-redux'
import  toast from 'react-hot-toast'


const ModiferDemandeTransfert = ({ setModalIsOpenUpdateTransfret, modalIsOpenUpdateTransfret, commande }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const user = useSelector(state => state.auth.user)
	const token = useSelector((state) => state.auth.token);
    const [demandeTransfert,setDemandeTransfert]=useState(null)


    const fetchData=async()=>{
        setChargementEnCours(true)
        try {
            const res=await getDemandeTransfertByCommandeIdAndTransprteurId (commande._id,user._id,token)
            setDemandeTransfert(res)
            reset(res)
        } catch (error) {
           console.log(error)  
        }finally{
            setChargementEnCours(false)

        }
    }
    const onSubmit = async (data) => {
        setUpdateLoading(true)
       try {
        if(Number(data.distanceParcourue)>commande.distance){
            toast.error(`la distance parcourue ne doit pas dépasser la distance de la commande ${commande.distance} km`, {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
            return 
        }
        await updateDemandeTransfert(demandeTransfert._id,data,token)
        toast.success('Demande de transfert a été effectuée avec succès', {
            style: {
                fontFamily: 'Montserrat',
            },
        })

       } catch (error) {
        console.error('Erreur lors de la demande de transfert', error);
        toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
       }finally{
        setUpdateLoading(false)
        setModalIsOpenUpdateTransfret(false)
       }
    };
    useEffect(()=>{
        fetchData()
    },[commande._id,modalIsOpenUpdateTransfret])

    return (

        <Modal
            isOpen={modalIsOpenUpdateTransfret}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.45)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 19,
                    background: '#E4EAF0',
                    height: '480px',
                    width: '600px',
                },
            }}
            contentLabel='Example Modal'
        >
            <div
                style={{
                    width: '100%',
                    fontFamily: 'Montserrat',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 15,
                    textAlign: 'left',
                    color: '#838EAB',
                }}
            >

                <div
                    style={{
                        color: '#51C7F2',
                        fontSize: 30,
                        marginBottom: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                  Modifier la demande de transfert
                </div>
                {chargementEnCours ?
                 <div
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   marginTop:'10rem'
                 }}
                 >
                 <div className='customLoader'></div>	
                 </div> 
                 :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Distance parcourue
                            </span>
                            <CustomInput
                                type='number'
                                placeholder=''
                                min={1}
                                {...register('distanceParcourue', { required: 'La distance est requise.' })}
                            />
                            {errors.distanceParcourue && <span style={{ color: 'red', marginTop: '0.5rem' }}>{errors.distanceParcourue.message}</span>}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginRight: '1rem',
                                marginTop: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Remarque
                            </span>
                            <StyledTextArea
                                type='text'
                                rows={4}
                                placeholder=''
                                {...register('remarque', { required: 'La remarque est requise.' })}
                            />
                            {errors.remarque && <span style={{ color: 'red', marginTop: '0.5rem' }}>{errors.remarque.message}</span>}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <button
                                type='submit'
                                disabled={updateLoading}
                                style={{
                                    background: '#50F5A9',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                }}
                            >
                                {updateLoading ? (
                                    <div className='loader'></div>
                                ) : (
                                     'Modifier'
                                )}
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    setModalIsOpenUpdateTransfret(false);
                                    reset();
                                }}
                                style={{
                                    background: 'red',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    marginLeft: '1rem',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                }}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </form>
                }

            </div>
        </Modal>
    );
};

export default ModiferDemandeTransfert;
import 'flatpickr/dist/themes/material_blue.css'

import { Controller, useForm } from 'react-hook-form'
import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	getSingleChauffeur,

} from '../../api/clients'
import {updateChauffeur} from '../../api/chauffeurs'
import { useDispatch, useSelector } from 'react-redux'

import { CustomInput } from '../RegisterTransporter/style'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import LayoutChauffeur from '../../Components/LayoutChauffeur/LayoutChauffeur'
import toast from 'react-hot-toast'
import { updateProfile } from '../../redux/auth'

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

function ChauffeurAccount(props) {
	const { _id } = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);

	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm()

	const [chauffeur, setChauffeur] = useState()
	const [loading, setLoading] = useState(false)
	const [chargementEnCours, setChargementEnCours] = useState(false);


	useEffect(() => {
		async function fetchChauffeur(_id) {
			setChargementEnCours(true)
			const data = await getSingleChauffeur(_id,token)
			setChauffeur(data)
			reset(data)
			setChargementEnCours(false)
		}

		if (_id) {
			fetchChauffeur(_id)
		}
	}, [_id])

	
	const onSubmit = async (data) => {
		setLoading(true)
		if (data.password.length === 0) {
			delete data.password
		}
		delete data.transporter
		delete data.type_vehicule
		delete data.vehicules
		delete data.manutention
		delete data.epi
		delete data.createdAt
		delete data.premium
		delete data.reduction
		delete data.status
		delete data.suspendre
		delete data.transporter
		delete data.type_vehicule
		delete data.updatedAt
		delete data.verification
		delete data.verified
		delete data.date_embauche
		delete data.date_naiss
		
        try {
		const res=await updateChauffeur(data,token)
		toast.success('Votre compte a été mis à jour', {
			style: {
				fontFamily: 'Montserrat',
			},
		})
		dispatch(updateProfile(res))

		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
		

        setLoading(false)
	// 	await sleep(1000)

	// 	window.location.reload()
	// }
	}

	const onError = async (errors) => {
		console.log(errors)
		if (errors.password) {
			toast('Mot de pass doit être de 8 caractères minimum', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
	}

	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<Wrapper>
					<GradientText>MON COMPTE</GradientText>
				</Wrapper>
				{chargementEnCours ?
			    <div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  position: 'absolute',
			  top: 0,
			  left: 0,
			  right: 0,
			  bottom: 0,
   
			}}
			>
			<div className='customLoader'></div>	
			    </div>
			      :
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					<div
						style={{
							width: '60%',
							fontFamily: 'Montserrat',
							alignSelf: 'center',
						}}
					>
						<form onSubmit={handleSubmit(onSubmit, onError)}>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Sexe
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div
											style={{
												width: '30%',
											}}
										>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('sexe', {
												})}
												value={'homme'}
											/>

											<span>Homme</span>
										</div>

										<div>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('sexe', {
													
												})}
												value={'femme'}
											/>

											<span>Femme</span>
										</div>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Permis
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										{[
											'Léger',
											'Lourds',
											'Super lourds',
										].map((item, index) => (
											<div
												key={String(index)}
												style={{
													width: '20%',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}
													{...register('permis', {
														required: true,
													})}
													value={item}
												/>

												<span>{item}</span>
											</div>
										))}
									</div>
								</div>

								{/* <div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Type de véhicule
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div
											style={{
												width: '30%',
											}}
										>
											<input
												type='checkbox'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('type_vehicule', {
													required: true,
												})}
												value={'Véhicule Léger'}
											/>

											<span>Véhicule Léger</span>
										</div>

										<div>
											<input
												type='checkbox'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('type_vehicule', {
													required: true,
												})}
												value={'Poids lourds'}
											/>

											<span>Poids lourds</span>
										</div>
									</div>
								</div> */}

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Nom
										</span>
										<CustomInput
											type='text'
											disabled
											placeholder='Doe'
											{...register('last_name', {
												required: true,
											})}
										/>
									</div>

									<div style={{ width: 50 }} />

									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Prénom
										</span>
										<CustomInput
											type='text'
											disabled
											placeholder='Doe'
											{...register('first_name', {
												required: true,
											})}
										/>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Adresse
										</span>
										<CustomInput
											type='text'
											placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
											{...register('address', {
												required: true,
											})}
										/>
									</div>
								</div>
                                <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									
									{...register('city', {
									 	required: 'Veuillez indiquer la ville.',
										 minLength: {
											value: 3,
											message: 'La ville doit contenir au moins 3 caractères.',
										  },
										  pattern: {
											value: /^.+$/,
											message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										  },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Téléphone
										</span>
										<CustomInput
											type='text'
											placeholder='(0) 01 45 66 32 88'
											{...register('phone', {
												required: true,
											})}
										/>
									</div>

									<div style={{ width: 50 }} />

									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Adresse email
										</span>
										<CustomInput
											type='text'
											disabled
											placeholder='John.doe@wayne.fr'
											{...register('email', {
												required: true,
											})}
										/>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '50%',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Date de naissance
										</span>
										<Controller
											name='date_naiss'
											rules={{ required: true }}
											control={control}
											render={({
												field: { onChange, value },
											}) => (
												<Flatpickr
													onChange={([date]) => {
														onChange(date)
													}}
													disabled
													className='date-input'
													options={{
														mode: 'single',
														locale: French,
														dateFormat: 'd/m/Y',
													}}
													value={value}
												/>
											)}
										/>
									</div>

									<div style={{ width: 50 }} />

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '50%',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Date d’embauche
										</span>
										{/* <StyledInput
										type='date'
										style={{
											fontFamily: 'Montserrat',
											color: '#838EAB',
										}}
										{...register('date_embauche', {
											required: true,
											maxLength: 80,
										})}
									/> */}
										<Controller
											name='date_embauche'
											rules={{ required: true }}
											control={control}
											render={({
												field: { onChange, value },
											}) => (
												<Flatpickr
													onChange={([date]) => {
														onChange(date)
													}}
													disabled
													className='date-input'
													options={{
														mode: 'single',
														locale: French,
														dateFormat: 'd/m/Y',
													}}
													value={value}
												/>
											)}
										/>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Fimo
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									</div>
									{errors.fimo && (
                            <div style={{ color: 'red',marginTop:'0.5rem'}}>
                              Veuillez sélectionner une option pour Fimo.
                             </div>
                             )}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Matière dangereuse
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
										{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
											    
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									</div>
									{errors.danger && (
                            <div style={{ color: 'red',marginTop:'0.5rem'}}>
                              Veuillez sélectionner une option pour Matière dangereuse.
                             </div>
                             )}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Changer mon mot de passe
										</span>
										<CustomInput
										type='password'
										autoComplete='new-password'
										placeholder='Saisir mot de passe'
										{...register('password', {
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors._password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors._password.message}
                                    </div>
                                    )}
									</div>



									<div style={{ width: 50 }} />

									{/* <div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Confirmer mot de passe
										</span>
										<CustomInput
											type='password'
											placeholder='***'
											{...register('_repeat_password', {
												required: true,
											})}
										/>
									</div> */}
								</div>
							</FormSectionCard>

							<div style={{ height: '1rem' }} />

							<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
							{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}	
							</SubmitButton>
						</SubmitButtonContainer>
						</form>
					</div>
				</div>
            }
			</div>
		</LayoutChauffeur>
	)
}

export default ChauffeurAccount

import { GradientText, SubText, Wrapper } from './PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	computePriceCourseACourse,
	nombreEtagesAtom,
	optionsPoidsLourdsAtom,
	optionsVehiculeLegerAtom,
	rippeurHoursAtom,
	rippeursAtom,
	temporisationAtom,
	totalDistance,
	typeCommandeAtom,
	typeEtageAtom,
	typeTransportAtom,
	dangerAtom,
} from '../../utils/price'
import { useDispatch, useSelector } from 'react-redux'

import CommandeForm from '../../Components/CommandeForm'
import Container from '../../Components/LayoutClient/container.component'
import Modal from 'react-modal'
import Numbro from 'numbro'
import { createCommande } from '../../api/commande'
import { deleteCommande } from '../../redux/commande'
import { getInvoiceForOneCommande } from '../../api/invoice'
import moment from 'moment'
import toast from 'react-hot-toast'
import { useAtom } from 'jotai'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

Numbro.setLanguage('fr-FR')

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function PasserCommande() {
	const history = useHistory()
	const query = useQuery()
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth.user)
	const commande = useSelector((state) => state.commande.commande)
	const tarif = useSelector((state) => state.tarif.tarif)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [modalIsOpenValidateDateDifference, setModalIsOpenValidateDateDifference] = useState(false)
	const [modalIsOpenHour,setModalIsOpenHour]=useState(false)
	const [modalIsOpenDate,setModalIsOpenDate]=useState(false)
	const [message,setMessage]=useState("")
	const [price, setPrice] = useState(0)

    // const [heur,setHeur]=useState(0)
	const [heur, setHeur] = useState({ item: 0, index: 0 });
	const [loading, setLoading] = useState(false)
    const [hours, setHours] = useState([]);
	const [adresseDepart,setAdresseDepart]=useState('')
	const [adresseDestination,setAdresseDestination]=useState('')
	const [hourError,setHourError]=useState(0)

	// Pricing Atoms
	const [typeCommande, setTypeCommande] = useAtom(typeCommandeAtom)
	
	const [distance, setDistance] = useAtom(totalDistance)

	const [prixAvecReduction, setPrixAvecReduction] = useState(null);
	const token = useSelector((state) => state.auth.token);
	const  client=useSelector((state)=>state.auth.user);
	const [danger,setDanger]=useAtom(dangerAtom)
	const [typeTransport, setTypeTransport] = useAtom(typeTransportAtom)
	const [temporisation, setTemporisation] = useAtom(temporisationAtom)
	const [typeEtage, setTypeEtage] = useAtom(typeEtageAtom)
	const [nombreEtages, setNombreEtages] = useAtom(nombreEtagesAtom)
	const [rippeurHours, setRippeurHours] = useAtom(rippeurHoursAtom)
	const [rippeurs, setRippeurs] = useAtom(rippeursAtom)
	const [optionsPoidsLourds, setOptionsPoidsLourds] = useAtom(
		optionsPoidsLourdsAtom
	)
	const [optionsVehiculeLeger, setOptionsVehiculeLeger] = useAtom(
		optionsVehiculeLegerAtom
	)
	const [step, setStep] = useState(1);
	const [isStepOne,setIsStepOne]=useState(false)
	const preloadForm = query.get('cid')

  

	useEffect(()=>{
		if(hours?.length===0){
		 if(heur.item!==0){
		 setHours(() => [
			 
			 { id:heur.index , heure: heur.item },
		   ])
		 }
		}else{	
			setHours((prevHours) => {
				const existingIndex = prevHours.findIndex((hour) => hour.id === heur.index);
				if (existingIndex !== -1) {
				  const updatedHours = [...prevHours];
				  updatedHours[existingIndex].heure = heur.item;
				  return updatedHours;
				} else {
				  return [
					...prevHours,
					{ id: heur.index, heure: heur.item }
				  ];
				}
			  });
			  
		}	
		
 },[heur])



	useEffect(() => {
		console.group()
		console.groupEnd()
      
		// if (!typeTransport) return

		if (!temporisation && typeCommande === 'course-a-course' ) return

		if (typeCommande === 'course-a-course' && !distance) {
			return
		}
		if(typeCommande!=="course-a-course" )  return
        

		let _price = computePriceCourseACourse(
			tarif,
			typeCommande,
			typeTransport,
			distance,
			temporisation,
			nombreEtages,
			typeEtage,
			rippeurs,
			rippeurHours,
			optionsVehiculeLeger,
			optionsPoidsLourds,
			danger,
		)
		console.log('price',_price)
		setPrice(_price?.toFixed(2))
		console.groupEnd()
	}, [
		typeCommande==="course-a-course",
		distance,
		typeTransport,
		temporisation,
		typeEtage,
		nombreEtages,
		rippeurs,
		rippeurHours,
		optionsPoidsLourds,
		optionsVehiculeLeger,
		tarif,
		danger,
	])
	useEffect(()=>{
      setPrice(0)
	},[typeCommande])
	
 
	function parseDateTime(dateStr, timeStr) {
		const [day, month, year] = dateStr.split('/');
		const [hours, minutes] = timeStr.split(':');
		return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
	}
	function calculateDifferenceInHours(date1, date2) {
		const differenceInMilliseconds = Math.abs(date2 - date1);
		return differenceInMilliseconds / 3600000;
	}
	function processCourses(data, hours) {
		const options = { year: 'numeric', month: '2-digit', day: '2-digit' };


			const course = data.courses[0];
			const dateDebut = new Date(course.date_debut);
			const dateFin = new Date(course.date_fin);

			const formattedDateDebut = dateDebut.toLocaleDateString('en-GB', options);
			const formattedDateFin = dateFin.toLocaleDateString('en-GB', options);

			const dateDebutNouveau = parseDateTime(formattedDateDebut, course.heure_debut);
			const dateFinNouveau = parseDateTime(formattedDateFin, course.heure_fin);

			const differenceInHours = calculateDifferenceInHours(dateDebutNouveau, dateFinNouveau);

			if (differenceInHours < hours[0]?.heure) {
				setAdresseDepart(course.adresse_debut.label);
				setAdresseDestination(course.adresse_fin.label);
				setHourError(hours[0]?.heure);
				return true
			}
			if (data.courses.length >= 2) {
			for (let i = 0; i < data.courses.length - 1; i++) {
				const courseStart = data.courses[i];
				const courseEnd = data.courses[i + 1];

				const dateDebut = new Date(courseStart?.date_fin || courseStart?.date);
				const dateFin = new Date(courseEnd.date);

				const formattedDateDebut = dateDebut.toLocaleDateString('en-GB', options);
				const formattedDateFin = dateFin.toLocaleDateString('en-GB', options);

				const dateDebutNouveau = parseDateTime(formattedDateDebut, courseStart.heure_fin || courseStart.heure);
				const dateFinNouveau = parseDateTime(formattedDateFin, courseEnd.heure);

				const differenceInHours = calculateDifferenceInHours(dateDebutNouveau, dateFinNouveau);

				if (differenceInHours < hours[i + 1]?.heure) {
					setAdresseDepart(courseStart?.adresse_fin ? courseStart?.adresse_fin?.label : courseStart?.adresse?.label);
					setAdresseDestination(courseEnd.adresse?.label);
					setHourError(hours[i + 1]?.heure);
					return true
				}
			}
		}
	}
	function calculerDifferenceEnHeures(data) {
		const dateActuelle = new Date();

		let dateDebutNouveau;

		if (data.type_commande !== 'mise-a-disposition') {
			const [heur_debut, minute_debut] = data.courses[0]?.heure_debut.split(':');
			dateDebutNouveau = new Date(
				data.courses[0].date_debut.getFullYear(),
				data.courses[0].date_debut.getMonth(),
				data.courses[0].date_debut.getDate(),
				parseInt(heur_debut),
				parseInt(minute_debut)
			);
		} 
		

		const differenceEnMillisecondes = dateDebutNouveau - dateActuelle;
		const differenceEnHeures = differenceEnMillisecondes / (1000 * 60 * 60);

		return differenceEnHeures;
	}
	const IsValidCourseInterval = (data) => {
		const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
		const course = data.courses[0];      
		const dateDebut = new Date(course.date_debut);
			const dateFin = new Date(course.date_fin);

			const formattedDateDebut = dateDebut.toLocaleDateString('en-GB', options);
			const formattedDateFin = dateFin.toLocaleDateString('en-GB', options);

			const dateDebutNouveau = parseDateTime(formattedDateDebut, course.heure_debut);
			const dateFinNouveau = parseDateTime(formattedDateFin, course.heure_fin);


		   if(dateDebutNouveau>=dateFinNouveau ){
           return true
		}
		if (data.courses.length >= 2) {
			for (let i = 0; i < data.courses.length - 1; i++) {
				const courseStart = data.courses[i];
				const courseEnd = data.courses[i + 1];

				const dateDebut = new Date(courseStart?.date_fin || courseStart?.date);
				const dateFin = new Date(courseEnd.date);

				const formattedDateDebut = dateDebut.toLocaleDateString('en-GB', options);
				const formattedDateFin = dateFin.toLocaleDateString('en-GB', options);

				const dateDebutNouveau = parseDateTime(formattedDateDebut, courseStart.heure_fin || courseStart.heure);
				const dateFinNouveau = parseDateTime(formattedDateFin, courseEnd.heure);
				if(dateDebutNouveau>=dateFinNouveau ){
					return true
				 }
			}


		}
		return false
	}

	useEffect(()=>{
		if (user?.reduction && price) {
			let nouveauPrix = Number(price) - (Number(price) * user.reduction) / 100;
			setPrixAvecReduction(nouveauPrix?.toFixed(2));
		}
		if(user?.reduction && price===0){
			let nouveauPrix = Number(price) - (Number(price) * user.reduction) / 100;
			setPrixAvecReduction(nouveauPrix?.toFixed(2));
		}
	},[price])

	function validateDateDifference(data) {
		const dateActuelle = new Date();
	   
		if(data.type_commande==="course-a-course"){
			const dataDebut=moment(data.courses[0].date_debut).format('DD/MM/YYYY')
			const [ jour,mois, annee] = dataDebut.split('/');
            const [heur, minute] = data.courses[0].heure_debut.split(':');
			const dateDebutNouveau = new Date(annee, mois - 1, jour, heur, minute);
			const differenceInMilliseconds = Math.abs(dateDebutNouveau - dateActuelle);
			const differenceInHours = differenceInMilliseconds / 3600000;
			if(data.temporisation==="immediat" && differenceInHours>24){
              return "La date de début de transport pour cette commande immédiate dépasse les 24 heures. Veuillez choisir une date appropriée."
			}
			if (data.temporisation === 'planifie' && differenceInHours <= 24) {
				return "La date de début de transport pour cette commande planifiée doit dépasser les 24 heures. Veuillez choisir une date appropriée.";
			}
		}
		return null;
	}
	const isMissionWithin24Hours = (missions) => {
		// Obtenez la date et l'heure actuelles
		const dateActuelle = new Date();

		// Parcourez chaque mission pour vérifier si elle est dans les 24 heures
		for (let mission of missions) {
	  
		  const dataDebut=moment(mission.date_debut).format('DD/MM/YYYY')
		  const [ jour,mois, annee] =dataDebut.split('/')
		  const [heur, minute] = mission.heure_debut.split(':')
		  const dateDebutNouveau = new Date(annee, mois - 1, jour, heur, minute);
		  const differenceInMilliseconds = Math.abs(dateDebutNouveau - dateActuelle);
		  const differenceInHours = differenceInMilliseconds / 3600000;
		  if(differenceInHours<=24){
			return true
		  }
		}
	  
		return false;
	  };

	const  isMissionPlanifie=(missions)=>{
		const dateActuelle = new Date();

		for (let mission of missions) {
	  
		  const dataDebut=moment(mission.date_debut).format('DD/MM/YYYY')
		  const [ jour,mois, annee] =dataDebut.split('/')
		  const [heur, minute] = mission.heure_debut.split(':')
		  const dateDebutNouveau = new Date(annee, mois - 1, jour, heur, minute);
		  const differenceInMilliseconds = Math.abs(dateDebutNouveau - dateActuelle);
		  const differenceInHours = differenceInMilliseconds / 3600000;
		  if(differenceInHours<=24){
			return false
		  }
		}
	  
		return true;
	}
	 
	const onSubmit = async (data,e) => {
		e.preventDefault();
        
		 
		try {
			delete data.accept
            
			
			


			if (data.type_commande === 'mise-a-disposition' && data.temporisation==="immediat") {
				const resulta=isMissionWithin24Hours(data.missions)
			   if(!resulta){
				setMessage("Pour une commande immédiate, au moins une mission doit commencer dans les 24 heures. Veuillez choisir une date appropriée.")
				setModalIsOpenValidateDateDifference(true)
				return
			   }
			
			   
			}
			if(data.type_commande==='mise-a-disposition' && data.temporisation==="planifie"){
              const resulta=isMissionPlanifie(data.missions)
			  if(!resulta){
				setMessage("Pour une commande planifiée, toutes les missions doivent commencer après 24 heures. Veuillez choisir une date appropriée.");
				setModalIsOpenValidateDateDifference(true)
				return
			  }
			}
			if(data.type_commande !== 'mise-a-disposition'){
				const resulta=validateDateDifference(data)
				if(resulta){
					setMessage(resulta)
					setModalIsOpenValidateDateDifference(true)
					return
				}
				const  differenceEnHeures=calculerDifferenceEnHeures(data)
		        if(differenceEnHeures < 2){
					setLoading(false)
					setModalIsOpenDate(true)
				return
				}

			
				const resultaValidCourseInterval=IsValidCourseInterval(data)
				if (resultaValidCourseInterval) { 
					setLoading(false);
					setModalIsOpen(true);
					return;
				}
			// 	const  resultaHours=processCourses(data, hours);
			//     if(resultaHours){
			// 	setLoading(false);
			// 	setModalIsOpenHour(true)
			// 	return;
				
			//   }
			
			
			}
		


			if (preloadForm) {
				if (data.type_commande === 'mise-a-disposition') {
					if (data.files) {
						data.files = data.files.map((file) => file.id)
					}
				} else {
					for (const course of data.courses) {
						if (course.files) {
							course.files = course.files.map((file) => file.id)
						}
					}
				}
			}
			
			if (data.type_commande === 'mise-a-disposition') {
				for(let i=0;i<data.missions.length;i++){
				   data.missions[i].date_debut = moment(data.missions[i].date_debut).format('DD/MM/YYYY');
				   const reduction = user.reduction ? Number(user.reduction) : 0; 
                   data.missions[i].prix= Number(data.missions[i].prix) - (Number(data.missions[i].prix) * reduction) / 100;
                   
				}
			 } else {
			   data.courses[0].date_debut = moment(data.courses[0].date_debut).format('DD/MM/YYYY');
			   data.courses[0].date_fin = moment(data.courses[0].date_fin).format('DD/MM/YYYY');
			   for (let i = 1; i < data.courses.length; i++) {
				 data.courses[i].date  = moment(data.courses[i].date).format('DD/MM/YYYY');
				}
			 }
			 if(step<3){
			 setStep(step + 1);
			 setIsStepOne(true)
			 const formSection = document.getElementById('form');
	 
			 if (formSection) {
			   formSection.scrollIntoView({ behavior: 'smooth' });
			 }
			}
			 if(step===3){
				setLoading(true)
             
			 const res = await createCommande({
				...data,
				prix: prixAvecReduction === null ? price : prixAvecReduction,
				statut: 'init',
				clientID: user._id,
				distance:distance / 1000,
				token
			  });
			  setHours([])         
			if (preloadForm) {
				dispatch(deleteCommande())
			}
			history.push(`/commandes/${res._id}/pay`)
			
			setPrice(0);
			setTypeTransport(null)
			setTypeCommande(null)

		}
		} catch (error) {
			console.log(error)
			toast.error(
				error.response?.data?.error,
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
			setLoading(false)
		}

		
	}

	const onError = (errors, e) => {
		console.log(errors)
		if (errors.accept) {
			toast(
				"Vous devez accepter les conditions d'utilisation de la Plateforme du Transport pour continuer !",
				{
					duration: 3000,
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}
	}


	return (
		<Container>
			<Wrapper>
				<GradientText>PASSER COMMANDE</GradientText>
				<SubText>
					Ici vous pouvez commander un transport selon les critéres
					proposés, votre commande sera pris en charge
				</SubText>

				<div
					style={{
						position: 'fixed',
						top: 250,
						right: 50,
						zIndex: 999,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							padding: '1rem 1rem',
							background: '#838EAB',
							color: 'white',
							fontFamily: 'Montserrat',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',

							borderRadius: '0.5rem',
							fontSize: 20,
						}}
					>
						<span>Distance</span>
						<span
							style={{ fontWeight: 'bold', marginBottom: '1rem' }}
						>
							{distance / 1000} km
						</span>
						<span>Prix</span>
						<span style={{ fontWeight: 'bold' }}>
						{Numbro(price?? 0).formatCurrency({
								currencySymbol: '€',
								currencyPosition: 'postfix',
								mantissa: 2,
							})}{' '}
							ht
							
						</span>
						{!!user?.reduction && (
						<><span>Prix avec remise</span>
						<span style={{ fontWeight: 'bold' }}>
							{Numbro(prixAvecReduction ?? 0).formatCurrency({
								currencySymbol: '€',
								currencyPosition: 'postfix',
								mantissa: 2,
							})}{' '}
							ht
						</span></>) }
					</div>
				</div>

				<CommandeForm
					onSubmit={onSubmit}
					onError={onError}
					preloadForm={preloadForm}
					preloadData={commande}
					heur={heur}
					setHeur={setHeur}
					submitButtonText={user?.premium ? 'Commander' : 'Commander et payer.'}
					loading={loading}
					step={step}
					setStep={setStep}
					isStepOne={isStepOne}
					setIsStepOne={setIsStepOne}
					setPrice={setPrice}
				/>
				{!client?.paymentMethodId && client?.premium &&
				<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background: 'rgba(0, 0, 0, 0.5)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						width: '50%',
						// height: '50%',
						background: 'white',
						borderRadius: 5,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '2rem 2rem',
						textAlign: 'center',
					}}
				>
					<span
						style={{
							fontFamily: 'Montserrat',
							fontSize: 20,
							marginBottom: '2rem',
						}}
					>
						Veuillez enregistrer vos informations de paiement avant de créer une commande.
					</span>
					
				</div>
			</div>
				}
				
			</Wrapper>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
					L'heure de livraison ne doit pas être egal ou inférieure à l'heure de chargement. La date de livraison ne doit pas être inférieure à la date de chargement!
					</div>

					<button
						onClick={() => setModalIsOpen(false)}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modalIsOpenHour}
				onRequestClose={() => setModalIsOpenHour(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
						{/* {`La durée du transport entre l'adresse de départ et l'adresse de destination doit être égale à ou dépasser ${Math.floor(heur)} heures et ${Math.round((heur - Math.floor(heur)) * 60)} minutes `} */}
						{`La durée du transport entre l'adresse de départ ${adresseDepart} et l'adresse de destination ${adresseDestination} doit être égale à ou dépasser ${Math.floor(hourError)} heures et ${Math.round((hourError - Math.floor(hourError)) * 60)} minutes `}

					</div>

					<button
						onClick={() => setModalIsOpenHour(false)}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modalIsOpenDate}
				onRequestClose={() => setModalIsOpenDate(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
				L'heure de début du transport doit être supérieure d'au moins deux heure à l'heure actuelle

					<div>
					</div>

					<button
						onClick={() => setModalIsOpenDate(false)}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>

			<Modal
				isOpen={modalIsOpenValidateDateDifference}
				onRequestClose={() => setModalIsOpenValidateDateDifference(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
						{message}
					</div>

					<button
						onClick={() => setModalIsOpenValidateDateDifference(false)}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</Container>
	)
}

export default PasserCommande

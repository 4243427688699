import {
	GradientText,
	SubText,
	Wrapper,
    FormContentSection,
    FormSectionCard,
	FormSectionTitle,
    StyledForm,
    StyledInput
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import CommandeFormReadOnly from '../../Components/CommandeFormReadOnly'
import Container from '../../Components/LayoutClient/container.component'
import TransportTracking from '../../Components/TransportTracking/TransportTracking'
import { getCommande } from '../../api/commande'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import Logo from './../../assets/img/logo-TPE.png'
function DetailsCommandeComptabilite(props) {
	const { id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isOrderCanceled, setIsOrderCanceled ] = useState(false)
	const [commande, setCommande] = useState(null)

	useEffect(() => {
		async function _getCommande(id) {
			const commande = await getCommande(id,token)
			setCommande(commande)
		}

		if (id) {
			_getCommande(id)
		}
	}, [id])
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const success = params.get('success');
		console.log({document})
		if (success === 'true') {
			setModalIsOpen(true)
			setIsOrderCanceled(false)
		}
		if (success === 'false') {
			setModalIsOpen(true)
			setIsOrderCanceled(true)
		}
	},[])
	return (
		<Container>
			<Wrapper>
				<GradientText>{`${'Détail COMMANDE'.toUpperCase()} ${!!commande ? ' N°' + commande.__id : '' }`}</GradientText>
				<SubText>
					Ici retrouver le détail complet du transport à effectuer
				</SubText>

				<div
					style={{
						width: '65%',
					}}
				>
					
					{commande?.type_commande === 'mise-a-disposition' && (
						<TransportTracking commande={commande} setCommande={setCommande} display  />
					)}
					
				</div>
				{commande && commande?.packages?.length!==0 && (
                <StyledForm>
                <FormContentSection>
                <FormSectionTitle>Forfait Supplémentaire</FormSectionTitle>
                 {commande.packages.map((item, index) => (
                 <FormSectionCard key={index}> 
                 <div
                 style={{
	             display:"flex",
	             flexDirection: 'row',
                 }}>
                <div
                style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                marginBottom: '2.5rem',
               }}
                >
               <span
                style={{
                 marginBottom: '1rem',
                 color: 'black',
                 fontSize: 14,
                   }}
                >
               Forfait
             </span>
            <StyledInput
             type='text'
             disabled={true}
             placeholder=''
             value={item.value}
            />
           </div>
            <div
            style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            marginLeft:'1rem',
            marginBottom: '2.5rem',
            }}
            >
           <span
           style={{
            marginBottom: '1rem',
            color: 'black',
            fontSize: 14,
             }}
            >         
            Nombre d'heures
           </span>
           <StyledInput
            type='text'
            disabled={true}
            placeholder=''
            value={item.heure.toFixed(2)}
            />
           </div>
            </div>
            <div
            style={{
	        display:"flex",
	        flexDirection: 'row',
             }}>
            <div
            style={{
           display: 'flex',
           flexDirection: 'column',
           width: '50%',
           marginBottom: '2.5rem',
           }}
           >
           <span
           style={{
           marginBottom: '1rem',
           color: 'black',
           fontSize: 14,
          }}
          >
          Statut de paiement
          </span>
         <StyledInput
          type='text'
          disabled={true}
          placeholder=''
          value={item.paymentStatus}
         />
        </div>
        <div
        style={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        marginLeft:'1rem',
        marginBottom: '2.5rem',
        }}
         >
       <span
       style={{
        marginBottom: '1rem',
         color: 'black',
          fontSize: 14,
        }}
        >
        Statut de remboursement
    </span>
    <StyledInput
     type='text'
     disabled={true}
     placeholder=''
     value={item.rembourse ? "remboursé" :'non remboursé'}
    />
   </div>
            </div>
			<div
            style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: '2.5rem',
            }}
            >
           <span
           style={{
            marginBottom: '1rem',
            color: 'black',
            fontSize: 14,
             }}
            >         
            Prix
           </span>
           <StyledInput
            type='text'
            disabled={true}
            placeholder=''
            value={item.price.toFixed(2)}
            />
           </div>
   </FormSectionCard>
   ))}

 </FormContentSection>
                </StyledForm>
				)}

				{commande && (
                    <>
					<CommandeFormReadOnly
						hideSubmit
						disabled
						onSubmit={() => {}}
						preloadForm={true}
						preloadData={commande}
					/>
                    
                   
                </>
				)}
			</Wrapper>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
					<img
					style={{
						width: '10rem',
						backgroundColor: 'grey',
						padding: '2px'
					}}
					alt='logo'
					src={Logo}
				/>
						<h1>{isOrderCanceled ? "Erreur de paiement": "paiement effectué avec succès"}</h1>
					</div>

					<button
						onClick={() => setModalIsOpen(false)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</Container>
	)
}

export default DetailsCommandeComptabilite

import React ,{ useEffect, useState } from 'react'
import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import {getValidClientOfCommercant} from '../../api/commerciaux'
import { getCommandesByMonthCommercial } from '../../api/commande'
import {getCommandesByMonthByClientCommercial} from '../../api/commerciaux'
import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import {
	GradientText,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import _ from 'lodash'
var months = {
	1: 'Jan',
	2: 'Fév',
	3: 'Mar',
	4: 'Avr',
	5: 'May',
	6: 'Juin',
	7: 'Juill',
	8: 'Aout',
	9: 'Sep',
	10: 'Oct',
	11: 'Nov',
	12: 'Déc',
}
function ComptabiliteCommerciaux() {
    const [data, setData] = useState([])
	const history = useHistory()
    const [total, setTotal] = useState(0)
	const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user)
	const [chargementEnCours, setChargementEnCours] = useState(false);
    const [clients,setClients]=useState([])
    const [selecteClient,setSelecteClient]=useState('')
	const [selectedMonth, setSelectedMonth] = useState(null);
    async function getData(id) {
		setChargementEnCours(true)
        const res = await getCommandesByMonthCommercial(id,token)
        setTotal(res.total_all)
        setData(_.groupBy(res.data, (e) => e.year))
        const resulta = await getValidClientOfCommercant(user._id,token)
        setClients(resulta.docs)
		setChargementEnCours(false)

    }
    useEffect(() => {
		getData(user?._id)
	}, [user])
    const handleMonthClick = (month) => {
        setSelectedMonth(selectedMonth === month ? null : month);
    };
    async function fetchCommandeByClient(clientID) {
        const res = await getCommandesByMonthByClientCommercial(clientID,token)
			setTotal(res.total_all)
			setData(_.groupBy(res.data, (e) => e.year))
    }
    useEffect(()=>{
        if(selecteClient==="all" || selecteClient===''){
            getData(user?._id)
        }else{
            fetchCommandeByClient(selecteClient)

        }
    },[selecteClient])
  return (
    <LayoutCommercant>
        <Wrapper>
        <GradientText>{'comptabilité'.toUpperCase()}</GradientText>
		{chargementEnCours ?
		<div
		style={{
		  display: 'flex',
		  alignItems: 'center',
		  justifyContent: 'center',
		  backgroundColor: 'white',
		  marginTop:"10rem"
		}}
		>
		<div className='customLoader'></div>	
		</div>
		:
		<>
        <div
		style={{
			    width: '100%',
				fontFamily: 'Montserrat',
				paddingLeft: '1rem',
				paddingRight: '1rem',
				marginBottom: '2rem',
				marginTop: '1rem',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				}}
				>
                <div>
                    <select
                    value={selecteClient}
                    onChange={(e) => {
                        setSelecteClient(e.target.value);
                       
                        }}
                    style={{
						padding: '1rem',
						borderRadius: 5,
						border: '1px solid #E4EAF0',
						width: '22rem',
						fontFamily: 'Montserrat',
						marginLeft:'1rem'
					}}
                    >
                     <option value="">Nom Client</option>
                     <option value="all">Tous</option>
                     {clients?.map((item,index)=>{
                        return  <option value={item._id} key={index}>{item.last_name} {item.first_name}</option>

                     })}
                    </select>
                    </div>
                <div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{total.toFixed(2)}€</span>
					</div>
       </div>
	
        <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '10rem',
					}}
				>
					{Object.entries(data).map((element, index) => {
                     const totalParAnnee = element[1].reduce((acc, item) => acc + item.totalAmount, 0);
						return (
							<div
								key={String(index)}
								style={{
									width: '100%',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										width: '100%',
									}}
								>
									<span
										style={{
											color: '#51C7F2',
											fontWeight: 'bold',
											marginRight: 10,
											marginBottom: 10,
										}}
									>
										{element[0]}
                                       
									</span>
                                   
               
									<div
										style={{
											width: '100%',
											height: 1,
											background: '#F2F2F2',
										}}
									/>

								</div>
								<div
										style={{
											color: '#51C7F2',
											fontWeight: 'bold',
											marginRight: 10,
											marginBottom: 10,
											display: 'flex',
						                    justifyContent: 'flex-end',
											
											
										}}
									>
                                    {totalParAnnee.toFixed(2)}€
										
                                       
									</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap',
										width: '100%',
										height: '100%',
										alignItems: 'flex-start',
									}}
								>
									{element[1].map((item, index) => (
										<div
                                           key={String(index)}
                                           style={{
                                           width: '25%',
                                           background: '#E4EAF0',
                                           borderRadius: 15,
                                           marginRight: '1rem',
                                           padding: '1rem 2rem',
                                           flexGrow: 0,
                                           cursor: 'pointer',
                                           border: selectedMonth === item.month ? '2px solid #51C7F2' : 'none',
                                          }}
                                           onClick={() => handleMonthClick(item.month)}
                                             >
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent:
														'space-between',
													marginBottom: '.5rem',
												}}
											>
												<span
													style={{
														color: '#838EAB',
														//fontSize: 18,
													}}
												>
													{months[`${item.month}`]}
												</span>
											</div>

											<span
												style={{
													color: '#51C7F2',
													fontWeight: 'bold',
													marginTop: '1rem',
													//fontSize: 18,
												}}
											>
												Total: {`${item.totalAmount.toFixed(2)}€`}
											</span>
										</div>
										
										
										
									
									))}
									
								</div>
								<div>
								{element[1].map((item, index) => (
								
										<div key={index}
										style={{
											width: '100%',
											fontFamily: 'Montserrat',
											paddingLeft: '1rem',
											marginTop:"1rem",
											paddingRight: '1rem',
											marginBottom: 5,
										}}>
											 {selectedMonth === item.month && (
												<div>
										{item.commandes?.map((commande,index) => {
										
										if (commande.type_commande === 'course-a-course'){
											return(
												<CourseACourseHorizontal
												key={index}
												commande={commande}
												hideRecu
												hidecancel
												hideAttribuer
												hideStatus
												hideUpdate
                                                hidefacture
                                                hideSignaler
												onClickDetails={() => {
													history.push(
														
                                                        `/admin/plateforme/commandes/${commande._id}`
													)
												}}
												
												onClickSignaler={() => {
													history.push(
														`/client/transports/${commande._id}/signaler`
													)
												}}
											/>
											)
										} else {
											return (
												<MiseADispoHorizontal
													key={index}
													commande={commande}
													hideRecu
													hidecancel
													hideAttribuer
													hideStatus
													hideUpdate
                                                    hidefacture
                                                    hideSignaler
													onClickDetails={() => {
														history.push(
                                                            `/admin/plateforme/commandes/${commande._id}`

														)
													}}
													
													onClickSignaler={() => {
														history.push(
															`/client/transports/${commande._id}/signaler`
														)
													}}
												/>
											)
										}
 




										}	
										)}
										       </div>
											 )}
										</div>
									
								))}	
							    </div>		
								
							</div>
						)
					})}
	   </div>
	   </>
        }
        </Wrapper>
    </LayoutCommercant>
  )
}

export default ComptabiliteCommerciaux
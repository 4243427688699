import React, { useEffect, useState } from 'react'
import {
	attributionCommandesTransporter,
	softDeleteCommande,
    getCommandeTerminer
} from '../../../api/commande'
import toast from 'react-hot-toast'
import {
    searchCommandesByClientAndTransporterForPlateforme,
	searchCommandesByDate,
	searchCommandesByPrixMinMax,
	searchCommandesById
} from '../../../api/search'

import DataTable from 'react-data-table-component'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import {BASE_URL} from '../../../api/base'
import Select from 'react-select'
import {getValidTransporters} from '../../../api/transporteurs'
import moment from 'moment'

import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function Terminer(props) {
	const history = useHistory()

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [transporters, setTransporters] = useState([])
	const [selected, setSelected] = useState([])
	const [selectedTransporter, setSelectedTransporter] = useState(null)
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [searchQuery, setSearchQuery] = useState('')
	const [searchQueryId, setSearchQueryId] = useState('')
	const [type,setType]=useState("terminer")
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [modalIsOpenAttribuer, setModalIsOpenAttribuer] = useState(false)
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [commande,setCommande]=useState('')
	const [chargementEnCours,setChargementEnCours]=useState(false)


	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };
	const fetchCommandes = async (page) => {
		setChargementEnCours(true)
		const res = await getCommandeTerminer(page, perPage,token)
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)
		setSearchQueryId('')
		setSearchQuery('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		  setSearchCriteria("id")
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchCommandes(page)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getCommandeTerminer(page, newPerPage,token)   
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}

	const fetchTransporters = async () => {
		// TODO change this
		const res = await getValidTransporters(1, 50,token)
		setTransporters(res.docs)
	}

	useEffect(() => {
		fetchCommandes(1)
		fetchTransporters()
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			width: '110px',
			sortable: false,
		},
		{
			name: 'Type du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: '#51C7F2',
							marginBottom: 3,
						}}
					>
						{row.type_commande == 'course-a-course'
							? 'Course à course'
							: 'Mise à disposition'}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		
		{
			name: 'Date début',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row.debut}</span>
							<span style={{marginLeft:'0.5rem'}}>{row.heure_debut}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].date_debut}</span>
								<span style={{marginLeft:'0.5rem'}}>{row.courses[0].heure_debut}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Date fin',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row.fin}</span>
							<span style={{marginLeft:'0.5rem'}}>{row.heure_fin}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								
								<span>{row.courses.length===1 ? row.courses[0].date_fin :row.courses[row.courses.length-1].date}</span>
								<span style={{marginLeft:'0.5rem'}}> {row.courses.length===1 ? row.courses[0].heure_fin : row.courses[row.courses.length-1].heure}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Départ',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{row?.adresse_debut?.label}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].adresse_debut?.label}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: ' Arrivé',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row?.adresse_fin?.label}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length===1 ? row.courses[0].adresse_fin?.label :row.courses[row.courses.length-1].adresse?.label}</span>
						</div>	
					)
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: 'Etapes ',
			selector: (row) => {

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length}</span>
						</div>	
					)
				}else {
					return '__'
				}
			},
			sortable: false,
			width: '100px',
		},
		{
			name: 'Statut',
			cell: (row) => (
				<div
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						
						style={{
							
							fontWeight: 'bold',
							color: '#51C7F2',
							
						}}
					>
						{row.statut ==="en-cours" ? 'En cours' : (row.statut==="terminer" ? "Terminée" : row.statut==="init" && "Non commencée" )}
					</span>	
				</div>
			),
			sortable: false,
			width: '180px',
		},
		{
			name: 'Date de terminaison',
			selector: (row) => moment(row.dateTerminaison).format('DD/MM/YYYY'),
			width: '170px',
			sortable: false,
		},
		{
			name: 'Signalements',
			cell: (row) => (
				<>
					{row?.signaler ? (
						<span
							onClick={() => {
								history.push(`/admin/transports/${row._id}/signaler`)
							}}
							style={{
								padding: '0.3rem 0.7rem',
								background: row?.signaler ? '#FCC200' : '#838EAB',
								color: row?.signaler ? 'white' : '#9EA8BF',
								cursor: 'pointer',
								borderRadius: 50,
								fontWeight: 'bold',
								marginBottom: 5,
								fontSize: 10,
							}}
						>
							Signaler +{row.signalement_count}
						</span>
					):'__'
				}
				</>
			),
			sortable: false,
			width: '180px',
		},
		{
			name: 'Montant',
			selector: (row) => {
				const totalForfait = row.packages || [];
				const totalPackagePrice = totalForfait.reduce((total, p) => total + p.price, 0);
				
				const montantTotal = row.prix + totalPackagePrice;
				const montantAvecMargin = (montantTotal - (montantTotal * (row.margin ?? 0)) / 100).toFixed(2);
			  
				return (
				  <div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{montantTotal.toFixed(2)}€</span>
					<span>{montantAvecMargin}€</span>
				  </div>
				);
			  },
			sortable: false,
			width: '180px',
		},
		{
			name: '% marge appliqué',
			selector: (row) => <div 
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		><span  
		style={{
			// background: '#50F5A9',
			border: '1px solid',
			borderBlockColor: 'grey',
			// cursor: 'pointer',
			color: 'black',
			fontFamily: 'Montserrat',
			padding: '.5rem',
			borderRadius: 6,
		}} >{row.margin ?? 0}</span></div>,
			sortable: false,
			width:'180px'
		},
		{
			name: 'Type de véhicule',
			selector: (row) => {
				return (
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							width: 100,
							padding: '1rem 0 1rem 0',
						}}
					>
						{row.type_transport == 'Poids lourds' ? (
							<>
								<span
									style={{
										marginBottom: 10,
									}}
								>
									{row.type_transport}
								</span>
								<span
									style={{
										marginBottom: 10,
									}}
								>
									{row.vehicule_poids_lourds}
								</span>
								{row.options_poids_lourds.map((item) => (
									<span key={item}>{item}</span>
								))}
							</>
						) : (
							<>
								<span>{row.type_transport}</span>
								<span
									style={{
										marginBottom: 10,
									}}
								>
									{row.type_transport_details}
								</span>

								{row.options_vehicule_leger.map((item) => (
									<span key={item}>{item}</span>
								))}
							</>
						)}
					</span>
				)
			},
			sortable: false,
			width: '160px',
		},
		
		{
			name: 'Transporteur',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
						onClick={()=>{
							if(row.transporterID?.deletedAt !== null){
								history.push(`/admin/transporters/single/${row.transporterID._id}`)
	
							}else{
								history.push(`/admin/transporters/${row.transporterID._id}`)
	
							}
						}}
					>
						<img
							style={{
								width: '2rem',
								height: '2rem',
								borderRadius: '1rem',
								marginRight: 10,
							}}
							src={
								row.transporterID && row.transporterID.logo
									? `${BASE_URL}/${row.transporterID.logo.path}`
									: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
							}
							alt=''
						/>
						<span style={{
					    fontWeight: 'bold',
						cursor: 'pointer',
						color:row?.clientID?.deletedAt !== null ? 'red' :'#51C7F2'
				        }}>
                        {row?.transporterID?.societe}
                       </span>
					</div>
					<button
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							// background: '#ECC9F7',
							// color: '#AB1CC5',
							background: row?.recu ? '#FCC200' : '#838EAB',
							color: row?.recu ? 'white' : '#9EA8BF',
							border: 'none',
							mariginTop:"2px",
							fontWeight: 'bold',
							padding: '0.3rem 1rem',
							borderRadius: 4,
						}}
					>
						Reçu
					</button>
				</div>
			),
			sortable: false,
			width: '200px',
		},
		{
			name: 'Client',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onClick={()=>{
						if(row.clientID?.deletedAt !== null){
							history.push(`/admin/clients/single/${row.clientID._id}`)

						}else{
							history.push(`/admin/clients/${row.clientID._id}`)

						}
					}}
				>
					<img
						style={{
							width: '2rem',
							height: '2rem',
							borderRadius: '1rem',
							marginRight: 10,
						}}
						src={
							row.clientID && row.clientID.logo
								? `${BASE_URL}/${row.clientID.logo.path}`
								: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
						}
						alt=''
					/>
                  <span style={{
					fontWeight: 'bold',
					cursor: 'pointer',
					color:row?.clientID?.deletedAt !== null ? 'red' :'#51C7F2'
				  }}>
                  {row?.clientID?.societe}
                  </span>				
				  </div>
			),
			sortable: false,
			width:'180px'
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(
								`/admin/plateforme/commandes/${row._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>

					<div style={{ width: 10 }} />
                    {(user.role==="admin" || user.role === 'super admin') &&
					<div
						onClick={ () => {
							setCommande(row._id)
							setModalIsOpenDelete(true)	
							
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.694'
							height='26.43'
							viewBox='0 0 21.694 26.43'
						>
							<g
								id='Group_11098'
								data-name='Group 11098'
								transform='translate(-11052.168 -6281.57)'
							>
								<path
									id='Rectangle_4434'
									data-name='Rectangle 4434'
									d='M0,0H17a1,1,0,0,1,1,1V17a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z'
									transform='translate(11054 6288)'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11757'
									data-name='Path 11757'
									d='M11081.736,6283.418h-7.568v2.032h21.694v-2.032h-7.8v-1.848h-6.324Z'
									transform='translate(-22)'
									fill='#e7e7eb'
								/>
							</g>
						</svg>
					</div>
		            }
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Terminées'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{ value: 'id', label: "Recherche par l'identifiant " },
								{
									value: 'ct',
									label: 'Nom Client / Nom transporteur',
								},
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
                    {searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'ct' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}

					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
						onClick={async () => {
							if (
								searchCriteria === 'id' &&
								searchQueryId.length === 0
							) {
								return
							}

							//await fetchCommandes(currentPage)

							let res
							if (searchCriteria === 'id') {
								res =
									await searchCommandesById(
										searchQueryId,
										type,
										token
									)
							}
							if (searchCriteria === 'ct') {
								res =
									await searchCommandesByClientAndTransporterForPlateforme(
										searchQuery,
										type,
										token
									)
							}

							if (searchCriteria === 'prix') {
								res = await searchCommandesByPrixMinMax(
									min,
									max,
									type,
									token
								)
							}

							if (searchCriteria === 'date') {
								let minDate = moment(range[0]).format()
								let maxDate = moment(range[1]).format()

								res = await searchCommandesByDate(
									minDate,
									maxDate,
									type,
									token
								)
							}

							setData(res)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={async () => {
							await fetchCommandes(currentPage)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>

					

					
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						selectableRows
						onSelectedRowsChange={(state) => {
							setSelected(state.selectedRows)
						}}
						noDataComponent="Il n'y a pas des commandes à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>Attribuer le transport à un Transporteur</div>

					<div
						style={{
							marginTop: 20,
							width: '100%',
						}}
					>
						<Select
							options={transporters.map((t) => ({
								value: t._id,
								label: `${t.societe}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							onChange={(x) => setSelectedTransporter(x.value)}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>

					<button
						onClick={async () => {
							setModalIsOpen(false)
							setModalIsOpenAttribuer(true)
						}}
						style={{
							padding: '1rem 1.5rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
							fontFamily: 'Montserrat',
						}}
					>
						Valider
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modalIsOpenAttribuer}
				onRequestClose={() => setModalIsOpenAttribuer(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Confirmation de l'attribution du transport</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>      Êtes-vous sûr de vouloir attribuer ce transport au transporteur sélectionné? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
						onClick={async () => {
							try {
								await attributionCommandesTransporter(
									selected.map((c) => c._id),
									selectedTransporter,
									token
								)
								fetchCommandes(currentPage)
								setModalIsOpenAttribuer(false)
							} catch (error) {
								setModalIsOpenAttribuer(false)

								toast.error(
									error.response.data.error,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
							}
							
							//history.push('/admin/plateforme/attribuer')
							
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
						Je confirme
					</button>
					<button
						onClick={async () => {
							setModalIsOpenAttribuer(false)	
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
			</Modal>
			<Modal
				isOpen={modalIsOpenDelete}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Commande</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer cette commande ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								const res=await softDeleteCommande(commande,token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								setModalIsOpenDelete(false)
								await fetchCommandes(currentPage)
							   } catch (error) {
								toast.error(
									error.response.data.message,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
								setModalIsOpenDelete(false)

							   }finally {
								setLoading(false)
							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
					 {loading ? 'Chargement' : 'Confirmer'} 
					</button>
					<button
						onClick={async () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		</LayoutAdmin>
	)
}

export default Terminer

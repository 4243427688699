import React ,{useEffect, useState} from 'react'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { FormSectionCard} from '../../PasserCommande/PasserCommande.styles'
import { CustomInput } from '../../RegisterTransporter/style';
import {getSingleAdministrateur} from '../../../api/administrateurs'
import { useForm } from 'react-hook-form';
import {  useSelector } from 'react-redux'
import { useParams } from 'react-router'

function AdminArchiveSingle() {
    const [administrateur, setAdministrateur] = useState(null)
    const [chargementEnCours,setChargementEnCours]=useState(false)

    const { id } = useParams()
    const token = useSelector((state) => state.auth.token);
    const {formState: { errors },register,reset } = useForm();
    useEffect(()=>{
        async function fetchAdministrateur(id){
          setChargementEnCours(true)
          const data=await getSingleAdministrateur(id,token)
          delete data.createdAt
          delete data.epi
          delete data.manutention
          delete data.premium
          delete data.reduction
          delete data.status
          delete data.updatedAt
          delete data.vehicules
          delete data.verification
          delete data.verified
          setAdministrateur(data)
          reset(data)
          setChargementEnCours(false)
        } 
          if(id){
            fetchAdministrateur(id)
          }
       
        },[id])
  return (
    <LayoutAdmin>
    <div
    style={{
        width: '100%',
        fontFamily: 'Montserrat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '1rem',
        }}
    >
        {   chargementEnCours ?
             <div
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               backgroundColor: 'white',
               marginTop:"10rem"
             }}
             >
             <div className='customLoader'></div>	
             </div>
            :

        <div
            style={{ width: '60%' }}
        >
        <FormSectionCard>
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <p
                            style={{
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                           Fiche Administrateur
                        </p>
                    </div>

                    
        </div>
        </FormSectionCard>  
        <FormSectionCard>
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Nom
                        </span>
                        <CustomInput
						type='text'
                        disabled
                        {...register('last_name', {
                            required: true,
                        })}
										
						/>
                    </div>

                    <div style={{ width: 50 }} />

                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Prénom
                        </span>
                        <CustomInput
                            disabled
							type='text'
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
						})}
						/>
                    </div>
        </div>  
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Adresse email
                        </span>
                        <CustomInput
                            disabled
							type='text'
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
							})}
						/>
                    </div>            
        </div>  
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Rôle
									</span>
									<CustomInput
                                     disabled
							         type='text'
										{...register('role')}
						            />
									
	       
        </div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
                                    disabled
									type='text'
									{...register('address')}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
		</div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville
								</span>
								<CustomInput
                                    disabled
									type='text'
									{...register('city')}
								/>
								{errors.city && (
                  <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                  {errors.city.message}
                  </div>
                )}
		</div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
                                    disabled
									type='text'
									{...register('postalCode')}
								/>
							{errors.postalCode && (
                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                {errors.postalCode.message}
                </div>
              )}
		</div>
        <div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
                                        disabled
										type='text'
										{...register('phone')}
									/>
									{errors.phone && (
                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                      {errors.phone.message}
                       </div>
                   )}
		</div>
        </FormSectionCard>    
        </div>
        }
    </div>
   
    </LayoutAdmin>
  )
}

export default AdminArchiveSingle
import React ,{useState,useEffect} from 'react'
import {
	GradientText,
	SubText,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import Container from '../../Components/LayoutClient/container.component'
import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import Select from 'react-select'
import { getAllCommandesAnnuléesByClientId } from '../../api/commande'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import {
	searchCommandesByDateForClient,
	searchCommandesByPrixMinMaxForClient,
	searchCommandesByIdForClient
} from '../../api/clients'
function CommandeAnnulées() {
    const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const clientID = useSelector((state) => state.auth.user._id)
	const token = useSelector((state) => state.auth.token);
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [type,setType]=useState("annulees")
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
     const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSearchCriteria(selectedOption.value)
      };
      async function fetchCommandes(clientID) {
	    setChargementEnCours(true)
		const commandes = await getAllCommandesAnnuléesByClientId(clientID,token)
		setCommandes(commandes.docs)
		setSearchQueryId('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setSearchCriteria("id")
		setChargementEnCours(false)
	}
    useEffect(() => {
		fetchCommandes(clientID)
	}, [clientID])
  return (
    <Container>
    <Wrapper>
        <GradientText>{'Annulées'.toUpperCase()}</GradientText>
        <SubText>
            Ici retrouver la totalité des transports annulés
        </SubText>
        <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
                    onClick={async () => {
                        let res
                        if (searchCriteria === 'id') {
                            res =
                                await searchCommandesByIdForClient(
                                    clientID,
                                    searchQueryId,
                                    type,
                                    token
                                    
                                )
                        }
                        if (searchCriteria === 'prix') {
                            res =
                                await searchCommandesByPrixMinMaxForClient(
                                    min,
                                    max,
                                    clientID,
                                    type,
                                    token
                                )
                        }

                        if (searchCriteria === 'date') {
                            let minDate = moment(range[0]).format()
                            let maxDate = moment(range[1]).format()

                            res = await searchCommandesByDateForClient(
                                minDate,
                                maxDate,
                                clientID,
                                type,
                                token
                            )
                        }

                        setCommandes(res)
                    }}
					style={{
                        padding: '0.6rem 1rem',
                        height: '3rem',
                        background: '#C9D5F7',
                        border: 'none',
                        cursor: 'pointer',
                        color: '#1C63C5',
                        borderRadius: 7,
                        //marginTop: 20,
                        marginLeft: '1rem',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                    }}
					>
						Rechercher
					</button>

					<button
						onClick={async () => {
							await fetchCommandes(clientID)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginRight: '1rem',
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
		</div>
        {chargementEnCours ?
		<div
		style={{
		  display: 'flex',
		  alignItems: 'center',
		  justifyContent: 'center',
		  backgroundColor: 'white',
		  marginTop:"3rem",
		  top: 0,
		  left: 0,
		  right: 0,
		  bottom: 0,

		}}
		>
		<div className='customLoader'></div>	
		</div>
		:
        <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>	{ commandes.length === 0 ? 
					<span
					style={{
					
						
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					Il n'y a pas des commandes à afficher
				</span>
					:commandes.map((commande) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidecancel
									hidefacture
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/details-commande/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidecancel
									hidefacture
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/details-commande/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
								/>
							)
						}
					})}
		</div>
        }
      

     
    </Wrapper>
</Container>
  )
}

export default CommandeAnnulées
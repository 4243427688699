import api from './base'

export const uploadSingleFile = async (file,id) => {
	const formData = new FormData()
	formData.append('file', file)
	const res = await api.post(`/upload/one/${id}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return res.data
}
export const uploadFile = async (file) => {
	const formData = new FormData()
	formData.append('file', file)
	const res = await api.post(`/upload/single`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return res.data
}
export const uploadSingleFileTarif = async (id,file) => {
	const formData = new FormData()
	formData.append('file', file)
	const res = await api.post(`/upload/tarif/${id}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return res.data
}
export const deleteMedia = async (id) => {
	const res = await api.delete(`/upload/delete/${id}`,null)
	return res.data
}
export const deleteMultipleMedia = async (deletedFiles) => {
	const res = await api.delete(`/upload/deleteMultipleMedia`,{data:deletedFiles})
	return res.data
}
export const updateMedia=async(id,file)=>{
	const res = await api.patch(`/upload/update/${id}`,{data:file})
	return res.data
}
export const updateMediaForAccount=async(id,data)=>{
	const res = await api.patch(`/upload/updateAccount/${id}`,data)
	return res.data
}
export const uploadMediaCommande=async(id,files)=>{
	const res = await api.patch(`/upload/commande/${id}`,{data:files})
	return res.data
}
export const uploadFilesCommande = async (file, commandeId, missionId) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('commandeId', commandeId);
	formData.append('missionId', missionId);
  
	const res = await api.post('/upload/upload_files', formData, {
	  headers: {
		'Content-Type': 'multipart/form-data',
	  },
	});
  
	return res.data;
  };
  
import {
	GradientText,
	SubText,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import Container from '../../Components/LayoutClient/container.component'
import GooglePlacesAutoComplete from '../../Components/GooglePlacesAutoComplete/GooglePlacesAutoComplete'

import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import _ from 'lodash'
import {mergeInvoiceForCommande,mergeAvoirForCommande} from '../../api/invoice'
import { getCommandesbyClientIdByDay ,getCommandesbyClientIdByDayById,getCommandesbyClientIdByDayByAdresse,getCommandesbyClientIdByDayByPrice,getCommandesbyClientIdByDayByDate} from '../../api/commande'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { BASE_URL } from '../../api/base'
function ComptabiliteByDay(props) {
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [total, setTotal] = useState(0)
	const [commandes, setCommandes] = useState([])
	const clientID = useSelector((state) => state.auth.user._id)
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [adresseValue, setAdresseValue] = useState('');
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedDate, setSelectedDate] = useState(null);
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 }
	 async function _getCommandes(clientID) {
		setChargementEnCours(true)
		const res = await getCommandesbyClientIdByDay(clientID,token)
		
		setCommandes(res.data)
		setTotal(res.total_all.toFixed(2))
		
		setSearchQueryId('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setSearchCriteria("id")
		setChargementEnCours(false)

	}
	const handleViewInvoices = async (id) => {
		try {
			const res=await mergeInvoiceForCommande(id,token)
			window.open(`${BASE_URL}/${res.mergedPdfPath}`)
		} catch (error) {
			console.log(error)
		}
    };
    const handleViewAvoir = async (id) => {
	try {
		const res=await mergeAvoirForCommande(id,token)
		window.open(`${BASE_URL}/${res.mergedPdfPath}`)
	} catch (error) {
		console.log(error)
	}
   };
	useEffect(() => {
	

		_getCommandes(clientID)
	}, [clientID])

	return (
		<Container>
			<Wrapper>
				<GradientText>{'comptabilité'.toUpperCase()}</GradientText>
				<SubText>
					Ici retrouver la totalité des transports facturer
				</SubText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
				<div
						style={{
							width: '100%',
							fontFamily: 'Montserrat',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							marginBottom: '2rem',
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					
					>
						<div
						style={{
							width: '360px',
							fontFamily: 'Montserrat',
							// paddingLeft: '1rem',
							// paddingRight: '1rem',
							// marginBottom: '2rem',
							// marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'city', label: "Recherche par ville " },
								{value:'prix',label:"Recherche par tarif"},
								{ value: 'date', label: 'Recherche par Date du transport' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>	
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '18rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
						
						/>
					) : null}
					{/* {searchCriteria === 'prix' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '18rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						
						/>
					) : null} */}
						{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}
					{searchCriteria==="city" ?(
                     <GooglePlacesAutoComplete
                    value={adresseValue}
                     onChange={(place) => {
						
						setAdresseValue(place)
						console.log("Place object:", place.label);
					}}
                    />
                    ): null}
                    
					
					{searchCriteria === 'date' ? (
							<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={selectedDate}
							type="date"
							onChange={(e) => setSelectedDate(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					<button
					   	onClick={async () => {
							if (
								searchCriteria === 'id' &&
								searchQueryId.length === 0
							) {
								return
							}
							let res
							if (searchCriteria === 'id') {
								res =
									await getCommandesbyClientIdByDayById(
										clientID,
										searchQueryId,
										token
									)
							}
							if (searchCriteria === 'city') {
								res =
									await getCommandesbyClientIdByDayByAdresse(
										clientID,
										adresseValue.label,
										token
										
									)
							}
							if (searchCriteria === 'prix') {
								res =
									await getCommandesbyClientIdByDayByPrice(
										clientID,
										min,
										max,
										token
									)
							}
							if (searchCriteria === 'date') {
								res =
									await getCommandesbyClientIdByDayByDate(
										clientID,
										selectedDate,
										token
										
									)
							}
							console.log('data',res.data)
							setCommandes(res.data)
							setTotal(res.total_all.toFixed(2))

						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
					    onClick={async () => {
							await _getCommandes(clientID)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							marginRight:'1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					<div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{total}€</span>
					</div>
					</div>

					
				</div>
				 {chargementEnCours ?
				 <div
				 style={{
				   display: 'flex',
				   alignItems: 'center',
				   justifyContent: 'center',
				   backgroundColor: 'white',
				   marginTop:"3rem",
				   top: 0,
				   left: 0,
				   right: 0,
				   bottom: 0,
		
				 }}
				 >
				 <div className='customLoader'></div>	
				 </div>
				 :
                 <>
				<div
					style={{
						width: '100%',
						padding: '1rem',
						marginBottom: '1rem',
					}}
				>
					<span
						onClick={() =>
							history.push('/comptabilite/client/month')
						}
						style={{
							padding: '1rem 2rem',
							borderRadius: 5,
							cursor: 'pointer',
							marginRight: '1rem',
							fontWeight: 'bold',
							color: 'white',
							background: '#E4EAF0',
						}}
					>
						Mois
					</span>
					<span
						onClick={() => history.push('/comptabilite/client/day')}
						style={{
							padding: '1rem 2rem',
							borderRadius: 5,
							cursor: 'pointer',
							fontWeight: 'bold',
							color: 'white',
							marginRight: '1rem',
							background: '#50F5A9',
						}}
					>
						Jours
					</span>
				</div>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>
					{commandes.length === 0 ? 
						<span
						style={{
						
							
							display: 'flex',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						Il n'y a pas des commandes à afficher
					</span>
					:commandes.map((commande,index) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={index}
									commande={commande}
									hideRecu
									hideAttribuer
									hidecancel
									hideUpdate
									hideStatus
									onClickDetails={() => {
										history.push(
											`/details-commande/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
									onClickFacture={()=>{
										handleViewInvoices(commande._id)

									}}
									onClickAvoir={()=>{
										handleViewAvoir(commande._id)

									}}
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={index}
									commande={commande}
									hideRecu
									hideAttribuer
									hidecancel
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/details-commande/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
									onClickFacture={()=>{
										handleViewInvoices(commande._id)
									}}
									onClickAvoir={()=>{
										handleViewAvoir(commande._id)

									}}
								/>
							)
						}
					})}
				</div>
				 </>
                 }
			</Wrapper>
		</Container>
	)
}

export default ComptabiliteByDay

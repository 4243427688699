import React from 'react';
import Select from 'react-select';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

function GooglePlacesAutoComplete({ value, onChange }) {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyB5Ah3pygC_FWu0WutJvOgPCL9rhk2Mrps',
      debounce: 1000,
      options: {
        componentRestrictions: {
          country: 'fr',
        },
      },
    });

  return (
    <Select
      isClearable
      isSearchable
      value={value}
      onChange={(place) => onChange(place)}
      onInputChange={(newValue) => getPlacePredictions({ input: newValue })}
      isLoading={isPlacePredictionsLoading}
      loadingMessage={() => 'Chargement...'}
      noOptionsMessage={() => "Saisir l'adresse"}
      options={placePredictions.map((place) => ({
        value: {
          place_id: place.place_id,
          description: place.description,
        },
        label: place.description,
      }))}
      styles={{
        control: (base) => ({
          ...base,
            padding: '0.4rem',
            borderRadius: 5,
            border: '1px solid #E4EAF0',
            height: '3rem',
            width: '20rem',
            fontFamily: 'Montserrat',
    
        }),
      }}
    />
  );
}

export default GooglePlacesAutoComplete;
import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'
import { getSingleClient, updateClientAccount } from '../../api/clients'
import { useDispatch, useSelector } from 'react-redux'
import Switch from 'react-switch'
import Container from '../../Components/LayoutClient/container.component'
import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import Modal from 'react-modal'
import { BASE_URL} from '../../api/base'
import UploadFile from '../../Components/UploadSingleFile/upload.component'
import toast from 'react-hot-toast'
import { updateProfile } from '../../redux/auth'
import { useForm,Controller } from 'react-hook-form'
import { useHistory } from 'react-router'
import { payTarifSEPA } from '../../api/tarif'
import {updateMediaForAccount} from  '../../api/upload'

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function ClientAccount() {
	const { _id } = useSelector((state) => state.auth.user)
	const [loading, setLoading] = useState(false)
	const history=useHistory()
	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		control,
		formState: { errors },
	} = useForm()

	 const [client, setClient] = useState()
	 const [chargementEnCours, setChargementEnCours] = useState(false);
	 const token = useSelector((state) => state.auth.token);
     async function fetchClient(_id) {
		setChargementEnCours(true)
		const data = await getSingleClient(_id,token)
		delete data.createdAt
		delete data.epi
		delete data.manutention
		delete data.reduction
		delete data.role
		delete data.status
		delete data.suspendre
		delete data.updatedAt
		delete data.vehicules
		delete data.verification
		delete data.verified
		setClient(data)
		reset(data)
		setChargementEnCours(false)
	}
	useEffect(() => {
			fetchClient(_id)	
	}, [_id])
	const handlePayTarif = async () => {
		try {
		  const response = await payTarifSEPA({})
		  console.log(response);
		  window.location.href = response.url;
		} catch (error) {
		  console.error('Une erreur s\'est produite lors du paiement :', error);
		  // Gérez l'erreur de manière appropriée, par exemple, affichez un message à l'utilisateur.
		}
	  };
	  
	// const password = useRef()
	// password.current = watch('password', '')

	const logo = useRef()
	logo.current = watch('logo', null)

	const kbis = useRef()
	kbis.current = watch('kbis', null)

	// useEffect(() => {
	// 	register('logo', null)
	// 	register('kbis', null)
	// }, [])

	const onError = async (errors) => {
		if (errors.password) {
			toast('Mot de pass doit être de 8 caractères minimum', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
	}

	const onSubmit = async (data) => {
		setLoading(true)
		console.log("logo",logo.current?.id)
		data.logo = logo.current?.id
		data.kbis = kbis.current?.id
		const update = {
			...client,
			societe:data.societe,
			siret:data.siret,
			last_name:data.last_name,
			first_name:data.first_name,
			quality:data.quality,
			domain:data.domain,
			address:data.address,
			phone:data.phone,
			email:data.email,
			city:data.city,
			postalCode:data.postalCode,
			password: data.password,
			logo:data.logo ,
			kbis:data.kbis
		}

		if (data.password.length === 0) {
			delete data.password
			delete update.password
		}
		try {
			if (data.logo) {
				let newFile = data.logo;
				let file = client.logo ? client.logo._id : null;
				updateMediaForAccount(client._id, { newFile, file });
			}
			if (data.kbis) {
				let newFile = data.kbis;
				let file = client.kbis ? client.kbis._id : null;
				updateMediaForAccount(client._id, { newFile, file });
			}
			
			const res = await updateClientAccount(update,token)
			
			toast.success('Votre compte a été mis à jour', {
				style: {
					fontFamily: 'Montserrat',
				},
			})

			dispatch(updateProfile(res))
      
			//await sleep(1000)

			//window.location.reload()
			fetchClient(_id)
		} catch (error) {
			console.log(error)
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setLoading(false)
		}
	}
	
	return (
		<Container>
			<Wrapper>
				<GradientText>MON COMPTE</GradientText>
			</Wrapper>
			{chargementEnCours ?
			<div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"3rem",
			  top: 0,
			  left: 0,
			  right: 0,
			  bottom: 0,
   
			}}
			>
			<div className='customLoader'></div>	
			</div>
			:
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						 width: '70%',
						fontFamily: 'Montserrat',
						alignSelf: 'center',
					}}
				>
					<div>
									{/* <button 
									 style={{
										padding: '1rem',
										borderRadius: '0.3rem',
										background: '#50f5a9',
										color:"white",
										border: 'none',
										marginTop:'1rem'
	                                  
									}}
									onClick={()=>handlePayTarif()}>Sauvegarder les informations de la carte</button> */}
									
									{client?.premium &&	(
									!client.paymentMethodId &&
													
									<button 
									 style={{
										padding: '1rem',
										borderRadius: '0.3rem',
										background: '#50f5a9',
										color:"white",
										border: 'none',
										marginTop:'1rem',
										fontFamily: 'Montserrat',
	                                    fontWeight: 'bold',
										cursor: 'pointer'
	                                  
									}}
									onClick={()=>{history.push(`/client/card`)}}
									>
									Enregistrer vos informations de paiement
									</button>
                                    )}
									</div>
					<form onSubmit={handleSubmit(onSubmit, onError)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Wayne'
										{...register('societe', {
											required: true,
										})}
									/>
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										SIRET
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='833 543 509 000'
										{...register('siret', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
							            disabled
										type='text'
										placeholder='Doe'
										{...register('last_name', {
											required: true,
										})}
									/>
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										placeholder='John'
										disabled
										{...register('first_name', {
											required: true,
										})}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité
									</span>
									<CustomInput
										type='text'
										placeholder='Directeur d’exploitation'
										{...register('quality', {
											required: 'Veuillez indiquer la qualité du poste.',
											minLength: {
												value: 3,
												message: 'La qualité doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'La qualité doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.quality && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                      {errors.quality.message}
                                    </div>
                                     )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Domaine d’activité
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Transport frigorifique'
										{...register('domain', {
											required: true,
										})}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
									type='text'
									placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
									{...register('address', {
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^[A-Za-z0-9\s',-]+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
									})}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									{...register('city', {
									 	required: 'Veuillez indiquer la ville.',
										 minLength: {
											value: 3,
											message: 'La ville doit contenir au moins 3 caractères.',
										  },
										  pattern: {
											value: /^.+$/,
											message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										  },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal 
								</span>
								<CustomInput
									type='text'
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
										type='text'
										placeholder='(0) 01 45 66 32 88'
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                               )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='test@gmail.com'
										{...register('email', {
											required: true,
										})}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Changer mon mot de passe
										</span>
									</div>
									<CustomInput
										type='password'
										//disabled
										autoComplete='new-password'
										placeholder='Saisir mot de passe'
										{...register('password', {
											minLength: 8,
										})}
									/>
								</div>
								

								 <div style={{ width: '1rem' }} />
								 <div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									
										<span
										
											style={{
												color: '#262626',
												paddingBottom: '1rem',
																						}}
										>
									    TVA
										</span>
										<Controller
									control={control}
									name='tva'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
											disabled
										/>
									)}
								/>
									
									
								</div>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
									width: '50%',
									fontFamily: 'Montserrat',
									//marginLeft: 10,
									marginTop: 10,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Logo(PNG ou JPG)
									</span>
									<span
										style={{
											marginBottom: '1rem',
											color: '#838EAB',
											fontSize: 14,
										}}
									>
										(Facultatif)
									</span>
								</div>
								<UploadFile
									name='logo'
									accept='.jpg, .png'
									onFile={(file) => setValue('logo', file)}
								/>
								{logo.current && (
									<div>
										<img
											alt=''
											style={{
												width: 100,
												height: 100,
												borderRadius: 5,
												objectFit: 'cover',
											}}
											src={`${BASE_URL}/${logo.current?.path}`}
										/>
									</div>
								)}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '50%',
									fontFamily: 'Montserrat',
									//marginLeft: 10,
									marginTop: 10,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										KBIS (PDF ou JPG)
									</span>
									<span
										style={{
											marginBottom: '1rem',
											color: '#838EAB',
											fontSize: 14,
										}}
									>
										(Facultatif)
									</span>
								</div>
								<UploadFile
									name='kbis'
									accept='.jpg, .png, .pdf'
									onFile={(file) => setValue('kbis', file)}
								/>
								{kbis?.current &&
								kbis?.current?.mimetype !==
									'application/pdf' && (
									<img
										alt=''
										style={{
											width: 100,
											height: 100,
											borderRadius: 5,
											objectFit: 'cover',
										}}
										src={`${BASE_URL}/${kbis?.current?.path}`}
									/>
								)
								}
                                {kbis?.current &&
								kbis?.current?.mimetype ==
									'application/pdf' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<img
											alt=''
											src={File}
											style={{
												height: 20,
												marginRight: '1rem',
											}}
										/>
										<span
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${BASE_URL}/${kbis?.current?.path}`
												)
											}}
										>
											{kbis?.current?.name}
										</span>
									</div>
								)}
							</div>
						</FormSectionCard>
						<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
								 {loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					
             
					</form>
				</div>
			</div>
             }
		</Container>
	)
}

export default ClientAccount

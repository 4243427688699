import { Controller, useForm } from 'react-hook-form'
import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	StyledTextArea,
	SubmitButton,
	SubmitButtonContainer,
	Select
} from '../../PasserCommande/PasserCommande.styles'
import toast from 'react-hot-toast'
import React, { useEffect, useRef, useState } from 'react'
import { getSignalement, updateSignalement,sendInvoiceSignalement } from '../../../api/signalements'
import { useHistory, useParams } from 'react-router-dom'

import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import TimeField from 'react-simple-timefield'
import UploadMultipleFiles from '../../../Components/UploadMultipleFiles/UploadMultipleFiles'
import { useSelector } from 'react-redux'
import { getCommande } from '../../../api/commande'

function EditSignalementAdmin(props) {
	const history = useHistory()
	const { commande_id, signalement_id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	const [signalement, setSignalement] = useState(null)
	const [commande,setCommande]=useState(null)
	const [loading, setLoading] = useState(false)
	const [chargementEnCours, setChargementEnCours] = useState(false);

	const  [frais,setFrais]=useState(0)
	const { register, handleSubmit, setValue, reset, control, watch ,formState: { errors} } =
		useForm()

	const type = useRef()
	type.current = watch('type', '')

	const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalIsOpenDuration,setModalIsOpenDuration]=useState(false)
	const [modalIsOpenStatus,setModalIsOpenStatus]=useState(false)
	const tarif = useSelector((state) => state.tarif.tarif)

	useEffect(() => {
		async function _getSignalement(_id) {
			try {
				const data = await getSignalement(_id,token)
				console.log("signalement", data);
				// setSignalement(data)
				// setFrais(parseMinutesFromString(signalement?.duration) *tarif?.FACTURATION_HEURES_D_ATTENTE)
                setSignalement(data, () => {
					console.log("signalement", signalement);
					setFrais(parseMinutesFromString(signalement?.duration) * tarif?.FACTURATION_HEURES_D_ATTENTE);
				  });
				reset({
					note: data.note,
					type: data.type,
					course:data?.course,
					duration: data.duration,
				})
			} catch (error) {
				// TODO show some type of error alert
				console.log(error)
			}
		}
		async function _getCommande(_id) {
			setChargementEnCours(true)
			try {
			    const data=await getCommande(_id,token)
				setCommande(data)
			} catch (error) {
				// TODO show some type of error alert
				console.log(error)
			}
			finally{
				setChargementEnCours(false)
	
			}
		}

		signalement_id && _getSignalement(signalement_id)
		_getCommande(commande_id)
	}, [commande_id, signalement_id])

	const parseMinutesFromString = (duration) => {
		const segments = duration.split(':')

		return Number(segments[0]) * 60 + Number(segments[1])
	}

	const onSubmit = async (values) => {
		let data = { ...values }

		if (data.files) {
			data.files = data.files.map((file) => file.id ?? file._id)
		}

		try {
			await updateSignalement(signalement_id, data,token)
			history.goBack()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								{...props}
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'DÉTAIL signalement'.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
						<div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  marginTop:'10rem'
						}}
						>
						<div className='customLoader'></div>	
						</div> 
					:<form onSubmit={handleSubmit(onSubmit)}>
						{signalement?.type && signalement?.type!=='commentaire' &&  signalement?.type!=='course' && user.role!=="admin distribution" && user.role!=="admin service après vente" &&(
							<FormContentSection>
								<FormSectionTitle></FormSectionTitle>
								<FormSectionCard>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<p>
											<b>Facturer</b>
										</p>
										
										<p>
											{`${signalement.duration} * ${
												tarif?.FACTURATION_HEURES_D_ATTENTE
											} euros = ${
												parseMinutesFromString(
													signalement.duration
												) *
												tarif?.FACTURATION_HEURES_D_ATTENTE
											} euros`}
										</p>
                                     
										<button
											type='button'
											onClick={() => {
												if(parseMinutesFromString(signalement.duration)<30){
													setModalIsOpenDuration(true)

												}else if(signalement.paymentStatus==="payé"){
													setModalIsOpenStatus(true)
												}
												else{
													setModalIsOpen(true)	
												}
												
											}
										}
											style={{
												background: 'ghostwhite',
												border: 'none',
												padding: '1rem 2rem',
												borderRadius: '1rem',
												cursor: 'pointer',
												fontFamily: 'Montserrat',
											}}
										>
											Envoyer la facture au client
										</button>
                                        
									</div>
								</FormSectionCard>
							</FormContentSection>
						)}
                      {commande &&
							commande?.type_commande === 'course-a-course'  && (
						<FormContentSection>
							<FormSectionTitle></FormSectionTitle>
							<FormSectionCard>
							<div 
									style={{
										display: 'flex',
                                        flexDirection: 'column',
                                         marginBottom: '2rem'}}>
				                    <span
									    style={{
											marginBottom: '1rem',
                                          color: 'black',
                                          fontSize: '14px',
                                          display: 'block',
                                          textAlign: 'center',
                                         fontWeight: 'bold',
										}}
										
									>
										Sélectionnez le type d'anomalie
									</span>							
								<div
									style={{ display: 'flex',
									flexDirection: 'column',
								   alignItems: 'center'}}
								>
									<div
										style={{ display: 'flex',
										alignItems: 'center',
										marginBottom: '1rem',
										marginLeft:'20px',
										justifyContent: 'center'
									 }}
									>
										{console.log('type signaler',signalement?.type)}
										<input
											id='chargement'
											type='radio'
											value='chargement'
											disabled
											{...register('type',{required:true})}
											style={{marginRight: '1rem',}}

										/>

										<label htmlFor='chargement'>
											Anomalie heure chargement
										</label>
									</div>

									<div
										style={{ display: 'flex',
										alignItems: 'center',
										marginBottom: '1rem',
										marginLeft:'10px',
										justifyContent: 'center'
									 }}
									>
										<input
											id='livraison'
											type='radio'
											value='livraison'
											disabled
											{...register('type',{required:true})}
											style={{marginRight: '1rem',}}

										/>

										<label htmlFor='livraison'>
											Anomalie heure de livraison
										</label>
									</div>
									<div
											style={{ display: 'flex',
											alignItems: 'center',
											marginBottom: '1rem',
											marginLeft:-80
										 }}	
												
											>
												<input
													id='course'
													type='radio'
													value='course'
													{...register('type')}
													disabled
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='course'>
													Anomalie course 
												</label>
											</div>
									<div
												style={{ display: 'flex',
												alignItems: 'center',
												marginBottom: '1rem',
												justifyContent: 'center',
												marginLeft:-100
											 }}
											>
												<input
													id='commentaire'
													type='radio'
													value='commentaire'
													disabled
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='commentaire'>
												Commentaire
												</label>
											</div>
											{errors.type &&
											<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez sélectionner le  type d'anomalie</p>}
								</div>

								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										textAlign: 'center',
									}}
								>
									{type.current &&  type.current!=='commentaire' && type.current!=='course' &&(
										<div>
											<p>
												<b>
													Anomalie heure{' '}
													{type.current ===
													'chargement'
														? 'chargement'
														: 'de livraison'}
												</b>
											</p>
											<p>
												Saisissez le temps du retard en
												minutes et en heures
											</p>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Controller
													name='duration'
													rules={{ required: true }}
													control={control}
													render={({
														field: {
															onChange,
															value,
														},
													}) => (
														<TimeField
															value={value}
															disabled
															onChange={(
																event,
																value
															) => {
																onChange(value)
															}}
															input={
																<input
																	type='text'
																	className='date-input'
																	onFocus={(
																		e
																	) => {
																		e.target.select()
																	}}
																/>
															}
															colon=':'
														/>
													)}
												/>
											</div>
										</div>
									)}
									{type.current && type.current==="course" && 
											    <div>
													<p>
														<b>
															Anomalie course
														</b>
													</p>
													<p>
													 Sélectionnez la course concernée par cette anomalie  
													</p>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'center',
														}}
													>
														
														<Select
														    disabled={true}
														    {...register('course', { required: true })}
                                                            onChange={(e) => {
                                                            setValue('course', e.target.value); 
                                                            }}
															value={watch('course')}

														>
														<option></option>
														 {/* Première option */}
                                                         <option value={commande?.courses[0]?._id}>
														 {`Course ${1}: ${commande?.courses[0]?.adresse_debut.label} --> ${commande?.courses[0]?.adresse_fin.label}`}

                                                         </option>

														 {commande?.courses.length === 2 && (
                                                         <option value={commande?.courses[1]?._id}>
                                                         {`Course ${2}: ${commande?.courses[0]?.adresse_fin.label} --> ${commande?.courses[1]?.adresse.label}`}

                                                         </option>
                                                         )}
														{commande?.courses.length>2 &&
														<>
														<option value={commande?.courses[1]?._id}>
														{`Course ${2}: ${commande?.courses[0]?.adresse_fin.label} --> ${commande?.courses[1]?.adresse.label}`}
                                                        </option>
														
														{commande?.courses.map((course, index) => (
															index > 1 && (
																<option key={course._id} value={course._id}>
																	{`Course ${index+1}: ${commande?.courses[index - 1]?.adresse.label} --> ${course.adresse.label}`}
																</option>
															)
														))}
														</>
                                                         }


														</Select>
														
														
													

													</div>
													{errors.course && 
									                    <p style={{color:'red',marginTop:'0,5rem'}}>Veuillez sélectionner  une  course</p>}
												</div>
											}
								</div>
								</div>
							</FormSectionCard>
						</FormContentSection>
							)}
						{commande && 
						commande?.type_commande === 'mise-a-disposition'  && (
							<FormContentSection>
									<FormSectionTitle></FormSectionTitle>
									<FormSectionCard>
									<div 
									style={{
										display: 'flex',
                                        flexDirection: 'column',
                                         marginBottom: '2rem'}}>
									   <span
									    style={{
											marginBottom: '1rem',
                                          color: 'black',
                                          fontSize: '14px',
                                          display: 'block',
                                          textAlign: 'center',
                                         fontWeight: 'bold',
										}}
										
									>
										Sélectionnez le type d'anomalie
									</span>	
										<div
												style={{ display: 'flex',
												flexDirection: 'column',
											   alignItems: 'center'}}
										>
											
											<div
												style={{ display: 'flex',
												alignItems: 'center',
												marginBottom: '1rem',
												marginLeft:'20px',
												justifyContent: 'center'
											 }}
											>
												<input
													id='livraison'
													type='radio'
													value='livraison'
													disabled
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}

												/>

												<label htmlFor='livraison'>
													Anomalie heure de livraison
												</label>
											</div>
											<div
											style={{ display: 'flex',
											alignItems: 'center',
											marginBottom: '1rem',
											marginLeft:-70
										 }}	
												
											>
												<input
													id='course'
													type='radio'
													value='course'
													{...register('type')}
													disabled
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='course'>
													Anomalie course 
												</label>
											</div>
											<div
												style={{ display: 'flex',
												alignItems: 'center',
												marginBottom: '1rem',
												justifyContent: 'center',
												marginLeft:-90
											 }}
											>
												<input
													id='commentaire'
													type='radio'
													value='commentaire'
													disabled
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='commentaire'>
												Commentaire
												</label>
											</div>
											{errors.type &&
											<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez sélectionner le  type d'anomalie</p>}
										</div>

										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											{type.current && type.current!=='commentaire' && type.current!=='course' &&  (
												<div>
													<p>
														<b>
															Anomalie heure{' '}
															{type.current ===
															'chargement'
																? 'chargement'
																: 'de livraison'}
														</b>
													</p>
													<p>
													Saisissez la durée supplémentaire en minutes et en heures
													</p>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'center',
														}}
													>
														<Controller
															name='duration'
															rules={{
																required: true,
															}}
															control={control}
															render={({
																field: {
																	onChange,
																	value,
																},
															}) => (
																<TimeField
																    disabled
																	value={
																		value
																	}
																	onChange={(
																		event,
																		value
																	) => {
																		onChange(
																			value
																		)
																	}}
																	input={
																		<input
																			type='text'
																			className='date-input'
																			onFocus={(
																				e
																			) => {
																				e.target.select()
																			}}
																		/>
																	}
																	colon=':'
																/>
															)}
														/>
													</div>
												</div>
											)}
										</div>
										</div>
									</FormSectionCard>
								</FormContentSection>
						)}		

						<FormContentSection>
							<FormSectionTitle></FormSectionTitle>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2.5rem',
										width: '100%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Note
									</span>
									<StyledTextArea
										type='text'
										disabled
										rows={4}
										placeholder='Les palettes n’ont pas supportés le chargement'
										{...register('note', {
											required: true,
										})}
									/>
									{errors.note && 
									<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez saisir une  note</p>}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Joindre images ou PDF
										</span>
									</div>
									{signalement && (
										<UploadMultipleFiles
										    disabled= {false}
											name='files'
											initialFiles={signalement.files}
											onChange={(files) => {
												setValue('files', files)
											}}
										/>
									)}
								</div>
							</FormSectionCard>
						</FormContentSection>

						<div style={{ height: '1rem' }} />

					</form>
                    }
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
					},
				}}
				contentLabel='Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
						style={{
							marginTop: 20,
						}}
					>
						Vous confirmez envoyer cette facture au client ?
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<button
							disabled={loading}
							onClick={async() => {
								setLoading(true)
								try {
	                                await sendInvoiceSignalement(signalement?._id,token)
									toast.success('La facture a été envoyée avec succès', {
										style: {
										  fontFamily: 'Montserrat',
										},
									  });
								} catch (error) {
									toast.error(
										error.response.data.error,
										{
											style: {
												fontFamily: 'Montserrat',
											},
										}
									)


								}finally{
									setModalIsOpen(false)
									setLoading(false)
									window.location.reload()


								}
							}}
							style={{
								padding: '1rem 3rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								marginTop: 30,
								fontFamily: 'Montserrat',
							}}
						>
						 {loading ? 
						<div className='loader'></div>

						  : 'Oui'} 
						</button>
						<div style={{ width: '1rem' }} />
						<button
							onClick={() => {
								setModalIsOpen(false)
							}}
							style={{
								padding: '1rem 3rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								marginTop: 30,
								fontFamily: 'Montserrat',
							}}
						>
							Annuler
						</button>
					</div>
				</div>
			</Modal>
			<Modal
    isOpen={modalIsOpenDuration}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
			
    La facture ne peut être envoyée car la durée est inférieure à 30 minutes.
		 </div>

        <button
            onClick={() => {
                setModalIsOpenDuration(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenStatus}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
			Vous ne pouvez pas envoyer la facture car elle a déjà été payée.
		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatus(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		</LayoutAdmin>
	)
}

export default EditSignalementAdmin

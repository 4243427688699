import React from 'react';
import Modal from 'react-modal';

// Fonction pour formater l'heure
const formatHeure = (heure) => {
    // Vérifier si heure est falsy ou ne contient pas ':'
    if (!heure || !heure.includes(':')) return ""; 

    const [hours, minutes] = heure.split(':').map(Number);
    
    // Gérer les cas où hours ou minutes peuvent être NaN
    if (isNaN(hours) || isNaN(minutes)) return "";

    // Retourner la durée formatée
    return hours === 0 ? `${minutes}min` : `${hours}h${minutes > 0 ? minutes + "min" : ""}`;
};


// Style pour le modal
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '80vh',
        overflow: 'hidden',
        width: "80%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

// Styles pour les éléments du modal
const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#51C7F2',
    padding: '20px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const closeButtonStyle = {
    position: 'absolute',
    right: '15px',
    backgroundColor: 'transparent',
    padding: '0',
    color: '#FF5A5F',
    border: 'none',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
};

const contentStyle = {
    padding: '20px',
    background: '#F7F8F8',
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 60px)',
};

const placeStyle = {
    background: '#fff',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const labelStyle = {
    marginBottom: '8px',
    fontSize: '14px',
    color: '#838EAB',
};

const PointsPassageModal = ({ isOpen, onClose, places }) => {

    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={onClose}  
            ariaHideApp={false}
            style={modalStyles}
        >
            <div style={headerStyle}>
                <h2 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>Points Passage</h2>
                <button 
                    onClick={onClose}  
                    style={closeButtonStyle}
                >
                    X
                </button>
            </div>
            <div style={contentStyle}>
                {places?.length===0 ?
                <div  style={{ 
                    background: '#fff',
                    padding: '15px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    textAlign:'center'
                }} >Il n'y a pas de points de passage à afficher</div>
                :
                places.map((place, index) => (
                    <div key={index} style={placeStyle}>
                        <div style={labelStyle}>
                                    <strong>Adresse :</strong> <span style={{ color: '#FF5A5F' }}>{place.adresse.label}</span>
                                    <strong style={{ marginLeft: "1rem" }}>Distance :</strong> <span style={{ color: '#FF5A5F' }}>{place.distance} Km</span>
                                    <strong style={{ marginLeft: "1rem" }}>Heure :</strong> <span  style={{ color: '#FF5A5F' }}>{formatHeure(place.heure) || '00min'}</span>
                             
                        </div>

                        {index !== 0 && (
                            <>
                                {place?.nom_destinataire && (
                                    <div style={labelStyle}>
                                        <strong>Nom du destinataire :</strong> {place.nom_destinataire}
                                        {place?.prénom_destinataire && (
                                            <>
                                                <strong style={{ marginLeft: "1rem" }}>Prénom du destinataire :</strong> {place.prénom_destinataire}
                                            </>
                                        )}
                                    </div>
                                )}

                                {place?.adresse_destinataire && (
                                    <div style={labelStyle}>
                                        <strong>Adresse du destinataire :</strong> {place.adresse_destinataire}
                                        {place?.téléphone_destinataire && (
                                            <>
                                                <strong style={{ marginLeft: "1rem" }}>Numéro de Téléphone du destinataire :</strong> {place.téléphone_destinataire}
                                            </>
                                        )}
                                    </div>
                                )}

                                {place?.email_destinateur && (
                                    <div style={labelStyle}>
                                        <strong>Email du destinataire :</strong> {place.email_destinateur}
                                    </div>
                                )}

                              
                                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#838EAB' }}>
                                        <strong>Signé :</strong> {place?.signé ? 'Oui' : 'Non'}
                                        <strong style={{ marginLeft: "1rem" }}>Signalé :</strong> {place?.signaler ? 'Oui' : 'Non'}

                                    </div>
                                

                               
                               
                            </>
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default PointsPassageModal;

import React,{useState,useEffect} from 'react'
import {getPointsPassageForMission} from '../../api/commande'
import Container from '../LayoutClient/container.component'
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles'
import { useSelector } from 'react-redux'
import { useParams,useHistory } from 'react-router'
import toast from 'react-hot-toast'
import Agrandir from '../../assets/img/agrandir@2x.png'
import Reduire from '../../assets/img/reduire@2x.png'
import { StyledInput } from '../../Containers/PasserCommande/PasserCommande.styles'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CustomInput } from '../../Containers/RegisterTransporter/style';

function PointsPassageMissionClient() {
    const  history=useHistory()
    const {idMission}=useParams()
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const [collapsedItems, setCollapsedItems] = useState([]);
    const [places,setPlaces]=useState([])

    const token = useSelector((state) => state.auth.token);

    const fetchPointPassages = async () => {
        setChargementEnCours(true)
		try {
		  const pointsPassages=await getPointsPassageForMission(idMission,token)
	      setPlaces(pointsPassages)
          setCollapsedItems(new Array(pointsPassages.length).fill(false))
		} catch (err) {
			toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
		}finally{
            setChargementEnCours(false)
        }
	}
    const  toggleCollapse=(index)=>{
        const  newCollapsedItems=[...collapsedItems]
        newCollapsedItems[index]=!newCollapsedItems[index]
        setCollapsedItems(newCollapsedItems)
    }
    useEffect(()=>{
        fetchPointPassages()
    },[idMission])
  return (
   <Container>
    <div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
                   <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
                        <div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>
                        <div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{`Points  de  passage`.toUpperCase()}
						</GradientText>
                    </div>
        <div
					style={{
						width: '90%',
						fontFamily: 'Montserrat',
						minHeight: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '2rem',

					}}
				>
                {chargementEnCours ?
				  <div
				  style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: 'white',
					marginTop:"10rem",
					marginLeft:"28rem"
				  }}
				  >
				  <div className='customLoader'></div>	
				  </div>
                :
                <div 
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-start',
                    marginBottom: '2rem',
                }}
                >
                    {places.length === 0 ? 
						<span
						style={{
						
							
							display: 'flex',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'center',
							paddingLeft:'300px'
						}}
					>
						Il n'y a pas des points  de  passage à afficher
					</span>
                    :
                    places.map((place,index)=>{
                        return(
                        <div 
                        key={index}
                        style={{
                            width: '100%',
                            background: place?.signé 
                            ? (place?.signaler ? '#FFFFE0' : '#E6F7FF')  // Signé et Signalé en même temps
                            : (place?.signaler ? '#FFDAB9' : '#E4EAF0'), // Signalé seulement ou Aucun des deux

                            display: 'flex',
                            flexDirection: 'column',
                            padding: '1rem',
                            borderRadius: 10,
                            marginBottom: 10,
                            position:'relative',
                        
                        }}>
                            {index!==0 &&
			<div
			>
                
			<div
			        onClick={()=>{
						toggleCollapse(index)
					}}
					style={{
						position: 'absolute',
						top: 10,
						right: 10,
						cursor: 'pointer',
						display: collapsedItems[index]
								? 'none'
								: 'flex',
					    alignItems: 'center',
					    justifyContent: 'center',
					}}	
					>
						<span style={{color: '#838EAB'}}>Agrandir</span>

						<img
							src={Agrandir}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
			</div>
       
			<div
					onClick={()=>{
                        toggleCollapse(index)
                    }}
						style={{
							position: 'absolute',
							bottom: 10,
							right: 10,
							cursor: 'pointer',
							display: !collapsedItems[index]
								? 'none'
								: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
                            
						}}
					>
						<span style={{color: '#838EAB'}}>Réduire</span>
						<img
							src={Reduire}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
			</div>
			
            
			</div>
            }
                <div
                style={{
                    marginBottom: '1rem',

                }}>
                <span
                    style={{
                        marginRight: 13,
                        marginBottom: '1rem',
                        color: '#838EAB',
                        width: '15rem',
                    }}
                >
                    Adresse : {' '}
                    <span
                        style={{ color: "red" }}>
                        {place?.adresse?.label}
                    </span>
                </span>
                <span
                    style={{
                        marginRight: 13,
                        marginBottom: '1rem',
                        color: '#838EAB',
                        width: '15rem',
                    }}
                >
                    Distance : {' '}
                    <span
                        style={{ color: "red" }}>
                        {place?.distance}
                    </span>
                </span>
                </div>
                {(index!==0 && collapsedItems[index]) &&
                <div style={{
                    paddingBottom: '2rem',

                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
                    {place?.nom_destinateur &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                            Nom du destinataire
                        </span>
                       
                                <StyledInput
									disabled={true}
                                    type="text"
                                    value={place?.nom_destinateur}

                                />
                            
                        
						
                    </div>
                    }
                    {place?.prénom_destinateur &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,

                            }}
                        >
                            Prénom du destinataire
                        </span>
                        <StyledInput
									disabled={true}
                                    type="text"
                                    value={place?.prénom_destinateur}
                                />
                    </div>
                    }
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
                    {place?.adresse_destinateur &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,

                            }}
                        >
                            Adresse du destinataire
                        </span>
                       
                                <StyledInput
                                    type="text"
									disabled={true}
                                    value={place?.adresse_destinateur}
                                />
                            
                        
                    </div>
                    }
                    {place?.téléphone_destinateur &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,

                            }}
                        >
                            Numéro de Téléphone du destinataire
                        </span>
                     
                                <PhoneInput
                                    value={place?.téléphone_destinateur}
                                    country={'fr'}
									disabled={true}
                                    inputStyle={{
                                        paddingRight: '1.5rem',
                                        paddingTop: '1.5rem',
                                        paddingBottom: '1.5rem',
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        fontFamily: 'Montserrat',
                                        color: '#858ea8',
                                        flex: '1',
                                        display: 'flex',
                                        width: '100%'
                                    }}
                                    buttonStyle={{
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        fontFamily: 'Montserrat',
                                        color: '#858ea8',
                                        flex: '1',
                                        display: 'flex'
                                    }}
                                />
                            
                        
                    </div>
                    }
                </div>

                {place?.email_destinateur &&
				<div
				style={{
					width: '50%',
					display: 'flex',
					flexDirection: 'column',
				}}> 

				<span
										style={{
											color: 'black',
											fontSize: 13,
											paddingBottom: '.5rem',
										}}
									>
										Adresse email  du destinataire 
									</span>
									<CustomInput
										type='text'
										value={place?.email_destinateur}
                                        disabled={true}
									/>
				</div>
                }    
                            <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
					}}
				>
					<span
						style={{
							marginBottom: '.5rem',
							color: 'black',
							fontSize: 13,
                            fontFamily: 'Montserrat',

						}}
					>
						Signé
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
					}}>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							disabled={true}
							 style={{
								background:place?.signé?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: place?.signé && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:place?.signé ?'#fff': '#444'
							}}
							>Oui</button>
							
						
						</div>
						<div
							
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							 disabled={true}
							 style={{
								background:!place?.signé ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: !place?.signé && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:!place?.signé?'#fff': '#444'
							}}
							>Non</button>
						</div>
						<div
							
							style={{
								marginRight: '1rem',
								marginTop:'-1rem'
							}}
						>
						<button
				        onClick={()=>{
					    history.push(`/client/transports/adresse/${place._id}/single`)
				        }}
                       style={{
                    marginTop: '1rem',
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    
					padding:' 0.5rem 1.25rem',

                    cursor: 'pointer',
					
                }}
            >
                Signalement
                       </button>
					   </div>
					</div>					
				
				</div>
				</div>
                    }
                </div>
                 )
                })
                 }
                </div>
                }
                </div>
                
    </div>
   </Container>
  )
}

export default PointsPassageMissionClient
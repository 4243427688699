import React, { useEffect, useState } from 'react'
import {
	updateStatutCommandeForChauffeur,
	getCommande,
	updateSenderCommande,
} from '../../api/commande'
import { StyledInput } from '../../Containers/PasserCommande/PasserCommande.styles'
import PhoneInput from 'react-phone-input-2'
import moment from 'moment'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import  toast from 'react-hot-toast'
import AjouterDemandeTransfert from '../DemandeTransfert/AjouterDemandeTransfert'
import ModiferDemandeTransfert from '../DemandeTransfert/ModiferDemandeTransfert'
function CourseACourseHorizontal({
	commande,
	onClickDetails = () => {},
	onClickFacture=()=>{},
	onClickAvoir=()=>{},
	onClickSignaler = () => {},
	onClickHistoriqueChauffeur=()=>{},
	hideStatus = false,
	hideAccept = true,
	hideTransfert=true,
	hideSignaler,
	onClickAccept = () => {},
	hideRadio = false,
	hidePrice = false,
	hideRecu = false,
	hideAttribuer = false,
	hideTarif = false,
	hidecancel=false,
	hideUpdate=false,
	hidefacture=false,
	enableSaveStatus = false,
	onClickRecu = () => {},
	onClickRefuser=()=>{},
	onClickAttribuer = () => {},
	onClickTransferer= ()=>{},
	onClickCancel=()=>{},
	onClickPayer=()=>{},
	setModalIsOpenStatus,
	setModalIsOpenStatusAnnuller,
	setModalIsOpenPayer,
	setUpdateStatus,
	setModalIsOpenStatusInitTerminer,
	setMessage
	

}) {
	const [statut, setStatut] = useState(commande.statut)
	const [modalIsOpenUpdate,setModalIsOpenUpdate]=useState(false)
	const user = useSelector(state => state.auth.user)
	const [modalIsOpenTransfret,setModalIsOpenTransfret]=useState(false)
	const token = useSelector((state) => state.auth.token);
	const [updateLoading,setUpdateLoading]=useState(false)
	const [commandeData,setCommandeData]=useState({})
	const [modalIsOpenStatusSendSMS,setModalIsOpenStatusSendSMS]=useState(false)
	const [modalIsOpenUpdateTransfret,setModalIsOpenUpdateTransfret]=useState(false)
	const [distributors, setDistributors] = useState(
		commande?.courses?.map((course,index) => ({
		  lastName: course?.lastNameDistinator || '',
		  firstName: course?.firstNameDistinator || '',
		  phone1: course?.phone1Distinator || '',
		  phone2: course?.phone2Distinator || '',
		  index:index
		})) || []
	  );
	const  [isValidPhone,setIsValidPhone]=useState(commande?.courses?.map((course,index) => ({
		validNom:false,
		validePrenom:false,
		validPhone1:false,
		validPhone2:false,
		messageErrorNom:'',
		messageErrorPrenom:'',
		messageErrorPhone1:'',
		messageErrorPhone2:'',
	  })) || [])
	  const isValidName = (name) => {
		const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/;
		return nameRegex.test(name);
	  };
 
	  const handleDistributorChange = (index) => (event) => {
		const { name, value } = event.target;
		setDistributors((prevDistributors) =>
		  prevDistributors.map((distributor, i) =>
			i === index ? { ...distributor, [name]: value } : distributor
		  )
		);
	  };
	  const handleDistributorChangePhone = (index,newValue,name,metadata)=> {
		 setDistributors((prevDistributors) =>
		  prevDistributors.map((distributor, i) =>
			i === index ? { ...distributor, [name]: newValue } : distributor
		  )
		);
		
	
	  };
      const handleAjouterClick=async()=>{
		setUpdateLoading(true)
		let valide=true
		for (let i = 0; i < distributors.length; i++) {
			if (distributors[i]?.phone1 === '') {
				valide=false
			  setIsValidPhone((prev) => [
				...prev.slice(0, i),
				{
				  ...prev[i],
				  validPhone1: true,
				  messageErrorPhone1: 'Veuillez indiquer le numéro',
				},
				...prev.slice(i + 1),
			  ]);
			}
            // if(!isValidName(distributors[i]?.lastName)){
			// 	valide=false
			// 	setIsValidPhone((prev) => [
			// 		...prev.slice(0, i),
			// 		{
			// 		  ...prev[i],
			// 		  validNom: true,
			// 		  messageErrorNom: ' Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
			// 		},
			// 		...prev.slice(i + 1),
			// 	  ]);
			// }
			if(distributors[i]?.lastName===''){
				valide=false
				setIsValidPhone((prev) => [
					...prev.slice(0, i),
					{
					  ...prev[i],
					  validNom: true,
					  messageErrorNom: 'Veuillez indiquer le nom',
					},
					...prev.slice(i + 1),
				  ]);
			}
			// if(!isValidName(distributors[i]?.firstName)===''){
			// 	valide=false
			// 	setIsValidPhone((prev) => [
			// 		...prev.slice(0, i),
			// 		{
			// 		  ...prev[i],
			// 		  validePrenom: true,
			// 		  messageErrorPrenom: 'le numéro de téléphone invalide',
			// 		},
			// 		...prev.slice(i + 1),
			// 	  ]);
			// }
			if(distributors[i]?.firstName===''){
				valide=false
				setIsValidPhone((prev) => [
					...prev.slice(0, i),
					{
					  ...prev[i],
					  validePrenom: true,
					  messageErrorPrenom: 'Veuillez indiquer le prénom',
					},
					...prev.slice(i + 1),
				  ]);
			}
			if (distributors[i]?.phone2 === '') {
				valide=false
			  setIsValidPhone((prev) => [
				...prev.slice(0, i),
				
				{
				  ...prev[i],
				  validPhone2: true,
				  messageErrorPhone2: 'Veuillez indiquer le numéro',
				},
				...prev.slice(i + 1),
			  ]);
			}
		  }
		if(valide) {
			await updateSenderCommande(distributors,token,commande._id)
			setModalIsOpenUpdate(false) 
		}
		setUpdateLoading(false)

	  
	  }
useEffect(()=>{
	for (let i = 0; i < distributors.length; i++) {
			
		  
		if (distributors[i]?.phone1 === '') {
			
		  setIsValidPhone((prev) => [
			...prev.slice(0, i),
			{
			  ...prev[i],
			  validPhone1: false,
			  messageErrorPhone1: 'le numéro de téléphone invalide',
			},
			...prev.slice(i + 1),
		  ]);
		}else{
			setIsValidPhone((prev) => [
				...prev.slice(0, i),
				{
				  ...prev[i],
				  validPhone1: true,
				  messageErrorPhone1: '',
				},
				...prev.slice(i + 1),
			  ]);
		}
	  
		if (distributors[i]?.phone2 === '') {
			
		  setIsValidPhone((prev) => [
			...prev.slice(0, i),
			
			{
			  ...prev[i],
			  validPhone2: true,
			  messageErrorPhone2: 'le numéro de téléphone invalide',
			},
			...prev.slice(i + 1),
		  ]);
		}else{
			setIsValidPhone((prev) => [
				...prev.slice(0, i),
				
				{
				  ...prev[i],
				  validPhone2: false,
				  messageErrorPhone2: '',
				},
				...prev.slice(i + 1),
			  ]);
		}
		// if(!isValidName(distributors[i]?.firstName)===''){
		
		// 	setIsValidPhone((prev) => [
		// 		...prev.slice(0, i),
		// 		{
		// 		  ...prev[i],
		// 		  validePrenom: true,
		// 		  messageErrorPrenom: 'le numéro de téléphone invalide',
		// 		},
		// 		...prev.slice(i + 1),
		// 	  ]);
		// }else{
		// 	setIsValidPhone((prev) => [
		// 		...prev.slice(0, i),
		// 		{
		// 		  ...prev[i],
		// 		  validePrenom: false,
		// 		  messageErrorPrenom: '',
		// 		},
		// 		...prev.slice(i + 1),
		// 	  ]);	
		// }

		if(distributors[i]?.firstName===''){
			setIsValidPhone((prev) => [
				...prev.slice(0, i),
				{
				  ...prev[i],
				  validePrenom: true,
				  messageErrorPrenom: 'Veuillez indiquer le prénom',
				},
				...prev.slice(i + 1),
			  ]);
		}else{
			setIsValidPhone((prev) => [
				...prev.slice(0, i),
				{
				  ...prev[i],
				  validePrenom: false,
				  messageErrorPrenom: '',
				},
				...prev.slice(i + 1),
			  ]);
		}
		// if(!isValidName(distributors[i]?.lastName)){
			
		// 	setIsValidPhone((prev) => [
		// 		...prev.slice(0, i),
		// 		{
		// 		  ...prev[i],
		// 		  validNom: true,
		// 		  messageErrorNom: 'le numéro de téléphone invalide',
		// 		},
		// 		...prev.slice(i + 1),
		// 	  ]);
		// }else{
		// 	setIsValidPhone((prev) => [
		// 		...prev.slice(0, i),
		// 		{
		// 		  ...prev[i],
		// 		  validNom: false,
		// 		  messageErrorNom: '',
		// 		},
		// 		...prev.slice(i + 1),
		// 	  ]);
		// }
		if(distributors[i]?.lastName===''){
			
			setIsValidPhone((prev) => [
				...prev.slice(0, i),
				{
				  ...prev[i],
				  validNom: true,
				  messageErrorNom: 'Veuillez indiquer le nom',
				},
				...prev.slice(i + 1),
			  ]);
		}else{
			setIsValidPhone((prev) => [
				...prev.slice(0, i),
				{
				  ...prev[i],
				  validNom: false,
				  messageErrorNom: '',
				},
				...prev.slice(i + 1),
			  ]);
		}
	  }
},[distributors])


	return (
		<div
			style={{
				width: '100%',
				background: '#E4EAF0',
				display: 'flex',
				flexDirection: 'row',
				padding: '1rem',
				borderRadius: 10,
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginBottom: 10,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						marginBottom: 3,
					}}
				>
					Course à course {!!commande ? ' N°' + commande.__id : '' } /{' '}
					<span style={{ color: 'red' }}>
						{commande.temporisation === 'immediat'
							? 'Immédiat'
							: 'Planifier'}
					</span>
				</span>

				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					Date du Transports :{' '}
					<span
						style={{
							marginRight: 15,
							
						}}
					>
						{commande.courses[0].date_debut}
					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					Date du Création :{' '}
					<span
						style={{
							marginRight: 15,
						}}
					>
				    {moment(commande.createdAt).format('DD/MM/YYYY')}
					</span>
				</span>
				
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,
					}}
				>
					<span
						style={{
							marginRight: 15,
						}}
					>
				      {moment(commande?.courses[0]?.heure_debut).isValid() ?
                      moment(commande?.courses[0]?.heure_debut).format('hh:mm') 
                      :commande?.courses[0]?.heure_debut}
					  {commande.courses[0]?.adresse_debut?.label}
					</span>

					<br />
					<span
						style={{
							marginRight: 15,
						
						}}
					>
						{moment(commande.courses[0]?.heure_fin).isValid() ? 
                         moment(commande.courses[0]?.heure_fin).format('HH:mm') 
                        :commande.courses[0]?.heure_fin}
						{commande.courses[0]?.adresse_fin?.label}
					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					 L'état de paiement :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
				 {commande.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					 Statut de la commande :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {commande?.statut==="init" && "Non commencée"}
             {commande?.statut==="en-cours" && "En cours"}
             {commande?.statut==="terminer" && "Terminée"}
             {commande?.statut==="annuler" && "Annulée"}
					</span>
				</span>
				{(!hideTransfert && commande.recu && commande.statut==="en-cours") && (
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					 Statut de Transfert :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {commande?.statutTransfert === 'transferé' && "Transferé"}
             {commande?.statutTransfert==="en cours de transfert" && "En cours de transfert"}
             {commande?.statutTransfert==="aucun transfert" && "Aucun transfert"}

					</span>
				</span>
				)}
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,
					}}
				>
					<span style={{ color: 'red' }}>
						+{commande?.courses?.length >= 1 ? commande.courses.length -1 : 0} Etapes{' '}
					</span>
					<span
						style={{ cursor: 'pointer' }}
						onClick={onClickDetails}
					>
						Consulter le détail
					</span>
				</span>
				{user?.role==="client" &&
				<span
				 style={{
					color: '#838EAB',
					marginTop: 10,
				}}>
				Affectation:{' '}
				<span style={{ color: 'red' }}>
				{!commande.transporterID && 'Non Affecté'}
				 {(commande.transporterID && !commande.chauffeurID ) && 'Transporteur Affecté' }
				 {(commande.chauffeurID && commande.transporterID) &&'Chauffeur Affecté'}
				</span>
				</span>
                }
			</div>

			{/*  */}

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						marginBottom: 3,
						color: '#838EAB',
					}}
				>
					Documents :{' '}
					<span style={{ color: 'red' }}>
						{/* {Number(commande?.courses[0]?.files_debut?.length) +
							Number(commande?.courses[0]?.files_fin?.length)} */}
					</span>
					<span style={{ marginLeft: '1rem' }}>
						Référence interne client :{' '}
						<span style={{ color: 'red' }}>
							{commande.ref_client}
						</span>
					</span>
				</span>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '1rem',
					}}
				>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						{commande.type_transport}
						{' : '}
						{commande.type_transport==="Poids lourds" ? commande.vehicule_poids_lourds : commande.type_transport_details} 
					</span>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						{commande.options_vehicule_leger.join(',')}
					</span>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						{commande.options_poids_lourds.join(',')}
					</span>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						Notes utiles:{' '}
						<span style={{ color: 'red' }}>
							{commande?.courses[0]?.notes_debut ||commande?.courses[0]?.notes_fin ? 'Oui' : 'Non'}
						</span>
					</span>
					<span
						style={{
							color: '#838EAB',
						}}
					>
						Nombre de ripper en plus du chauffeur :{' '}
						<span style={{ color: 'red' }}>
							
							{(commande.rippeurs===undefined || commande.rippeurs===null )? '0' : commande.rippeurs}
							
						</span>
					</span>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				{!hideTarif && (<div
					style={{
						background: 'white',

						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Totale:{' '}
							<span className='price' style={{ color: 'red' }}>
							{
                              user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user?.role==='super admin' ?
                              (commande.prix ).toFixed(2) :
                              ((commande.prix)- ((commande.prix+(commande.packages || []).reduce((total, p) => total + p.price, 0)) * (commande.margin ?? 0)) / 100).toFixed(2)
                            }€
							</span>
						</span>
					</span>
				</div>)}
				{!hideTarif && (<div
					style={{
						background: 'white',

						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Initiale:{' '}
							<span className='price' style={{ color: 'red' }}>
								{user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role ==="super admin" ? (commande.prix).toFixed(2) : (commande.prix - (commande.prix * (commande.margin ?? 0)) / 100).toFixed(2)}€
							</span>
						</span>
					</span>
				</div>)}
				
				{!hideTarif && commande.statut==="annuler" && commande.rembourse===true &&(
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
    Montant Remboursé:{' '}
    <span className='price' style={{ color: 'red' }}>
        {((user.role === 'client' || user.role === 'commercial' || user.role === 'admin'|| user.role === 'super admin' ) && commande.statutPrecedent==='init') ? (
            Number(commande?.prixRembourse).toFixed(2)
        ) : (
            ((user.role === 'client' || user.role === 'commercial' || user.role === 'admin' || user.role === 'super admin') && commande.statutPrecedent==='en-cours' ) && (
                Number(commande?.prixRembourse).toFixed(2)
            )
        )}
        €
    </span>
</span>

					</span>
				</div> )}
				
				
                 {!hideStatus &&
				<div
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						onClick={async () => {
							if (enableSaveStatus) {
								try {
									await updateStatutCommandeForChauffeur(
										commande._id,
										'en-cours',
										token
									)
									setStatut('en-cours')	
									setUpdateStatus(true)
								} catch (error) {
									setMessage(error.response.data.error)
									setModalIsOpenStatus(true)
									
								}
								
							}
						}}
						style={{
							padding: '0.3rem 0.7rem',
							background:
								statut === 'en-cours' ? '#C752B6' : '#838EAB',
							color: statut === 'en-cours' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						En cours
					</span>

					<span
						onClick={onClickSignaler}
						style={{
							padding: '0.3rem 0.7rem',
							background: commande?.signaler
								? '#FCC200'
								: '#838EAB',
							color: commande?.signaler ? 'white' : '#9EA8BF',
							cursor: 'pointer',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							width: '4.5rem',
						}}
					>
						Signaler{' '}
						{commande?.signaler && `+${commande.signalement_count}`}
					</span>

					<span
						onClick={async () => {
							if (enableSaveStatus) {
							const commandeData = await getCommande(commande._id,token)
							let aucunCoursLivre;
							aucunCoursLivre = commandeData?.courses.some(course => course?.livrée === false);

							console.log("aucunCoursLivre",aucunCoursLivre)
							if(commandeData?.statut==="init"){
								setModalIsOpenStatusInitTerminer(true)
							}
							else{
								try {
									await updateStatutCommandeForChauffeur(
										commande._id,
										'terminer',
										token
									)
									setStatut('terminer')
									setUpdateStatus(true)
								} catch (error) {
								    setMessage(error.response.data.error)
									setModalIsOpenStatus(true)
								}
							}
					
								
								
								
							}
						}}
						style={{
							padding: '0.3rem 0.7rem',
							background:
								statut === 'terminer' ? '#0FD39E' : '#838EAB',
							color: statut === 'terminer' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						Terminer
					</span>
					{/* <span

						style={{
							padding: '0.3rem 0.7rem',
							background:commande?.statut ==="annuler" ?'red' :'#838EAB',
							
							color:commande?.statut ==="annuler"   ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						Annuler
					</span> */}
					
				</div>
                }
			</div>

			{/*  */}

			<div
				style={{
					color: '#838EAB',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span>
					Diable:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Diable') && 'Oui'}
					</span>
				</span>
				<span>
					Transpalette manuel:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Transpalette manuel') &&
							'Oui'}
					</span>
				</span>
				<span>
					Transpalette électrique:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes(
							'Transpalette électrique'
						) && 'Oui'}
					</span>
				</span>
				<span>
					Sangle:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Sangle') && 'Oui'}
					</span>
				</span>

				<span>
					Casque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Casque') && 'Oui'}
					</span>
				</span>
				<span>
					Gants:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gants') && 'Oui'}
					</span>
				</span>
				<span>
					Masque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Masque') && 'Oui'}
					</span>
				</span>
				<span>
					Lunettes:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Lunettes') && 'Oui'}
					</span>
				</span>
				<span>
					Chaussure de sécurité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Chaussure de sécurité') &&
							'Oui'}
					</span>
				</span>
				<span>
					Gilet de visibilité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gilet de visibilité') && 'Oui'}
					</span>
				</span>
			</div>
			<div
				style={{
					//width: '16%',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					marginTop: 35,
				}}
			>
				{!hideAttribuer && !commande.chauffeurID && (
					<>
						{commande.chauffeurID && (
							<span
								style={{
									color: 'black',
									fontWeight: 'bold',
								}}
							>
								{commande.chauffeurID.first_name}{' '}
								{commande.chauffeurID.last_name}
							</span>
						)}
						
						<button
							onClick={onClickAttribuer}
							style={{
								padding: '0.5rem 1.5rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								marginTop: 5,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Attribuer
						</button>
                        
					</>
				)}
				{!hideAttribuer && commande.chauffeurID &&(
					<>
					{commande.chauffeurID && (
						<span
							style={{
								color: 'black',
								fontWeight: 'bold',
							}}
						>
							{commande.chauffeurID.first_name}{' '}
							{commande.chauffeurID.last_name}
						</span>
					)}

					<button
						onClick={onClickTransferer}
						style={{
							padding: '0.5rem 1.5rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Transférer
					</button>

				</>
				) }
				<button
					onClick={onClickDetails}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir détails
				</button>
				{!hideAttribuer && commande.chauffeurID &&(
					<button
					onClick={onClickHistoriqueChauffeur}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#50F5A9',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir historique chauffeur
				</button>
				)}
				{ hideStatus && !hideSignaler &&
				<button
				onClick={onClickSignaler}
				style={{
					padding: '0.5rem 1.5rem',
					background: '#838EAB',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Signalements
			   </button>
				}
				{!hidefacture &&
				<button
					onClick={onClickFacture}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir Facture
				</button>
                }
				{!hidefacture &&  commande.statut==="annuler" && commande.rembourse===true &&
				<button
					onClick={onClickAvoir}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir Avoir
				</button>
                }
				{!hidecancel &&
				<button
					onClick={onClickCancel}
					style={{
						padding: '0.5rem 1.5rem',
						background:commande?.statut ==="annuler" ? 'red' : '#ff9999',
						border: 'none',
						cursor: 'pointer',
						color: commande?.statut ==="annuler"? 'white' : 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Annuler
				</button>
                }
					{(!hidecancel && !user.premium && commande.prix!==0 &&  commande.paymentStatus==="non payé")&&
				<button
					// onClick={onClickPayer}
					onClick={() => {
						if (commande?.statut === "annuler") {
						  setModalIsOpenPayer(true);
						} else {
						  onClickPayer();
						}
					  }}
					style={{
						padding: '0.5rem 1.5rem',
						background:commande?.statut ==="payé" ? '#66ff66' : '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Payer
				</button>
                }
				{!hideUpdate && 
				<button
				onClick={()=>setModalIsOpenUpdate(true)}
				style={{
					padding: '0.5rem 1.5rem',
					background:'#5bc0de',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Modifier Destinataire
			</button>
				}
				{!hideRecu && (
					<button
						onClick={onClickRecu}
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							background: commande?.recu ? '#FCC200' : '#fce399 ',
							color: 'white',
							border: 'none',
							fontWeight: 'bold',
							padding: '0.5rem 1.5rem',
							borderRadius: 4,
							marginTop: 5,
						}}
					>
						Reçu
					</button>
					
				)}
				{(!hideTransfert && commande.recu  && commande.statut==="en-cours") && (
					<button
					onClick={()=>{
						if(commande.statutTransfert && commande.statutTransfert==="en cours de transfert"){

                            setModalIsOpenUpdateTransfret(true)
						}
						if(commande.statutTransfert && commande.statutTransfert==="aucun transfert"){
							setModalIsOpenTransfret(true)

						}
					}}
					style={{
						cursor: 'pointer',
						fontFamily: 'Montserrat',
						background:  '#51C7F2',
						color: 'white',
						border: 'none',
						fontWeight: 'bold',
						padding: '0.5rem 1.5rem',
						borderRadius: 4,
						marginTop: 5,
					}}
				>
						Demande de transfert 
				</button>
				)}
				{(!hideRecu && !commande?.recu)&& (
					<button
						onClick={onClickRefuser}
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							background:'#f29393',
							color:  'white',
							border: 'none',
							fontWeight: 'bold',
							padding: '0.5rem 1.5rem',
							borderRadius: 4,
							marginTop: 5,
						}}
					>
						Refuser
					</button>
					
				)}

                {!hideAccept && (
					<button
						onClick={onClickAccept}
						disabled={!commande?.canAccept} 
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							background: commande?.canAccept? '#50F5A9':'#838EAB',
							color: commande?.canAccept? 'white' :'#9EA8BF',
							border: 'none',
							fontWeight: 'bold',
							padding: '0.5rem 1.5rem',
							borderRadius: 4,
							marginTop: 5,
						}}
					>
						Accepter
					</button>
				)}
			</div>
			<Modal
    isOpen={modalIsOpenStatusSendSMS}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
Vous ne pouvez pas marquer la commande comme terminée tant que toutes les courses n'ont pas été livrées.		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusSendSMS(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
				isOpen={modalIsOpenUpdate}
				onRequestClose={() => {
					setModalIsOpenUpdate(false)
				
				}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
						paddin: '4rem',
						height:"460px"
					
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'left',
						color: '#838EAB',
					}}
				>
					<div
						style={{
							color: '#51C7F2',
							fontSize: 30,
							marginBottom: '1rem',
							
						}}
					>
						  Modifier les informations du destinataire
					</div>
					
					<form >
					{commande?.courses?.map((course,index)=> (
					<div key={index}>
						<div style={{
						marginBottom: '2rem',
						color:'black',
						fontSize:14,
						marginLeft:-40
					}}>
                    Destination de la course : { index===0 ?course?.adresse_fin?.label : course?.adresse?.label }
					</div>			            					
					<div
					 style={{
						display:"flex",
						flexDirection: 'row',
					 }}>
					<div
					 style={{
							display: 'flex',
							flexDirection: 'column',
							width:'50%',
							marginRight: '1rem',
							}}
					>
					 <span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
							}}
							>
						   Nom					 
					</span>
					<StyledInput
                  type="text"
				  pattern="^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$"
                  value={distributors[index]?.lastName || ''}
                  onChange={handleDistributorChange(index)}
                  name="lastName"
                />

	
				</div>	
					
					<div
					 style={{
							display: 'flex',
							flexDirection: 'column',
							width:'50%',
							marginRight: '1rem',
							}}
					>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
							}}
							>
						   Prénom						 
					</span>
					<StyledInput
                    type="text"
                    value={distributors[index]?.firstName || ''}
                    onChange={handleDistributorChange(index)}
                    name="firstName"	
								
					/>
					
					</div>
					 </div>
					 <div  
					 style={{
						display:"flex",
						flexDirection: 'row',
						
					 }}>
					{isValidPhone[index].validNom &&
                    <p style={{color:'red',width:'50%',marginRight: '1rem',}}>{isValidPhone[index].messageErrorNom}</p>
                    }
					{isValidPhone[index].validePrenom &&
					
                    <p style={{color:'red',width:'50%'}}>{isValidPhone[index].messageErrorPrenom}</p>
                  
					}
					 </div>
					 <div
					 style={{
						display:"flex",
						flexDirection: 'row',
						marginTop: '2rem',
					 }}>
					<div
					 style={{
							display: 'flex',
							flexDirection: 'column',
							width:'50%',
							marginRight: '1rem',
							}}
					>
					 <span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
							}}
							>
							 Numéro de Téléphone 1
					</span>
					<PhoneInput 
					onChange={(newPhoneValue,metadata) => handleDistributorChangePhone(index, newPhoneValue,'phone1',metadata)}
                    value={distributors[index]?.phone1 || ''}
                    
                  
									placeholder=''
									
									inputStyle={{
										paddingRight: '1.5rem',
										paddingTop:'1.5rem',
										paddingBottom:'1.5rem',
										borderRadius: '0.5rem',
										border: 'none',
										fontFamily: 'Montserrat',
										color: '#858ea8',
										flex: '1',
										display: 'flex',
										width:'100%'
									
									}}
									
	
									  buttonStyle={{
										
										borderRadius: '0.5rem',
										border: 'none',
										fontFamily: 'Montserrat',
										color: '#858ea8',
										flex: '1',
										display: 'flex'
									  }}
									  
									/>
					
					
					
					</div>	
					<div
					 style={{
							display: 'flex',
							flexDirection: 'column',
							width:'50%',
							marginRight: '1rem',
							
							}}
					>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
							}}
							>
							 Numéro de Téléphone 2					 
					</span>
					<PhoneInput 
					 value={distributors[index]?.phone2 || ''}
					 onChange={(newPhoneValue,metadata) => handleDistributorChangePhone(index, newPhoneValue,'phone2',metadata)}
					              
									placeholder=''
									inputStyle={{
										paddingRight: '1.5rem',
										paddingTop:'1.5rem',
										paddingBottom:'1.5rem',
										borderRadius: '0.5rem',
										border: 'none',
										fontFamily: 'Montserrat',
										color: '#858ea8',
										flex: '1',
										display: 'flex',
										width:'100%'
									
									}}
									
	
									  buttonStyle={{
										
										borderRadius: '0.5rem',
										border: 'none',
										fontFamily: 'Montserrat',
										color: '#858ea8',
										flex: '1',
										display: 'flex'
									  }}
									
									/>
					
				
				
					</div>
					 </div>
					 <div  
					 style={{
						display:"flex",
						flexDirection: 'row',
						
					 }}>
					{isValidPhone[index].validPhone1 &&
                    <p style={{color:'red',width:'50%',marginRight: '1rem',}}>{isValidPhone[index].messageErrorPhone1}</p>
                    }
					{isValidPhone[index].validPhone2 &&
					
                    <p style={{color:'red',width:'50%'}}>{isValidPhone[index].messageErrorPhone2}</p>
                  
					}
					 </div>
					 
						
						</div>
				    ))}
					<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '1rem',
							}}
						>
							<div
							style={{
								display: 'flex',
						        flexDirection: 'row',
								
							}}
						>
						
							<button
								type='button'
								disabled={updateLoading}
								onClick={()=>handleAjouterClick()}
								style={{
									background: '#50F5A9',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									fontFamily: 'Montserrat',
									padding: '1rem 2rem',
									borderRadius: 6,
								}}
							>
								{ updateLoading ? (
									<div className='loader'></div>
								) : (
									'Modifier'
								)}
								
							</button>
							<button
								type='button'
								onClick={()=>setModalIsOpenUpdate(false)}
								style={{
									background: 'red',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									marginLeft:'1rem',
									fontFamily: 'Montserrat',
									padding: '1rem 2rem',
									borderRadius: 6,
								}}
							>
								Annuler
							</button>
						</div>
						</div>
					</form>
					
					

					
				
				</div>
			</Modal>
			<AjouterDemandeTransfert 
			 modalIsOpenTransfret={modalIsOpenTransfret}
			 setModalIsOpenTransfret={setModalIsOpenTransfret}
			 commande={commande}/>
			 	{modalIsOpenUpdateTransfret &&
			<ModiferDemandeTransfert
		    modalIsOpenUpdateTransfret={modalIsOpenUpdateTransfret}
			setModalIsOpenUpdateTransfret={setModalIsOpenUpdateTransfret}
			commande={commande}
			/>
}
		</div>
	)
}

export default React.memo(CourseACourseHorizontal)

// MissionCard.js
import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';

const MissionCard = ({
  mission,
  index,
  handleSetPlaces,
  setSelectedMission,
  setModalIsOpen,
  setModalIsOpenTransfret,
  setModalIsOpenForfait,
  setForfaits,
  setMarge
}) => {
  const history = useHistory();
  
  return (
    <div
      style={{
        width: '100%',
        fontFamily: 'Montserrat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '1rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
      }}
    >
      <div
        style={{
          width: '100%',
          background: '#E4EAF0',
          display: 'flex',
          flexDirection: 'row',
          padding: '1rem',
          borderRadius: 10,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 13,
            height: '100%',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              color: '#51C7F2',
              marginBottom: 3,
            }}
          >
            Mission N° {index + 1} pour la commande {!!mission ? 'N°' + mission?.commande?.__id : ''}
          </span>
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Adresse de début :{' '}
            <span style={{ color: 'red' }}>{mission?.adresse_debut?.label}</span>
          </span>
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Date de début :{' '}
            <span style={{ color: 'red' }}>
              {mission?.date_debut} {mission?.heure_debut}
            </span>
          </span>
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Adresse de fin :{' '}
            <span style={{ color: 'red' }}>{mission?.adresse_fin?.label}</span>
          </span>
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Date de fin :{' '}
            <span style={{ color: 'red' }}>
              {mission?.date_fin} {mission?.heure_fin}
            </span>
          </span>
          {mission?.date_debut_reel &&
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Date de début réelle :{' '}
            <span style={{ color: 'red' }}>
              {moment(mission?.date_debut_reel).format('DD/MM/YYYY HH:mm') || 'Non spécifiée'}
            </span>
          </span>
          }
          {mission?.date_fin_reel && 
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Date de fin réelle :{' '}
            <span style={{ color: 'red' }}>
              {moment(mission?.date_fin_reel).format('DD/MM/YYYY HH:mm') || 'Non spécifiée'} 
            </span>
          </span>
          }
        </div>
        <div style={{ color: '#838EAB', display: 'flex', flexDirection: 'column', fontSize: 13 }}>
           <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Statut de la mission :{' '}
            <span style={{ color: 'red' }}>
            <span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {mission?.statut==="init" && "Non commencée"}
             {mission?.statut==="en-cours" && "En cours"}
             {mission?.statut==="terminer" && "Terminée"}
             {mission?.statut==="annuler" && "Annulée"}
			</span>
            </span>
          </span>
          <span style={{ color: '#838EAB', marginBottom: '1rem' }}>
            Nombre de kilomètrage :{' '}
            <span style={{ color: 'red' }}>{mission.kilometrage}</span>
          </span>
          <span style={{ color: '#838EAB' }}>
            Nombre de ripper en plus du chauffeur :{' '}
            <span style={{ color: 'red' }}>
              {mission.nombre_rippeur === null || mission.nombre_rippeur === undefined ? '0' : mission.nombre_rippeur}
            </span>
          </span>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
            <span style={{ color: '#838EAB', marginBottom: '.5rem' }}>
              {mission.type_transport} : {mission.type_transport === 'Poids lourds' ? mission.vehicule_poids_lourds : mission.type_transport_details}
            </span>
            <span style={{ color: '#838EAB', marginBottom: '.5rem' }}>
              {mission.type_transport === 'Poids lourds' ? mission.options_poids_lourds.join(',') : mission.options_vehicule_leger.join(',')}
            </span>
          </div>
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Matières dangereuses :{' '}
            <span style={{ color: 'red' }}>{mission.danger === 'Oui' ? 'Oui' : 'Non'}</span>
          </span>
        </div>
        <div style={{ color: '#838EAB', display: 'flex', flexDirection: 'column', fontSize: 13 }}>
          {mission.chauffeurID && (
            <span style={{ color: 'black', fontWeight: 'bold', marginBottom: '.5rem' }}>
              {mission.chauffeurID.first_name} {mission.chauffeurID.last_name}
            </span>
          )}
        {((mission.statut === 'init' || mission.statut === 'en-cours')) && (
            !mission.chauffeurID ? (
            <button
              onClick={() => {
                setSelectedMission(mission._id);
                setModalIsOpen(true);
              }}
              style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginRight: 10,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                width: '150px',
                marginBottom: '.5rem',
              }}
            >
              Attribuer
            </button>
            ):(
            <button
          onClick={() => {
            setSelectedMission(mission._id);
            setModalIsOpenTransfret(true);
          }}
          style={{
            padding: '0.5rem 1rem',
            background: '#51C7F2',
            border: 'none',
            cursor: 'pointer',
            color: 'white',
            borderRadius: 7,
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            width: '150px',
          }}
        >
          Transférer
            </button> ))}
           { mission.statut==='en-cours' &&
            <>
             
              <button
                onClick={() => {
                  history.push(`/transports/mission/historique-chauffeur/${mission._id}`);
                }}
                style={{
                  padding: '0.5rem 1rem',
                  background: '#50F5A9',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: 7,
                  marginRight: 10,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  width: '150px',
                  marginBottom: '.5rem',
                  marginTop: '.5rem',
                }}
              >
                Historique Chauffeur
              </button>
              <button
                onClick={() => {
                  handleSetPlaces(mission);
                }}
                style={{
                  padding: '0.5rem 1rem',
                  background: '#50F5A9',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: 7,
                  marginRight: 10,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  width: '150px',
                  marginBottom: '.5rem',
                }}
              >
                Points passage
              </button>
              <button
                onClick={() => {
                  setForfaits(mission?.forfaits);
                  setMarge(mission?.commande?.margin);
                  setModalIsOpenForfait(true);
                }}
                style={{
                  padding: '0.5rem 1rem',
                  background: '#51C7F2',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: 7,
                  marginRight: 10,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  width: '150px',
                  marginBottom: '.5rem',
                }}
              >
                Forfaits supplémentaires
              </button>
            </>
            }
         
        </div>
      </div>
    </div>
  );
};

export default MissionCard;

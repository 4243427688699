import React ,{useState,useEffect} from 'react'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { GradientText} from '../../PasserCommande/PasserCommande.styles'
import {getAllAdministrators,getAllAdministratorsbyIdAndName,getNotValidAdministrators,getValidAdministrators} from '../../../api/administrateurs'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useHistory } from 'react-router'
function MesAdministrateur () {
  const [loading, setLoading] = useState(false)
  const [chargementEnCours,setChargementEnCours]=useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = useState([])
  const [filterStatus, setFilterStatus] = useState('all');
  const history=useHistory()

  const fetchAdministrateur = async (page) => {
	    setChargementEnCours(true)
		let res;
		if (filterStatus === 'all') {
			 res = await getAllAdministrators(page, perPage,token)

		 }
		 if (filterStatus === 'validated') {
			res=await getValidAdministrators(page,perPage,token)
		 }
		 if (filterStatus === 'notValidated') {
			res=await getNotValidAdministrators(page,perPage,token)
		 }	
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)
	}
	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchAdministrateur(page)
	}
	const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getAllAdministrators(page, perPage,token)
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}

  useEffect(() => {
		fetchAdministrateur(currentPage)
	}, [])
	useEffect(() => {
		fetchAdministrateur(currentPage)
	}, [filterStatus])
	const searchForAministrateur = async (q) => {
		if (q.length === 0) {
			return
		}
		setLoading(true)
		const res = await getAllAdministratorsbyIdAndName(q,token,filterStatus)
		setData(res.docs)
		setLoading(false)
	}
	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Nom du administrateur',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:'200px'

		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:'200px'
		},
		{
			name: "Rôle",
			selector: (row) => `${row.role} ` ,
			sortable: false,
			width:'200px'
		},
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:'200px'
		},
		{
			name: "Date de suspension",
			selector: (row) => (row?.suspendre ? moment(row?.dateSuspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
			width: "200px"
		},
		{
			name: 'Statut',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
		},
	
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/admin/administrateurs/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>
				</div>
			),
		},
	]
  return (
    <LayoutAdmin>
      <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
     <GradientText marginTop={'0'}>
					{'Mes administrateurs'.toUpperCase()}
	</GradientText>
      <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
					disabled={loading}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							searchForAministrateur(searchQuery)
						}
					}}
						placeholder='Rechercher par identifiant,nom administrateur '
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '25rem',
							fontFamily: 'Montserrat',
						}}
					/>
                     <select
                    value={filterStatus}
                    onChange={(e) => {
                        setFilterStatus(e.target.value);
                    }}
                    style={{
						padding: '1rem',
						borderRadius: 5,
						border: '1px solid #E4EAF0',
						width: '15rem',
						fontFamily: 'Montserrat',
						marginLeft:'1rem'
					}}
                    >
                     <option value="all">Tous</option>
                     <option value="validated">Vérifiés</option>
                     <option value="notValidated">Non vérifiés</option>
                    </select>
					<button
						disabled={loading}
						onClick={() => searchForAministrateur(searchQuery)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={async () => {
							await fetchAdministrateur()
							setSearchQuery('')
							setFilterStatus('all')

						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
	  </div>
	  <div
	  style={{
	  width: '100%',
	  padding: '0 1rem',
	 }}>
		<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des administrateurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
						
		/>
	 </div>
      </div>
     </LayoutAdmin>
  )
}

export default MesAdministrateur 
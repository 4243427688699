import React, { useEffect, useState } from 'react';
import { getSignalementForAdresse, softDeleteSignalement } from '../../../api/signalements';
import {getPointsPassageById} from '../../../api/commande'
import { useHistory, useParams } from 'react-router-dom';
import { GradientText } from '../../PasserCommande/PasserCommande.styles';
import LayoutChauffeur from '../../../Components/LayoutChauffeur/LayoutChauffeur';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import DataTable from 'react-data-table-component';

function SignalerChauffeurAllAdresse() {

    const { placeID,commandeID } = useParams();
    const history = useHistory();
    const token = useSelector((state) => state.auth.token);
    const [signalements, setSignalements] = useState([]);
    const [signalement, setSignalement] = useState(null);
	const [place,setPlace]=useState(null)
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
    
	// Fonction pour récupérer les données de signalement
    const fetchData = async (page) => {
        setChargementEnCours(true);
        try {
			const [signalementsRes,placeRes]=await Promise.all([
				 getSignalementForAdresse(placeID, token, page, perPage),
                 getPointsPassageById(placeID,token)
			])
			setSignalements(signalementsRes.docs);
			setTotalRows(signalementsRes.totalDocs);
			setPlace(placeRes)
			
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false);

		}
    };

	// Fonction appelée lors du changement de page
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page);
    };
    
	 // Fonction appelée lors du changement du nombre de lignes par page
    const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true);
		try {
			const signalementsRes = await getSignalementForAdresse(placeID, token, page, newPerPage);
			setSignalements(signalementsRes.docs);
			setTotalRows(signalementsRes.totalDocs);
			setPerPage(newPerPage); 
		} catch (error) {
			toast.error("Une erreur s'est produite lors de la modification du nombre de lignes par page", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
    };

	// Effet pour charger les données au montage du composant et lorsqu'il y a un changement de placeID
    useEffect(() => {
        fetchData(currentPage);
    }, [placeID]);

    const columns = [
        {
            name: "Type d'anomalie",
            selector: (row) => row.type,
        },
        
        {
            name: 'Note',
            selector: (row) => row.note,
        },
        {
            name: 'Actions',
            button: true,
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/chauffeur/signalement-details/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
					
					
				<div
										onClick={async () => {
											history.push(
												`/chauffeur/transports/${commandeID}/signaler/${row._id}/edit`
											)
										}}
										style={{
											cursor: 'pointer',
										}}
									>
										<div
											style={{
												height: 24,
												width: 24,
												color: '#51C7F2',
											}}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 20 20'
												fill='currentColor'
											>
												<path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z' />
												<path
													fillRule='evenodd'
													d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z'
													clipRule='evenodd'
												/>
											</svg>
										</div>
									</div>
                    <div
										onClick={() => {
											setSignalement(row._id)
											setModalIsOpenDelete(true)	
										}}
										style={{
											cursor: 'pointer',
										}}
									>
										<div
											style={{
												height: 24,
												width: 24,
												color: 'red',
											}}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-6 w-6'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth={2}
													d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
												/>
											</svg>
										</div>
					</div>
                </div>
            ),
        },
    ];

    return (
        <LayoutChauffeur>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '2rem', paddingBottom: '4rem', fontFamily: 'Montserrat' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                        <div onClick={() => history.goBack()} style={{ background: '#51C7F2', width: '3rem', height: '3rem', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width={14.017} height={14.11} viewBox='0 0 14.017 14.11'>
                                <g fill='none' stroke='#fff' strokeWidth={2}>
                                    <path d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232' transform='translate(-8.838 -11.113)' />
                                    <path d='M22.147 11.818l-6.348 6.349 6.348 6.348' transform='translate(-8.838 -11.113)' />
                                </g>
                            </svg>
                        </div>
                        <div style={{ width: '1rem' }} />
                        <GradientText marginTop='unset'>
                            { 'signalement'.toUpperCase() }
                        </GradientText>
                        <div style={{ width: '1rem' }} />
                        <div onClick={() =>{
                         if(place?.nom_destinataire){
							history.push(`/chauffeur/missions/${placeID}/${commandeID}/signaler/new`)
						 }else{
							toast.error("Veuillez introduire les informations du destinataire avant d'ajouter un signalement", {
								style: {
									fontFamily: 'Montserrat',
								},
							});
						 }
						}} 
						style={{ background: '#51C7F2', padding: '1rem 2rem', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <span style={{ color: 'white', fontSize: '1rem', fontWeight: 'bold' }}>Ajouter</span>
                        </div>
                    </div>
                    {chargementEnCours ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div className='customLoader'></div>
                        </div> :
                        <div style={{ width: '100%', padding: '0 1rem', marginTop: '4rem' }}>
                            <DataTable
                                columns={columns}
                                data={signalements}
                                progressPending={chargementEnCours}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                noDataComponent="Il n'y a pas des signalements à afficher"
                                progressComponent='Chargement'
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rangs par page',
                                    rangeSeparatorText: 'sur',
                                }}
                            />
                        </div>
                    }
                </div>  
               
               
           </div>
           <Modal
				isOpen={modalIsOpenDelete}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Confirmer la suppression'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Signalement</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce signalement ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								await softDeleteSignalement(signalement,token)
								toast.success("Le signalement a été supprimé avec succès.", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								fetchData(currentPage)
							   } catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								});
							   }finally {
								setLoading(false)
								setModalIsOpenDelete(false)

							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
        </LayoutChauffeur>
    )
}
export default SignalerChauffeurAllAdresse;     
import api  from './base'

export const updateMarge = async (newMarge,token) => {
	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/json',
		},
	}

	const res = await api.patch('configurations/marge', { marge: newMarge }, config)

	return res.data
}
export const  getMarge=async (token)=>{
    const res = await api.get('/configurations/marge',{
		headers: {
			  Authorization: `Bearer `+token
			},
   })
   return res.data
}
import api from './base'

export const getStats = async (token) => {
	const res = await api.get(`/stats`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getStatsForSingleClient = async (id,token) => {
	const res = await api.get(`/stats/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getStatsForAllSignalementsByCommandeID = async (id,token) => {
	const res = await api.get(`/stats/commande/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

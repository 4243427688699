import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { useHistory,useParams } from 'react-router'
import {getComptabiliteCommercial,getComptabiliteCommercialByMonth,getComptabiliteCommercialByMonthAndName,getComptabiliteCommercialByName} from '../../../api/commerciaux'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
Modal.setAppElement('#root')

function ComptabiliteCommercial(props) {
	const history = useHistory()
	const [selectedDate, setSelectedDate] = useState(null);
	const  {id}=useParams()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const token = useSelector((state) => state.auth.token);
	const [currentPage, setCurrentPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
	const [totalPrix, setTotalPrix] = useState(0)
	const [chargementEnCours, setChargementEnCours] = useState(false);

	const fetchClients = async (page) => {
		setChargementEnCours(true)
		const res = await getComptabiliteCommercial(id,page, perPage,token)
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setTotalPrix(res.totalPrix)
		setChargementEnCours(false)
	}
	const searchForClient = async (q) => {
		if (q.length === 0) {
			return
		}

		setLoading(true)
		
        if(selectedDate){
			const [year, month] = selectedDate.split('-'); 
		    const res=await  getComptabiliteCommercialByMonthAndName(id,currentPage, perPage,token,month,year,q)
		    setData(res.docs)
			setTotalRows(res.nbHits)
		}else {
			const res = await getComptabiliteCommercialByName(id,q,token)
			setData(res.docs)
			setTotalRows(res.nbHits)
		}
		setLoading(false)

	}
	
	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchClients(page)
	}
	const handleDateChange = async(e) => {
		const value = e.target.value; 
		setSelectedDate(value)
		const [year, month] = value.split('-'); 
		try {
		setChargementEnCours(true)
        if(year && month ){
		const res=await getComptabiliteCommercialByMonth(id,currentPage, perPage,token,month,year)
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setTotalPrix(res.totalPrix)
		}else{
			
			await fetchClients(currentPage)
		}
		} catch (error) {
			console.log(error)

		}finally{
			setChargementEnCours(false)
		}
		
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getComptabiliteCommercial(id,page, newPerPage,token)
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}

	useEffect(() => {
		fetchClients(1)
		
	}, [])
	
   
	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:'200px'
		},
		{
			name: 'Nom du client',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:"200px"
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:'250px'
		},
		{
			name: 'Montant total',
			selector: (row) => `${(row.totalSpent+row.totalPackagePrice+row.totalSignalement)?.toFixed(2)}€`,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Type de compte',
			selector: (row) => (row.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width:'150px'
		},
		{
			name: '% de réduction',
			selector: (row) => (row.reduction ? `${row.reduction}%` : '0%'),
			sortable: false,
			width:'150px'
		},
		{
			name: 'Status',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
			width:'150px'
		},
		
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:'150px'
		},
		
		
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {

							history.push(`/admin/comptabilite/client/${row._id}`)
							
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>

					
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Comptabilité commercial'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '4rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<input
						disabled={loading}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
							
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								searchForClient(searchQuery)
							}
							
						}}
							style={{
								padding: '1rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							placeholder="Rechercher par l'identifiant, nom"
						/>
					    <input 
                        type="month"
	                    value={selectedDate || ''}
	                    onChange={handleDateChange}
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '15rem',
							fontFamily: 'Montserrat',
							marginLeft:'1rem'
						}}
						placeholder="Sélectionnez un mois et une année"
                        />
					<button
						onClick={() => searchForClient(searchQuery)}
							style={{
								padding: '1rem 2rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								//marginTop: 20,
								marginLeft: 20,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Rechercher
						</button>
						<button
						onClick={async () => {
							await fetchClients(currentPage)
							setSearchQuery('')
							setSelectedDate('')
						}}
						
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>


						
					</div>
					
					<div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{totalPrix?.toFixed(2)}€</span>
					</div>
				</div>
				{chargementEnCours ?
				 <div
				 style={{
				   display: 'flex',
				   alignItems: 'center',
				   justifyContent: 'center',
				   backgroundColor: 'white',
				   marginTop:"10rem"
				 }}
				 >
				 <div className='customLoader'></div>	
				 </div>
				 :
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des clients à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
                } 
			</div>
		</LayoutAdmin>
	)
}

export default ComptabiliteCommercial

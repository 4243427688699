import React, { useEffect, useState } from 'react'
import {
	getChauffeur,
} from '../../api/chauffeurs'
import {useParams,useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { CustomInput } from '../RegisterTransporter/style'

import toast from 'react-hot-toast'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import {
	FormSectionCard,
	GradientText,
} from '../PasserCommande/PasserCommande.styles'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'

function DetailsChauffeur() {
    const history=useHistory()
	const { id } = useParams()
    const [chargementEnCours,setChargementEnCours]=useState(false)
	const [chauffeur, setChauffeur] = useState(null)
	const token = useSelector((state) => state.auth.token);


    async function _getChauffeur(id) {
		setChargementEnCours(true)
		try {
		const chauffeur = await getChauffeur(id,token)
		setChauffeur(chauffeur)
		}catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
		
	}



	useEffect(() => {
		_getChauffeur(id)	
	}, [id])

  return (
    <LayoutTransporter>
        <div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
           <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Détails du chauffeur'.toUpperCase()}
						</GradientText>
					</div>
            {chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:"10rem"
				}}
				>
				<div className='customLoader'></div>	
				</div>
				:
                <div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
                <FormSectionCard>
                <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Sexe
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '30%',
										}}
									>
										<input
                                            name="sexe"
											type='radio'
                                            checked={chauffeur?.sexe === 'homme'}
											disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											value={'homme'}
										/>

										<span>Homme</span>
									</div>

									<div>
										<input
                                            name="sexe"
                                            checked={chauffeur?.sexe === 'femme'}
											type='radio'
											disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											value={'femme'}
										/>

										<span>Femme</span>
									</div>
								</div>
			   </div>
               <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Permis
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Léger', 'Lourds', 'Super lourds'].map(
										(item, index) => (
											<div
												key={String(index)}
												style={{
													width: '20%',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}

                                                    checked={chauffeur?.permis?.includes(item)}
                                                    value={item}
                                                    disabled
												/>

												<span>{item}</span>
											</div>
										)
									)}
								</div>
				</div>
                <div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Doe'
                                        value={chauffeur?.last_name ||  ''}
									/>
									
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Doe'
                                        value={chauffeur?.first_name || ""}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
                                        value={chauffeur?.address || ""}
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									disabled
                                    value={chauffeur?.city ||''}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									disabled
									value={chauffeur?.postalCode || ''}
								/>
								
							</div>
                            <div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='(0) 01 45 66 32 88'
                                        value={chauffeur?.phone || ''}
									/>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='John.doe@wayne.fr'
                                        value={chauffeur?.email || ""}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date de naissance
									</span>
											<Flatpickr
											    disabled
												
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={chauffeur?.date_naiss || ''}
											/>
											
										
									
                                            
									<div >
									</div>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date d’embauche
									</span>
									
                                    
											<Flatpickr
											    disabled
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={chauffeur?.date_embauche || ""}
											/>
									
								<div/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Fimo
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
                                                disabled
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
                                                checked={chauffeur?.fimo === 'Oui'}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
                                                disabled
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
                                                checked={chauffeur?.fimo === 'Non'}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Matière dangereuse
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
                                                disabled
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}

                                                checked={chauffeur?.danger === 'Oui'}
												value={item}/>

											<span>{item}</span>
										</div>
									))}
										{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
                                                disabled
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
                                                checked={chauffeur?.danger === 'Non'}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								
							</div>
                </FormSectionCard>

                </div>
            }
            </div>
    </LayoutTransporter>

  )
}

export default DetailsChauffeur
import { Controller, useForm } from 'react-hook-form'
import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	StyledTextArea,
	SubmitButton,
	SubmitButtonContainer,
	Select
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'

import LayoutChauffeur from '../../../Components/LayoutChauffeur/LayoutChauffeur'
import Modal from 'react-modal'
import TimeField from 'react-simple-timefield'
import UploadMultipleFiles from '../../../Components/UploadMultipleFiles/UploadMultipleFiles'
import { createSignalement } from '../../../api/signalements'
import { getCommande } from '../../../api/commande'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

function NewSignalement(props) {
	const { id } = useParams()
	const history = useHistory()

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const { register, handleSubmit, watch, setValue, control,formState: { errors}} = useForm()
	const [commande, setCommande] = useState()
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user)
	const file = useRef()
	file.current = watch('file', null)

	const type = useRef()
	type.current = watch('type', '')

	const $getCommande = async (id) => {
		setChargementEnCours(true)
		try {
			const commande = await getCommande(id,token)
			console.log("commande",commande?.courses[0]?.adresse_debut)
			setCommande(commande)
		} catch (error) {
			console.log(error)
		}finally{
			setChargementEnCours(false)

		}
	}

	useEffect(() => {
		$getCommande(id)
	}, [id])

	const onSubmit = async (values) => {
		console.log("onSubmit a été appelée.");
		values.commande = id
		values.chauffeur=user._id

		setLoading(true)
		try {
			await createSignalement(values,token)
			setModalIsOpen(true)
		} catch (error) {
			console.log(error)
		}finally{
			setLoading(false)
		}
	}

	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<GradientText marginTop={'unset'}>
							{'ajouter signalement'.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
						<div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  marginTop:"4rem",
						  top: 0,
						  left: 0,
						  right: 0,
						  bottom: 0,
			   
						}}
						>
						<div className='customLoader'></div>	
						</div> 
						: 
						<form onSubmit={handleSubmit(onSubmit)}>
						{commande &&
							commande.type_commande === 'course-a-course' && (
								<FormContentSection>
									<FormSectionTitle></FormSectionTitle>
									<FormSectionCard>
									<div 
									style={{
										display: 'flex',
                                        flexDirection: 'column',
                                         marginBottom: '2rem'}}>
									<span
									    style={{
											marginBottom: '1rem',
                                          color: 'black',
                                          fontSize: '14px',
                                          display: 'block',
                                          textAlign: 'center',
                                         fontWeight: 'bold',
										}}
										
									>
										Sélectionnez le type d'anomalie <span style={{ color: 'red' }}>*</span>
									</span>
										<div
											// className='radio-btn-group-palettes'
											
											 style={{ display: 'flex',
                                             flexDirection: 'column',
                                            alignItems: 'center'}}
										>
											<div
												style={{ display: 'flex',
                                                         alignItems: 'center',
                                                         marginBottom: '1rem',
                                                         marginLeft:'10px',
														 justifyContent: 'center'
	                                                  }}
											>
												<input
													id='chargement'
													type='radio'
													value='chargement'
													{...register('type',{required:true}) }
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='chargement'>
													Anomalie heure chargement
												</label>
											</div>

											<div
											style={{ display: 'flex',
											alignItems: 'center',
											marginBottom: '1rem'
										 }}	
												
											>
												<input
													id='livraison'
													type='radio'
													value='livraison'
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='livraison'>
													Anomalie heure de livraison
												</label>
											</div>
											<div
											style={{ display: 'flex',
											alignItems: 'center',
											marginBottom: '1rem',
											marginLeft:-90
										 }}	
												
											>
												<input
													id='course'
													type='radio'
													value='course'
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='course'>
													Anomalie course 
												</label>
											</div>
											<div
												style={{ display: 'flex',
												alignItems: 'center',
												marginBottom: '1rem',
												justifyContent: 'center',
												marginLeft:-110
											 }}
											>
												<input
													id='commentaire'
													type='radio'
													value='commentaire'
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='commentaire'>
												Commentaire
												</label>
											</div>
											{errors.type &&
											<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez sélectionner le  type d'anomalie</p>}
										</div>


										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											{type.current && type.current !=='commentaire' && type.current !=='course' && (
												<div>
													<p>
														<b>
															Anomalie heure{' '}
															{type.current ===
															'chargement'
																? 'chargement'
																: 'de livraison'}
														</b>
													</p>
													<p>
														Saisissez le temps du
														retard en minutes et en
														heures  <span style={{ color: 'red' }}>*</span>
													</p>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'center',
														}}
													>
														<Controller
															name='duration'
															rules={{
																required: true,
															}}
															control={control}
															render={({
																field: {
																	onChange,
																	value,
																},
															}) => (
																<TimeField
																	value={
																		value
																	}
																	onChange={(
																		event,
																		value
																	) => {
																		onChange(
																			value
																		)
																	}}
																	input={
																		<input
																			type='text'
																			className='date-input'
																			onFocus={(
																				e
																			) => {
																				e.target.select()
																			}}
																		/>
																	}
																	colon=':'
																/>
															)}
														/>
													</div>
													{errors.duration && 
									                    <p style={{color:'red',marginTop:'0,5rem'}}>Veuillez saisir le temps du retard</p>}
												</div>
											)}
											{type.current && type.current==="course" && 
											    <div>
													<p>
														<b>
															Anomalie course
														</b>
													</p>
													<p>
													 Sélectionnez la course concernée par cette anomalie  <span style={{ color: 'red' }}>*</span>
													</p>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'center',
														}}
													>
														<Controller
															name='course'
															rules={{
																required: true,
															}}
															control={control}
															render={({
																field: {
																	onChange,
																	value,
																},
															}) => (
														<Select
														value={value} 
														onChange={(e) => onChange(e.target.value)}
														>
														<option></option>
														 {/* Première option */}
                                                         <option value={commande?.courses[0]?._id}>
														 {`Course ${1}: ${commande?.courses[0]?.adresse_debut.label} --> ${commande?.courses[0]?.adresse_fin.label}`}

                                                         </option>

														 {commande?.courses.length === 2 && (
                                                         <option value={commande?.courses[1]?._id}>
                                                         {`Course ${2}: ${commande?.courses[0]?.adresse_fin.label} --> ${commande?.courses[1]?.adresse.label}`}

                                                         </option>
                                                         )}
														{commande?.courses.length>2 &&
														<>
														<option value={commande?.courses[1]?._id}>
														{`Course ${2}: ${commande?.courses[0]?.adresse_fin.label} --> ${commande?.courses[1]?.adresse.label}`}
                                                        </option>
														
														{commande?.courses.map((course, index) => (
															index > 1 && (
																<option key={course._id} value={course._id}>
																	{`Course ${index+1}: ${commande?.courses[index - 1]?.adresse.label} --> ${course.adresse.label}`}
																</option>
															)
														))}
														</>
                                                         }


														</Select>
														)}
														/>
													

													</div>
													{errors.course && 
									                    <p style={{color:'red',marginTop:'0,5rem'}}>Veuillez sélectionner  une  course</p>}
												</div>
											}
										</div>
										
										</div>
										
									</FormSectionCard>


								</FormContentSection>
							)}
						{commande && 
						commande.type_commande === 'mise-a-disposition' && (
							<FormContentSection>
									<FormSectionTitle></FormSectionTitle>
									<FormSectionCard>
									<div 
									style={{
										display: 'flex',
                                        flexDirection: 'column',
                                         marginBottom: '2rem'}}>
											<span
									    style={{
											marginBottom: '1rem',
                                          color: 'black',
                                          fontSize: '14px',
                                          display: 'block',
                                          textAlign: 'center',
                                         fontWeight: 'bold',
										}}
										
									>
										Sélectionnez le type d'anomalie <span style={{ color: 'red' }}>*</span>
									</span>
										<div
												 style={{ display: 'flex',
												 flexDirection: 'column',
												alignItems: 'center'}}
										>
											
											<div
												style={{ display: 'flex',
												alignItems: 'center',
												marginBottom: '1rem',
												marginLeft:'10px',
												justifyContent: 'center'
											 }}
											>
												<input
													id='livraison'
													type='radio'
													value='livraison'
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='livraison'>
													Anomalie heure de livraison
												</label>
											</div>
											<div
											style={{ display: 'flex',
											alignItems: 'center',
											marginBottom: '1rem',
											marginLeft:-80
										 }}	
												
											>
												<input
													id='course'
													type='radio'
													value='course'
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='course'>
													Anomalie course 
												</label>
											</div>
											<div
												style={{ display: 'flex',
												alignItems: 'center',
												marginBottom: '1rem',
												justifyContent: 'center',
												marginLeft:-100
											 }}
											>
												<input
													id='commentaire'
													type='radio'
													value='commentaire'
													{...register('type',{required:true})}
													style={{marginRight: '1rem',}}
												/>

												<label htmlFor='commentaire'>
												Commentaire
												</label>
											</div>
											{errors.type &&
											<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez sélectionner le  type d'anomalie</p>}
										</div>

										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											{type.current && type.current!=='commentaire' && type.current!=='course' && (
												<div>
													<p>
														<b>
															Anomalie heure{' '}
															{type.current ===
															'chargement'
																? 'chargement'
																: 'de livraison'}
														</b>
													</p>
													<p>
													Saisissez la durée supplémentaire en minutes et en heures <span style={{ color: 'red' }}>*</span>
													</p>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'center',
														}}
													>
														<Controller
															name='duration'
															rules={{
																required: true,
															}}
															control={control}
															render={({
																field: {
																	onChange,
																	value,
																},
															}) => (
																<TimeField
																	value={
																		value
																	}
																	onChange={(
																		event,
																		value
																	) => {
																		onChange(
																			value
																		)
																	}}
																	input={
																		<input
																			type='text'
																			className='date-input'
																			onFocus={(
																				e
																			) => {
																				e.target.select()
																			}}
																		/>
																	}
																	colon=':'
																/>
															)}
														/>

													</div>
													{errors.duration && 
									                    <p style={{color:'red',marginTop:'0,5rem'}}>Veuillez saisir la durée supplémentaire</p>}
												</div>
											)}
										</div>
										</div>
									</FormSectionCard>
								</FormContentSection>
						)}	

						<FormContentSection>
							<FormSectionTitle></FormSectionTitle>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2.5rem',
										width: '100%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
											fontWeight: 'bold',
										}}
									>
										Note <span style={{ color: 'red' }}>*</span>
									</span>
									<StyledTextArea
										type='text'
										rows={4}
										placeholder=''
										{...register('note', {
											required: true,
										})}
									/>
									{errors.note && 
									<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez saisir une  note</p>}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
										// marginLeft: 10,
										// marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Joindre images ou PDF
										</span>
									</div>
									<UploadMultipleFiles
										name='files'
										onChange={(files) =>
											setValue(
												'files',
												files.map((f) => f.id)
											)
										}
										id={commande?._id}

									/>

								</div>
							</FormSectionCard>
						</FormContentSection>

						<div style={{ height: '1rem' }} />
                        {console.log('errroe',errors)}
						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
							{loading ? (
									<div className='loader'></div>
								) : (
									'Ajouter'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					   </form>
						}
				
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				//onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
					},
				}}
				contentLabel='Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontSize: '25px',
							fontWeight: 'bold',
						}}
					>
						Enregistrement
					</span>

					<div
						style={{
							marginTop: 20,
						}}
					>
						Vos données ont été enregistrés avec succès.
					</div>

					<button
						onClick={() => {
							setModalIsOpen(false)
							history.goBack()
						}}
						style={{
							padding: '1rem 3rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</LayoutChauffeur>
	)
}

export default NewSignalement

import React ,{useState,useEffect} from 'react'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import {getValidCommerciaux,getCommercantbyIdAndName,getComptabiliteCommercial} from '../../../api/commerciaux'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { useHistory } from 'react-router'

function CommerciauxComptabilite() {
    const [loading, setLoading] = useState(false)
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const token = useSelector((state) => state.auth.token);
    const [data, setData] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const history=useHistory()
    const fetchCommercant = async (page) => {
          setChargementEnCours(true)
          const res = await getValidCommerciaux(page, perPage,token)
          const data = await Promise.all(res.docs.map(async (item) => {
            const resulta = await getComptabiliteCommercial(item._id, page, perPage, token);
            return { ...item, totaleprix: resulta.totalPrix };
          }));
          setData(data)
          setTotalRows(res.totalDocs)
          setChargementEnCours(false)
      }
      const handlePerRowsChange = async (newPerPage, page) => {
          setChargementEnCours(true)
          const res = await getValidCommerciaux(page, newPerPage,token)
          setData(res.docs)
          setTotalRows(res.totalDocs)
          setPerPage(newPerPage)
          setChargementEnCours(false)
  
      }
      const handlePageChange = (page) => {
          setCurrentPage(page)
          fetchCommercant(page)
      }
      
    useEffect(() => {
      fetchCommercant(currentPage)
      }, [])
      const searchForCommercant = async (q) => {
          if (q.length === 0) {
              return
          }
          setLoading(true)
          const res = await getCommercantbyIdAndName(q,token,"validated")
          setData(res.docs)
          setTotalRows(res.totalDocs)
          setLoading(false)
      }
      const columns = [
          {
              name: 'ID',
              selector: (row) => row.__id,
              sortable: false,
              width:"200px"
          },
          {
              name: 'Nom et Prénom',
              selector: (row) => `${row.first_name} ${row.last_name}`,
              sortable: false,
              width:"200px"
          },
          {
              name: 'Email',
              selector: (row) => `${row.email}`,
              sortable: false,
              width:"250px"
          },
          {
              name: '% de réduction',
              selector: (row) => (row.reduction ? `${row.reduction}%` : '0%'),
              sortable: false,
              width:"200px" 
          },
          {
			name: 'Montant total',
			selector: (row) => `${(row.totaleprix)?.toFixed(2)}€`,
			sortable: false,
			width:'150px'
		},
          {
            name: "Date d'inscription",
            selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
            sortable: false,
            width:"200px"
        },
          {
              name: "Date de suspension",
              selector: (row) => (row?.suspendre ? moment(row?.dateSuspension).format('DD/MM/YYYY') : '__'),
              sortable: false,
              width: "200px"
          },
          {
              name: 'Status',
              selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
              sortable: false,
          },
      
          {
              name: 'Actions',
              button: true,
              cell: (row) => (
                  <div
                      style={{
                          display: 'flex',
                          flexDirection: 'row',
                      }}
                  >
                      <div
                          onClick={() => {
                              if(row.emailVerified === true && row.status === 'ok'){
  
                              history.push(`/admin/commerçant/comptabilite/${row._id}/month`)
                              }
                          }}
                          style={{
                              cursor: 'pointer',
                              marginRight:'1rem'
                          }}
                      >
                          <svg
                                      id='icon_dashboard'
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='21.75'
                                      height='27.466'
                                      viewBox='0 0 16 16'
                                  >
                                      <path
                                          id='Union_8'
                                          data-name='Union 8'
                                          d='M13,16a.945.945,0,0,1-1-1V6a.945.945,0,0,1,1-1h2a.945.945,0,0,1,1,1v9a.945.945,0,0,1-1,1ZM7,16a.944.944,0,0,1-1-1V1A.945.945,0,0,1,7,0H9a.945.945,0,0,1,1,1V15a.944.944,0,0,1-1,1ZM1,16a.945.945,0,0,1-1-1V11a.945.945,0,0,1,1-1H3a.946.946,0,0,1,1,1v4a.945.945,0,0,1-1,1Z'
                                          transform='translate(0 0)'
                                          fill='#b4b4c3'
                                      />
                                  </svg>
                      </div>
                      <div
                          onClick={() => {
                              history.push(`/admin/commerçants/${row._id}`)
                          }}
                          style={{
                              cursor: 'pointer',
                          }}
                      >
                          <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21.75'
                              height='27.466'
                              viewBox='0 0 21.75 27.466'
                          >
                              <g
                                  id='Group_11097'
                                  data-name='Group 11097'
                                  transform='translate(-10999.709 -6280)'
                              >
                                  <path
                                      id='Path_11755'
                                      data-name='Path 11755'
                                      d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
                                      fill='#b4b4c3'
                                  />
                                  <path
                                      id='Path_11756'
                                      data-name='Path 11756'
                                      d='M10999.709,6306.466h20.784'
                                      fill='none'
                                      stroke='#e7e7eb'
                                      strokeWidth='2'
                                  />
                              </g>
                          </svg>
                      </div>
                  </div>
              ),
          },
      ]
  return (
    <LayoutAdmin>
      <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
     <GradientText marginTop={'0'}>
					{'COMPTABILITÉ  COMMERCIAUX'.toUpperCase()}
					
	</GradientText>
      <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
					disabled={loading}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							searchForCommercant(searchQuery)
						}
					}}
						placeholder='Rechercher par identifiant,nom commercial '
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '25rem',
							fontFamily: 'Montserrat',
						}}
					/>
					<button
						disabled={loading}
						onClick={() => searchForCommercant(searchQuery)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={async () => {
							await fetchCommercant(currentPage)
							setSearchQuery('')
							
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
	  </div>
	  <div
	  style={{
	  width: '100%',
	  padding: '0 1rem',
	 }}>
		<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des commerçants à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						
		/>
	 </div>
      </div>
     </LayoutAdmin>
  )
}

export default CommerciauxComptabilite
import React ,{ useEffect, useState }from 'react'
import Modal from 'react-modal'
import { useHistory ,useParams} from 'react-router'
import { useSelector } from 'react-redux'
import { getChauffeursOfTransporterDeleted } from '../../../api/chauffeurs'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import UltimatePaginationBasic from '../../../Components/Pagination/Pagination'
import { getSingleTransporter } from '../../../api/clients'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
Modal.setAppElement('#root')

const SIZE = 4
function ListChauffeurForTransporteurDelete() {
    const { id } = useParams()
	const history = useHistory()
	const [chauffeurs, setChauffeurs] = useState([])
	const [page, setPage] = useState(1)
	const [total, setTotal] = useState(1)
    const [transporter, setTransporter] = useState(null)
	//const transporter = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);
    useEffect(() => {
		async function fetchChauffeurs() {
			const res = await getChauffeursOfTransporterDeleted(
				id,
				token,
				page,
				SIZE,
			)
			setChauffeurs(res.docs)
			setTotal(res.totalPages)
		}

		fetchChauffeurs()
	}, [page])
    useEffect(() => {
		console.log(id)
		async function getTransporter(id) {
			const data = await getSingleTransporter(id,token)
			setTransporter(data)
			
		}

		if (id) {
			getTransporter(id)
		}
	}, [id])
  return (
    
    <LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>Liste CHAUFFEURS</GradientText>
				<div
					style={{
						width: '90%',
						fontFamily: 'Montserrat',
						height: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '40px',
					}}
				>
					
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							width: '100%',
							height: '100%',
							alignItems: 'flex-start',
						}}
					>
						{chauffeurs.length ===0?
						<p style={{
							textAlign: 'center',
							marginLeft:'220px',
							fontSize:22,
							marginTop:"100px"
							

						}}
						>
							Aucun chauffeur disponible pour le transporteur sélectionné</p>
						:chauffeurs.map((item, index) => (
							<div
							onClick={()=>{
								history.push(
											`/admin/transports-archived/chauffeur/single/${item._id}`
										)
							}}
								key={String(index)}
								style={{
									width: '25%',
									background: '#E4EAF0',
									borderRadius: 15,
									cursor: 'pointer',
									marginRight: '1rem',
									padding: '1rem 2rem',
									flexGrow: 0,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											color: '#838EAB',
											fontSize: 18,
											fontWeight: 'bold',
										}}
									>
										{`${item.first_name} ${item.last_name}`}
									</span>
									
								</div>
								<div
									style={{
										marginTop: '0.5rem',
										marginBottom: '0.5rem',
										width: '100%',
										height: 1,
										background: '#D4DBE6',
									}}
								/>

								{/* <div
									style={{
										display: 'flex',
										flexDirection: 'column',
										color: '#838EAB',
										fontSize: 12,
									}}
								>
									<span>Fourgon 12m³</span>
									<span>Fourgon 20m³</span>
								</div> */}
							</div>
						))}
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={total}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>
				</div>
			</div>
	</LayoutAdmin>
  )
}

export default ListChauffeurForTransporteurDelete
import Cookies from 'js-cookie'
import axios from 'axios'

export const BASE_URL =
	process.env.NODE_ENV === 'production'
		? 'https://devapi.b2bglob.com'
		: 'http://localhost:8000'
		
export const PUBLIC = `${BASE_URL}/media`

const api = axios.create({ baseURL: BASE_URL })

// This for the client side

api.interceptors.request.use((config) => {
	const jwt = Cookies.get('jwt')
	if (jwt) {
		config.headers.Authorization = ''
		delete config.headers.Authorization
		config.headers.Authorization = `Bearer ${jwt}`
	}
	return config
})

export default api

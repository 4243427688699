import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	Seperator,
} from '../../Containers/PasserCommande/PasserCommande.styles'
import React, { useEffect } from 'react'
import { useRef, useState } from 'react'
import vehiculeCamion from '../../assets/img/vehicule_camion.png'
import Modal from 'react-modal'
import PoidsLours from '../../assets/img/poid_lourd@2x.png'
import tracteurSemi from '../../assets/img/tracteur-actos.png'
import Semi from '../../assets/img/semi.png'
import SemiImg from '../../assets/img/Capturesemi.png'
import tonnes7 from '../../assets/img/7.5.png'
import  tonnes19 from  '../../assets/img/tonnes19.png'
import tonnes12 from '../../assets/img/tonnes12.png'
import VehiculeLeger from '../../assets/img/vehicule_leger@2x.png'
import Break from '../../assets/img/CaptureBreak.png'
import Fourgon12 from '../../assets/img/captureF12.png'
import Fourgon14 from '../../assets/img/fourgon12m.png'
import { useForm ,Controller} from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import moment from 'moment'
Modal.setAppElement('#root')

function AddVehicleRegister({
	onChange,
	initialVehicles = [],
	disableAdd = false,
}) {
	const [vehicles, setVehicles] = useState([])
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [showDetails, setShowDetails] = useState(false);
	const  [showDetailsFourgon12,setShowDetailsFourgon12]=useState(false)
	const  [showDetailsFourgon14,setShowDetailsFourgon14]=useState(false)
	const [showDetailsTonnes7, setShowDetailsTonnes7] = useState(false);
	const [showDetailsTonnes12, setShowDetailsTonnes12] = useState(false);
	const [showDetailsTonnes19, setShowDetailsTonnes19] = useState(false);
	const [showDetailsTracteur, setShowDetailsTracteur] = useState(false);
	const [showDetailsSemi, setShowDetailsSemi] = useState(false);
	const { register, handleSubmit, watch, reset ,control,formState: { errors}} = useForm()
	const  [showDetailsCamion ,setShowDetailsCamion ]=useState(false)
	useEffect(() => {
		setVehicles(initialVehicles)
	}, [initialVehicles.length])

	const type = useRef()
	type.current = watch('type', '')

	const vehicule_leger = useRef()
	vehicule_leger.current = watch('vehicule_leger', '')

	const vehicule_poids_lourds=useRef()
	vehicule_poids_lourds.current=watch('vehicule_poids_lourds','')
	
	const handleOptionChange = (e) => {
		const selectedOption = e.target.value;
	  
		// Si l'option sélectionnée est "Bachée" ou "Frigorifique"
		if (selectedOption === "Bachée" || selectedOption === "Frigorifique") {
		  // Désélectionner l'autre option si elle est sélectionnée
		  const otherOption = selectedOption === "Bachée" ? "Frigorifique" : "Bachée";
		  const otherOptionCheckbox = document.querySelector(`[value="${otherOption}"]`);
		  if (otherOptionCheckbox) {
			otherOptionCheckbox.checked = false;
		  }
		}
	  };
	const onSubmit = async (data) => {
		console.log(data)

		if (!data.count) return

		setVehicles([...vehicles, data])
		onChange([...vehicles, data])
		setModalIsOpen(false)
		reset()
	}

	const getOptionsVehiuleLeger = () => {
		if (
			['Break', 'Fourgon 12m³', 'Fourgon 14m³'].includes(
				vehicule_leger.current
			)
		) {
			return ['Frigorifique','Tall', 'Hayon']
		}

		return ['Frigorifique', 'Bachée', 'Tall', 'Hayon']
	}
	const getOptionsVehiuleLourd=()=>{
		
		if (vehicule_poids_lourds.current==='Tracteur') {
			console.log(vehicule_poids_lourds)
			return ['Frigorifique','Tall', 'Hayon']
		}

		return ['Frigorifique', 'Bachée', 'Tall', 'Hayon']
	}
	

	return (
		<FormContentSection>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<FormSectionTitle>Véhicules</FormSectionTitle>
					<span>
						Veuillez indiquer le type et la quantité de véhicules
						que vous exploiter
					</span>
				</div>
				{!disableAdd && (
					<button
						type='button'
						onClick={(e) => {
							setModalIsOpen(true)
							e.stopPropagation()
						}}
						style={{
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							fontFamily: 'Montserrat',
							padding: '0 2rem',
							borderRadius: 6,
						}}
					>
						Ajouter véhicule
					</button>
				)}
			</div>
			<FormSectionCard>
				<table
					cellPadding={0}
					cellSpacing={0}
					style={{
						width: '100%',
						textAlign: 'left',
						borderRadius: 20,
						overflow: 'hidden',
						border: 'none',
					}}
				>
					<thead>
						<tr
							style={{
								paddingBottom: 10,
							}}
						>
							<th
								style={{
									fontWeight: 'normal',
									color: '#262626',
									padding: '1rem',
								}}
							>
								Détails
							</th>
							<th
								style={{
									fontWeight: 'normal',
									color: '#262626',
									padding: '1rem',
								}}
							>
								Options
							</th>
							<th
								style={{
									fontWeight: 'normal',
									color: '#262626',
									padding: '1rem',
								}}
							>
								Date de mise en circulation
							</th>
						</tr>
					</thead>

					<tbody>
						{vehicles.map((v, index) => (
							<tr
								key={String(index)}
								style={{
									background: 'white',
								}}
							>
								<td
									style={{
										padding: '1rem',
									}}
								>
									{v?.type == 'Poids lourds' &&
										Array.isArray(v?.vehicule_poids_lourds) ?  v?.vehicule_poids_lourds?.join(', ') : v?.vehicule_poids_lourds}
									{v?.type == 'Véhicule léger' &&
										v?.vehicule_leger}
								</td>
								<td
									style={{
										padding: '1rem',
									}}
								>
									{v?.type == 'Poids lourds' &&
										Array.isArray(v?.options_poids_lourds) ? v?.options_poids_lourds?.join(', ') : v?.options_poids_lourds}
									{v?.type == 'Véhicule léger' &&
										Array.isArray(v?.options_vehicule_leger) ? v?.options_vehicule_leger?.join(', ') : v?.options_vehicule_leger}
								</td>
								<td
									style={{
										padding: '1rem',
									}}
								>
									{v.date_mise_circulation}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</FormSectionCard>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					setModalIsOpen(false)
					reset()
				}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
						paddin: '4rem',
						height:"450px"
					
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'left',
						color: '#838EAB',
					}}
				>
					<div
						style={{
							color: '#51C7F2',
							fontSize: 30,
							marginBottom: '1rem',
							
						}}
					>
						Ajouter un véhicule
					</div>

					<form onSubmit={handleSubmit(onSubmit)}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								//marginBottom: '2.5rem',
								width: '100%',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Type de véhicules
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										//marginBottom: '1rem',
									}}
								>
									<div
										style={{
											width: '15rem',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start',
										}}
									>
										<input
											type='radio'
											style={{
												marginRight: '1rem',
											}}
											{...register('type', {
												required: true,
											})}
											value={'Véhicule léger'}
										/>

										<span
											style={{
												marginRight: '0.5rem',
											}}
										>
											Véhicule léger
										</span>
										<img
											src={VehiculeLeger}
											style={{
												width: 40,
											}}
										/>
									</div>
									
									<div
										style={{
											width: '15rem',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start',
										}}
									>
										<input
											style={{
												marginRight: '1rem',
											}}
											type='radio'
											{...register('type', {
												required: true,
											})}
											value={'Poids lourds'}
										/>
                                     
										<span
											style={{
												marginRight: '0.5rem',
											}}
										>
											Poids lourds
										</span>

										<img
											src={PoidsLours}
											style={{
												width: 40,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						{errors.type && (
                                  <div style={{ color: 'red'}}>
                                  Veuillez sélectionner le type de véhicules .
                                 </div>
                                 )}

						{type.current && (
							<>
								<div
									style={{
										width: '100%',
										height: 1,
										background: 'red',
										borderTop: '1px solid #707070',
										opacity: '0.3',
										marginBottom: '1rem',
									}}
								/>

								{type.current !== 'Poids lourds' && (
									<div 
									style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%'
									}}>
									<div  style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>	
											<img
										    src={Break}
											style={{
												width: '100%',
												height: 'auto', // Pour conserver les proportions de l'image
												maxWidth: '120px', // Limiter la largeur de l'image
												maxHeight: '80px', // Limiter la hauteur de l'image
												objectFit: 'cover', // Ajuster la taille tout en conservant les proportions
											  }}
										    alt=''
									        />
											</div>
                                            <div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger', {required:true}
													)}
													value='Break'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Break
												</span>
												<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetails(!showDetails)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetails ? '-' : '+'}
                                                 </button>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
											1097 kg
											</span>
											</div>
											{showDetails && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 6M3 </span>
                                                    <span>Longueur utile : 3750mm </span>  
                                                    <span>Largeur utile : 1956mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1097kg </span>  
													<span>Nombre de palettes: 2 pal europe </span> 
												</div>	
											)}
										</div>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={Fourgon12}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>
                                           
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger'
													)}
													value='Fourgon 12m³'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Fourgon 12m³
												</span>
												<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsFourgon12(!showDetailsFourgon12)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsFourgon12 ? '-' : '+'}
                                                 </button>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
											1244 kg
											</span>
											</div>
											{showDetailsFourgon12 && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 12M3 </span>
													<span>Longueur utile : 3030mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1244kg </span>  
													<span>Nombre de palettes: 5 pal europe</span> 
												</div>	
											)}
										</div>
										
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
												marginRight: '2%',
											}} >
									        <div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={Fourgon14}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	

											
                                            <div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger'
													)}
													value='Fourgon 14m³'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Fourgon 14m³
												</span>
												<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsFourgon14(!showDetailsFourgon14)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsFourgon14 ? '-' : '+'}
                                                 </button>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
											1353 kg
											</span>
											</div>
											{showDetailsFourgon14 && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 14M3 </span>
													<span>Longueur utile :3680mm</span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1820mm </span>  
                                                    <span>Charge utiles : 1353kg </span>  
													<span>Nombre de palettes: 6 pal europe</span> 
												</div>	
											)}
											</div>	
										
									</div>
									</div>	
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>
									
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										src={vehiculeCamion}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '120px',
											maxHeight: '80px',
											objectFit: 'cover',
										  }}
										alt=''
									     />
											</div>	
                                          
										   <div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger'
													)}
													value='Camion 20m³'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Camion 20m³
												</span>
												<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsCamion(!showDetailsCamion)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsCamion ? '-' : '+'}
                                                 </button>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												1200 kg
											</span>
											</div>
											{showDetailsCamion && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'45%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 20M3 </span>  
													<span>Longueur utile :4320mm</span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 2100mm </span>  
                                                    <span>Charge utiles : 1200kg </span>  
													<span>Nombre de palettes: 7 pal europe</span> 
												</div>	
											)}
										</div>
									</div>	
									</div>
									{errors.vehicule_leger && (
                    <div style={{ color: 'red', marginBottom: '0.5rem' }}>
                        Veuillez choisir le type de véhicule détaillé.
                   </div>
                   )}
									</div>
								)}
                      
								{type.current !== 'Poids lourds' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options Véhicule léger
										</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
												}}
											>
												{getOptionsVehiuleLeger().map(
													(item, index) => (
														<div
															key={String(index)}
															style={{
																width: '15rem',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																{...register(
																	'options_vehicule_leger',
																	{required:true}
																)}
																value={item}
																onChange={(e) => handleOptionChange(e)}
															/>

															<span
																style={{
																	marginRight:
																		'0.5rem',
																}}
															>
																{item}
															</span>
														</div>
													)
												)}
											</div>
											{type.current == null && (
												<Seperator />
											)}
										</div>
										{errors.options_vehicule_leger && (
                                        <div style={{ color: 'red' }}>
                                        Veuillez cocher au moins une option pour le type de transport.
                                        </div>
                                        )}
									</div>
									
								)}
								

								{type.current !== 'Véhicule léger' && (
									<div
                                    style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%'
									}}>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										<div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes7}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='7,5 Tonnes'
													/>

													<span>7,5 Tonnes</span>
													<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsTonnes7(!showDetailsTonnes7)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsTonnes7 ? '-' : '+'}
                                                 </button>
												</div>
												<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												2470 kg
											</span>
											</div>
											{showDetailsTonnes7 && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 30M3 </span>
													<span>Longueur utile : 6575mm </span>  
                                                    <span>Largeur utile : 2045mm </span>  
                                                    <span>Hauteur utile : 2400mm </span>  
                                                    <span>Charge utiles : 2470kg </span>  
													<span>Nombre de palettes: 16 pal europe</span> 
												</div>	
											)}
											</div>	
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes12}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='12 Tonnes'
													/>

													<span>12 Tonnes</span>
													<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsTonnes12(!showDetailsTonnes12)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsTonnes12 ? '-' : '+'}
                                                 </button>
												</div>
												<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												5600 kg
											</span>
											</div>
											{showDetailsTonnes12 && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 45M3 </span>
													<span>Longueur utile : 7540mm </span>  
                                                    <span>Largeur utile : 2480mm </span>  
                                                    <span>Hauteur utile : 2500mm </span>  
                                                    <span>Charge utiles : 5600kg </span>  
													<span>Nombre de palettes: 19 pal europe</span> 
												</div>	
											)}
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
											<div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes19}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='19 Tonnes'
													/>

													<span>19 Tonnes</span>
													<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsTonnes19(!showDetailsTonnes19)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsTonnes19 ? '-' : '+'}
                                                 </button>
												</div>
												<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												9200 kg
											</span>
											</div>
											{showDetailsTonnes19 && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 58M3 </span>
													<span>Longueur utile : 9100mm </span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2490mm </span>  
                                                    <span>Charge utiles : 9200kg </span>  
													<span>Nombre de palettes: 22 pal europe</span> 
												</div>	
											)}
											</div>
											</div>

									</div>	
									</div>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
									<div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
									}}>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
												<div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
												<img
										    src={SemiImg}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
												marginTop:-15
											  }}
										    alt=''
									        />
												</div>
										
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
															marginTop:-20
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='Semi'
													/>

													<span>Semi</span>
													<button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsSemi(!showDetailsSemi)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsSemi ? '-' : '+'}
                                                 </button>
												</div>
												<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												27000 kg
											</span>
											</div>
											{showDetailsSemi && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<span>Dimension en M3 : 86M3 </span>
													<span>Longueur utile:13500mm</span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2590mm </span>  
                                                    <span>Charge utiles : 27000kg </span>  
													<span>Nombre de palettes: 33 pal europe</span> 
												</div>	
											)}
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
                                           
										   <div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
										   <img
										    src={Semi}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
										   </div>
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds',
															{required:true}
														)}
														value='Tracteur+semi'
													/>

													<span>Tracteur+semi</span>
													{/* <button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsTracteurSemi(!showDetailsTracteurSemi)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsTracteurSemi ? '-' : '+'}
                                                 </button> */}
												</div>
												{/* <div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												800 - 1000 kg
											</span>
											</div> */}
											
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
												<div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
												<img
										    src={tracteurSemi}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '100px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
												</div>
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='Tracteur'
													/>

													<span>Tracteur</span>
													{/* <button   
												onClick={(e) => {
													e.preventDefault();
													setShowDetailsTracteur(!showDetailsTracteur)
												  }}
												style={{
													border:'none',
													fontSize:'22px',
													marginLeft: '0.5rem',
													background: '#e5eaef',
													color: '#858ea8',
												}}>
                                                 {showDetailsTracteur ? '-' : '+'}
                                                 </button> */}
												</div>
												{/* <div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
																												marginRight: '0.5rem',

													}}
												>
												800 - 1000 kg
											</span>
											</div> */}
											{showDetailsTracteur && (
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px'
												}}>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Longueur utile : </span>  
													<span>2.45 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Largeur utile : </span>  
													<span>1.50 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Hauteur utile : </span>  
													<span>1.60 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Volume utile : </span>  
													<span>6 M3</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Charge max : </span>  
													<span>1000 kg</span>   
													</div>
												</div>	
											)}
											</div>	
											
											
									</div>	
									</div>
									</div>	
									{errors.vehicule_poids_lourds && (
                    <div style={{ color: 'red', marginBottom: '0.5rem' }}>
                        Veuillez choisir le type de véhicule détaillé.
                   </div>
                   )}
									</div>
								)}
					

								{type.current !== 'Véhicule léger' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options poids lourds
										</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													//marginBottom: '1rem',
												}}
											>
												{getOptionsVehiuleLourd().map((item, index) => (
													<div
														key={String(index)}
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															{...register(
																'options_poids_lourds',
																{required:true}
															)}
															value={item}
														/>

														<span>{item}</span>
													</div>
												))}
											</div>
										</div>
										{errors.options_poids_lourds && (
                                        <div style={{ color: 'red',marginTop:'0.5rem' }}>
                                        Veuillez cocher au moins une option pour le type de transport.
                                        </div>
                                        )}
									</div>
								)}
							</>
						)}

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								marginTop: '2rem',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
                            Date de mise en circulation
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '50%',
								}}
							>
						    <Controller
                            name="date_mise_circulation"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                            <Flatpickr
                             {...field}
                             className='date-input'
                             options={{
                             mode: 'single',
                             locale: French,
                             dateFormat: 'd/m/Y',
                             }}
                            onChange={([date]) =>{
								const formattedDate = moment(date).format('DD/MM/YYYY')
								field.onChange(formattedDate)
							} }
                           />
                            )}
                           />
							</div>
						</div>
						<div
                         style={{
                         width: '15rem',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'flex-start',
                         marginBottom: '1rem',
						 marginTop:"2rem"
                         }}
                        >
                       <input
                        type='checkbox'
                        style={{ marginRight: '1rem' }}
                        {...register('location',)}
						defaultChecked={false}
                         />
                        <span style={{ marginRight: '0.5rem' }}>Véhicule de location</span>
                       </div>
						
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '1rem',
							}}
						>
							<div style={{display: 'flex',flexDirection: 'row',}}>
							<button
								type='button'
								onClick={handleSubmit((data) => {
										setVehicles([...vehicles, data]);
										onChange([...vehicles, data]);
									    setModalIsOpen(false)
									    reset()
								})}
								style={{
									background: '#50F5A9',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									fontFamily: 'Montserrat',
									padding: '1rem 2rem',
									borderRadius: 6,
								}}
							>
								Ajouter
							</button>
							<button 
							onClick={()=>{setModalIsOpen(false)}}
							style={{
								background: 'red',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								fontFamily: 'Montserrat',
								padding: '1rem 2rem',
								borderRadius: 6,
								marginLeft:'1rem'
							}}>
								Annuler
							</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</FormContentSection>
	)
}

export default AddVehicleRegister

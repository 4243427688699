import React, { useEffect, useState } from 'react'
import {getDeletelTransporteur,restoreDeletedTransporteur} from '../../../api/clients'
import DataTable from 'react-data-table-component'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { fullTextSearchTransporteurs } from '../../../api/search'
import moment from 'moment'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function MesTransporteursSupprimer(props) {
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [perPage, setPerPage] = useState(10)
    const [type,setType]=useState('supprimer')
	const [currentPage, setCurrentPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
    const [transporteur,setTransporteur]=useState('')

	const fetchTransporteurs = async (page) => {
		setChargementEnCours(true)
		const res = await getDeletelTransporteur(page, perPage,token)
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchTransporteurs(page)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getDeletelTransporteur(page, newPerPage,token)
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}

	const searchForTransporteurs = async (q) => {
		if (q.length === 0) {
			return
		}

		setLoading(true)

		const res = await fullTextSearchTransporteurs(q,token,type)
		setData(res.docs)
		setTotalRows(res.nbHits)
		setLoading(false)
	}

	useEffect(() => {
		fetchTransporteurs(1)
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width:"200px"
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Nom du transporteur',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Status',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
			width: "200px"
		},
		{
			name: "Date de suspension",
			selector: (row) => (row?.suspendre ? moment(row?.dateSuspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
			width: "200px"
		},
		
		{
			name: "Date de suppression ",
			selector: (row) => moment(row?.deletedAt).format('DD/MM/YYYY') ,
			sortable: false,
			width: "200px"
		},
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width: "200px"
		},
        {
			name: 'Restaurer le Compte',
			button: true,
			width: '200px',
			cell: (row) => (
                <button
						
						onClick={ () => {
							setTransporteur(row._id)
							setModalIsOpen(true)
						}}
						style={{
							background: '#C9F7F5',
							color: '#1CC5BD',
							fontFamily: 'Montserrat',
							border: 'none',
							cursor: 'pointer',
							padding: '0.5rem 1rem',
							borderRadius: 5,
							fontWeight: 'bold',
							marginRight: 10,
						}}
					>
						Valider
					</button>
            )
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/admin/transporters/single/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'ARCHIVES transporteurs'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
						disabled={loading}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								searchForTransporteurs(searchQuery)
							}
						}}
						placeholder='Rechercher par identifiant,nom transporteur / societe'
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '25rem',
							fontFamily: 'Montserrat',
						}}
					/>

					<button
						disabled={loading}
						onClick={() => searchForTransporteurs(searchQuery)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={async () => {
							await fetchTransporteurs(currentPage)
							setSearchQuery('')
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des transporteurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>
						Restaurer Transporteur
					
		            </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						Êtes-vous sûr de vouloir Restaurer ce Transporteur 

						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
							const res=await restoreDeletedTransporteur(transporteur,token) 
						    toast.success(res.message, {
							style: {
								fontFamily: 'Montserrat',
							},
						    })
						    setModalIsOpen(false)
						    history.push('/admin/transporteurs')
							} catch (error) {
								console.log(error)
								setModalIsOpen(false)
							}finally {
								setLoading(false)
							}
                              
                            }}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
						}}
					>
				   {loading ? 'Chargement' : 'Confirmer'} 

					</button>
					<button
						onClick={async () => {
							setModalIsOpen(false)						
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		</LayoutAdmin>
	)
}

export default MesTransporteursSupprimer

import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {  useParams } from 'react-router'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'

import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { CustomInput } from '../../RegisterTransporter/style'
import {
    getChauffeur,
} from '../../../api/chauffeurs'
import { FormSectionCard,GradientText } from '../../PasserCommande/PasserCommande.styles'
Modal.setAppElement('#root')
function SingleChauffeurDelete() {
    const { register, reset, control,formState: { errors } } = useForm()
	const { id } = useParams()
	const [chauffeur, setChauffeur] = useState(null)
	const token = useSelector((state) => state.auth.token);

	useEffect(() => {
		async function _getChauffeur(id) {
			const chauffeur = await getChauffeur(id,token)
			console.log(token)
			console.log(chauffeur)
			setChauffeur(chauffeur)
			reset(chauffeur)
		}

		if (id) {
			_getChauffeur(id)
		}
	}, [id])
  return (
    <LayoutAdmin>
        <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>{`DÉTAIL CHAUFFEUR N°${chauffeur?.__id}`} </GradientText>
				<div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
				<FormSectionCard>
						
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Fiche Chauffeur
								</p>
								
							</div>

							<div
								style={{
									display: 'flex',
								}}
							>
								
								
								
								
							</div>
						</div>
					</FormSectionCard>
				</div>
				<div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
					
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Sexe
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '30%',
										}}
									>
										<input
											type='radio'
                                            disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('sexe', {
												required: true,
											})}
											value={'homme'}
										/>

										<span>Homme</span>
									</div>

									<div>
										<input
											type='radio'
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('sexe', {
												required: true,
											})}
											value={'femme'}
										/>

										<span>Femme</span>
									</div>
								</div>
								{errors.sexe && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     Veuillez sélectionner le sexe
                                     </div>
                                )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Permis
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Léger', 'Lourds', 'Super lourds'].map(
										(item, index) => (
											<div
												key={String(index)}
												style={{
													width: '20%',
												}}
											>
												<input
													type='checkbox'
                                                    disabled
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}
													{...register('permis', {
														required: true,
													})}
													value={item}
												/>

												<span>{item}</span>
											</div>
										)
									)}
								</div>
								{errors.permis && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     Veuillez sélectionner le type de permis
                                     </div>
                                )}
							</div>

							{/* <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Type de véhicule
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '30%',
										}}
									>
										<input
											type='checkbox'
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('type_vehicule', {
												required: true,
											})}
											value={'Véhicule Léger'}
										/>

										<span>Véhicule Léger</span>
									</div>

									<div>
										<input
											type='checkbox'
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('type_vehicule', {
												required: true,
											})}
											value={'Poids lourds'}
										/>

										<span>Poids lourds</span>
									</div>
								</div>
							</div> */}

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
										type='text'
										placeholder='Doe'
                                        disabled
										{...register('last_name', {
											required: 'Veuillez indiquer le nom',
											minLength: {
												value: 3,
												message: 'Le nom  doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.last_name && (
                                    <div style={{ color: 'red', marginTop: '0.5rem' ,fontSize:'14px'}}>
                                     {errors.last_name.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
                                        disabled
										placeholder='Doe'
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.first_name && (
                                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                       {errors.first_name.message}
                                       </div>
                                       )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse
									</span>
									<CustomInput
                                        disabled
										type='text'
										placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
										{...register('address', {
											required: ' Veuillez indiquer l\'adresse .',
											minLength: {
												value: 3,
												message: 'L\'adresse doit contenir au moins 3 caractères.',
											},
											pattern: {
												value: /^[A-Za-z0-9\s',-]+$/,
												message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
											},
										})}
									/>
									{errors.address && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     {errors.address.message}
                                     </div>
                                    )}
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									disabled
									{...register('city', {
									 	required: 'Veuillez indiquer la ville.',
										 minLength: {
											value: 3,
											message: 'La ville doit contenir au moins 3 caractères.',
										  },
										  pattern: {
											value: /^.+$/,
											message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										  },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									disabled
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
                                        disabled
										type='text'
										placeholder='(0) 01 45 66 32 88'
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},

										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
                                        disabled
										type='text'
										placeholder='John.doe@wayne.fr'
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
									{errors.email && (
                                         <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                         {errors.email.message}
                                         </div>
                                        )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date de naissance
									</span>
									<Controller
                                        disabled
										name='date_naiss'
										rules={{ required: "Veuillez indiquer la date de naissance.",
										validate: value => {
										const today = new Date();
										const birthDate = new Date(value);
										const age = today.getFullYear() - birthDate.getFullYear();
										birthDate.setFullYear(today.getFullYear());
						 
										if (
										   age < 18 ||
										   (age === 18 &&
											  today.getTime() < birthDate.getTime())
										) {
										   return 'Vous devez avoir au moins 18   ans pour travailler.';
										}
						 
										return true;
									 },
									 }}
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<Flatpickr
                                                disabled
												onChange={([date]) => {
													onChange(date)
												}}
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={value}
											/>
											
										)}
									/>
									<div >
									{errors.date_naiss && (
                                         <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                         {errors.date_naiss.message}
                                         </div>
                                    )}
									</div>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date d’embauche
									</span>
									{/* <StyledInput
										type='date'
										style={{
											fontFamily: 'Montserrat',
											color: '#838EAB',
										}}
										{...register('date_embauche', {
											required: true,
											maxLength: 80,
										})}
									/> */}
									<Controller
                                        disabled
										name='date_embauche'
										rules={{  required: "Veuillez indiquer la date d’embauche."  }}
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<Flatpickr
                                                disabled
												onChange={([date]) => {
													onChange(date)
												}}
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={value}
											/>
										)}
									/>
								<div/>
								{errors.date_embauche && (
                                         <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                         {errors.date_embauche.message}
                                         </div>
                                )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Fimo
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Matière dangereuse
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
								{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
										{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
											    disabled
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
							
							</div>
						</FormSectionCard>

						

						
					
				</div>
		</div>
		
    </LayoutAdmin>
  )
}

export default SingleChauffeurDelete
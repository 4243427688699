import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getCommandesByTransporterIDByMonth } from '../../api/commande'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import { GradientText, SubText } from '../PasserCommande/PasserCommande.styles'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import {getInvoiceMonthTransporter} from '../../api/invoice'
import  toast from 'react-hot-toast'
import { PUBLIC } from '../../api/base'
var months = {
	1: 'Jan',
	2: 'Fév',
	3: 'Mar',
	4: 'Avr',
	5: 'May',
	6: 'Juin',
	7: 'Juill',
	8: 'Aout',
	9: 'Sep',
	10: 'Oct',
	11: 'Nov',
	12: 'Déc',
}

function ComptabiliteByMonthTransporter(props) {
	const history = useHistory()
	const [data, setData] = useState([])
	const [total, setTotal] = useState(0)
	const token = useSelector((state) => state.auth.token);
	const transporterID = useSelector((state) => state.auth.user._id)
	const transporteur = useSelector((state) => state.auth.user)

	const [selectedMonth, setSelectedMonth] = useState(null); // Add state for selected month

	useEffect(() => {
		async function getData(transporterID) {
			const res = await getCommandesByTransporterIDByMonth(transporterID,token)
			console.log(res.data[0])
			setTotal(res.total_all)
			setData(_.groupBy(res.data, (e) => e.year))
		}

		getData(transporterID)
	}, [transporterID])
	const handleMonthClick = (month) => {
		setSelectedMonth(selectedMonth === month ? null : month);
		  };
	const handleInvoiceManagement = async (event,month,price) => {
		event.stopPropagation()
			try{
            await getInvoiceMonthTransporter(transporterID,month,price,token)
			toast.success(`La commande a été gérée avec succès pour le mois ${month}`, {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		   }catch{
			toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
			}
	};
	const handleViewInvoice=async(event,month,year)=>{
		event.stopPropagation()
		window.open(`${PUBLIC}/transporteur/${transporteur.__id}/Facture_${year}_${month}.pdf`)
	}
	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>{'comptabilité'.toUpperCase()}</GradientText>
				<SubText>
					Ici retrouver la totalité des transports facturer
				</SubText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div>
						
					</div>

					<div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{total.toFixed(2)}€</span>
					</div>
				</div>

				<div
					style={{
						width: '100%',
						padding: '1rem',
						marginBottom: '1rem',
					}}
				>
					<span
						onClick={() =>
							history.push('/comptabilite/transporter/month')
						}
						style={{
							padding: '1rem 2rem',
							borderRadius: 5,
							cursor: 'pointer',
							marginRight: '1rem',
							fontWeight: 'bold',
							color: 'white',
							background: '#50F5A9',
							fontFamily: 'Montserrat',
						}}
					>
						Mois
					</span>
					<span
						onClick={() =>
							history.push('/comptabilite/transporter/day')
						}
						style={{
							padding: '1rem 2rem',
							borderRadius: 5,
							cursor: 'pointer',
							fontWeight: 'bold',
							color: 'white',
							marginRight: '1rem',
							background: '#E4EAF0',
							fontFamily: 'Montserrat',
						}}
					>
						Jours
					</span>
				</div>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>
					{Object.entries(data).map((element, index) => {
						return (
							<div
								key={String(index)}
								style={{
									width: '100%',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										width: '100%',
									}}
								>
									<span
										style={{
											color: '#51C7F2',
											fontWeight: 'bold',
											marginRight: 10,
											marginBottom: 10,
										}}
									>
										{element[0]}
									</span>

									<div
										style={{
											width: '100%',
											height: 1,
											background: '#F2F2F2',
										}}
									/>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap',
										width: '100%',
										height: '100%',
										alignItems: 'flex-start',
									}}
								>
									{element[1].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '25%',
												background: '#E4EAF0',
												borderRadius: 15,
												marginRight: '1rem',
												padding: '1rem 2rem',
												flexGrow: 0,
												cursor: 'pointer',
												border: selectedMonth === item.month ? '2px solid #51C7F2' : 'none',

											}}
											onClick={() => handleMonthClick(item.month)}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent:
														'space-between',
													marginBottom: '.5rem',
												}}
											>
												<span
													style={{
														color: '#838EAB',
														//fontSize: 18,
													}}
												>
													{months[`${item.month}`]}
												</span>
											</div>

											<span
												style={{
													color: '#51C7F2',
													fontWeight: 'bold',
													marginTop: '1rem',
													//fontSize: 18,
												}}
											>
												Total: {`${item.totalAmount.toFixed(2)}€`}
											</span>
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>

											<button
                                              onClick={async(event) => await handleInvoiceManagement(event,item.month,item.totalAmount)}
											  style={{
												marginTop: '1rem',
												padding: '0.5rem 1rem',
												borderRadius: 5,
												background: '#FFC107',
												color: 'white',
												border: 'none',
												cursor: 'pointer',
											
											}}
                                            >
                                                Gérer la facture
                                            </button>
											<button
													onClick={ (event) => handleViewInvoice(event,item.month,element[0])}
													style={{
														marginTop: '1rem',
														marginLeft: '1rem',
														padding: '0.5rem 1rem',
														borderRadius: 5,
														background: '#17A2B8',
														color: 'white',
														border: 'none',
														cursor: 'pointer',
														
													}}
												>
													Voir Facture
											</button>
											</div>
										</div>
									))}
								</div>
								<div>
								{element[1].map((item, index) => (
								
										<div key={index}
										style={{
											width: '100%',
											fontFamily: 'Montserrat',
											paddingLeft: '1rem',
											marginTop:"1rem",
											paddingRight: '1rem',
											marginBottom: 5,
										}}>
											 {selectedMonth === item.month && (
												<div>
										{item.commandes.map((commande) => {
										
										if (commande.type_commande === 'course-a-course'){
											return(
												<CourseACourseHorizontal
												key={commande._id}
												commande={commande}
												hideAttribuer
									            hidecancel
									            hideRecu
												hideUpdate
												hidefacture
												hideStatus
												onClickDetails={() => {
													history.push(
														`/transports/${commande._id}`
													)
												}}
												onClickSignaler={() => {
													history.push(
														`/transporter/transports/${commande._id}/signaler`
													)
												}}
											/>
											)
										} else {
											return (
												<MiseADispoHorizontal
													key={commande._id}
													commande={commande}
													hideRecu
													hidecancel
													hideAttribuer
													hidefacture
													hideStatus
													hideUpdate
													onClickDetails={() => {
														history.push(
															`/transports/${commande._id}`
														)
													}}
													onClickSignaler={() => {
														history.push(
															`/transporter/transports/${commande._id}/signaler`
														)
													}}
												/>
											)
										}
 




										}	
										)}
										</div>
											 )}
										</div>
									
								))}	
							    </div>	
							</div>
						)
					})}
				</div>
			</div>
		</LayoutTransporter>
	)
}

export default ComptabiliteByMonthTransporter

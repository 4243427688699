import {
	GradientText,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import Container from '../../Components/LayoutClient/container.component'
import { useSelector } from 'react-redux'
import {getPrelevementForClient}  from '../../api/commande'
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router'
import {BASE_URL } from '../../api/base'
import { FaRegFilePdf } from "react-icons/fa";
function Prelevement() {
    const history = useHistory()
    const [data, setData] = useState([])
	const clientID = useSelector((state) => state.auth.user._id)
	const token = useSelector((state) => state.auth.token);
    const [loading, setLoading] = useState(false)

    async function getData(clientID) {
        setLoading(true)
        const res = await getPrelevementForClient(clientID,token)
        setData(res)
        setLoading(false)
    }
    const columns = [	
		{
			name: 'Montant',
			selector: (row) =>`${row.compteur.toFixed(2)}€` ,
			sortable: false,
		},
        {
			name: "Statut du paiement",
			selector: (row) =>( row.paymentStatus ) ,
			sortable: false,
		},
        {
			name: "Nombre de commandes",
			selector: (row) =>( row.commandes?.length ) ,
			sortable: false,
		},
        {
			name: "Nombre de forfaits",
			selector: (row) =>( row.packages?.length ) ,
			sortable: false,
		},
        {
			name: "Nombre de signalements",
			selector: (row) =>( row.signalement?.length ) ,
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
                    <div
					 onClick={()=>{
						window.open(`${BASE_URL}/${row.facture}`)


					 }}
						style={{
							cursor: 'pointer',
						}}>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'#51C7F2'}} />

					</div>
					
					<div
						onClick={()=>history.push(`/client/prelevement-detail/${row._id}`)}
						style={{
							cursor: 'pointer',
							marginLeft:'2px'
						}}
					>
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
				
			),
		},
		
		
		
		
	]

    useEffect(()=>{
      getData(clientID)
    },[clientID])
  return (
<Container>
			<Wrapper>
				<GradientText>{'Prélèvements'.toUpperCase()}</GradientText>
                <div
					style={{
						width: '100%',
						padding: '0 1rem',
                        marginTop:'3rem'
					}}
				>
				<DataTable
						columns={columns}
						data={data}
						progressPending={loading}
						pagination
						paginationServer
					
						
						
						noDataComponent="Il n'y a pas des clients à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>	
				</div>
            </Wrapper>
  </Container>

  )
}

export default Prelevement
import React from 'react'
import Footer from '../../Components/Footer/footer.component'
import Logo from '../../assets/img/logo-TPE@2x.png'
import imagebg from '../../assets/img/bg@2x.png'
import { useForm } from 'react-hook-form'
import { resetPassword } from '../../api/auth'
import { useParams,useHistory } from 'react-router-dom';
import toast from 'react-hot-toast'
function ResetPassword() {
    const { register, handleSubmit,formState: { errors, isValid, isDirty }  } = useForm()
    const history=useHistory()
    const {id}=useParams()
    const onSubmit = async (data) => {
		try {
		
        if (data.password === data.passwordconfirmer) {
          const res=await resetPassword(id,data.password)
          console.log(res)
          if(res.msg==="PASSWORD_CHANGED"){
           history.push('/login')
          }
        }else{
            toast.error(
				"Les mots de passe ne correspondent pas.",
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
        }
		 

		} catch (error) {
			console.log(error)
		}
	}
  return (
    
    <div>
    <div
        style={{
            height: '95vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            background:
            `url(${imagebg})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'no-repeat',
        }}
    >
        <div
            style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 50,
            }}
        >
            <img src={Logo} style={{ height: 80 }} alt='' />
        </div>

        <div
            style={{
                padding: '1rem',
                height: '80%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //background: 'red',
                fontFamily: 'Montserrat',
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    background: '#E4EAF0',
                    // width: '25rem',
                    // height: '20rem',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '3rem 4rem',
                    borderRadius: 30,
                }}
            >
               <label
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: 7,
										fontSize: 14,
									}}
								>
									Mot de passe 
								</span>
								
							</div>
							<input
								{...register('password', {
									required: 'Veuillez indiquer le mot de passe',
									minLength: {
										value: 8,
										message: 'Le mot de passe doit contenir au moins 8 caractères',
											},
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#95989A',
									borderRadius: 5,
									border: 'none',
									background: 'white',
								}}
								name='password'
								placeholder='Mot de passe'
								type='password'
							/>
							{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
			   </label>
               <label
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: 7,
										fontSize: 14,
									}}
								>
									Confirmez le mot de passe
								</span>
								
							</div>
							<input
								{...register('passwordconfirmer', {
									required: 'Veuillez indiquer le mot de passe',
									minLength: {
										value: 8,
										message: 'Le mot de passe doit contenir au moins 8 caractères',
											},
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#95989A',
									borderRadius: 5,
									border: 'none',
									background: 'white',
								}}
								name='passwordconfirmer'
								placeholder='Mot de passe'
								type='password'
							/>
								{errors.passwordconfirmer && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.passwordconfirmer.message}
                                    </div>
                               )}
			   </label>
              

               

                <button
                    style={{
                        color: 'white',
                        fontFamily: 'Montserrat',
                        background: '#51C7F2',
                        border: 'none',
                        padding: '1rem',
                        cursor: 'pointer',
                        width:'300px',
                        fontWeight: 'bold',
                        borderRadius: 6,
                        marginBottom: '1rem',
                    }}
                    type='submit'
                >
                    Envoyer
                </button>

               
            </form>
        </div>
    </div>
    <Footer />
</div>
  )
}

export default ResetPassword
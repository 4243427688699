import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTransporterStats } from '../../api/transporteurs';
import { GradientText } from '../PasserCommande/PasserCommande.styles';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

function TransporterStats() {
    const { _id } = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const [caMensuel, setCaMensuel] = useState([]);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const defaultStartDate = new Date(`${currentYear}-01-01`);
    const [range, setRange] = useState([defaultStartDate, currentDate]);
    const [stats,setStats]=useState([])
   
    const handleFilterChange = async () => {
        setChargementEnCours(true);
        try {
            const startDateISO = range[0].toISOString();
            const endDateISO = range[1].toISOString();
            const response = await getTransporterStats(_id, startDateISO, endDateISO, token);
            setCaMensuel(response.caMensuel);
            setStats(response.stats);
        } catch (error) {
            console.error('Erreur lors de la récupération des statistiques:', error);
        } finally {
            setChargementEnCours(false);
        }
    };
    const handleSearch = () => {
        handleFilterChange();
    };

    const handleClear = () => {
        const defaultStartDate = new Date(`${currentYear}-01-01`);
        setRange([defaultStartDate, currentDate]);
        handleFilterChange();
    };
    useEffect(() => {
        handleFilterChange();
    }, [range, _id, token]);

    const totalCounts = stats.reduce((acc, stat) => {
        acc.countEnCours += stat.countEnCours;
        acc.countInit += stat.countInit;
        acc.countAnnuler += stat.countAnnuler;
        acc.countTerminer += stat.countTerminer;
        return acc;
    }, { countEnCours: 0, countInit: 0, countAnnuler: 0, countTerminer: 0 });

    const barData = {
        labels: caMensuel.map(item => `${item.month}/${item.year}`),
        datasets: [
            {
                label: 'Chiffre d\'affaire mensuel',
                data: caMensuel.map(item => item.totalPrix),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const barOptions = {
        layout: {
            padding: {
                top: 20,
                bottom: 20,
                left: 20,
                right: 20
            }
        },
        scales: {
            x: {
                type: 'category',
                labels: caMensuel.map(item => `${item.month}/${item.year}`)
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Chiffre d\'affaire mensuel (en euros)'
                },
                ticks: {
                    callback: function(value) {
                        return `${value} €`; 
                    }
                }
            }
        }
    };

    const pieData = {
        labels: ['En cours', 'Non démarrées', 'Annulées', 'Terminées'],
        datasets: [
            {
                data: [
                totalCounts.countEnCours,
                totalCounts.countInit,
                totalCounts.countAnnuler,
                totalCounts.countTerminer
                ],
                backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0']
            }
        ]
    };

    const pieOptions = {
        plugins: {
            legend: {
                display: true,
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            }
        }
    };

    const lineData = {
        labels: stats.map(item => `${item.month}/${item.year}`),
        datasets: [
            {
                label: 'Commandes non démarrées',
                data: stats.map(item => item.countInit || 0),
                borderColor: 'rgba(255, 159, 64, 1)',
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                fill: true
            },
            {
                label: 'Commandes en cours',
                data: stats.map(item => item.countEnCours || 0),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true
            },
            {
                label: 'Commandes annulées',
                data: stats.map(item => item.countAnnuler || 0),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true
            },
            {
                label: 'Commandes terminées',
                data: stats.map(item => item.countTerminer || 0),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true
            }
        ]
    };

    const lineOptions = {
        scales: {
            x: {
                type: 'category',
                labels: caMensuel.map(item => `${item.month}/${item.year}`)
            },
            y: {
                type: 'linear',
                beginAtZero: true
            }
        }
    };

    return (
        <LayoutTransporter>
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                    paddingBottom: '4rem',
                }}
            >
                <GradientText>{'Statistiques '.toUpperCase()}</GradientText>

                <div
                    style={{
                        width: '100%',
                        fontFamily: 'Montserrat',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        marginBottom: '2rem',
                        marginTop: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <DateRangePicker
                        onChange={setRange}
                        value={range}
                    />
                    <button
                        onClick={handleSearch}
                        style={{
                            padding: '0.6rem 1rem',
                            height: '3rem',
                            background: '#C9D5F7',
                            border: 'none',
                            cursor: 'pointer',
                            color: '#1C63C5',
                            borderRadius: 7,
                            marginLeft: '1rem',
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                        }}
                    >
                        Rechercher
                    </button>
                    <button
                         onClick={handleClear}
                        style={{
                            padding: '0.6rem 1rem',
                            height: '3rem',
                            background: '#C9D5F7',
                            border: 'none',
                            cursor: 'pointer',
                            color: '#1C63C5',
                            borderRadius: 7,
                            marginLeft: '1rem',
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                        }}
                    >
                        Effacer
                    </button>
                </div>

                <div 
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)', 
                        gap: '1rem',
                        marginBottom: '2rem',
                        width: '100%',
                        fontFamily: 'Montserrat',
                        paddingLeft: '2rem',
                        paddingRight: '2rem'
                    }}
                >
                    
                    <div 
                        style={{
                            backgroundColor: '#f0f0f0',
                            padding: '20px',
                            borderRadius: '10px',
                            textAlign: 'center',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                        }}>
                        <h4>Chiffre d'affaires</h4>
                        <p>{caMensuel.reduce((sum, item) => sum + item.totalPrix, 0)} €</p>
                    </div>
                    
                    
                </div>

                <div 
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '1rem',
                        width: '100%',
                        fontFamily: 'Montserrat',
                        paddingLeft: '2rem',
                        paddingRight: '2rem'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#f0f0f0',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <h4>Répartition des commandes</h4>
                        <Pie data={pieData} options={pieOptions} />
                    </div>
                    <div
                        style={{
                            backgroundColor: '#f0f0f0',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <h4>Commandes par mois</h4>
                        <Line data={lineData} options={lineOptions} />
                    </div>
                </div>

                <div 
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        fontFamily: 'Montserrat',
                        marginTop:'1rem',
                        marginLeft:"2rem",
                        marginRight:'2rem',
                        width:'80%'
                    }}
                >
                    <h4>Chiffre d'affaires comparé</h4>
                    <Bar data={barData} options={barOptions} />
                </div>
            </div>
        </LayoutTransporter>
    );
}

export default TransporterStats;

import React, { useState } from 'react'

import File from '../../assets/img/file@2x.png'
import UploadSingleFileCommande from '../UploadSingleFile/UploadSingleFileCommande'
function UpdateUploadFileCommande({ name, onChange, initialFiles = [],commandeId,missionId }) {
	const [files, setFiles] = useState(initialFiles)

	return (
		<div>
			<UploadSingleFileCommande
				name={name}
				accept=' .pdf'
				onFile={(file) => {
					setFiles([...files, file])
					onChange([...files, file])
				}}
                commandeId={commandeId}
                missionId={missionId}
			/>
            
			
			
		</div>
	)
}

export default React.memo(UpdateUploadFileCommande)

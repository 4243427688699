import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { CustomInput } from '../../Containers/RegisterTransporter/style';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { updateSenderCommande } from '../../api/commande'
import  toast from 'react-hot-toast'
import { Controller } from 'react-hook-form';
const UpdateInfomationsSignataire = ({ modalIsOpenUpdate, setModalIsOpenUpdate, commande }) => {
    const { register, handleSubmit, setValue,watch, reset,control, formState: { errors } } = useForm();
    const [updateLoading, setUpdateLoading] = useState(false);
    const token = useSelector(state => state.auth.token);


    const onSubmit = async (data) => {
        setUpdateLoading(true);
        try {
            await updateSenderCommande(data,token,commande._id) 
        } catch (error) {
            console.error('Erreur lors de la demande de transfert', error);
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        } finally {
            setUpdateLoading(false);
            setModalIsOpenUpdate(false);
           
        }
    };
    const onError = (errors) => {
        console.log('Validation errors:', errors);
      
    };
    
    useEffect(() => {
        if (commande) {
            reset({
                phone1: commande?.téléphone1_signataire ,
                phone2: commande?.téléphone2_signataire ,
                lastName: commande?.nom_signataire || '',
                firstName: commande?.prénom_signataire || '',
                email:commande?.email_signataire || ''
            });
        }
    }, [commande, reset]);

    return (
        <Modal
            isOpen={modalIsOpenUpdate}
            onRequestClose={() => setModalIsOpenUpdate(false)}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.45)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 19,
                    background: '#E4EAF0',
                    padding: '2rem',
                    height:"5O0px"

                },
            }}
            contentLabel='Modifier les informations du signateur'
        >
            <div
                style={{
                    width: '100%',
                    fontFamily: 'Montserrat',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 15,
                    textAlign: 'left',
                    color: '#838EAB',
                }}
            >
                <div
						style={{
							color: '#51C7F2',
							fontSize: 30,
							marginBottom: '1rem',
							
						}}
					>
						  Modifier les informations du signataire
					</div>
                <form onSubmit={handleSubmit(onSubmit,onError)}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Nom
                            </span>
                            <CustomInput
                                type="text"
                                {...register('lastName', { required: 'Veuillez indiquer le noms' })}
                                name="lastName"
                            />
                            {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName.message}</p>}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Prénom
                            </span>
                            <CustomInput
                                type="text"
                                {...register('firstName', { required: 'Veuillez indiquer le prénom' })}
                                name="firstName"
                            />
                            {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName.message}</p>}
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                            marginBottom: '1rem',
                            marginTop:"1rem"
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Numéro de Téléphone 1
                            </span>
                            <Controller
						       name='phone1'
						       rules={
								{required: 'Veuillez indiquer le numéro de téléphone .',
							     pattern: {
								 value: /^[0-9]+$/, 
								 message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
								},}
							   }
						      control={control}
	
						      render={({ field: { onChange, value } }) => (
						 	   <PhoneInput 
								country='fr'
								placeholder=''
								value={value}
								onChange={onChange}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'
								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>
						)}
                        />
                            {errors.phone1 && <p style={{ color: 'red' }}>{errors.phone1.message}</p>}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Numéro de Téléphone 2
                            </span>
                            <PhoneInput
                                {...register('phone2', { required: 'Veuillez indiquer le numéro de téléphone' })}
                                value={watch('phone2')}
                                onChange={(newPhoneValue) =>setValue("phone2",newPhoneValue)}
                                country='fr'
                                inputStyle={{
                                    paddingRight: '1.5rem',
                                    paddingTop: '1.5rem',
                                    paddingBottom: '1.5rem',
                                    borderRadius: '0.5rem',
                                    border: 'none',
                                    fontFamily: 'Montserrat',
                                    color: '#858ea8',
                                    flex: '1',
                                    display: 'flex',
                                    width: '100%'
                                }}
                                buttonStyle={{
                                    borderRadius: '0.5rem',
                                    border: 'none',
                                    fontFamily: 'Montserrat',
                                    color: '#858ea8',
                                    flex: '1',
                                    display: 'flex'
                                }}
                            />
                            {errors.phone2 && <p style={{ color: 'red' }}>{errors.phone2.message}</p>}
                        </div>

                    </div>
                    <div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '.5rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail du signataire.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
									{errors.email&& (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
								</div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <button
                                disabled={updateLoading}
                                type='submit'
                                style={{
                                    background: '#50F5A9',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                    fontWeight:'bold',
                                    height:'50px',
                                    width:"120px",
                                    display: 'flex',         
                                    alignItems: 'center',   
                                    justifyContent: 'center'                           
                                     }}
                            >
                                {updateLoading ? <div className='loader'></div> : 'Modifier'}
                            </button>
                            <button
                                type='button'
                                onClick={() => setModalIsOpenUpdate(false)}
                                style={{
                                    background: 'red',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    marginLeft: '1rem',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                    fontWeight:'bold',
                                    height:'50px',
                                    width:"120px"


                                }}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default UpdateInfomationsSignataire;

import React,{ useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import {StyledTextArea,Select } from '../../Containers/PasserCommande/PasserCommande.styles'
import {reattributionMissionChauffeur} from '../../api/commande'
import {getValidChauffeursByTransporter } from '../../api/chauffeurs'
import { useSelector } from 'react-redux'
import  toast from 'react-hot-toast'
import { CustomInput } from '../../Containers/RegisterTransporter/style';

const TransfertMissionChauffeur = ({ modalIsOpenTransfret, setModalIsOpenTransfret, selectedMission,setRefresh }) => {
    const { register, handleSubmit, reset, formState: { errors },control } = useForm();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [chauffeurs,setChauffeurs]=useState([])

    const user = useSelector(state => state.auth.user)
	const token = useSelector((state) => state.auth.token);
   
    
    const onSubmit = async (data) => {
        setUpdateLoading(true);
        try {
            data = {
                ...data,
                missionID:selectedMission,
                transporteurID:user._id
            };

           const res= await reattributionMissionChauffeur(data,token)	
            toast.success(res.message, {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                });
            setRefresh(true)
        } catch (error) {
            if (error.response && error.response.status === 403) {
                toast.error(error.response.data.error, {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                });
            }else{
                toast.error("Une erreur s'est produite", {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                });
            }
        } finally {
            setUpdateLoading(false);
            setModalIsOpenTransfret(false);
            reset()
        }
    };
    useEffect(() => {
		async function fetchChauffeurs() {
			const res = await getValidChauffeursByTransporter(user._id,token)
			setChauffeurs(res)
			
		}

		fetchChauffeurs()
	}, [])
    return (
        <Modal
            isOpen={modalIsOpenTransfret}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.45)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 19,
                    background: '#E4EAF0',
                    width: '600px',
                },
            }}
            contentLabel='Example Modal'
        >
            <div
                style={{
                    width: '100%',
                    fontFamily: 'Montserrat',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 15,
                    textAlign: 'left',
                    color: '#838EAB',
                }}
            >
                <div
                    style={{
                        color: '#51C7F2',
                        fontSize: 30,
                        marginBottom: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                Transférer une mission                
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                    <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginRight: '1rem',
                            }}
                        >
                           <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Chauffeur
                            </span>
                            
                            <Select
				           {...register('chauffeurID',
                            { required: 'Le chauufeur est requis.'    
                            })}
				            >
                            <option value="" >
                            Sélectionner un chauffeur
                            </option>

                           {chauffeurs.map((c) => (
                             <option key={c._id} value={c._id}>
                               {c.first_name} {c.last_name}
                             </option>
                            ))} 
				         </Select>
                            {errors.chauffeurID && (
                                <span style={{ color: 'red', marginTop: '0.5rem' }}>
                                    {errors.chauffeurID.message}
                                </span>
                            )}
                        </div>
                     <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginRight: '1rem',
                                marginTop:'1rem'
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Distance parcourue
                            </span>
                            <CustomInput
                                type='number'
                                placeholder=''
                                min={1}
                                {...register('distance')}

                            />
                        </div> 
                   
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginRight: '1rem',
                                marginTop: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Remarque
                            </span>
                            <StyledTextArea
                                type='text'
                                rows={4}
                                placeholder=''
                                {...register('remarque', { required: 'La remarque est requise.' })}
                            />
                                      {errors.remarque && <span style={{ color: 'red', marginTop: '0.5rem' }}>{errors.remarque.message}</span>}

                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <button
                                type='submit'
                                disabled={updateLoading}
                                style={{
                                    background: '#50F5A9',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                    width:'130px',
                                    display: 'flex',
				         	        justifyContent: 'center',
					                alignItems: 'center'
                                }}
                            >
                                {updateLoading ? (
                                    <div className='loader'></div>
                                ) : (
                                     'Transférer'
                                )}
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    setModalIsOpenTransfret(false);
                                    reset();
                                }}
                                style={{
                                    background: 'red',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    marginLeft: '1rem',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                }}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default TransfertMissionChauffeur;
import React from 'react'
import Footer from '../../Components/Footer/footer.component'
import Logo from '../../assets/img/logo-TPE@2x.png'
import imagebg from '../../assets/img/bg@2x.png'
import { useForm } from 'react-hook-form'
import { forgotPassword } from '../../api/auth'
import { useSnackbar } from 'react-simple-snackbar'
import toast from 'react-hot-toast'
function ForgotPassword() {
    const { register, handleSubmit,formState: { errors, isValid, isDirty }  } = useForm()
    const [openSnackbar] = useSnackbar({
		position: 'top-center',
		style: {
			color: 'black',
			background: 'white',
			fontFamily: 'Montserrat',
		},
		closeStyle: {
			fontSize: '2rem',
			color: '#51C7F2',
		},
	})
    const onSubmit = async (data) => {
		try {
			const res = await forgotPassword(data.email)
            if(res.msg==="RESET_EMAIL_SENT"){
                openSnackbar(
                    `Un e-mail de réinitialisation a été envoyé à ${res.email}. Veuillez vérifier votre boîte de réception.`,
                    120000
               )
            }
		} catch (error) {
			console.log(error)
            toast.error(
				"Cette adresse e-mail n'existe pas. Veuillez vérifier et réessayer.",
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}
	}
  return (
    <div>
    <div
        style={{
            height: '95vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            background:
            `url(${imagebg})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'no-repeat',
        }}
    >
        <div
            style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 50,
            }}
        >
            <img src={Logo} style={{ height: 80 }} alt='' />
        </div>

        <div
            style={{
                padding: '1rem',
                height: '80%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //background: 'red',
                fontFamily: 'Montserrat',
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    background: '#E4EAF0',
                    // width: '25rem',
                    // height: '20rem',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '3rem 4rem',
                    borderRadius: 30,
                }}
            >
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '1rem',
                    }}
                >
                    <span
                        style={{
                            marginBottom: 7,
                            fontSize: 14,
                        }}
                    >

                    


                        Adresse email 

                    


                    </span>
                    <input
                        {...register('email', {
                            required: "Veuillez indiquer l'adresse e-mail.",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Veuillez entrer une adresse e-mail valide',
                              },
                        })}
                        style={{
                            padding: '1rem',
                            fontFamily: 'Montserrat',
                            color: '#95989A',
                            borderRadius: 5,
                            border: 'none',
                            width:"300px",
                            background: 'white',
                        }}
                        name='email'
                        placeholder='Email'
                        type='text'
                    />
                    {errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
                </label>

               

                <button
                    style={{
                        color: 'white',
                        fontFamily: 'Montserrat',
                        background: '#51C7F2',
                        border: 'none',
                        padding: '1rem',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        borderRadius: 6,
                        marginBottom: '1rem',
                    }}
                    type='submit'
                >
                    Envoyer
                </button>

            
            </form>
        </div>
    </div>
    <Footer />
</div>
  )
}

export default ForgotPassword
import 'flatpickr/dist/themes/material_blue.css'

import {
    FormSectionCard,
	FormContentSection,
	FormSectionTitle,
} from './PasserCommande.styles'

import React, { useEffect, useRef, useState } from 'react'

import AddCourse from '../../assets/img/add_course@2x.png'
import Agrandir from '../../assets/img/agrandir@2x.png'
import MiseADispo from './MiseADispo'
import Reduire from '../../assets/img/reduire@2x.png'
import _ from 'lodash'
import { totalDistance } from '../../utils/price'
import { useAtom } from 'jotai'
import MissionsMiseADispo from './MissionsMiseADispo'

function InformationsMiseADispo({
	unregister,
	register,
	watch,
	setValue,
	initialNumberOfMissions = 1,
	display = false,
	keepFilesAsObjects = false,
	control,
	getValues,
	errors,
	setHeur,
	isStepOne,
    isActiverRippeur,
	setPrice
}) {
	const [distance, setDistance] = useAtom(totalDistance)

	const [numberOfMissions, setNumberOfMissions] = useState(initialNumberOfMissions)
	const [collapsedItems, setCollapsedItems] = useState([])

    const _missions = useRef([]);
    const missions = watch('missions');
    
    if (missions) {
        _missions.current = missions;
      }
      
	useEffect(() => {
		setNumberOfMissions(initialNumberOfMissions)
	}, [initialNumberOfMissions])
	useEffect(() => {
		if(!isStepOne  ){
		localStorage.setItem('numberOfMissions', numberOfMissions?.toString());
		}
		if(isStepOne && numberOfMissions!==1 ){
		localStorage.setItem('numberOfMissions', numberOfMissions?.toString());
		}
		  }, [numberOfMissions]);
		  
			
	  
	  useEffect(() => {

		if(isStepOne  ){
			const storedNumberOfMissions = localStorage.getItem('numberOfMissions');
			setNumberOfMissions(parseInt(storedNumberOfMissions))
		}
		
	  }, []);
	
	const [distanceVector, setDistanceVector] = useState(new Map())
	const [priceVector,setPriceVector]= useState(new Map())
  
	useEffect(() => {
		setDistance(_.sum(Array.from(distanceVector.values())))
	}, [distanceVector])
	useEffect(()=>{
		setPrice(_.sum(Array.from(priceVector.values())))
	},[priceVector])
    
    


	return (
		<div>
			

			{[...Array(parseInt(numberOfMissions || 1)).keys()].map((i) => (
				
				<div key={String(i)}>
				
				<div
				
			style={{
				display: 'flex',
				flexDirection: 'row',
				marginTop:'1rem'
			}}
		>
			<FormSectionTitle>Informations de transport pour la mission {i+1}</FormSectionTitle>

			
			</div>
				<FormSectionCard >
					
					<div
						onClick={() =>
							setCollapsedItems(
								collapsedItems.filter((x) => x != i)
							)
						}
						style={{
							position: 'absolute',
							top: 10,
							right: 10,
							cursor: 'pointer',
							display: !collapsedItems.includes(i)
								? 'none'
								: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<span>Agrandir</span>

						<img
							src={Agrandir}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
					</div>

					<div
						onClick={() =>
							setCollapsedItems([...collapsedItems, i])
						}
						style={{
							position: 'absolute',
							bottom: 10,
							right: 10,
							cursor: 'pointer',
							display: collapsedItems.includes(i) || numberOfMissions <= 1
								? 'none'
								: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<span>Réduire</span>
						<img
							src={Reduire}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
					</div>

					{(!display && numberOfMissions-1===i) && (
						<div
							onClick={async () => {
                                if (_missions.current && _missions.current.length > i) {
                                    _missions.current.splice(i, 1);
                                    setNumberOfMissions(numberOfMissions - 1);
                                    localStorage.setItem('numberOfMissions', parseInt(localStorage.getItem('numberOfMissions')) - 1);
                                  
                                        // Supprimer la distance associée à l'index i de distanceVector
                                        const filteredDistanceVector = new Map(
                                            Array.from(distanceVector.entries()).filter(([key, value]) => Number(key) !== i)
                                        );
                                        setDistanceVector(filteredDistanceVector);
										const filteredPriceVector = new Map(
                                            Array.from(priceVector.entries()).filter(([key, value]) => Number(key) !== i)
                                        );
                                        setPriceVector(filteredPriceVector);
                                        
                                        
                                  } else {
                                    console.error('Cannot modify _missions.current: invalid index or undefined array');
                                  }

								setValue('missions', _missions.current)
								unregister(`missions.${i}.date_debut`)
								unregister(`missions.${i}.heure_debut`)
								unregister(`missions.${i}.heure_fin`)
								unregister(`missions.${i}.date_fin`)
								unregister(`missions.${i}.kilo_jour`)
                                unregister(`missions.${i}.nombre_rippeur`)
                                unregister(`missions.${i}.heure_rippeur`)
								unregister(`missions[${i}].manutention_chauffeur`)
								unregister(`missions[${i}].livraison`)
								unregister(`missions[${i}].etage`)
								unregister(`missions[${i}].type_etage`)
							}}
							style={{
								position: 'absolute',
								bottom: collapsedItems.includes(i)
									? 'unset'
									: 10,
								top: collapsedItems.includes(i) ? 10 : 'unset',
								right: 120,
								cursor: 'pointer',
								display: i > 0 ? 'flex' : 'none',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									height: 30,
									width: 30,
								}}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
									/>
								</svg>
							</div>
						</div>
					)}

					<div
						style={{
							display: collapsedItems.includes(i)
								? 'flex'
								: 'none',
							flexDirection: 'row',
						}}
					>
						
					</div>

					{
						i === 0 ? (
							<MiseADispo
								collapsedItems={collapsedItems}
								keepFilesAsObjects={keepFilesAsObjects}
								i={i}
								watch={watch}
								register={register}
								errors={errors} 
								getValues={getValues}
								setValue={setValue}
								control={control}
								display={display}
								distanceVector={distanceVector}
								setDistanceVector={setDistanceVector}
								priceVector={priceVector}
								setPriceVector={setPriceVector}
								setHeur={setHeur}
                                isActiverRippeur={isActiverRippeur}
							/>
						) : (
							<MissionsMiseADispo
								collapsedItems={collapsedItems}
								keepFilesAsObjects={keepFilesAsObjects}
								i={i}
								watch={watch}
								register={register}
								getValues={getValues}
								setValue={setValue}
								control={control}
								display={display}
                                distanceVector={distanceVector}
								setDistanceVector={setDistanceVector}
								priceVector={priceVector}
								setPriceVector={setPriceVector}
								errors={errors} 
								setHeur={setHeur}
                                isActiverRippeur={isActiverRippeur}
							/>
						)
					}

				</FormSectionCard>
				</div>
			))}

			{!display && (
				<div
					onClick={() => setNumberOfMissions(numberOfMissions + 1)}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						cursor: 'pointer',
						marginTop: '2rem',
					}}
				>
					<img
						style={{ height: 40, marginRight: '1rem' }}
						src={AddCourse}
						alt=''
					/>
					<span>Ajouter une missions</span>
				</div>
			)}
		</div>
	)
}

export default InformationsMiseADispo

import React, { useEffect, useState } from 'react';
import { getCommande } from '../../api/commande';
import { useParams, useHistory } from 'react-router';
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles';
import Container from '../../Components/LayoutClient/container.component';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { Select } from '../../Containers/PasserCommande/PasserCommande.styles';
import { addPackage } from '../../api/commande';
import { computePriceForfait,computePriceEtage,computePriceHeure } from '../../utils/price';
import MissionCardForfait from '../../Components/MissionCard/MissionCardForfait';
import Logo from '../../assets/img/logo-TPE.png'
import moment from 'moment';

function ForfaitSupplementaireMission() {
  const { id } = useParams();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const user= useSelector((state) => state.auth.user);
  const tarif = useSelector((state) => state.tarif.tarif);
  const [commande, setCommande] = useState(null);
  const [chargementEnCours, setChargementEnCours] = useState(false);
  const [selectedKilometrage, setSelectedKilometrage] = useState(null);
  const [selectedHeures, setSelectedHeures] = useState(null);
  const [selectedEtages, setSelectedEtages] = useState(null);
  const [selectedMission, setSelectedMission] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenPayment,setModalIsOpenPayment]=useState(false)
  const [isOrderCanceled, setIsOrderCanceled ] = useState(false)
  const [loading, setLoading] = useState(false);
  const [priceTotal,setPriceTotal]=useState(0)
  const [priceEtage,setPriceEtage]=useState(0)
  const [priceKilometrage,setPriceKilometrage]=useState(0)
  const [priceHeure,setPriceHeure]=useState(0)
  const [priceAvecReduction, setPriceAvecReduction] = useState(0);

 

  async function _getCommandeWithLoading(id) {
    setChargementEnCours(true);
    try {
      const commande = await getCommande(id, token);
      setCommande(commande);
    } catch (error) {
      toast.error("Une erreur s'est produite", {
        style: {
          fontFamily: 'Montserrat',
        },
      });
    } finally {
      setChargementEnCours(false);
    }
  }

  const handleMissionChange = (e) => {
    const missionId = e.target.value;
    const mission = commande?.missions?.find((m) => m._id === missionId);
    setSelectedMission(mission);
  }; 
  const convertToDecimal=(timeString)=> {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + (minutes / 60);
  }
  const extractHoursFromForfaits = (forfaits) => {
    return forfaits?.reduce((total, forfait) => {
      if (forfait.type === "heure" || forfait.type === "heure_etage") {
        total += convertToDecimal(forfait.details.heures);
      }
      return total;
    }, 0);
  };

  const calculateTotalHours = (dateDebut, heureDebut, dateFin, heureFin,extraHours) => {
    const startDateTime = `${dateDebut} ${heureDebut}`;
    const endDateTime = `${dateFin} ${heureFin}`;

    const startMoment = moment(startDateTime, 'DD/MM/YYYY HH:mm');
    const endMoment = moment(endDateTime, 'DD/MM/YYYY HH:mm');

    let totalHours = endMoment.diff(startMoment, 'hours', true);

    // Add extra hours to the total
    
     totalHours += extraHours;

    return totalHours;
  };
  const calculateHeuresSupplementaires = () => {
    if (!selectedMission) return 0;
    const extraHours = extractHoursFromForfaits(selectedMission?.forfaits);
    const totalHours = calculateTotalHours(
      selectedMission.date_debut,
      selectedMission.heure_debut,
      selectedMission.date_fin,
      selectedMission.heure_fin,
      extraHours
    );

    const intervalAutorise = 12;

    return intervalAutorise > totalHours ? intervalAutorise -totalHours   : 0;
  };
  const generateHourOptions = () => {
    const heuresSupplementaires = calculateHeuresSupplementaires();
    const options = [];

    for (let i = 1; i <= heuresSupplementaires; i++) {
      options.push(`${i}:00`);
      if (i < heuresSupplementaires) {
        options.push(`${i}:30`);
      }
    }

    return options;
  };
  const handleAddPackage = async () => {
   
  
    let selectedPackage = null;
    let additionalDetails = {};
  
    if (selectedKilometrage && selectedEtages) {
      selectedPackage = 'kilometrage_etage';
      additionalDetails = {
        kilometrage: selectedKilometrage,
        etages: selectedEtages,
      };
    }  else if (selectedHeures && selectedEtages) {
      selectedPackage = 'heure_etage';
      additionalDetails = {
        heures: selectedHeures,
        etages: selectedEtages,
      };
    } else if (selectedKilometrage) {
      selectedPackage = 'kilometrage';
      additionalDetails = {
        kilometrage: selectedKilometrage,
      };
    } else if (selectedHeures) {
      selectedPackage = 'heure';
      additionalDetails = {
        heures: selectedHeures,
      };
    } else if (selectedEtages) {
      selectedPackage = 'etage';
      additionalDetails = {
        etages: selectedEtages,
      };
    } 
    setLoading(true);
    try {
      const response = await addPackage(selectedMission._id, { forfaitType: selectedPackage, forfaitPrice: user?.reduction ? priceAvecReduction :priceTotal ,additionalDetails}, token);
      window.location.href = response.url;
    } catch (error) {
      console.error('Error adding package:', error);
      toast.error("Une erreur s'est produite lors de l'ajout du forfait.", {
        style: {
          fontFamily: 'Montserrat',
        },
      });
    } finally {
      setModalIsOpen(false);
      setLoading(false);
    }
  };
  const showPrice = () => {
    let result = '';
  
    if (priceKilometrage) {
      result += priceKilometrage.toFixed(2);
    }
  
    if (priceHeure) {
      result += (result ? ' + ' : '') + priceHeure.toFixed(2);
    }
  
    if (priceEtage) {
      result += (result ? ' + ' : '') + priceEtage.toFixed(2);
    }
  
    return result;
  };
  useEffect(() => {
    if (id) {
      _getCommandeWithLoading(id);
    }
  }, [id]);
  useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const success = params.get('success');
		if (success === 'true') {
			setModalIsOpenPayment(true)
			setIsOrderCanceled(false)
		}
		if (success === 'false') {
			setModalIsOpenPayment(true)
			setIsOrderCanceled(true)
		}
    params.delete('success');
    history.replace({
      search: params.toString()
    });
	},[history])
  useEffect(()=>{
    if(selectedKilometrage){
      setSelectedHeures(null)
      setPriceHeure(0)
    }else if(selectedHeures){
      setSelectedKilometrage(null)
      setPriceKilometrage(0)
    }

  },[selectedHeures,selectedKilometrage])

  useEffect(()=>{
    setPriceTotal(priceKilometrage+priceHeure+priceEtage)
  },[priceEtage,priceHeure,priceKilometrage])
  useEffect(()=>{
  setPriceAvecReduction(Number(priceTotal) - (Number(priceTotal) * user?.reduction) / 100)
  },[priceTotal])
  useEffect(()=>{
    
    if(selectedKilometrage&&selectedMission){
      const heureSupp=extractHoursFromForfaits(selectedMission?.forfaits)
      setPriceKilometrage(computePriceForfait(tarif,selectedMission,selectedKilometrage, calculateTotalHours(selectedMission?.date_debut,selectedMission?.heure_debut,selectedMission?.date_fin, selectedMission?.heure_fin,heureSupp)))
    }else{
      setPriceKilometrage(0)
    }
   
  },[selectedKilometrage,selectedMission])
  useEffect(()=>{
    if(selectedMission && selectedHeures){
      setPriceHeure(computePriceHeure(tarif,selectedMission,selectedHeures)) 
    }else{
      setPriceHeure(0)
    }
  },[selectedHeures,selectedMission])
  useEffect(()=>{
    if(selectedMission && selectedEtages){
      setPriceEtage(computePriceEtage(tarif,selectedMission,selectedEtages))
    }else{
      setPriceEtage(0)
    }
  },[selectedMission,selectedEtages])

  return (
    <Container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: '2rem',
          paddingBottom: '4rem',
          fontFamily: 'Montserrat',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => {
              history.push('/commandes/list');
            }}
            style={{
              background: '#51C7F2',
              width: '3rem',
              height: '3rem',
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width={14.017} height={14.11} viewBox='0 0 14.017 14.11'>
              <g data-name='Group 11287' fill='none' stroke='#fff' strokeWidth={2}>
                <path data-name='Path 11687' d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232' transform='translate(-8.838 -11.113)' />
                <path data-name='Path 11688' d='M22.147 11.818l-6.348 6.349 6.348 6.348' transform='translate(-8.838 -11.113)' />
              </g>
            </svg>
          </div>

          <div
            style={{
              width: '1rem',
            }}
          />

          <GradientText marginTop={'unset'}>
            {`Forfait supplémentaire`.toUpperCase()}
          </GradientText>
        </div>
        <div
					style={{
						position: 'fixed',
						top: 250,
						right: 50,
						zIndex: 999,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							padding: '1rem 1rem',
							background: '#838EAB',
							color: 'white',
							fontFamily: 'Montserrat',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',

							borderRadius: '0.5rem',
							fontSize: 20,
						}}
					>
            <span>Prix Total </span>
            <span style={{ fontWeight: 'bold',marginBottom:'.5rem'}}>{priceTotal?.toFixed(2)}€ ht</span>
            <span style={{ fontWeight: 'bold' }}>{showPrice()}</span>
            {!!user?.reduction && (
						<><span>Prix avec remise</span>
						<span style={{ fontWeight: 'bold' }}>
							{ priceAvecReduction?.toFixed(2)}€ ht
						</span></>) }
          </div>
          </div>

        {chargementEnCours ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
              marginTop: '10rem',
            }}
          >
            <div className='customLoader'></div>
          </div>
        ) : (
          <div 
		  style={{
			
			width: '100%',
			fontFamily: 'Montserrat',
			paddingLeft: '3rem',
			paddingRight: '3rem',
			marginBottom: '2rem',
			marginTop: '1rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			
		}}>
      
        <div
        style={{
				background: '#f8f9fa',
				borderRadius: '10px',
				padding: '2rem',
				marginBottom: '5rem',
				boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
				width: '100%',
		
			}}
            >
		
              <div style={{ marginTop: '1rem' }}>
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Mission :
                </span>
             
              <div style={{ marginTop: '1rem' }}>
                <Select value={selectedMission?._id || ''} onChange={handleMissionChange}>
                  <option value=''>Sélectionner une mission</option>
                  {commande?.missions?.
                  filter((mission)=>mission.statut==="en-cours")
                  .map((mission, index) => (
                    <option key={index} value={mission._id}>
                     Mission N°{index + 1} - {mission.date_debut} {mission.heure_debut} - {mission.date_fin} {mission.heure_fin}  
                    </option>
                  ))}
                </Select>
              </div>
              </div>
              <div style={{ marginTop: '1rem' }}>
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                   Kilométrage supplémentaire :
                </span>
             
              <div style={{ marginTop: '1rem', position: 'relative' }}>
                <Select 
                value={selectedKilometrage || ''}
                onChange={(e) => {
                  setSelectedKilometrage(e.target.value)
                 
                }}
                disabled={!!selectedHeures}
                 >
                  <option value=''>Sélectionner le kilométrage</option>
                  <option value='200'>200</option>
                  <option value='300'>300</option>
                  <option value='400'>400</option>
                </Select>
                <span
                  style={{
                    position: 'absolute',
                    right: '3rem',
                    top: '1rem',
                  }}
                >
                  KM
                </span>
              </div>
              </div>
              {!selectedMission?.forfaits?.some((forfait)=>forfait.type==='kilometrage'||  forfait.type==='kilometrage_etage') &&
              <div style={{ marginTop: '1rem' }}>
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                   Heures supplémentaires :
                </span>
             
              <div style={{ marginTop: '1rem' }}>
              <Select 
              value={selectedHeures || ''} 
              onChange={(e) => {
                setSelectedHeures(e.target.value)
              }}
              disabled={!!selectedKilometrage}
              >
              <option value=''>Sélectionner les heures </option>
              {generateHourOptions().map((hour, index) => (
              <option key={index} value={hour}>
              {hour}
              </option>
              ))}
              </Select>
              </div>

              </div>
              }
              {selectedMission?.manutention_chauffeur==="Oui" || selectedMission?.nombre_rippeur &&
              <div style={{ marginTop: '1rem' }}>
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                   Étages supplémentaires :
                </span>
             
              <div style={{ marginTop: '1rem' }}>
              <Select value={selectedEtages || ''} onChange={(e) => {
                setSelectedEtages(e.target.value)
              }}
                >
              <option value=''>Sélectionner l'étages </option>
              <option value='1-10' >1-10</option>
							<option value='11-20' >11-20</option>
							<option value='21-30' >21-30</option>
              </Select>
              </div>

              </div>
              }
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  onClick={()=>{
                    if (!selectedMission || (!selectedKilometrage && !selectedHeures && !selectedEtages)) {
                      toast.error("Veuillez sélectionner une mission et un forfait approprié.", {
                        style: {
                          fontFamily: 'Montserrat',
                        },
                      });
                      return;
                    }
                    setModalIsOpen(true);

                  }}
                  style={{
                    marginTop: '1rem',
                    background: '#50F5A9',
                    padding: '1rem 2rem',
                    color: 'white',
                    borderRadius: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    border: 'none',
                    width: '200px',
                    height: '50px',
                  }}
                >
                  Ajouter
                </button>
              </div>
			
		
            </div>
			
			{commande?.missions.map((mission, index) => (
        mission?.forfaits?.length>0 &&
            <MissionCardForfait 
            key={index}
		         mission={mission}
		         commande={commande}
		         index={index}
		        />
           ))}
		  
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 19,
            padding: '2rem',
          },
        }}
        contentLabel='Ajouter Forfait'
      >
        <div
          style={{
            width: '20rem',
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              fontFamily: 'Montserrat',
              fontSize: 20,
              color: '#51C7F2',
            }}
          >
            Ajouter Forfait
          </div>
          <div
            style={{
              marginTop: 4,
              width: '100%',
            }}
          >
            <p>Êtes-vous sûr de vouloir ajouter ce forfait ?</p>
          </div>
          <div
            style={{
              width: '100%',
              fontFamily: 'Montserrat',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              marginBottom: '5px',
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <button
            onClick={async () => {
              await handleAddPackage();
            }}
			       disabled={loading}
              style={{
                width: '150px',
                height: '50px',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 5,
                fontFamily: 'Montserrat',
                marginRight: 10,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loading ? <div className='loader'></div> : 'Confirmer'}
            </button>
            <button
              onClick={() => {
                setSelectedMission(null)
                setModalIsOpen(false);
              }}
              style={{
                width: '150px',
                height: '50px',
                background: 'red',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 5,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
              }}
            >
              Annuler
            </button>
          </div>
        </div>
      </Modal>

      <Modal
				isOpen={modalIsOpenPayment}
				onRequestClose={() => setModalIsOpenPayment(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
					<img
					style={{
						width: '10rem',
						backgroundColor: 'grey',
						padding: '2px'
					}}
					alt='logo'
					src={Logo}
				/>
						<h1>{isOrderCanceled ? "Erreur de paiement": "paiement effectué avec succès"}</h1>
					</div>

					<button
						onClick={() => setModalIsOpenPayment(false)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
    </Container>
  );
}

export default ForfaitSupplementaireMission;

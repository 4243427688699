import React, {  useState } from 'react'
import {
	updateStatutCommandeForChauffeur,
	getCommande,
} from '../../api/commande'
import moment from 'moment'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
function CourseACourseHorizontal({
	commande,
	onClickDetails = () => {},
	onClickSignaler = () => {},
	hideStatus = false,
	hideTransfert=true,
	hideSignaler,
	
	enableSaveStatus = false,
	
	setModalIsOpenStatus,
	
	setUpdateStatus,
	setModalIsOpenStatusInitTerminer,
	setMessage
	

}) {
	const [statut, setStatut] = useState(commande.statut)
	const user = useSelector(state => state.auth.user)
	const token = useSelector((state) => state.auth.token);
	const [commandeData,setCommandeData]=useState({})
	const [modalIsOpenStatusSendSMS,setModalIsOpenStatusSendSMS]=useState(false)

	



	return (
		<div
			style={{
				width: '100%',
				background: '#E4EAF0',
				display: 'flex',
				flexDirection: 'row',
				padding: '1rem',
				borderRadius: 10,
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginBottom: 10,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						marginBottom: 3,
					}}
				>
					Course à course {!!commande ? ' N°' + commande.__id : '' } /{' '}
					<span style={{ color: 'red' }}>
						{commande.temporisation === 'immediat'
							? 'Immédiat'
							: 'Planifier'}
					</span>
				</span>

				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					Date du Transports :{' '}
					<span
						style={{
							marginRight: 15,
							
						}}
					>
						{commande.courses[0]?.date_debut}
					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					Date du Création :{' '}
					<span
						style={{
							marginRight: 15,
						}}
					>
				    {moment(commande.createdAt).format('DD/MM/YYYY')}
					</span>
				</span>
				
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,
					}}
				>
					<span
						style={{
							marginRight: 15,
						}}
					>
				      {moment(commande?.courses[0]?.heure_debut).isValid() ?
                      moment(commande?.courses[0]?.heure_debut).format('hh:mm') 
                      :commande?.courses[0]?.heure_debut}
					  {commande.courses[0]?.adresse_debut?.label}
					</span>

					<br />
					<span
						style={{
							marginRight: 15,
						
						}}
					>
						{moment(commande.courses[0]?.heure_fin).isValid() ? 
                         moment(commande.courses[0]?.heure_fin).format('HH:mm') 
                        :commande.courses[0]?.heure_fin}
						{commande.courses[0]?.adresse_fin?.label}
					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					 L'état de paiement :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
				 {commande.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					 Statut de la commande :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {commande?.statut==="init" && "Non commencée"}
             {commande?.statut==="en-cours" && "En cours"}
             {commande?.statut==="terminer" && "Terminée"}
             {commande?.statut==="annuler" && "Annulée"}
					</span>
				</span>
				{(!hideTransfert && commande.recu && commande.statut==="en-cours") && (
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
					}}
				>
					 Statut de Transfert :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {commande?.statutTransfert === 'transferé' && "Transferé"}
             {commande?.statutTransfert==="en cours de transfert" && "En cours de transfert"}
             {commande?.statutTransfert==="aucun transfert" && "Aucun transfert"}

					</span>
				</span>
				)}
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,
					}}
				>
					<span style={{ color: 'red' }}>
						+{commande?.courses?.length >= 1 ? commande.courses.length -1 : 0} Etapes{' '}
					</span>
					<span
						style={{ cursor: 'pointer' }}
						onClick={onClickDetails}
					>
						Consulter le détail
					</span>
				</span>
				{user?.role==="client" &&
				<span
				 style={{
					color: '#838EAB',
					marginTop: 10,
				}}>
				Affectation:{' '}
				<span style={{ color: 'red' }}>
				{!commande.transporterID && 'Non Affecté'}
				 {(commande.transporterID && !commande.chauffeurID ) && 'Transporteur Affecté' }
				 {(commande.chauffeurID && commande.transporterID) &&'Chauffeur Affecté'}
				</span>
				</span>
                }
			</div>

			{/*  */}

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						marginBottom: 3,
						color: '#838EAB',
					}}
				>
					Documents :{' '}
					<span style={{ color: 'red' }}>
						{/* {Number(commande?.courses[0]?.files_debut?.length) +
							Number(commande?.courses[0]?.files_fin?.length)} */}
					</span>
					<span style={{ marginLeft: '1rem' }}>
						Référence interne client :{' '}
						<span style={{ color: 'red' }}>
							{commande.ref_client}
						</span>
					</span>
				</span>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '1rem',
					}}
				>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						{commande.type_transport}
						{' : '}
						{commande.type_transport==="Poids lourds" ? commande.vehicule_poids_lourds : commande.type_transport_details} 
					</span>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						{commande.options_vehicule_leger.join(',')}
					</span>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						{commande.options_poids_lourds.join(',')}
					</span>
					<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',
						}}
					>
						Notes utiles:{' '}
						<span style={{ color: 'red' }}>
							{commande?.courses[0]?.notes_debut ||commande?.courses[0]?.notes_fin ? 'Oui' : 'Non'}
						</span>
					</span>
					<span
						style={{
							color: '#838EAB',
						}}
					>
						Nombre de ripper en plus du chauffeur :{' '}
						<span style={{ color: 'red' }}>
							
							{(commande.rippeurs===undefined || commande.rippeurs===null )? '0' : commande.rippeurs}
							
						</span>
					</span>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				
				
				
                 {!hideStatus &&
				<div
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						onClick={async () => {
							if (enableSaveStatus) {
								try {
									await updateStatutCommandeForChauffeur(
										commande._id,
										'en-cours',
										token
									)
									setStatut('en-cours')	
									setUpdateStatus(true)
								} catch (error) {
									setMessage(error.response.data.error)
									setModalIsOpenStatus(true)
									
								}
								
							}
						}}
						style={{
							padding: '0.3rem 0.7rem',
							background:
								statut === 'en-cours' ? '#C752B6' : '#838EAB',
							color: statut === 'en-cours' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						En cours
					</span>

					<span
						onClick={onClickSignaler}
						style={{
							padding: '0.3rem 0.7rem',
							background: commande?.signaler
								? '#FCC200'
								: '#838EAB',
							color: commande?.signaler ? 'white' : '#9EA8BF',
							cursor: 'pointer',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							width: '4.5rem',
						}}
					>
						Signaler{' '}
						{commande?.signaler && `+${commande.signalement_count}`}
					</span>

					<span
						onClick={async () => {
							if (enableSaveStatus) {
							const commandeData = await getCommande(commande._id,token)
							let aucunCoursLivre;
							aucunCoursLivre = commandeData?.courses.some(course => course?.livrée === false);

							console.log("aucunCoursLivre",aucunCoursLivre)
							if(commandeData?.statut==="init"){
								setModalIsOpenStatusInitTerminer(true)
							}
							else{
								try {
									await updateStatutCommandeForChauffeur(
										commande._id,
										'terminer',
										token
									)
									setStatut('terminer')
									setUpdateStatus(true)
								} catch (error) {
								    setMessage(error.response.data.error)
									setModalIsOpenStatus(true)
								}
							}
					
								
								
								
							}
						}}
						style={{
							padding: '0.3rem 0.7rem',
							background:
								statut === 'terminer' ? '#0FD39E' : '#838EAB',
							color: statut === 'terminer' ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							marginBottom: 5,
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						Terminer
					</span>
					{/* <span

						style={{
							padding: '0.3rem 0.7rem',
							background:commande?.statut ==="annuler" ?'red' :'#838EAB',
							
							color:commande?.statut ==="annuler"   ? 'white' : '#9EA8BF',
							borderRadius: 50,
							fontWeight: 'bold',
							fontSize: 10,
							cursor: 'pointer',
							width: '4.5rem',
						}}
					>
						Annuler
					</span> */}
					
				</div>
                }
			</div>

			{/*  */}

			<div
				style={{
					color: '#838EAB',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span>
					Diable:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Diable') && 'Oui'}
					</span>
				</span>
				<span>
					Transpalette manuel:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Transpalette manuel') &&
							'Oui'}
					</span>
				</span>
				<span>
					Transpalette électrique:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes(
							'Transpalette électrique'
						) && 'Oui'}
					</span>
				</span>
				<span>
					Sangle:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Sangle') && 'Oui'}
					</span>
				</span>

				<span>
					Casque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Casque') && 'Oui'}
					</span>
				</span>
				<span>
					Gants:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gants') && 'Oui'}
					</span>
				</span>
				<span>
					Masque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Masque') && 'Oui'}
					</span>
				</span>
				<span>
					Lunettes:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Lunettes') && 'Oui'}
					</span>
				</span>
				<span>
					Chaussure de sécurité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Chaussure de sécurité') &&
							'Oui'}
					</span>
				</span>
				<span>
					Gilet de visibilité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gilet de visibilité') && 'Oui'}
					</span>
				</span>
			</div>
			<div
				style={{
					//width: '16%',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					marginTop: 35,
				}}
			>
				
				
				<button
					onClick={onClickDetails}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir détails
				</button>
				{ hideStatus && !hideSignaler &&
				<button
				onClick={onClickSignaler}
				style={{
					padding: '0.5rem 1.5rem',
					background: '#838EAB',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Signalements
			   </button>
				}
				
				
				
				
				
				
				
				

                
			</div>
			<Modal
    isOpen={modalIsOpenStatusSendSMS}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
Vous ne pouvez pas marquer la commande comme terminée tant que toutes les courses n'ont pas été livrées.		 </div>

        <button
            onClick={() => {
                setModalIsOpenStatusSendSMS(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		  
			

		</div>
	)
}

export default React.memo(CourseACourseHorizontal)

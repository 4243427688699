import React, {  useState } from 'react'
import { verifyCodeSMSMission } from '../../api/commande'
import { StyledInput } from '../../Containers/PasserCommande/PasserCommande.styles'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { IoEyeSharp } from "react-icons/io5"
import { useHistory } from 'react-router'

function MissionCard({mission,index,commande,setChargement}) {
	const history=useHistory()
    const [loadingverifySMS,setLoadingverifySMS]=useState(false)
	const [enteredCode, setEnteredCode] = useState('');
    const token = useSelector((state) => state.auth.token);
  return (
<div
            style={{
                width: '100%',
                background: mission?.signé ?'#E6F7FF' :'#E4EAF0',
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                borderRadius: 10,
                marginBottom: 10,
				position:'relative'
            
            }}
        >
            <span
					style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						marginBottom: '1rem',
                        fontSize: 13,
						display: 'flex',
		                justifyContent: 'space-between',   
					}}
				>
					<span>
					Mission N° {index+1} pour la commande {!! commande ? 'N°' + commande?.__id : '' } /{' '}
					<span style={{ color: 'red' }}>
						{commande.temporisation === 'immediat'
							? 'Immédiat'
							: 'Planifier'}
					</span>
					</span>
				   
					<button 
				    onClick={async()=>{
						history.push(`/client/points-passage/${mission._id}`)
				    }}
				style={{
					padding: '1rem 2rem',
					background: '#60d2f8',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					marginRight: '1rem',					
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height:"50px",

				}}>
             Suivi des livraisons
                </button>
			</span>
             {!mission.signé && 
             <div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '1rem',
                    width:'100%'
				 }}>
                 <div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 13,
                        fontFamily: 'Montserrat',

					    }}
						>
				       Introduit le code 					 
				</span>
				<StyledInput
							type='text'
							placeholder=''
							value={enteredCode}
                           onChange={(e) => setEnteredCode(e.target.value)}

							
				/>
				
				</div>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
					 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				     					 
				</span>
					<button 
					disabled={loadingverifySMS}
				    onClick={async(e)=>{
					setLoadingverifySMS(true)
					e.preventDefault()
					try {
						const response=await verifyCodeSMSMission({"missionId":mission._id,"code":enteredCode},token)
						setEnteredCode('')
						toast.success(response.message, {
								style: {
									fontFamily: 'Montserrat',
								},
						})
						setChargement(true)
						
							
						} catch (error) {
							toast.error("Une erreur s'est produite", {
								style: {
									fontFamily: 'Montserrat',
								},
							})						
						}finally{
							setLoadingverifySMS(false)
						}
				}}
				style={{
					padding: '1rem 2rem',
					background: '#60d2f8',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					marginRight: '1rem',
					marginTop:'1rem',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height:"50px",

				}}>
              {loadingverifySMS ? (
									<div className='loader'></div>
								) : (
									'  Valider le code'
								)}
                </button>
					</div>	
			</div>
             }
            <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
					}}
				>
					<span
						style={{
							marginBottom: '.5rem',
							color: 'black',
							fontSize: 13,
                            fontFamily: 'Montserrat',

						}}
					>
						Signé
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
					}}>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							disabled={true}
							 style={{
								background:mission?.signé ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: mission?.signé && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:mission?.signé ?'#fff': '#444'
							}}
							>Oui</button>
							
						
						</div>
						<div
							
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							 disabled={true}
							 style={{
								background:!mission?.signé ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: !mission?.signé && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:!mission?.signé ?'#fff': '#444'
							}}
							>Non</button>
						</div>
					
					</div>					
				
				</div>
        </div>
  )
}

export default MissionCard
import React, {  useState } from 'react'
import moment from 'moment'


function MissionCardForfait({mission,commande,index}) {
	const getForfaitDetails = (forfait) => {
        switch (forfait.type) {
            case 'kilometrage':
            case 'kilometrage_etage':
                return (
                    <>
                        <span 
                        style={{
                            marginRight: 13,
                            marginTop: "0.5rem",
                            marginBottom: '0.5rem',
                            color: '#838EAB',
                            width: '15rem',
                        }}>
                            Kilométrage :{' '}
                            <span style={{ color: "red" }}>
                                {forfait.details?.kilometrage ?? 'N/A'} km
                            </span>
                        </span>
                        <span 
                        style={{
                            marginRight: 13,
                            marginTop: "0.5rem",
                            marginBottom: '0.5rem',
                            color: '#838EAB',
                            width: '15rem',
                        }} >
                            Étages :{' '}
                            <span style={{ color: "red" }}>
                                {forfait.details?.etages ?? '0'}
                            </span>
                        </span>
                    </>
                );
            case 'heure':
            case 'heure_etage':
                return (
                    <>
                        <span 
                        style={{
                            marginRight: 13,
                            marginTop: "0.5rem",
                            marginBottom: '0.5rem',
                            color: '#838EAB',
                            width: '15rem',
                        }}>
                            Heures :{' '}
                            <span style={{ color: "red" }}>
                                {forfait.details?.heures ?? 'N/A'}
                            </span>
                        </span>
                        <span  
                        style={{
                            marginRight: 13,
                            marginTop: "0.5rem",
                            marginBottom: '0.5rem',
                            color: '#838EAB',
                            width: '15rem',
                        }}>
                            Étages :{' '}
                            <span style={{ color: "red" }}>
                                {forfait.details?.etages ?? '0'}
                            </span>
                        </span>
                    </>
                );
            case 'etage':
                return (
                    <>
                        <span 
                        style={{
                            marginRight: 13,
                            marginTop: "0.5rem",
                            marginBottom: '0.5rem',
                            color: '#838EAB',
                            width: '15rem',
                        }} >
                            Étages :{' '}
                            <span style={{ color: "red" }} >
                                {forfait.details?.etages ?? '0'}
                            </span>
                        </span>
                    </>
                );
            default:
                return (
                    <span >
                        Aucun détail disponible
                    </span>
                );
        }
    };
  return (
    <div
	style={{
		width: '100%',
		background: '#E4EAF0',
		display: 'flex',
		flexDirection: 'column',
		padding: '1rem',
		borderRadius: 10,
		marginBottom: 10,
		position:'relative'
	
	}}
        >
        <div style={{
		marginBottom:'1rem',
		display: 'flex', 
		flexDirection: 'row', 
		justifyContent: 'space-between'
		}}>
		<span
		style={{
			fontWeight: 'bold',
			color: '#51C7F2',
			fontSize: 13,
		}}
	>
		Mise a disposition {commande ? 'N°' + commande.__id : '' } /{' '}
		<span style={{ color: 'red' }}>
			{commande.temporisation === 'immediat'
				? 'Immédiat'
				: 'Planifier'}
		</span>
	    </span>
		</div>
		
		
		<div>
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '100%' }}>
        {mission.forfaits.map((forfait, index) => (
            <div key={index}> 
                <span
                    style={{
                        fontWeight: 'bold',
                        color: '#51C7F2',
                        fontSize: 13,
                    }}
                >
                    Forfait N° {index + 1} {' '}{forfait.type}
                </span>
                <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                
                <div
                    style={{
                        width: '80%',
                        background: '#E4EAF0',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '1rem',
                        borderRadius: 10,
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            color: '#838EAB',
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 13,
                        }}
                    >
                       {getForfaitDetails(forfait)}
                        
                    </div>
					<div
                    
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					
					<span
                            style={{
                                marginRight: 13,
                                marginTop: "0.5rem",
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                        Prix : {' '}
                            <span style={{ color: "red" }}>
							{forfait.prix} €
                            </span>
                        </span>
					
				</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                            fontSize: 13,
                        }}
                    >
                      <span
                            style={{
                                marginRight: 13,
                                marginTop: "0.5rem",
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                        Statut du paiement   : {' '}
                            <span style={{ color: "red" }}>
							{forfait?.paymentStatus} 
                            </span>
                        </span>  
                    </div>
					<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                            fontSize: 13,
                        }}
                    >
                      <span
                            style={{
                                marginRight: 13,
                                marginTop: "0.5rem",
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                        Date du paiement   : {' '}
                            <span style={{ color: "red" }}>
							{moment(forfait?.datePaiement).format('DD/MM/YYYY')}
							
                            </span>
                        </span>  
                    </div>
                    
                    
                             
                </div>
				
                </div>
         
 
 
            </div>
        ))}
    </div>
   
</div>
			
			
			
				
    </div>
	</div>
  )
}

export default MissionCardForfait
import React, { useEffect, useState } from 'react'
import { getValidClients,getClientsForFacturation,getClientsByDateForFacturation } from '../../../api/clients'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { getStats } from '../../../api/stats'
import moment from 'moment'
import { useHistory } from 'react-router'
import Select from 'react-select'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function Facturation(props) {
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [searchQuery, setSearchQuery] = useState('')
	const [selectedDate, setSelectedDate] = useState(null);
	const [montantTotal, setMontantTotal] = useState(null)
	const [loadingStats, setLoadingStats] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const [searchCriteria, setSearchCriteria] = useState('id')
	 const handleChange = (selectedOption) => {
		setSelectedOption(selectedOption);
		setSearchCriteria(selectedOption.value)
	  };
 
	const fetchClients = async (page) => {
		setChargementEnCours(true)
		const res = await getValidClients(page, perPage,token)
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)
		setSelectedDate(null)
		setSearchQuery('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		  setSearchCriteria("id")
	}
	
	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchClients(page)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getValidClients(page, perPage,token)
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}

	const fetchStats = async () => {
		setLoadingStats(true)

		const res = await getStats(token)

		
		setMontantTotal(res.montantTotal)

		setLoadingStats(false)
	}

	useEffect(() => {
		fetchClients(1)

		fetchStats()
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:"200px"
		},
		{
			name: 'Nom du client',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:"200px"

		},
		{
			name: 'Email',
			selector: (row) => ` ${row.email}`,
			sortable: false,
			width:"250px"

		},
		{
			name: 'Type de compte',
			selector: (row) => (row.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width:"150px"
		},
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:"150px"
		},
		{
			name: '% de réduction',
			selector: (row) => (row.reduction ? `${row.reduction}%` : '0%'),
			sortable: false,
			width:"150px"
		},
		{
			name: 'Status',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
			width:"150px"
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(
								`/admin/plateforme/facturation/${row._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Facturation'.toUpperCase()}
				</GradientText>
				<div style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						justifyContent: 'flex-end',
					}}>
				<div
						style={{
							//background: 'red',
					        width:'40%',
							padding: '1rem',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							border: 'solid .1rem black',
							borderRadius: '1rem',
							marginRight: '1rem',
						}}
					>
					

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<span>Montant total</span>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: 20,
								}}
							>
								{montantTotal}
							</span>
						</div>
					</div>
					</div>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{ value: 'id', label: "Recherche par l'identifiant " },
								
								{ value: 'date', label: "Date d'inscription" },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							placeholder='Recherche'
						/>
					) : null}
						{searchCriteria === 'date' ? (
						<input
						    type='date'
							value={selectedDate}
							onChange={(e) => setSelectedDate(e.target.value)}
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							placeholder='Recherche'
						/>
					) : null}
					<button
						onClick={async () => {
							if (
								searchCriteria === 'id' &&
								searchQuery.length === 0
							) {
								return
							}
							let res
							if (searchCriteria === 'id') {
								res =await getClientsForFacturation(searchQuery,token)
							}
							if (searchCriteria === 'date') {
								res =await getClientsByDateForFacturation (selectedDate,token)

							}
							setData(res.docs)
							
							// searchForClient(searchQuery)
						}}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							// marginTop: 20,
							// marginLeft: 20,
							marginLeft: '1rem',
						fontFamily: 'Montserrat',
							fontWeight: 'bold',
							marginRight: '1rem',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={async () => {
							await fetchClients(currentPage)
						
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginRight: '0.5rem',
							
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
			
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des clients à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
		</LayoutAdmin>
	)
}

export default Facturation

import React,{useState,useEffect} from 'react'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import {getChauffeurs,getValidChauffeurs,getNotValidChauffeurs,getChauffeursByTransporter} from '../../api/chauffeurs'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router'
import {fullTextSearchChauffeur} from '../../api/search'
import { getValidTransporters} from '../../api/transporteurs'

import moment from 'moment'
function MesChauffeurs() {
    const history = useHistory()
    const token = useSelector((state) => state.auth.token);
    const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [filterStatus, setFilterStatus] = useState('all');
	const [searchQuery, setSearchQuery] = useState('')
    const [transporters,setTransporters]=useState([])
    const [selectedTransporter, setSelectedTransporter] = useState('');
    const fetchChauffeur = async (page) => {
		setChargementEnCours(true)	
        let res;
		 if (filterStatus === 'all') {
            res = await getChauffeurs(page, perPage,token)
		 }	
	     if (filterStatus === 'validated') {
			res=await getValidChauffeurs(page,perPage,token)
		 }
         if (filterStatus === 'notValidated') {
			res=await getNotValidChauffeurs(page,perPage,token)
		 }
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setChargementEnCours(false)
	}
    const fetchTransporters=async()=>{
        const res = await getValidTransporters(1, 50,token)
		setTransporters(res.docs)
        
    }
    const searchForChauffeur = async (q) => {
		if (q.length === 0) {
			return
		}

		setLoading(true)

		const res = await fullTextSearchChauffeur(q,token,filterStatus)
		setData(res.docs)
		setTotalRows(res.nbHits)
		setLoading(false)
	}
    const searchForChauffeurByTransporteur = async (q,page) => {
		if (q.length === 0) {
			return
		}
		setLoading(true)
		const res = await getChauffeursByTransporter(selectedTransporter,page,perPage,token,filterStatus) 
		setData(res.docs)
		setTotalRows(res.nbHits)
		setLoading(false)
	}
    const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchChauffeur(page)
	}
    const handlePerRowsChange = async (newPerPage, page) => {
		setChargementEnCours(true)
		const res = await getChauffeurs(page, newPerPage,token)
		setData(res.docs)
		setPerPage(newPerPage)
		setChargementEnCours(false)
	}

    useEffect(() => {
		fetchChauffeur(1)
		fetchTransporters()
	}, [])
    useEffect(() => {
		fetchChauffeur(1)
        fetchTransporters()
		
	}, [filterStatus])
    useEffect(()=>{
        searchForChauffeurByTransporteur(selectedTransporter,1)
    },[selectedTransporter])
    const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
		},
		{
			name: 'Nom et Prénom',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
            width:'200px'
		},
        {
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
            width:'300px'
		},
        {
			name: 'Date De Naissance',
			selector: (row) => moment(row.date_naiss).format('DD/MM/YYYY'),
			sortable: false,
            width:'200px'
            
		},
        {
			name: "Date d’embauche",
			selector: (row) => moment(row.date_embauche).format('DD/MM/YYYY'),
			sortable: false,
            width:'200px'
		},
        {
			name: "Permis",
			
			sortable: false,
            width:'250px',
            cell: (row) => (
                <span>{row.permis.join(', ')}</span>
            )
		},
        {
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
            width:'200px'
		},
		
		{
			name: 'Transporteur',
			sortable: false,
            width:'300px',
            cell: (row) => (
                <div
						onClick={() => {
							history.push(`/admin/transporters/${row.transporter._id}`)
						}}
						style={{
							cursor: 'pointer',
                            color:'#1C63C5'
						}}
				>
                {row.transporter?.societe}
                    </div>
            )
		},
		
		{
			name: 'Statut',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
            width:'200px'
		},
      
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/admin/transports/chauffeur/single/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>
				</div>
			),
		},
	]
  return (
    <LayoutAdmin>
     <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
                <GradientText marginTop={'0'}>
					{'Mes chauffeurs'.toUpperCase()}
				</GradientText>
                <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
						disabled={loading}
						value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
						onKeyDown={(e) => {
                            if (e.key === 'Enter') {
								searchForChauffeur(searchQuery)
							}
							
						}}
						placeholder='Rechercher par identifiant,nom chauffeur'
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '20rem',
							fontFamily: 'Montserrat',
						}}
					/>
                    <select
                    value={filterStatus}
                    onChange={(e) => {
                        setFilterStatus(e.target.value);
                        setSelectedTransporter('')
                    }}
                    style={{
						padding: '1rem',
						borderRadius: 5,
						border: '1px solid #E4EAF0',
						width: '15rem',
						fontFamily: 'Montserrat',
						marginLeft:'1rem'
					}}
                    >
                     <option value="all">Tous</option>
                     <option value="validated">Vérifiés</option>
                     <option value="notValidated">Non vérifiés</option>
                    </select>
                    <select
                    value={selectedTransporter}
                    onChange={(e) => {
                        setSelectedTransporter(e.target.value);
                    }}
                    style={{
						padding: '1rem',
						borderRadius: 5,
						border: '1px solid #E4EAF0',
						width: '15rem',
						fontFamily: 'Montserrat',
						marginLeft:'1rem'
					}}
                    >
                        <option value=''>Transporteur</option>
                      {transporters?.map((item,index)=>{
                        return <option value={item?._id} key={index}>{item?.societe}</option>
                      })}
                     
                    </select>
					<button
						disabled={loading}
                        onClick={() => searchForChauffeur(searchQuery)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={async () => {
							await fetchChauffeur(currentPage)
							setSearchQuery('')
                            setFilterStatus('all')
                            setSelectedTransporter('')
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
                <div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des chauffeurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
            </div>
    </LayoutAdmin>
  )
}

export default MesChauffeurs
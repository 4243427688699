import api from './base'

export const signup = async (data, files) => {
	const formData = new FormData();
  
	
	Object.keys(data).forEach(key => {
		if (typeof data[key] === 'object') {
		  formData.append(key, JSON.stringify(data[key]));
		} else {
		  formData.append(key, data[key]);
		}
	  });
  

    Object.keys(files).forEach(key => {
      if (files[key]) {
        formData.append(key, files[key]); 
      }
    });
	try {
	  const response = await api.post('/register', formData, {
		headers: {
		  'Content-Type': 'multipart/form-data'
		}
	  });
	  return response.data; 
	} catch (error) {
	  console.error('Erreur lors de l\'enregistrement:', error);
	  throw error;
	}
  };

export const CreateUser = async (data) => {
	const res = await api.post('/register', data)
	return res.data

  };
  
export const login = async (email, password) => {
	const params = new URLSearchParams()
	params.append('email', email)
	params.append('password', password)

	const res = await api.post('/login', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})

	return res.data
}
export const forgotPassword = async (email) => {
	const params = new URLSearchParams()
	params.append('email', email)
	
	const res = await api.post('/forgot', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})

	return res.data
}
export const resetPassword = async (id,password) => {
	const params = new URLSearchParams()
	params.append('id', id)
	params.append('password', password)
	
	const res = await api.post('/reset', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})

	return res.data
}

export const getUser = async (_id) => {
	const res = await api.get(`/users/${_id}`)
	return res.data
}
